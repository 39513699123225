/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Image, Text } from "../../../components/primitives";
import React, { FunctionComponent, useCallback } from "react";
import { useCampaign, useRole } from "../../../components/contexts";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { useProfiles } from "../../../components/utils";
import { ResolvedToken, Token, TokenImageMetadata, TokenTemplate } from "../../../store";
import {
    DnD5ECharacterTemplate,
    DnD5EMonsterTemplate,
    DnD5EToken,
    DnD5ETokenTemplate,
    createMonsterToken,
    getRuleKey,
} from "../common";
import {
    isCharacter,
    isMonster,
    getRaceDisplayName,
    getClassDisplayName,
    ActorType,
    Character,
    ResolvedCharacter,
} from "../creature";
import { theme } from "../../../design";
import { DraggableBox } from "../../../components/draggable";
import { nanoid } from "nanoid";
import { AnimatePresence } from "framer-motion";
import { defaultAnimate, defaultExit, defaultInitial, MotionTag } from "../../../components/motion";
import { resolveUri, tokenImageSize } from "../../../components/common";
import { MonsterTags } from "./monster/MonsterSheetContent";
import { useRules } from "./hooks";

export const CharacterTags: FunctionComponent<{
    character: Character | ResolvedCharacter;
    token: ResolvedToken<DnD5EToken> | DnD5ETokenTemplate;
}> = ({ character, token }) => {
    character = character["resolvedFrom"] ? (character as ResolvedCharacter).resolvedFrom : (character as Character);

    return (
        <Box flexDirection="row" flexWrap="wrap" justifyContent="flex-start">
            <AnimatePresence initial={false} mode="popLayout">
                {!token.owner && (
                    <MotionTag
                        key="NPC"
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mb={1}
                        mr={1}
                        bg="blues.7"
                        color="blues.0">
                        NPC
                    </MotionTag>
                )}
                {!!token.owner && (
                    <MotionTag
                        key="PC"
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mb={1}
                        mr={1}
                        bg="cyans.7"
                        color="cyans.0">
                        PC
                    </MotionTag>
                )}
                {character.race && (
                    <MotionTag
                        key={
                            "race_" +
                            getRuleKey(character.race) +
                            (character.subrace ? getRuleKey(character.subrace) : "")
                        }
                        layout
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        mb={1}
                        mr={1}
                        bg="purples.7"
                        color="purples.0">
                        {getRaceDisplayName(character.race!, character.subrace)}
                    </MotionTag>
                )}
                {character.classes &&
                    Object.values(character.classes).map(o => (
                        <MotionTag
                            key={"class_" + getRuleKey(o.class) + (o.subclass ? getRuleKey(o.subclass) : "")}
                            layout
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            mb={1}
                            mr={1}
                            bg="greens.7"
                            color="greens.0">
                            {`${getClassDisplayName(o.class, o.subclass)} (${o.level})`}
                        </MotionTag>
                    ))}
            </AnimatePresence>
        </Box>
    );
};

export const CharacterSummary: FunctionComponent<{
    thumbnailUri?: string;
    character: DnD5ECharacterTemplate;
    onDragStart?: (e: React.DragEvent) => void;
}> = ({ thumbnailUri, character, onDragStart }) => {
    const { campaign } = useCampaign();

    const { profiles } = useProfiles(character.owner ? [character.owner] : []);

    return (
        <DraggableBox
            draggableId={character.templateId}
            dragDisabled={!onDragStart}
            data={onDragStart}
            type="Token"
            dragOverlay
            fullWidth>
            <Box
                width={tokenImageSize}
                height={tokenImageSize}
                bg="grayscale.7"
                borderRadius={3}
                css={{ position: "relative" }}>
                {thumbnailUri ? (
                    <Image
                        src={resolveUri(thumbnailUri)}
                        borderRadius={3}
                        css={{ overflow: "hidden" }}
                        responsive
                        fullWidth
                        fullHeight
                        draggable={false}
                    />
                ) : (
                    ""
                )}

                {character.owner && (
                    <Box
                        css={{
                            position: "absolute",
                            right: -theme.space[1],
                            bottom: -theme.space[1],
                        }}>
                        <ProfileAvatar
                            profile={profiles[character.owner]}
                            campaignProfile={campaign.players[character.owner]}
                        />
                    </Box>
                )}
            </Box>
            <Box flex={1} ml={3} flexDirection="column" alignItems="flex-start">
                <CharacterTags character={character.dnd5e} token={character} />
                <Text color="grayscale.2" fontSize={0}>
                    {character.dnd5e.name}
                </Text>
            </Box>
        </DraggableBox>
    );
};

export const CharacterListItem: FunctionComponent<{
    character: DnD5ECharacterTemplate;
}> = ({ character }) => {
    const role = useRole();
    const onDragStart = useCallback(() => {
        const token: Omit<DnD5EToken, "pos"> = {
            id: nanoid(),
            type: "creature",
            templateId: character.templateId,
            dnd5e: { type: ActorType.Character },
        };

        return token;
    }, [character]);

    const { thumbnailUri } = getThumbnailUri(character);
    return (
        <CharacterSummary
            thumbnailUri={thumbnailUri}
            character={character}
            onDragStart={role === "GM" ? onDragStart : undefined}
        />
    );
};

export const MonsterListItem: FunctionComponent<{
    monster: DnD5EMonsterTemplate;
    dragDisabled?: boolean;
    tokenImageBg?: string;
}> = ({ monster, dragDisabled, tokenImageBg }) => {
    const { thumbnailUri } = getThumbnailUri(monster);
    const { campaign } = useCampaign();
    const rules = useRules();

    const content = (
        <React.Fragment>
            <Box
                width={tokenImageSize}
                height={tokenImageSize}
                bg={tokenImageBg ?? "grayscale.7"}
                borderRadius={3}
                css={{ overflow: "hidden" }}>
                {thumbnailUri ? (
                    <Image src={resolveUri(thumbnailUri)} responsive fullWidth fullHeight draggable={false} />
                ) : (
                    ""
                )}
            </Box>
            <Box flex={1} ml={3} flexDirection="column" alignItems="flex-start">
                <MonsterTags monster={monster.dnd5e} />
                <Text color="grayscale.2" fontSize={0}>
                    {monster.dnd5e.name}
                </Text>
            </Box>
        </React.Fragment>
    );

    return !dragDisabled ? (
        <DraggableBox
            draggableId={monster.dnd5e.name}
            dragOverlay
            data={() => {
                const token = createMonsterToken(monster, campaign, rules);
                return token;
            }}
            type="Token"
            fullWidth>
            {content}
        </DraggableBox>
    ) : (
        <Box fullWidth>{content}</Box>
    );
};

function getThumbnailUri(token: Token | TokenTemplate): {
    thumbnailUri?: string;
    metadata?: TokenImageMetadata;
} {
    let imageUri = token.imageUri;
    let imageMetadata: TokenImageMetadata | undefined;
    if (imageUri) {
        const metadata = token.images?.find(o => o.uri === imageUri);
        if (metadata) {
            imageMetadata = metadata;
        }
    } else if (token.images) {
        imageMetadata = token.images[Math.floor(Math.random() * token.images.length)];
    }

    if (imageMetadata) {
        imageUri = imageMetadata.thumbnailUri ?? imageMetadata.uri;
    }

    return { thumbnailUri: imageUri, metadata: imageMetadata };
}

export const TokenListItem: FunctionComponent<{
    token: DnD5ETokenTemplate;
}> = ({ token }) => {
    const creature = token.dnd5e;
    return (
        <React.Fragment>
            {isMonster(creature) && <MonsterListItem monster={token as DnD5EMonsterTemplate} />}
            {isCharacter(creature) && <CharacterListItem character={token as DnD5ECharacterTemplate} />}
        </React.Fragment>
    );
};
