import React, { FunctionComponent, ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { Route, Switch } from "react-router";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { Box } from "./components/primitives/Box";
import { theme } from "./design";
import { Notifications } from "./components/Notifications";
import { ThemeProvider } from "@emotion/react";

import Layout from "./components/Layout";
import Home from "./components/Home";
import { Login } from "./components/Login";
import SessionHost from "./components/sessionhost";
import { VTTStore } from "./store";
import { UserContext } from "./components/contexts";
import { Normalize } from "./design/Normalize";

import "rc-slider/assets/index.css";

import "./systems/dnd5e";
import {
    DndContext,
    DragOverlay,
    KeyboardSensor,
    MouseSensor,
    TouchSensor,
    useSensor,
    useSensors,
    Over,
} from "@dnd-kit/core";
import { DragData, DropData, getDragData } from "./components/draggable";
import { AnimatePresence } from "framer-motion";
import { defaultAnimate, defaultInitial, defaultExit, MotionMessage } from "./components/motion";
import { Point } from "./position";
import { dragStatus } from "./components/common";

const mapStateToProps = (state: VTTStore) => ({ user: state.userInfo });

const App: FunctionComponent<ReturnType<typeof mapStateToProps>> = props => {
    const location = useLocation();

    const mousePoint = useRef<Point>();
    useEffect(() => {
        document.addEventListener("pointermove", e => {
            mousePoint.current = { x: e.clientX, y: e.clientY };
        });
    }, []);

    const mouseSensor = useSensor(MouseSensor, {
        activationConstraint: {
            distance: 2,
        },
    });
    const touchSensor = useSensor(TouchSensor);
    const keyboardSensor = useSensor(KeyboardSensor);
    const sensors = useSensors(mouseSensor, touchSensor, keyboardSensor);
    const [dragOver, setDragOver] = useState<Over | null>(null);
    const [dragData, setDragData] = useState<DragData>();
    const [dropData, setDropData] = useState<DropData>();

    const renderDragOverlay = dragData?.renderDragOverlay;
    const dragComponent = useMemo(() => renderDragOverlay?.(), [renderDragOverlay]);

    const [dragFeedback, setDragFeedback] = useState<ReactNode>();

    return (
        <ThemeProvider theme={theme}>
            <UserContext.Provider value={props.user}>
                <Notifications notificationPosition="bottom">
                    <DndContext
                        sensors={sensors}
                        modifiers={dragData?.modifiers}
                        onDragStart={e => {
                            dragStatus.isDragging = true;
                            setDragData(getDragData(e.active));
                        }}
                        onDragMove={e => {
                            if (
                                dragData &&
                                !dragData.isEnded &&
                                dropData?.onDragOver &&
                                (!dropData?.accepts || dropData?.accepts?.indexOf(dragData.type) >= 0) &&
                                (!dropData.canDrop || dropData.canDrop(dragData))
                            ) {
                                dropData.onDragOver(dragData, e.active, mousePoint.current);
                                setDragFeedback(dropData?.renderFeedback?.(dragData));
                            }
                        }}
                        onDragOver={e => {
                            if (dropData?.onDragLeave && e.over?.id !== dragOver?.id) {
                                if (
                                    dragData &&
                                    (!dropData?.accepts || dropData?.accepts?.indexOf(dragData.type) >= 0) &&
                                    (!dropData.canDrop || dropData.canDrop(dragData))
                                ) {
                                    dropData.onDragLeave(dragData);
                                }
                            }

                            setDragOver(e.over);
                            const newDropData = e.over ? (e.over.data.current as DropData | undefined) : undefined;
                            setDropData(newDropData);

                            if (
                                dragData &&
                                newDropData &&
                                (!newDropData?.accepts || newDropData?.accepts?.indexOf(dragData.type) >= 0) &&
                                (!newDropData.canDrop || newDropData.canDrop(dragData))
                            ) {
                                setDragFeedback(newDropData.renderFeedback?.(dragData));
                            }
                        }}
                        onDragCancel={e => {
                            if (dropData?.onDragLeave && dragData) {
                                if (dragData) {
                                    dropData.onDragLeave(dragData);
                                }
                            }

                            setDragData(undefined);
                            setDropData(undefined);
                            setDragFeedback(undefined);
                            setTimeout(() => {
                                dragStatus.isDragging = false;
                            }, 0);
                        }}
                        onDragEnd={e => {
                            if (
                                dragData &&
                                dropData &&
                                (!dropData.accepts || dropData.accepts.indexOf(dragData.type) >= 0) &&
                                (!dropData.canDrop || dropData.canDrop(dragData))
                            ) {
                                dropData.onDrop(dragData, e.active, mousePoint.current);
                            }

                            if (dropData?.onDragLeave && dragData) {
                                dropData.onDragLeave(dragData);
                            }

                            if (dragData) {
                                dragData.isEnded = true;
                            }

                            setDragData(undefined);
                            setDropData(undefined);
                            setDragFeedback(undefined);
                            setTimeout(() => {
                                dragStatus.isDragging = false;
                            }, 0);
                        }}>
                        <Layout>
                            {!props.user.profile && <Login />}
                            {props.user.profile && (
                                <Switch location={location}>
                                    <Route exact path="/" component={Home} />
                                    <Route path="/campaign/:id" component={SessionHost} />
                                    <Route path="/login" component={Login} />
                                </Switch>
                            )}
                        </Layout>
                        <Normalize />

                        <DragOverlay dropAnimation={null}>
                            {dragData?.renderDragOverlay && (
                                <Box flexDirection="column" alignItems="stretch">
                                    {dragComponent}
                                    <AnimatePresence mode="wait">
                                        {dragData &&
                                            dragFeedback &&
                                            dropData &&
                                            (!dropData.accepts || dropData.accepts?.indexOf(dragData?.type) >= 0) && (
                                                <MotionMessage
                                                    mt={3}
                                                    variant="info"
                                                    minWidth={300}
                                                    initial={defaultInitial}
                                                    animate={defaultAnimate}
                                                    exit={defaultExit}>
                                                    {dragFeedback}
                                                </MotionMessage>
                                            )}
                                    </AnimatePresence>
                                </Box>
                            )}
                        </DragOverlay>
                    </DndContext>
                </Notifications>
            </UserContext.Provider>
        </ThemeProvider>
    );
};

export default connect(mapStateToProps)(App);
