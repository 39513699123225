/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Button } from "../../../components/Button";
import { defaultAnimate, defaultExit, defaultInitial, MotionGrid } from "../../../components/motion";
import { DiceBag } from "../../../store";
import { AnimatePresence } from "framer-motion";
import { copyState } from "../../../reducers/common";

export const DiceTools: FunctionComponent<{
    dice: DiceBag;
    setDice: (dice: DiceBag) => void;
}> = ({ dice, setDice }) => {
    const term = dice.d20?.[0];
    return (
        <AnimatePresence>
            {term &&
                (term.amount === 1 ||
                    (term.amount === 2 &&
                        (term.keep == null || term.keep === 0 || term.keep === 1 || term.keep === -1))) && (
                    <MotionGrid
                        fullWidth
                        mt={2}
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}
                        gridTemplateColumns="1fr 1fr">
                        <Button
                            toggled={term.amount === 2 && term.keep === 1}
                            mr={2}
                            onClick={() => {
                                if (term.amount === 2 && term.keep === 1) {
                                    setDice(
                                        Object.assign({}, dice, {
                                            d20: [copyState(term, { amount: 1, keep: undefined })],
                                        })
                                    );
                                } else {
                                    setDice(
                                        Object.assign({}, dice, {
                                            d20: [Object.assign({}, term, { amount: 2, keep: 1 })],
                                        })
                                    );
                                }
                            }}>
                            Advantage
                        </Button>
                        <Button
                            toggled={term.amount === 2 && term.keep === -1}
                            onClick={() => {
                                if (term.amount === 2 && term.keep === -1) {
                                    setDice(
                                        Object.assign({}, dice, {
                                            d20: [copyState(term, { amount: 1, keep: undefined })],
                                        })
                                    );
                                } else {
                                    setDice(
                                        Object.assign({}, dice, {
                                            d20: [Object.assign({}, term, { amount: 2, keep: -1 })],
                                        })
                                    );
                                }
                            }}>
                            Disadvantage
                        </Button>
                    </MotionGrid>
                )}
        </AnimatePresence>
    );
};
