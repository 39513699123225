/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useState } from "react";
import styled from "@emotion/styled";
import {
    Ability,
    advantageOrDisadvantage,
    ApplicableAbilityEffect,
    applicableEffectHasChoices,
    applicableEffectHasUnmadeChoices,
    AppliedAbilityEffectChoices,
    creatureConditions,
    DnD5ECharacterTemplate,
    DnD5ECharacterToken,
    DnD5EToken,
    DnD5ETokenTemplate,
    evaluateCharacterExpression,
    evaluateCreatureExpression,
    fromRuleKey,
    getRuleKey,
    isDnD5EToken,
    isNamedRuleRef,
    ModifiedValue,
    NamedRuleRef,
} from "../common";
import {
    AnnotationPlacementTemplate,
    Campaign,
    getModifyId,
    IMenuItem,
    isLocation,
    isToken,
    Location,
    LocationSummary,
    parseDiceBag,
    ResolvedToken,
    Token,
    TokenTemplate,
} from "../../../store";
import {
    abilityMeetsCondition,
    ArmorProficiencies,
    canPayCombatTimeCost,
    canUseCombatAbility,
    CharacterRuleSet,
    CreatureDying,
    damageTypesToMarkdown,
    Feature,
    FeatureChoice,
    getAttackModifiers,
    getCoreAbilityAbbr,
    getSpellAttackModifier,
    getSpellDc,
    getUnarmedWeapon,
    isCharacter,
    MonsterAbility,
    MonsterAbilityState,
    NamedSpellSlots,
    ResolvedAdditionalSpell,
    ResolvedAdditionalSpells,
    ResolvedCharacter,
    ResolvedCharacterResource,
    ResolvedClassLevels,
    ResolvedMonster,
    resolveModifiedValue,
    Senses,
    WeaponProficiencies,
} from "../creature";
import {
    useAppState,
    useDiceBag,
    useDispatch,
    useLocalGrid,
    useLocation,
    useLocationLevel,
} from "../../../components/contexts";
import { useRules } from "./hooks";
import { Box, Grid, Heading, Text, Image, Checkbox, Link } from "../../../components/primitives";
import { CharacterTags } from "./TokenListItem";
import { theme } from "../../../design";
import { HitPointBox } from "./HitPointBox";
import {
    CompendiumPage,
    getModifiedValueTooltip,
    renderIconForAbility,
    renderIconForWeapon,
    TooltipGrid,
    TooltipText,
    useCompendium,
    useDispatchAbility,
    useWeaponAttackOptions,
} from "./common";
import { AnimatePresence } from "framer-motion";
import {
    MotionCard,
    MotionGrid,
    MotionBox,
    sectionAnimate,
    sectionExit,
    sectionInitial,
    MotionLobotomizedBox,
    defaultInitial,
    defaultExit,
    defaultAnimate,
    MotionText,
    MotionMessage,
    MotionInOverlayCard,
    MotionHeading,
} from "../../../components/motion";
import {
    LobotomizedBox,
    SidebarButton,
    SmallText,
    resolveUri,
    tokenImageSize,
    useVttApp,
} from "../../../components/common";
import { BasicExpander } from "./BasicExpander";
import { SpellInfo } from "./SpellInfo";
import { Spell } from "../spells";
import React from "react";
import { clearTokenSpellSlot, getCastMenuItems, markInnateSpellUse, markTokenSpellSlot } from "../actions/spells";
import { FeatureExpander } from "./FeatureExpander";
import { ItemList, ItemListItem, getItemSubtitle } from "./ItemInfo";
import {
    DraggedItems,
    getItemsInInventory,
    isInventoryItem,
    isWeapon,
    ItemType,
    ResolvedInventoryItem,
    ResolvedInventoryWeapon,
    ResolvedItem,
} from "../items";
import { addItems, attuneItems, modifyCurrency } from "../actions/inventory";
import { CurrencyEditor, currencyFormatter } from "./CurrencyEditor";
import { AnyAction, Dispatch } from "redux";
import { RollButton, RollButtonHorizontal } from "./RollButton";
import { AbilityScoreBox } from "./AbilityScoreBox";
import InventoryIcon from "../../../components/icons/Inventory";
import SpellbookIcon from "../../../components/icons/Spellbook";
import CharacterSheetIcon from "../../../components/icons/CharacterSheet";
import CombatIcon from "../../../components/icons/Combat";
import BookPileIcon from "../../../components/icons/BookPile";
import { SkillsBox } from "./SkillsBox";
import { Markdown } from "../../../components/markdown";
import { CharacterSavingThrowBox } from "./character/CharacterSavingThrowBox";
import { ILocalGrid } from "../../../grid";
import { ConditionEditor } from "./ConditionEditor";
import { Badge } from "../../../components/Badge";
import { Button } from "../../../components/Button";
import {
    longRest,
    shortRest,
    clearResource,
    markResource,
    markAbilityUse,
    clearAbilityUse,
    setMultiattackOption,
    markDeathSavingThrow,
    clearDeathSavingThrow,
    applyDeathSave,
    applyRechargeRoll,
    rechargeAbility,
} from "../actions/token";
import { Event, KeyedListItem, keyedListToKeyArray, mapKeyedList } from "../../../common";
import { Annotation } from "../../../annotations";
import { HitDiceUsage } from "./HitDieUsage";
import { getAnnotationTemplate } from "../abilities";
import { AdvantageBox } from "./AdvantageBox";
import { AppliedAbilityEffectItem } from "./AppliedAbilityEffectItem";
import { startsWithVowel } from "../../../components/utils";
import { AppliedAbilityEffectChoicesEditor } from "./AppliedAbilityEffectChoicesEditor";
import { ScrollableTest } from "../../../components/ScrollableTest";
import { CombatResources } from "./CombatResources";
import { usePopper } from "react-popper";
import { ListBox } from "../../../components/ListBox";
import { MultiattackOption } from "./MultiattackOption";
import SkullIcon from "../../../components/icons/Skull";
import { getCreatureEditor } from "./CreatureEditor";
import { dragDropPalette, useTypedDroppable, useTypedDroppableArea } from "../../../components/draggable";
import { MonsterSheetContent } from "./monster/MonsterSheetContent";
import create from "zustand";

const ColorBox = styled(Box)`
    color: inherit;
`;

//     border: 1px solid ${props => props.theme.colors.transparency[2].grayscale[6]};
const ShieldBox = styled.div`
    position: relative;
    border-radius: 0 0 70% 70%;
    display: inline-block;
    background: ${props => props.theme.colors.transparency[1].background};

    &::before,
    &::after {
        z-index: -1;
        position: absolute;
        margin-top: 50%;
        content: "";
        left: 50%;
        top: 0;
        width: 50%;
        height: 53%;
        border-radius: 70% 70% 0 0;
        -webkit-transform: rotate(-61.5deg);
        -moz-transform: rotate(-61.5deg);
        -ms-transform: rotate(-61.5deg);
        -o-transform: rotate(-61.5deg);
        transform: rotate(-61.5deg);
        -webkit-transform-origin: 0 100%;
        -moz-transform-origin: 0 100%;
        -ms-transform-origin: 0 100%;
        -o-transform-origin: 0 100%;
        transform-origin: 0 100%;
    }

    &::after {
        left: 0;
        -webkit-transform: rotate(61.5deg);
        -moz-transform: rotate(61.5deg);
        -ms-transform: rotate(61.5deg);
        -o-transform: rotate(61.5deg);
        transform: rotate(61.5deg);
        -webkit-transform-origin: 100% 100%;
        -moz-transform-origin: 100% 100%;
        -ms-transform-origin: 100% 100%;
        -o-transform-origin: 100% 100%;
        transform-origin: 100% 100%;
    }
`;

const ArmorClassBox: FunctionComponent<{ ac: ModifiedValue }> = ({ ac }) => {
    return (
        <TooltipGrid tooltip={getModifiedValueTooltip(ac)} tooltipAlignment="end" mb={2}>
            <ShieldBox>
                <Box flexDirection="column" mt={2} ml={3} mr={3} mb={3} minWidth={theme.space[7]}>
                    <Text fontSize={0} color="grayscale.2">
                        ARMOR
                    </Text>
                    <Text fontSize={6}>{resolveModifiedValue(ac)}</Text>
                    <Text fontSize={0} color="grayscale.2">
                        CLASS
                    </Text>
                </Box>
            </ShieldBox>
        </TooltipGrid>
    );
};

const FeatureInfo: FunctionComponent<{
    feature: Feature;
    choices?: FeatureChoice;
}> = ({ feature, choices }) => {
    // TODO: Show info about the actual choices made here too.
    return (
        <FeatureExpander isInOverlay key={getRuleKey(feature)} title={feature.name}>
            <Markdown>{feature.content}</Markdown>
        </FeatureExpander>
    );
};

const FeaturesPage: FunctionComponent<{ character: ResolvedCharacter }> = ({ character }) => {
    return (
        <React.Fragment>
            <BasicExpander title="Class features" defaultIsExpanded>
                <LobotomizedBox flexDirection="column" py={2}>
                    {character.classFeatures.map(o => (
                        <FeatureInfo key={getRuleKey(o.feature)} feature={o.feature} choices={o.choices} />
                    ))}
                </LobotomizedBox>
            </BasicExpander>
            <BasicExpander title="Racial traits" defaultIsExpanded>
                <LobotomizedBox flexDirection="column" py={2}>
                    {character.racialTraits.map(o => (
                        <FeatureInfo key={getRuleKey(o.feature)} feature={o.feature} choices={o.choices} />
                    ))}
                </LobotomizedBox>
            </BasicExpander>
        </React.Fragment>
    );
};

const EquipmentPage: FunctionComponent<{
    character: ResolvedCharacter;
    token: DnD5ECharacterToken | DnD5ECharacterTemplate;
}> = ({ character, token }) => {
    const { system, campaign, location } = useLocation();
    const dispatch = useDispatch();

    const { searchPropertiesSection, setSearchPropertiesSection, searchPropertiesSections } = useAppState();
    const { isSearchExpanded, setIsSearchExpanded } = useVttApp();
    const { page, setPage } = useCompendium();

    const { isOver, active } = useTypedDroppableArea({
        id: "DnD5E_inventory_" + getModifyId(token),
        accepts: ["DnD5E_Item"],
        onDrop: drag => {
            if (isLocation(location)) {
                const items = drag.data as DraggedItems;
                dispatch(addItems(campaign, location, [], { items: items.items, from: items.token, to: token }));
            }
        },
        renderFeedback: drag => {
            const item = drag.data as DraggedItems;
            return (
                <React.Fragment>
                    Drop to add {item.items.length === 1 ? item.items[0].name : `${item.items.length} items`} to{" "}
                    {system.getDisplayName(token, campaign)}'s inventory
                </React.Fragment>
            );
        },
    });

    const attunedDroppable = useTypedDroppable({
        id: "DnD5E_inventory_attuned_" + getModifyId(token),
        accepts: ["DnD5E_Item"],
        canDrop: drag => {
            const draggedItems = drag.data as DraggedItems;
            return draggedItems.items.some(o => {
                let isItemInInventory = isInventoryItem(o) && o.id && character.inventory[o.id];
                let isAttuned = isInventoryItem(o) && o.attuned;
                return o.requiresAttunement && ((isItemInInventory && !isAttuned) || !isItemInInventory);
            });
        },
        onDrop: drag => {
            if (isLocation(location)) {
                const draggedItems = drag.data as DraggedItems;

                const itemsToAdd: ResolvedItem[] = [];
                const itemsInInventory: ResolvedInventoryItem[] = [];
                for (let item of draggedItems.items) {
                    let isItemInInventory = isInventoryItem(item) && item.id && character.inventory[item.id];
                    if (isItemInInventory) {
                        itemsInInventory.push(item as ResolvedInventoryItem);
                    } else {
                        itemsToAdd.push(item);
                    }
                }

                let addedItemIds: string[] | undefined;
                if (itemsToAdd.length) {
                    const itemInfo: {
                        items: (number | ResolvedItem)[];
                        inventoryKeys?: string[];
                        from?: Token | TokenTemplate;
                        to?: Token | TokenTemplate;
                    } = {
                        items: itemsToAdd,
                        from: draggedItems.token,
                        to: token,
                    };
                    dispatch(addItems(campaign, location, [], itemInfo));
                    addedItemIds = itemInfo.inventoryKeys;
                }

                const keysToAttune = itemsInInventory.map(o => o.id);
                if (addedItemIds) {
                    keysToAttune.push(...addedItemIds);
                }

                if (keysToAttune.length) {
                    dispatch(attuneItems(campaign, location, [token], { ids: keysToAttune, attuned: true }));
                }
            }
        },
        renderFeedback: drag => {
            // TODO: The feedback depends on whether the item is already in the players inventory.
            const item = drag.data as ResolvedItem;
            let isItemInInventory = isInventoryItem(item) && item.id && character.inventory[item.id];
            if (isItemInInventory) {
                return <React.Fragment>Drop to add to attune {item.name}</React.Fragment>;
            }

            return (
                <React.Fragment>
                    Drop to add {item.name} to {system.getDisplayName(token, campaign)}'s inventory and attune it
                </React.Fragment>
            );
        },
    });

    return (
        <React.Fragment>
            <MotionInOverlayCard
                layout
                p={2}
                mb={3}
                flexDirection="column"
                borderRadius={3}
                boxShadowSize="none"
                alignSelf="stretch">
                <Text textAlign="center" fontSize={1}>
                    CURRENCY ({currencyFormatter.format(character.currency.total)}GP TOTAL)
                </Text>
                <CurrencyEditor
                    mt={2}
                    character={character}
                    onChange={delta => dispatch(modifyCurrency(campaign, location, [token], delta))}
                />
            </MotionInOverlayCard>
            <MotionInOverlayCard
                layout
                p={2}
                ref={attunedDroppable.setNodeRef}
                flexDirection="column"
                alignSelf="stretch"
                mb={3}
                borderRadius={3}
                boxShadowSize="none"
                animate={{
                    background: attunedDroppable.active
                        ? attunedDroppable.isOver
                            ? dragDropPalette[1]
                            : dragDropPalette[0]
                        : theme.colors.transparency[2].background,
                }}>
                <MotionBox layout="position">
                    <Text textAlign="center" fontSize={1}>
                        ATTUNED ITEMS
                    </Text>
                </MotionBox>
                <LobotomizedBox flexDirection="column" mt={2}>
                    <AnimatePresence mode="popLayout">
                        {!character.attunedItems.length && (
                            <MotionBox
                                fullWidth
                                layout="position"
                                key="none"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <Text>No items are currently attuned.</Text>
                            </MotionBox>
                        )}
                        {character.attunedItems.map(o => (
                            <MotionBox
                                fullWidth
                                key={o.id}
                                layout="position"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <Markdown>{`:item{id="${getRuleKey(o)}"}`}</Markdown>
                            </MotionBox>
                        ))}
                    </AnimatePresence>
                </LobotomizedBox>
            </MotionInOverlayCard>
            <MotionBox
                layout
                m={-2}
                p={2}
                width={`calc(100% + ${theme.space[2] * 2}px)`}
                borderRadius={3}
                css={{ userSelect: "none" }}
                flexDirection="column"
                animate={{
                    background: active
                        ? isOver
                            ? dragDropPalette[1]
                            : dragDropPalette[0]
                        : theme.colors.transparency[5].grayscale[9],
                }}>
                <ItemList
                    token={token}
                    items={getItemsInInventory(character.inventory)}
                    multiSelect
                    character={character}
                    popupPlacement="left"
                    popupOffset={theme.space[5] + theme.space[2] + theme.space[2]}
                />
                <Link
                    disabled={
                        isSearchExpanded && page === CompendiumPage.Items && searchPropertiesSection.id === "dnd5e"
                    }
                    css={{
                        alignSelf: "flex-start",
                    }}
                    onClick={() => {
                        const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                        if (searchPage) {
                            setSearchPropertiesSection(searchPage);
                            setPage(CompendiumPage.Items);
                            setIsSearchExpanded(true);
                        }
                    }}>
                    Find more items…
                </Link>
            </MotionBox>
        </React.Fragment>
    );
};

export const CreatureAbility: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    creature: ResolvedCharacter | ResolvedMonster;
    abilityKey: string;
    action?: Ability & NamedRuleRef;
}> = ({ token, creature, abilityKey, action }) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();
    const rules = useRules();
    const [choices, setChoices] = useState<{
        [effectKey: string]: AppliedAbilityEffectChoices;
    }>({});
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
        modifiers: [
            { name: "offset", options: { offset: [0, theme.space[2]] } },
            { name: "preventOverflow", options: { padding: theme.space[3] } },
        ],
    });
    const { setDice } = useDiceBag();

    let ability: KeyedListItem<Ability & { feature?: Feature }> | undefined =
        action ?? creature.abilities?.[abilityKey];

    //    const items = useWeaponAttackOptions(token, creature, ability, abilityKey, rules);
    const { doWeaponAttack, items } = useWeaponAttackOptions(token, creature, ability, abilityKey, rules);
    const hasWeaponChoice = items != null && doWeaponAttack != null;

    const multiattackOptions = (ability as MonsterAbility).attackOptions
        ? mapKeyedList((ability as MonsterAbility).attackOptions, (o, i, k) => ({
              key: k,
              parts: o,
          }))
        : undefined;
    const hasMultiattackChoice = (multiattackOptions?.length ?? 0) > 1;

    const dispatchAbility = useDispatchAbility(token, creature);

    if (!ability) {
        return <React.Fragment></React.Fragment>;
    }

    const effectKeys = keyedListToKeyArray(ability.effects);
    const hasChoices =
        !ability.aoe &&
        !ability.attack &&
        effectKeys?.some(o => applicableEffectHasChoices(ability!.effects![o].applied as any, rules));
    const hasUnmadeChoices =
        hasChoices &&
        effectKeys?.some(o => applicableEffectHasUnmadeChoices(ability!.effects![o].applied as any, choices[o], rules));
    const maxUses = evaluateCreatureExpression(creature, ability?.maxUsesExpr, ability?.maxUses);
    const abilityUsage = creature.abilityUsage?.[abilityKey];
    let currentUses = abilityUsage?.used ?? 0;

    let renderUsageSlot = (i: number) => {
        const key = `${isToken(token) ? token.id : token.templateId}_${abilityKey}_${i}`;
        return (
            <Checkbox
                key={key}
                id={key}
                mr={1}
                checked={i < currentUses}
                disabled={!isLocation(location)}
                onChange={e => {
                    if (e.target.checked) {
                        dispatch(markAbilityUse(campaign, location!, [token], abilityKey ?? getRuleKey(ability!)));
                    } else {
                        dispatch(clearAbilityUse(campaign, location!, [token], abilityKey ?? getRuleKey(ability!)));
                    }
                }}
            />
        );
    };
    let abilityUses: JSX.Element[] | undefined;
    if (maxUses != null) {
        abilityUses = [];
        for (let i = 0; i < maxUses; i++) {
            abilityUses.push(renderUsageSlot(i));
        }
    }

    const icon = renderIconForAbility(ability, creature, rules, 20);

    const isRecharging = !!(ability as MonsterAbility & MonsterAbilityState)?.isRecharging;
    const hasExtraContent = isRecharging;

    return (
        <MotionBox
            layout="position"
            flexDirection="column"
            fullWidth
            alignItems="flex-start"
            bg="grayscale.9"
            borderRadius={3}>
            <Box fullWidth justifyContent="flex-start" pr={2} minHeight={theme.space[4]}>
                <Button
                    size="s"
                    mr={2}
                    ref={hasWeaponChoice || hasMultiattackChoice ? setReferenceElement : undefined}
                    disabled={!canUseCombatAbility(location, token, creature, ability, abilityKey) || hasUnmadeChoices}
                    onClick={() => {
                        if ((items && items.length > 1) || hasMultiattackChoice) {
                            setIsPopupOpen(!isPopupOpen);
                        } else if (items && items.length === 1) {
                            doWeaponAttack!(items[0]);
                        } else {
                            dispatchAbility?.(ability!, abilityKey, choices);
                        }
                    }}
                    css={{
                        borderTopRightRadius: 0,
                        borderBottomRightRadius: 0,
                        borderBottomLeftRadius: hasExtraContent ? 0 : undefined,
                        height: "auto",
                        minHeight: theme.space[4],
                        alignSelf: "stretch",
                    }}>
                    {icon.icon}
                </Button>
                <AnimatePresence>
                    {isPopupOpen && (
                        <Box ref={setPopperElement} {...attributes.popper} style={styles.popper}>
                            <MotionCard
                                p={3}
                                layout="size"
                                maxWidth={600}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}
                                flexDirection="column"
                                bg="grayscale.9"
                                borderRadius={3}>
                                {hasWeaponChoice && (
                                    <ListBox
                                        flexDirection="column"
                                        alignItems="stretch"
                                        items={items!}
                                        itemKey={item => {
                                            return item.id + (item.handed ?? "");
                                        }}
                                        onSelectionChanged={selection => {
                                            if (selection?.length) {
                                                doWeaponAttack!(selection[0]);
                                                setIsPopupOpen(false);
                                            }
                                        }}>
                                        {({ item }) => (
                                            <ItemListItem
                                                key={item.id}
                                                item={item}
                                                canSelect
                                                isDynamicWidth
                                                character={isCharacter(creature) ? creature : undefined}
                                            />
                                        )}
                                    </ListBox>
                                )}
                                {multiattackOptions != null && (
                                    <ListBox
                                        flexDirection="column"
                                        alignItems="stretch"
                                        items={multiattackOptions}
                                        itemKey={item => {
                                            return item.key;
                                        }}
                                        onSelectionChanged={items => {
                                            if (items?.length) {
                                                dispatch(
                                                    setMultiattackOption(
                                                        campaign,
                                                        location,
                                                        token,
                                                        abilityKey,
                                                        items[0].key
                                                    )
                                                );
                                                setIsPopupOpen(false);
                                            }
                                        }}>
                                        {({ item }) => <MultiattackOption option={item} />}
                                    </ListBox>
                                )}
                            </MotionCard>
                        </Box>
                    )}
                </AnimatePresence>
                <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
                    {action && <Markdown>{`:action[${action.name}]{id="${getRuleKey(action)}"}`}</Markdown>}
                    {ability.feature && (
                        <Markdown>{`:feature[${ability.name}]{id="${getRuleKey(ability.feature)}"}`}</Markdown>
                    )}
                    {!ability.feature && !action && <Markdown>{ability.name}</Markdown>}
                    {abilityUses && <Box my={1}>{abilityUses}</Box>}

                    {hasChoices && (
                        <Box flexDirection="column" py={1}>
                            {mapKeyedList(ability.effects, (o, i, k) => {
                                const applicableEffect = isNamedRuleRef(o.applied)
                                    ? rules.effects.get(o.applied)
                                    : (o.applied as ApplicableAbilityEffect);
                                if (!applicableEffect) {
                                    return undefined;
                                }

                                return (
                                    <AppliedAbilityEffectChoicesEditor
                                        key={k}
                                        applicableEffect={applicableEffect}
                                        choices={choices[k]}
                                        onChoicesUpdated={delta => {
                                            const newEffectChoices = Object.assign({}, choices[k], delta);
                                            const newChoices = Object.assign({}, choices, {
                                                [k]: newEffectChoices,
                                            });
                                            setChoices(newChoices);
                                        }}
                                    />
                                );
                            })}
                        </Box>
                    )}
                </Box>
            </Box>
            {isRecharging && (
                <Box p={2}>
                    This ability needs to recharge
                    {isDnD5EToken(token) && isLocation(location) && (
                        <Button
                            ml={2}
                            size="s"
                            onClick={() => {
                                setDice({
                                    d6: [{ amount: 1 }],
                                    options: {
                                        location: location.id,
                                        token: token.id,
                                        data: {
                                            abilityKey: abilityKey,
                                            rechargeOn: (ability as MonsterAbility).rechargeOn,
                                        },
                                    },
                                    onRolled: async ({ confirmed }) => {
                                        const result = await confirmed;
                                        dispatch(
                                            applyRechargeRoll(
                                                campaign,
                                                location,
                                                token,
                                                abilityKey,
                                                ability as MonsterAbility,
                                                result
                                            )
                                        );
                                    },
                                });
                            }}>
                            Roll
                        </Button>
                    )}
                    <Button
                        ml={2}
                        size="s"
                        onClick={() => {
                            dispatch(rechargeAbility(campaign, location, token, abilityKey));
                        }}>
                        Recharge
                    </Button>
                </Box>
            )}
        </MotionBox>
    );
};

const CharacterAbilities: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    character: ResolvedCharacter;
    abilityKeys: string[];
}> = ({ token, character, abilityKeys }) => {
    return (
        <React.Fragment>
            {abilityKeys.map(o => (
                <CreatureAbility token={token} creature={character} abilityKey={o} key={o} />
            ))}
        </React.Fragment>
    );
};

const CommonAbilities: FunctionComponent<{
    token: DnD5EToken | DnD5ETokenTemplate;
    character: ResolvedCharacter;
    abilities: (Ability & NamedRuleRef)[];
}> = ({ token, character, abilities }) => {
    return (
        <React.Fragment>
            {abilities.map(o => {
                const key = getRuleKey(o);
                return <CreatureAbility token={token} creature={character} action={o} abilityKey={key} key={key} />;
            })}
        </React.Fragment>
    );
};

const ActionsPage: FunctionComponent<{
    character: ResolvedCharacter;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ character, token }) => {
    const { campaign, location, levelKey } = useLocationLevel();
    const { actions } = useRules();
    const { setDice } = useDiceBag();
    const grid = useLocalGrid();
    const dispatch = useDispatch();
    const rules = useRules();
    const annotationPlacementTemplateChanged = useVttApp(state => state.annotationPlacementTemplateChanged);

    const activeWeapons = getItemsInInventory(character.inventory).filter(
        o => o.active && isWeapon(o)
    ) as KeyedListItem<ResolvedInventoryWeapon & ResolvedInventoryItem>[];

    const unarmedWeapon = getUnarmedWeapon(character, rules);
    activeWeapons.push(unarmedWeapon);

    const actionToString = o => `:action[${getRuleKey({ name: o.name, source: o.source })}]`;

    const actionsList = actions.all.filter(
        o => o.time?.amount === 1 && o.time?.unit === "action" && abilityMeetsCondition(character, o, rules)
    );
    const bonusActionsList = actions.all.filter(
        o => o.time?.amount === 1 && o.time?.unit === "bonus" && abilityMeetsCondition(character, o, rules)
    );
    const reactionsList = actions.all.filter(
        o => o.time?.amount === 1 && o.time?.unit === "reaction" && abilityMeetsCondition(character, o, rules)
    );
    const othersList = actions.all.filter(
        o =>
            !(
                o.time?.amount === 1 &&
                (o.time?.unit === "action" || o.time?.unit === "bonus" || o.time?.unit === "reaction")
            ) && abilityMeetsCondition(character, o, rules)
    );

    const actionsTextEntry = actionsList
        .filter(o => !o.effects && !o.weaponAttack)
        .map(actionToString)
        .join(", ");
    const bonusesTextEntry = bonusActionsList
        .filter(o => !o.effects && !o.weaponAttack)
        .map(actionToString)
        .join(", ");
    const reactionsTextEntry = reactionsList
        .filter(o => !o.effects && !o.weaponAttack)
        .map(actionToString)
        .join(", ");
    const othersTextEntry = othersList
        .filter(o => !o.effects && !o.weaponAttack)
        .map(o => `:action[${getRuleKey({ name: o.name, source: o.source })}]`)
        .join(", ");

    const commonActionAbilities = actionsList.filter(o => o.effects || o.weaponAttack);
    const commonBonusActionAbilities = bonusActionsList.filter(o => o.effects || o.weaponAttack);
    const commonReactionAbilities = reactionsList.filter(o => o.effects || o.weaponAttack);
    const commonOtherAbilities = othersList.filter(o => o.effects || o.weaponAttack);

    const nonVersatileCss = {
        ">div": {
            flexGrow: 1,
            width: "100%",
            ">button": { height: "100%" },
        },
        ">button": { height: "100%" },
    };

    let allAbilityKeys = keyedListToKeyArray(character.abilities);
    let actionAbilities = allAbilityKeys?.filter(
        o =>
            character.abilities![o].time?.unit === "action" &&
            abilityMeetsCondition(character, character.abilities![o], rules)
    );
    let bonusAbilities = allAbilityKeys?.filter(
        o =>
            character.abilities![o].time?.unit === "bonus" &&
            abilityMeetsCondition(character, character.abilities![o], rules)
    );
    let reactionAbilities = allAbilityKeys?.filter(
        o =>
            character.abilities![o].time?.unit === "reaction" &&
            abilityMeetsCondition(character, character.abilities![o], rules)
    );
    let otherAbilities = allAbilityKeys?.filter(o => {
        const unit = character.abilities![o].time?.unit;
        return (
            unit !== "action" &&
            unit !== "bonus" &&
            unit !== "reaction" &&
            abilityMeetsCondition(character, character.abilities![o], rules)
        );
    });

    let renderResourceSlot = (resource: ResolvedCharacterResource, i: number) => {
        const key = `${isToken(token) ? token.id : token.templateId}_${resource.name}_${i}`;
        return (
            <Checkbox
                key={key}
                id={key}
                checked={i < resource.used}
                disabled={!isLocation(location)}
                onChange={e => {
                    if (e.target.checked) {
                        dispatch(markResource(campaign, location!, [token], resource.name));
                    } else {
                        dispatch(clearResource(campaign, location!, [token], resource.name));
                    }
                }}
            />
        );
    };

    const isInCombat =
        isLocation(location) && location.combat && isToken(token) && !!location.combat.participants[token.id];
    const resources = character.resources ? Object.values(character.resources) : undefined;
    return (
        <Box fullWidth flexDirection="column">
            <Box fullWidth flexDirection="column" alignItems="flex-start">
                <AnimatePresence initial={false}>
                    {((resources != null && resources.length) || isInCombat) && (
                        <MotionBox
                            layout="position"
                            mb={3}
                            flexDirection="column"
                            alignItems="flex-start"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Heading as="h6">RESOURCES</Heading>
                            {isInCombat && (
                                <Box flexDirection="row" mb={2}>
                                    <CombatResources token={token as DnD5EToken} creature={character} />
                                </Box>
                            )}
                            {resources?.map(o => {
                                const resourceSlotElements: JSX.Element[] = [];
                                for (let i = 0; i < o.max; i++) {
                                    resourceSlotElements.push(renderResourceSlot(o, i));
                                }

                                return (
                                    <React.Fragment key={o.name}>
                                        <Text>{o.name}</Text>
                                        <Box
                                            css={{
                                                flexDirection: "row",
                                                flexWrap: "wrap",
                                                justifyContent: "flex-start",
                                                gap: theme.space[1],
                                            }}>
                                            {resourceSlotElements}
                                        </Box>
                                    </React.Fragment>
                                );
                            })}
                        </MotionBox>
                    )}
                </AnimatePresence>
                <Box flexDirection="column" alignItems="flex-start">
                    <MotionHeading as="h6" layout="position" mt={resources != null && resources.length ? 3 : undefined}>
                        ACTIONS
                    </MotionHeading>
                    <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                        <MotionGrid
                            layout="position"
                            fullWidth
                            gridTemplateColumns="max-content minmax(0, 1fr) max-content max-content"
                            gridAutoRows="min-content"
                            css={{
                                columnGap: 0,
                                justifyItems: "stretch",
                                alignItems: "stretch",
                            }}>
                            <Text fontSize={3}>Weapon</Text>
                            <Text fontSize={3} textAlign="center">
                                Range
                            </Text>
                            <Text fontSize={3}>Hit</Text>
                            <Text fontSize={3}>Damage</Text>
                            {activeWeapons.map((item, i) => {
                                const damageBonus = resolveModifiedValue(item.damageBonus);
                                const hitBonus = resolveModifiedValue(item.hitBonus);

                                const attackModifiers = getAttackModifiers(character, undefined, item);

                                const isTwoHanded = item.properties?.some(o => o.abbreviation === "2H");
                                const baseDamage = (isTwoHanded ? item.dmg2h : item.dmg1h) ?? "0";
                                const damageRoll =
                                    damageBonus !== 0
                                        ? `${baseDamage}${damageBonus > 0 ? "+" : ""}${damageBonus}`
                                        : baseDamage;

                                const isVersatile = item.properties?.some(o => o.abbreviation === "V");
                                const versatileDamageRoll = isVersatile
                                    ? damageBonus !== 0
                                        ? `${item.dmg2h}${damageBonus > 0 ? "+" : ""}${damageBonus}`
                                        : item.dmg2h
                                    : undefined;

                                const criticalRange =
                                    character.criticalRange?.[item.type === ItemType.Melee ? "mw" : "rw"];

                                const attacksMade = character.combatTurn?.attacks
                                    ? Object.values(character.combatTurn.attacks).reduce((p, c) => p + c, 0)
                                    : 0;
                                const attacksRemaining = character.attacks - attacksMade;
                                return (
                                    <React.Fragment key={i}>
                                        <Text
                                            css={{
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                gridColumn: "span 4 / span 4",
                                            }}>
                                            {item.name}
                                        </Text>
                                        {!isVersatile && (
                                            <Button
                                                size="s"
                                                disabled={
                                                    !isLocation(location) ||
                                                    !character.canTakeActions ||
                                                    !isToken(token) ||
                                                    (location.combat?.participants?.[token.id] &&
                                                        character.combatTurn?.action &&
                                                        (character.combatTurn?.attacks == null ||
                                                            attacksRemaining === 0))
                                                }
                                                css={{
                                                    borderBottomRightRadius: 0,
                                                    borderTopRightRadius: 0,
                                                    height: "100%",
                                                }}
                                                onClick={() => {
                                                    const template = getAnnotationTemplate(
                                                        location as Location,
                                                        levelKey!,
                                                        character,
                                                        token as DnD5EToken,
                                                        grid,
                                                        item,
                                                        attacksRemaining,
                                                        criticalRange,
                                                        undefined,
                                                        character.ignoreLongRangePenalty,
                                                        true
                                                    );
                                                    annotationPlacementTemplateChanged.trigger(template);
                                                }}>
                                                {renderIconForWeapon(item, 20)}
                                            </Button>
                                        )}
                                        {isVersatile && (
                                            <Box flexDirection="column" fullHeight>
                                                <Button
                                                    size="s"
                                                    disabled={
                                                        !isLocation(location) ||
                                                        !character.canTakeActions ||
                                                        !isToken(token) ||
                                                        (location.combat?.participants?.[token.id] &&
                                                            character.combatTurn?.action &&
                                                            (character.combatTurn?.attacks == null ||
                                                                attacksRemaining === 0))
                                                    }
                                                    css={{
                                                        flexGrow: 1,
                                                        borderBottomLeftRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                        borderTopRightRadius: 0,
                                                    }}
                                                    fullWidth
                                                    onClick={() => {
                                                        const template = getAnnotationTemplate(
                                                            location as Location,
                                                            levelKey!,
                                                            character,
                                                            token as DnD5EToken,
                                                            grid,
                                                            item,
                                                            attacksRemaining,
                                                            criticalRange,
                                                            1,
                                                            character.ignoreLongRangePenalty,
                                                            true
                                                        );
                                                        annotationPlacementTemplateChanged.trigger(template);
                                                    }}>
                                                    {renderIconForWeapon(item, 20)}
                                                    <Badge bg="grayscale.7">1H</Badge>
                                                </Button>
                                                <Button
                                                    size="s"
                                                    disabled={
                                                        !isLocation(location) ||
                                                        !character.canTakeActions ||
                                                        !isToken(token) ||
                                                        (location.combat?.participants?.[token.id] &&
                                                            character.combatTurn?.action &&
                                                            (character.combatTurn?.attacks == null ||
                                                                attacksRemaining === 0))
                                                    }
                                                    css={{
                                                        flexGrow: 1,
                                                        borderTopLeftRadius: 0,
                                                        borderTopRightRadius: 0,
                                                        borderBottomRightRadius: 0,
                                                    }}
                                                    fullWidth
                                                    onClick={() => {
                                                        const template = getAnnotationTemplate(
                                                            location as Location,
                                                            levelKey!,
                                                            character,
                                                            token as DnD5EToken,
                                                            grid,
                                                            item,
                                                            attacksRemaining,
                                                            criticalRange,
                                                            2,
                                                            character.ignoreLongRangePenalty,
                                                            true
                                                        );
                                                        annotationPlacementTemplateChanged.trigger(template);
                                                    }}>
                                                    {renderIconForWeapon(item, 20)}
                                                    <Badge bg="grayscale.7">2H</Badge>
                                                </Button>
                                            </Box>
                                        )}
                                        {item.range && (
                                            <Box bg="grayscale.9">
                                                <Text>{item.range.near}ft.</Text>
                                                <SmallText> ({item.range.far}ft.)</SmallText>
                                            </Box>
                                        )}
                                        {!item.range && (
                                            <Box bg="grayscale.9">
                                                <Text>
                                                    {item.properties &&
                                                    item.properties.find(o => o.abbreviation === "R")
                                                        ? "10ft."
                                                        : "5ft."}
                                                </Text>
                                            </Box>
                                        )}
                                        <RollButtonHorizontal
                                            size="s"
                                            css2={{ height: "100%", borderRadius: 0 }}
                                            tooltip={getModifiedValueTooltip(item.hitBonus, attackModifiers)}
                                            modifier={hitBonus}
                                            extraRolls={attackModifiers.extraRolls}
                                            advantage={attackModifiers.advantage}
                                            token={token}
                                            data={{ attack: item.type }}>
                                            <Text fontSize={2}>{hitBonus >= 0 ? "+" + hitBonus : hitBonus}</Text>
                                        </RollButtonHorizontal>
                                        <Box
                                            flexDirection="column"
                                            alignItems="stretch"
                                            fullHeight
                                            css={versatileDamageRoll ? undefined : nonVersatileCss}>
                                            <Button
                                                size="s"
                                                fullWidth
                                                disabled={!isLocation(location)}
                                                css={
                                                    isVersatile
                                                        ? {
                                                              borderBottomLeftRadius: 0,
                                                              borderBottomRightRadius: 0,
                                                              borderTopLeftRadius: 0,
                                                          }
                                                        : {
                                                              borderTopLeftRadius: 0,
                                                              borderBottomLeftRadius: 0,
                                                          }
                                                }
                                                tooltip={getModifiedValueTooltip(item.damageBonus)}
                                                onClick={() => {
                                                    setDice(
                                                        parseDiceBag(damageRoll, {
                                                            location: location!.id,
                                                            token: isToken(token) ? token.id : token.templateId,
                                                            data: { dmgType: item.dmgType },
                                                        })
                                                    );
                                                }}>
                                                <Text fontSize={2}>{damageRoll}</Text>
                                                {versatileDamageRoll && <SmallText> (1H)</SmallText>}
                                            </Button>
                                            {versatileDamageRoll && (
                                                <Button
                                                    size="s"
                                                    fullWidth
                                                    disabled={!isLocation(location)}
                                                    css={{
                                                        borderTopLeftRadius: 0,
                                                        borderTopRightRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                    }}
                                                    tooltip={getModifiedValueTooltip(item.damageBonus)}
                                                    onClick={() => {
                                                        setDice(
                                                            parseDiceBag(versatileDamageRoll, {
                                                                location: location!.id,
                                                                token: isToken(token) ? token.id : token.templateId,
                                                                data: { dmgType: item.dmgType },
                                                            })
                                                        );
                                                    }}>
                                                    <Text fontSize={2}>{versatileDamageRoll}</Text>
                                                    <SmallText> (2H)</SmallText>
                                                </Button>
                                            )}
                                        </Box>
                                        <SmallText css={{ gridColumn: "span 4 / span 4" }} mb={3}>
                                            {getItemSubtitle(item)}
                                        </SmallText>
                                    </React.Fragment>
                                );
                            })}
                        </MotionGrid>
                        <Box
                            fullWidth
                            flexDirection="column"
                            alignItems="flex-start"
                            css={{ gap: theme.space[2] }}
                            mb={actionsTextEntry ? 2 : undefined}>
                            {commonActionAbilities != null && commonActionAbilities.length > 0 && (
                                <CommonAbilities
                                    token={token}
                                    character={character}
                                    abilities={commonActionAbilities}
                                />
                            )}
                            {actionAbilities != null && actionAbilities.length > 0 && (
                                <CharacterAbilities token={token} character={character} abilityKeys={actionAbilities} />
                            )}
                        </Box>
                        {actionsTextEntry && <Markdown>{actionsTextEntry}</Markdown>}
                    </MotionInOverlayCard>
                </Box>
            </Box>

            {((bonusAbilities && bonusAbilities.length > 0) ||
                (commonBonusActionAbilities != null && commonBonusActionAbilities.length > 0) ||
                bonusesTextEntry) && (
                <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                    <MotionHeading as="h6" layout="position">
                        ACTIONS
                    </MotionHeading>
                    <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                        <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                            {bonusAbilities != null && bonusAbilities.length > 0 && (
                                <CharacterAbilities token={token} character={character} abilityKeys={bonusAbilities} />
                            )}
                            {commonBonusActionAbilities != null && commonBonusActionAbilities.length > 0 && (
                                <CommonAbilities
                                    token={token}
                                    character={character}
                                    abilities={commonBonusActionAbilities}
                                />
                            )}
                            {bonusesTextEntry && <Markdown>{bonusesTextEntry}</Markdown>}
                        </Box>
                    </MotionInOverlayCard>
                </Box>
            )}

            {((reactionAbilities != null && reactionAbilities.length > 0) ||
                (commonReactionAbilities != null && commonReactionAbilities.length > 0) ||
                reactionsTextEntry) && (
                <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                    <MotionHeading as="h6" layout="position">
                        REACTIONS
                    </MotionHeading>
                    <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                        <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                            {reactionAbilities != null && reactionAbilities.length > 0 && (
                                <CharacterAbilities
                                    token={token}
                                    character={character}
                                    abilityKeys={reactionAbilities}
                                />
                            )}
                            {commonReactionAbilities != null && commonReactionAbilities.length > 0 && (
                                <CommonAbilities
                                    token={token}
                                    character={character}
                                    abilities={commonReactionAbilities}
                                />
                            )}
                            {reactionsTextEntry && <Markdown>{reactionsTextEntry}</Markdown>}
                        </Box>
                    </MotionInOverlayCard>
                </Box>
            )}

            {((otherAbilities != null && otherAbilities.length > 0) ||
                (commonOtherAbilities != null && commonOtherAbilities.length > 0) ||
                othersTextEntry) && (
                <Box mt={3} fullWidth flexDirection="column" alignItems="flex-start">
                    <MotionHeading as="h6" layout="position">
                        OTHER
                    </MotionHeading>
                    <MotionInOverlayCard layout fullWidth borderRadius={3} p={2}>
                        <Box fullWidth flexDirection="column" alignItems="flex-start" css={{ gap: theme.space[2] }}>
                            {otherAbilities != null && otherAbilities.length > 0 && (
                                <CharacterAbilities token={token} character={character} abilityKeys={otherAbilities} />
                            )}
                            {commonOtherAbilities != null && commonOtherAbilities.length > 0 && (
                                <CommonAbilities token={token} character={character} abilities={commonOtherAbilities} />
                            )}
                            {othersTextEntry && <Markdown>{othersTextEntry}</Markdown>}
                        </Box>
                    </MotionInOverlayCard>
                </Box>
            )}
        </Box>
    );
};

const SpellsPage: FunctionComponent<{
    character: ResolvedCharacter;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ character, token }) => {
    const { campaign, location, levelKey } = useLocationLevel();
    const dispatch = useDispatch();
    const grid = useLocalGrid();
    const annotationPlacementTemplateChanged = useVttApp(state => state.annotationPlacementTemplateChanged);

    const spellsByLevel: {
        [level: number]: {
            class?: ResolvedClassLevels;
            additionalSpells?: ResolvedAdditionalSpells;
            spell: Spell & Partial<ResolvedAdditionalSpell>;
        }[];
    } = {};
    Object.values(character.classes).forEach(o => {
        const classSpells = o.preparedSpells ?? o.knownSpells;
        if (classSpells) {
            for (let i = 0; i < classSpells.length; i++) {
                let spellsForLevel = spellsByLevel[classSpells[i].level];
                if (!spellsForLevel) {
                    spellsForLevel = [];
                    spellsByLevel[classSpells[i].level] = spellsForLevel;
                }

                spellsForLevel.push({ class: o, spell: classSpells[i] });
            }
        }
    });

    character.additionalSpells.forEach(o => {
        o.spells.forEach(p => {
            let spellsForLevel = spellsByLevel[p.level];
            if (!spellsForLevel) {
                spellsForLevel = [];
                spellsByLevel[p.level] = spellsForLevel;
            }

            spellsForLevel.push({ additionalSpells: o, spell: p });
        });
    });

    // Always show levels that have spell slots, even if there are no spells, as you can still use the slots for lower levels.
    if (character.spellSlots) {
        for (let ss of character.spellSlots) {
            let spellSlots: number[];
            if (Array.isArray(ss)) {
                spellSlots = ss;
            } else {
                spellSlots = ss.spellSlots;
            }

            spellSlots.forEach((o, i) => {
                if (o > 0 && !spellsByLevel[i + 1]) {
                    spellsByLevel[i + 1] = [];
                }
            });
        }
    }

    const spellLevelsWithSpells = Object.keys(spellsByLevel).map(o => parseInt(o));
    return (
        <LobotomizedBox fullWidth flexDirection="column">
            {spellLevelsWithSpells.map(lvl => {
                const spells = spellsByLevel[lvl];

                const slotTypes = character.spellSlots;
                const slotTypeElements: JSX.Element[] = [];
                if (slotTypes) {
                    let renderSpellSlot = (st: number[] | NamedSpellSlots, s: number, usedSlots: number) => {
                        const key = `${isToken(token) ? token.id : token.templateId}_${
                            Array.isArray(st) ? "default" : st.name
                        }_L${lvl}S${s}`;
                        return (
                            <Checkbox
                                key={key}
                                id={key}
                                mr={1}
                                checked={s < usedSlots}
                                disabled={!isLocation(location)}
                                onChange={e => {
                                    // TODO: Need to (optionally) pass the name of the spell slot being marked here.
                                    if (e.target.checked) {
                                        dispatch(
                                            markTokenSpellSlot(
                                                campaign,
                                                location!,
                                                [token],
                                                lvl,
                                                Array.isArray(st) ? undefined : st.name
                                            )
                                        );
                                    } else {
                                        dispatch(
                                            clearTokenSpellSlot(
                                                campaign,
                                                location!,
                                                [token],
                                                lvl,
                                                Array.isArray(st) ? undefined : st.name
                                            )
                                        );
                                    }
                                }}
                            />
                        );
                    };

                    for (let st of slotTypes) {
                        const maxSlots = Array.isArray(st) ? st[lvl - 1] : st.spellSlots[lvl - 1];
                        const usedSlots = Array.isArray(st)
                            ? character.usedSpellSlots?.default?.[lvl - 1] ?? 0
                            : character.usedSpellSlots?.[st.name]?.[lvl - 1] ?? 0;
                        const slotElements: JSX.Element[] = [];
                        for (let s = 0; s < maxSlots; s++) {
                            slotElements.push(renderSpellSlot(st, s, usedSlots));
                        }

                        if (slotElements.length) {
                            slotTypeElements.push(
                                <Box key={Array.isArray(st) ? "default" : st.name}>
                                    {slotElements}
                                    {Array.isArray(st) ? "SLOTS" : st.name.toLocaleUpperCase()}
                                </Box>
                            );
                        }
                    }
                }

                return (
                    <MotionLobotomizedBox key={lvl} flexDirection="column" fullWidth layout>
                        <MotionBox
                            flexDirection="row"
                            fullWidth
                            justifyContent="space-between"
                            mt={lvl > 1 || !spellsByLevel[0] ? 2 : ""}
                            layout>
                            <Heading as="h6">{lvl === 0 ? "CANTRIPS" : `LEVEL ${lvl}`}</Heading>
                            <LobotomizedBox>{slotTypeElements}</LobotomizedBox>
                        </MotionBox>
                        {levelKey &&
                            spells?.map(o => {
                                const maxUses = evaluateCharacterExpression(
                                    character,
                                    o.spell.maxUsesExpr,
                                    o.spell.maxUses
                                );
                                return (
                                    <SpellInfo
                                        key={getRuleKey(o.spell)}
                                        spell={o.spell}
                                        isInOverlay
                                        dc={getSpellDc(character, o.class ?? o.additionalSpells!)}
                                        subtitle={
                                            o.additionalSpells ? o.additionalSpells.name : o.class?.classData.name
                                        }
                                        actions={getMenuForSpell(
                                            character,
                                            dispatch,
                                            annotationPlacementTemplateChanged,
                                            campaign,
                                            location,
                                            levelKey,
                                            grid,
                                            token,
                                            o
                                        )}
                                        reset={o.spell.reset}
                                        maxUses={maxUses}
                                        used={o.spell.used}
                                    />
                                );
                            })}
                    </MotionLobotomizedBox>
                );
            })}
        </LobotomizedBox>
    );
};

function getMenuForSpell(
    character: ResolvedCharacter,
    dispatch: Dispatch<AnyAction>,
    evt: Event<AnnotationPlacementTemplate<Annotation> | undefined>,
    campaign: Campaign,
    location: Location | LocationSummary | undefined,
    levelKey: string,
    grid: ILocalGrid,
    token: DnD5EToken | DnD5ETokenTemplate,
    spellInfo: {
        class?: ResolvedClassLevels;
        additionalSpells?: ResolvedAdditionalSpells;
        spell: Spell & Partial<ResolvedAdditionalSpell>;
    }
): IMenuItem[] | undefined {
    if (!isLocation(location)) {
        return undefined;
    }

    const maxUses = evaluateCharacterExpression(character, spellInfo.spell.maxUsesExpr, spellInfo.spell.maxUses);
    if (maxUses != null && spellInfo.additionalSpells) {
        // This is an innate spell with limited uses.
        // TODO: This should support annotation templates too.
        return [
            {
                label: "Use",
                disabled:
                    (spellInfo.spell.used ?? 0) >= maxUses || !canPayCombatTimeCost(character, spellInfo.spell.time),
                onClick: () => {
                    dispatch(
                        markInnateSpellUse(
                            campaign,
                            location,
                            [token],
                            spellInfo.additionalSpells!.source,
                            spellInfo.spell
                        )
                    );
                },
            },
        ];
    } else if (spellInfo.spell.level > 0) {
        const dc = getSpellDc(character, spellInfo.class ?? spellInfo.additionalSpells!);
        const attackModifier = getSpellAttackModifier(character, spellInfo.class ?? spellInfo.additionalSpells!);
        const ability = spellInfo.class?.classData.spellcastingAbility ?? spellInfo.additionalSpells?.ability;
        return getCastMenuItems(
            character,
            spellInfo.spell,
            dc,
            attackModifier,
            ability,
            dispatch,
            evt,
            campaign,
            location,
            levelKey,
            grid,
            token
        );
    }

    return undefined;
}

// const ClassHitDice: FunctionComponent<{ character: Character | Monster, resolvedCharacter: ResolvedCharacter | ResolvedMonster, token: DnD5EToken, classLevels: ResolvedClassLevels | DiceType }> = ({ character, resolvedCharacter, token, classLevels }) => {
//     const [pending, setPending] = useState(0);
//     const [pendingSpent, setPendingSpent] = useState(0);
//     const { setDice } = useDiceBag();
//     const { campaign, location } = useLocation();
//     const dispatch = useDispatch();

//     let hitDieTotal = 0;
//     let storedSpent: number;
//     let name: string;
//     let hitDie: DiceType;
//     let constitution: number;
//     let hitDieRef: NamedRuleRef | DiceType;

//     if (isCharacter(character)) {
//         storedSpent = (classLevels as ResolvedClassLevels).hitDiceSpent ?? 0;
//         hitDieTotal = (classLevels as ResolvedClassLevels).level;
//         name = (classLevels as ResolvedClassLevels).classData.name;
//         hitDie = (classLevels as ResolvedClassLevels).classData.hitDie;
//         constitution = resolveModifiedValue((resolvedCharacter as ResolvedCharacter).constitution);
//         hitDieRef = (classLevels as ResolvedClassLevels).classData;
//     } else {
//         storedSpent = (resolvedCharacter as ResolvedMonster).hitDiceSpent?.[classLevels as DiceType] ?? 0;
//         hitDieTotal = (resolvedCharacter as ResolvedMonster).hitDice;
//         name = classLevels as DiceType;
//         hitDie = classLevels as DiceType;
//         constitution = (resolvedCharacter as ResolvedMonster).constitution;
//         hitDieRef = classLevels as DiceType;
//     }

//     const spent = (storedSpent ?? 0) + pendingSpent;

//     const remaining = hitDieTotal - spent - pending;
//     const hitDice: ReactElement[] = [];

//     // Show the hit dice that have already been rolled as disabled checked boxes.
//     for (let i = 0; i < spent; i++) {
//         hitDice.push(<Checkbox checked disabled></Checkbox>)
//     }

//     // The remaining checkboxes can be interacted with.
//     for (let i = 0; i < (hitDieTotal - spent); i++) {
//         hitDice.push(<Checkbox key={i} id={name + "_" + i} checked={i < pending} onChange={e => {
//             if (e.target.checked) {
//                 setPending(pending + 1);
//             } else {
//                 setPending(pending - 1);
//             }
//         }} />);
//     }

//     const modifier = modifierFromAbilityScore(constitution) * Math.max(1, pending);
//     const hitDieRoll = `${pending < 1 ? 1 : pending}${hitDie}${modifier < 0 ? modifier : (modifier === 0 ? "" : "+" + modifier)}`;

//     return <Box flexDirection="column" alignItems="flex-start">
//         <Text>{name} hit dice (1{hitDie}) {remaining}/{hitDieTotal} remaining</Text>
//         <LobotomizedBox mt={1} mb={1}>
//             {hitDice}
//             <Button disabled={pending === 0} onClick={() => {
//                 const bag = parseDiceBag(hitDieRoll, { token: token.id, location: location.id, notify: { toast: false, token: false } });
//                 bag.onRolled = async roll => {
//                     setPending(0);
//                     setPendingSpent(pending);

//                     // Set the results to the annotation.
//                     var result = await roll.confirmed;

//                     setPendingSpent(0);
//                     dispatch(applyHitDie(campaign, location, [token], hitDieRef, result));
//                 };
//                 setDice(bag);
//             }}>Roll {hitDieRoll}</Button>
//         </LobotomizedBox>
//     </Box>
// }

function sensesToString(senses: Senses<ModifiedValue>) {
    const keys = Object.keys(senses);
    return keys
        .map(o => {
            const value = resolveModifiedValue(senses[o]);
            return value > 0 ? `${o.substring(0, 1).toUpperCase()}${o.substring(1)} ${value} ft.` : undefined;
        })
        .filter(o => !!o)
        .join(", ");
}

function armorProficienciesToString(armorProficiencies: Partial<ArmorProficiencies<boolean>>) {
    const profs: string[] = [];

    if (armorProficiencies.heavy) {
        profs.push("Heavy Armor");
    }

    if (armorProficiencies.medium) {
        profs.push("Medium Armor");
    }

    if (armorProficiencies.light) {
        profs.push("Light Armor");
    }

    if (armorProficiencies.shields) {
        profs.push("Shields");
    }

    return profs.length ? profs.join(", ") : "None";
}

function weaponProficienciesToString(weaponProficiencies: Partial<WeaponProficiencies<boolean>>) {
    const profs: string[] = [];

    if (weaponProficiencies.martial) {
        profs.push("Martial Weapons");
    }

    if (weaponProficiencies.simple) {
        profs.push("Simple Weapons");
    }

    return profs.length ? profs.join(", ") : "None";
}

function toolProficienciesToString(
    toolProficiencies: { [tool: string]: number | undefined }[],
    rules: CharacterRuleSet,
    proficiencyBonus: number
) {
    const tools: string[] = [];

    for (let toolset of toolProficiencies) {
        const keys = Object.keys(toolset);
        for (let key of keys) {
            const prof = toolset[key];
            if (prof != null && prof >= 1) {
                const toolRef = fromRuleKey(key);
                if (toolRef) {
                    const tool = rules.items.items.get(toolRef);
                    if (tool) {
                        tools.push(`${tool.name} (+${Math.floor(prof * proficiencyBonus)})`);
                    }
                } else {
                    tools.push(`${key} (+${Math.floor(prof * proficiencyBonus)})`);
                }
            }
        }
    }

    return tools.length ? tools.join(", ") : "None";
}

export enum CharacterSheetPage {
    CoreAbilities,
    Spells,
    Equipment,
    Features,
    Actions,
}

const DeathSavingThrowsBox: FunctionComponent<{
    character: ResolvedCharacter;
    token: DnD5ECharacterToken | DnD5ETokenTemplate;
}> = ({ character, token }) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();

    let renderDeathSavingThrowSlot = (success: boolean, i: number) => {
        const prop: keyof CreatureDying = success ? "success" : "failure";
        const key = `${isToken(token) ? token.id : token.templateId}_death_${prop}_${i}`;
        return (
            <Checkbox
                key={key}
                id={key}
                mr={1}
                checked={i < (character.dying?.[prop] ?? 0)}
                disabled={
                    !isLocation(location) || !character.dying || character.dying.stable || (character.isDead && success)
                }
                onChange={e => {
                    if (e.target.checked) {
                        dispatch(markDeathSavingThrow(campaign, location!, token, success));
                    } else {
                        dispatch(clearDeathSavingThrow(campaign, location!, token, success));
                    }
                }}
            />
        );
    };

    const modifier = resolveModifiedValue(character.savingThrows.death);
    let adv = advantageOrDisadvantage(
        character.savingThrows.death.advantage,
        character.savingThrows.death.disadvantage
    );

    return (
        <MotionCard
            layout
            px={3}
            py={2}
            fullWidth
            borderRadius={3}
            bg="guidance.error.1"
            color="guidance.error.0"
            boxShadowSize="none"
            flexDirection="column"
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}>
            <ColorBox fullWidth>
                <SkullIcon size={16 as any} />
                <Text ml={2} textAlign="center" color="inherit" fontSize={1}>
                    DEATH SAVING THROWS
                </Text>
            </ColorBox>
            <ColorBox fullWidth justifyContent="space-between" mt={2}>
                <RollButton
                    token={token}
                    modifier={modifier}
                    disabled={character.isDead}
                    advantage={adv}
                    data={{ savingThrow: "death" }}
                    extraRolls={character.savingThrows.death.extraRolls}
                    onRolled={async roll => {
                        const logEntry = await roll.confirmed;
                        dispatch(
                            applyDeathSave(campaign, location, token, {
                                expression: logEntry.expression,
                                result: logEntry.result,
                                terms: logEntry.terms,
                            })
                        );
                    }}>
                    Roll
                </RollButton>
                <ColorBox flexDirection="column">
                    Successes
                    <ColorBox>
                        {renderDeathSavingThrowSlot(true, 0)}
                        {renderDeathSavingThrowSlot(true, 1)}
                        {renderDeathSavingThrowSlot(true, 2)}
                    </ColorBox>
                </ColorBox>
                <ColorBox flexDirection="column">
                    Failures
                    <ColorBox>
                        {renderDeathSavingThrowSlot(false, 0)}
                        {renderDeathSavingThrowSlot(false, 1)}
                        {renderDeathSavingThrowSlot(false, 2)}
                    </ColorBox>
                </ColorBox>
                <Button
                    disabled={character.isDead}
                    onClick={() => {
                        dispatch(markDeathSavingThrow(campaign, location, token, true, 3));
                    }}>
                    Stabilise
                </Button>
            </ColorBox>
        </MotionCard>
    );
};

export const useCharacterSheet = create<{
    page: CharacterSheetPage;
    setPage: (page: CharacterSheetPage) => void;
}>(set => ({
    page: CharacterSheetPage.CoreAbilities,
    setPage: page => {
        set(() => ({ page: page }));
    },
}));

export const CharacterSheet: FunctionComponent<{
    character: ResolvedCharacter;
    token: ResolvedToken<DnD5ECharacterToken> | DnD5ECharacterTemplate;
    isPanel?: boolean;
}> = ({ character, token, isPanel }) => {
    const rules = useRules();

    const { page, setPage } = useCharacterSheet();
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();
    const [isShortRestOpen, setIsShortRestOpen] = useState(false);

    const [isShowingTransformed, setIsShowingTransformed] = useState(true);
    const transformEffect = character.transformedInto
        ? character.effects?.[character.transformedInto.byEffect]
        : undefined;

    const imageMetadata = token.imageUri ? token.images?.find(o => o.uri === token.imageUri) : undefined;
    const thumbnailUri = imageMetadata?.thumbnailUri ?? token.imageUri;

    const closePanel = useVttApp(state => state.closePanel);
    const addOverlay = useVttApp(state => state.addOverlay);

    return (
        <React.Fragment>
            <AnimatePresence>
                <Box px={3}>
                    {character.transformedInto && (
                        <MotionMessage
                            layout
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            variant="info"
                            fullWidth
                            mb={!isShowingTransformed ? 2 : 0}>
                            {isShowingTransformed && (
                                <React.Fragment>
                                    <MotionText layout css={{ color: "inherit" }}>
                                        This creature is actually {character.name}
                                        {transformEffect ? ` under the ${transformEffect.name} effect` : ""}.
                                    </MotionText>
                                    <MotionBox layoutId="switch_button" layout>
                                        <Button mt={2} onClick={() => setIsShowingTransformed(false)}>
                                            Show {character.name}
                                        </Button>
                                    </MotionBox>
                                </React.Fragment>
                            )}
                            {!isShowingTransformed && (
                                <React.Fragment>
                                    <MotionText layout css={{ color: "inherit" }}>
                                        This character is currently transformed into{" "}
                                        {startsWithVowel(character.transformedInto.name) ? "an" : "a"}{" "}
                                        {character.transformedInto.name}
                                        {transformEffect ? ` by the ${transformEffect.name} effect` : ""}.
                                    </MotionText>
                                    <MotionBox layoutId="switch_button" layout>
                                        <Button mt={2} onClick={() => setIsShowingTransformed(true)}>
                                            Show {character.transformedInto.name}
                                        </Button>
                                    </MotionBox>
                                </React.Fragment>
                            )}
                        </MotionMessage>
                    )}
                </Box>
            </AnimatePresence>
            <AnimatePresence mode="wait" initial={false}>
                {isShowingTransformed && character.transformedInto && (
                    <MotionBox
                        layout
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}
                        key="transformedInto"
                        flexDirection="column"
                        fullWidth
                        flexGrow={1}>
                        <MonsterSheetContent
                            monster={character.transformedInto}
                            thumbnailUri={character.transformedInto.transform.appearance?.imageUri ?? token.imageUri}
                            token={token}
                        />
                    </MotionBox>
                )}
                {(!isShowingTransformed || !character.transformedInto) && (
                    <MotionBox
                        layout
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}
                        key="default"
                        flexDirection="column"
                        alignItems="flex-start"
                        fullWidth
                        flexGrow={1}>
                        <MotionBox layout fullWidth alignItems="flex-start" px={3} pt={3}>
                            {token.portraitImageUri && (
                                <Box
                                    width={102}
                                    height={138}
                                    bg="grayscale.7"
                                    borderRadius={3}
                                    css={{ overflow: "hidden" }}>
                                    <Image
                                        src={token.portraitImageUri}
                                        responsive
                                        fullWidth
                                        fullHeight
                                        draggable={false}
                                    />
                                </Box>
                            )}
                            {!token.portraitImageUri && (
                                <Box
                                    width={tokenImageSize}
                                    height={tokenImageSize}
                                    bg="grayscale.7"
                                    borderRadius={3}
                                    css={{ overflow: "hidden" }}>
                                    {thumbnailUri ? (
                                        <Image
                                            src={resolveUri(thumbnailUri)}
                                            responsive
                                            fullWidth
                                            fullHeight
                                            draggable={false}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            )}
                            <Box flex={1} ml={3} flexDirection="column" alignItems="flex-start">
                                <Heading as="h3" mb={2}>
                                    {character.name}
                                </Heading>
                                <CharacterTags character={character} token={token} />

                                {token.portraitImageUri && (
                                    <Box
                                        width={tokenImageSize}
                                        height={tokenImageSize}
                                        bg="grayscale.7"
                                        borderRadius={3}
                                        css={{ overflow: "hidden" }}>
                                        {thumbnailUri ? (
                                            <Image
                                                src={resolveUri(thumbnailUri)}
                                                responsive
                                                fullWidth
                                                fullHeight
                                                draggable={false}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </MotionBox>
                        <MotionLobotomizedBox layout fullWidth mt={2} px={3} justifyContent="flex-start">
                            <Button
                                variant="inOverlaySecondary"
                                disabled={!isLocation(location)}
                                onClick={() => {
                                    if (token.templateId) {
                                        closePanel(token.templateId);
                                    }

                                    addOverlay(
                                        getCreatureEditor(
                                            getModifyId(token),
                                            location?.id,
                                            !isPanel ? "right" : undefined
                                        )
                                    );
                                }}>
                                Edit…
                            </Button>
                            <Button
                                variant="inOverlaySecondary"
                                disabled={!isLocation(location) || character.isDead}
                                toggled={isShortRestOpen}
                                onClick={() => {
                                    setIsShortRestOpen(!isShortRestOpen);
                                }}>
                                Short rest
                            </Button>
                            <Button
                                variant="inOverlaySecondary"
                                disabled={isShortRestOpen || !isLocation(location) || character.isDead}
                                onClick={() => {
                                    dispatch(longRest(campaign, location, [token]));
                                }}>
                                Long rest
                            </Button>
                        </MotionLobotomizedBox>
                        <AnimatePresence>
                            {isShortRestOpen && (
                                <MotionBox
                                    flexDirection="column"
                                    alignItems="flex-start"
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}
                                    mt={2}
                                    px={3}>
                                    {Object.values(character.classes).map((o, i) => {
                                        return (
                                            <HitDiceUsage
                                                key={i}
                                                token={token}
                                                spent={o.hitDiceSpent}
                                                total={o.level}
                                                constitution={resolveModifiedValue(character.constitution)}
                                                hitDieType={o.classData.hitDie}
                                                hitDieRef={o.classData}
                                                name={o.classData.name}
                                            />
                                        );
                                    })}
                                    <Button
                                        disabled={!isLocation(location)}
                                        mt={2}
                                        onClick={() => {
                                            setIsShortRestOpen(false);
                                            dispatch(shortRest(campaign, location, [token]));
                                        }}>
                                        Finish short rest
                                    </Button>
                                </MotionBox>
                            )}
                            {character.dying && !character.dying.stable && (
                                <Box px={3} mt={3} fullWidth>
                                    <DeathSavingThrowsBox character={character} token={token} />
                                </Box>
                            )}
                        </AnimatePresence>
                        <MotionBox
                            layout
                            fullWidth
                            px={3}
                            py={2}
                            mt={3}
                            flexWrap="wrap"
                            justifyContent="flex-start"
                            css={{ gap: theme.space[2], boxSizing: "content-box" }}>
                            <SidebarButton
                                tooltip="Core Abilities"
                                toggled={page === CharacterSheetPage.CoreAbilities}
                                onClick={() => setPage(CharacterSheetPage.CoreAbilities)}>
                                <CharacterSheetIcon />
                            </SidebarButton>
                            <SidebarButton
                                tooltip="Actions"
                                toggled={page === CharacterSheetPage.Actions}
                                onClick={() => setPage(CharacterSheetPage.Actions)}>
                                <CombatIcon />
                            </SidebarButton>
                            <SidebarButton
                                tooltip="Spells"
                                toggled={page === CharacterSheetPage.Spells}
                                onClick={() => setPage(CharacterSheetPage.Spells)}>
                                <SpellbookIcon />
                            </SidebarButton>
                            <SidebarButton
                                tooltip="Equipment"
                                toggled={page === CharacterSheetPage.Equipment}
                                onClick={() => setPage(CharacterSheetPage.Equipment)}>
                                <InventoryIcon />
                            </SidebarButton>
                            <SidebarButton
                                tooltip="Features"
                                toggled={page === CharacterSheetPage.Features}
                                onClick={() => setPage(CharacterSheetPage.Features)}>
                                <BookPileIcon />
                            </SidebarButton>
                        </MotionBox>
                        <ScrollableTest minimal fullWidth fullHeight p={3}>
                            <AnimatePresence mode="wait" initial={false}>
                                {page === CharacterSheetPage.CoreAbilities && (
                                    <MotionBox
                                        key="CoreAbilities"
                                        layout
                                        flexDirection="column"
                                        initial={sectionInitial}
                                        animate={sectionAnimate}
                                        exit={sectionExit}>
                                        <MotionGrid
                                            layout
                                            css={{ gap: theme.space[2] }}
                                            gridTemplateColumns="1fr 1fr 1fr"
                                            gridTemplateRows="auto auto"
                                            mb={2}
                                            fullWidth>
                                            <AbilityScoreBox
                                                ability="strength"
                                                value={character.strength}
                                                token={token}
                                                tooltipAlignment="start"
                                            />
                                            <AbilityScoreBox
                                                ability="dexterity"
                                                value={character.dexterity}
                                                token={token}
                                            />
                                            <AbilityScoreBox
                                                ability="constitution"
                                                value={character.constitution}
                                                token={token}
                                                tooltipAlignment="end"
                                            />
                                            <AbilityScoreBox
                                                ability="intelligence"
                                                value={character.intelligence}
                                                token={token}
                                                tooltipAlignment="start"
                                            />
                                            <AbilityScoreBox ability="wisdom" value={character.wisdom} token={token} />
                                            <AbilityScoreBox
                                                ability="charisma"
                                                value={character.charisma}
                                                token={token}
                                                tooltipAlignment="end"
                                            />
                                        </MotionGrid>
                                        <MotionGrid
                                            layout
                                            css={{ gap: theme.space[2] }}
                                            gridTemplateColumns="1fr auto"
                                            gridTemplateRows="auto"
                                            fullWidth>
                                            <HitPointBox token={token} creature={character} />
                                            <ArmorClassBox ac={character.ac} />
                                        </MotionGrid>
                                        <MotionInOverlayCard
                                            layout
                                            p={2}
                                            flexDirection="column"
                                            fullWidth
                                            mb={2}
                                            borderRadius={3}
                                            boxShadowSize="none">
                                            <Text textAlign="center" fontSize={1}>
                                                SAVING THROWS
                                            </Text>
                                            <Grid
                                                css={{ gap: theme.space[2] }}
                                                gridTemplateColumns="1fr 1fr"
                                                gridTemplateRows="auto auto auto">
                                                <CharacterSavingThrowBox
                                                    variant="inOverlayTransparent"
                                                    ability="strength"
                                                    character={character}
                                                    token={token}
                                                />
                                                <CharacterSavingThrowBox
                                                    variant="inOverlayTransparent"
                                                    ability="dexterity"
                                                    character={character}
                                                    token={token}
                                                />
                                                <CharacterSavingThrowBox
                                                    variant="inOverlayTransparent"
                                                    ability="constitution"
                                                    character={character}
                                                    token={token}
                                                />
                                                <CharacterSavingThrowBox
                                                    variant="inOverlayTransparent"
                                                    ability="intelligence"
                                                    character={character}
                                                    token={token}
                                                />
                                                <CharacterSavingThrowBox
                                                    variant="inOverlayTransparent"
                                                    ability="wisdom"
                                                    character={character}
                                                    token={token}
                                                />
                                                <CharacterSavingThrowBox
                                                    variant="inOverlayTransparent"
                                                    ability="charisma"
                                                    character={character}
                                                    token={token}
                                                />
                                            </Grid>
                                            {character.savingThrows?.advantage?.map((o, i) => (
                                                <AdvantageBox
                                                    key={i}
                                                    advantage="adv"
                                                    reason={o.reason}
                                                    condition={o.condition}
                                                    alignSelf="flex-start"
                                                    marginX={3}
                                                    mt={2}
                                                />
                                            ))}
                                            {character.savingThrows?.disadvantage?.map((o, i) => (
                                                <AdvantageBox
                                                    key={i}
                                                    advantage="dis"
                                                    reason={o.reason}
                                                    condition={o.condition}
                                                    alignSelf="flex-start"
                                                    marginX={3}
                                                    mt={2}
                                                />
                                            ))}
                                        </MotionInOverlayCard>
                                        <SkillsBox
                                            token={token}
                                            skills={character.skillChecks}
                                            proficiencies={character.skillProficiencies}
                                        />
                                        <MotionInOverlayCard
                                            layout
                                            p={2}
                                            flexDirection="column"
                                            fullWidth
                                            mb={2}
                                            borderRadius={3}
                                            boxShadowSize="none">
                                            <Text textAlign="center" fontSize={1}>
                                                SENSES
                                            </Text>
                                            <Grid
                                                paddingX={3}
                                                fullWidth
                                                css={{ gap: theme.space[2] }}
                                                gridTemplateColumns="1fr"
                                                gridTemplateRows="1fr">
                                                <TooltipGrid
                                                    tooltip={getModifiedValueTooltip(character.passive.perception)}
                                                    fullWidth
                                                    css={{
                                                        gap: theme.space[2],
                                                        alignItems: "center",
                                                        justifyItems: "flex-start",
                                                    }}
                                                    gridTemplateColumns={`${
                                                        theme.space[4] + theme.space[2]
                                                    }px 1fr auto`}
                                                    gridAutoRows="auto">
                                                    <Text color="grayscale.2">{getCoreAbilityAbbr("wisdom")}</Text>
                                                    <Text>Passive perception</Text>
                                                    <Text fontSize={2}>
                                                        {resolveModifiedValue(character.passive.perception)}
                                                    </Text>
                                                </TooltipGrid>
                                                <TooltipGrid
                                                    tooltip={getModifiedValueTooltip(character.passive.investigation)}
                                                    fullWidth
                                                    css={{
                                                        gap: theme.space[2],
                                                        alignItems: "center",
                                                        justifyItems: "flex-start",
                                                    }}
                                                    gridTemplateColumns={`${
                                                        theme.space[4] + theme.space[2]
                                                    }px 1fr auto`}
                                                    gridAutoRows="auto">
                                                    <Text color="grayscale.2">
                                                        {getCoreAbilityAbbr("intelligence")}
                                                    </Text>
                                                    <Text>Passive investigation</Text>
                                                    <Text fontSize={2}>
                                                        {resolveModifiedValue(character.passive.investigation)}
                                                    </Text>
                                                </TooltipGrid>
                                                <TooltipGrid
                                                    tooltip={getModifiedValueTooltip(character.passive.insight)}
                                                    fullWidth
                                                    css={{
                                                        gap: theme.space[2],
                                                        alignItems: "center",
                                                        justifyItems: "flex-start",
                                                    }}
                                                    gridTemplateColumns={`${
                                                        theme.space[4] + theme.space[2]
                                                    }px 1fr auto`}
                                                    gridAutoRows="auto">
                                                    <Text color="grayscale.2">{getCoreAbilityAbbr("wisdom")}</Text>
                                                    <Text>Passive insight</Text>
                                                    <Text fontSize={2}>
                                                        {resolveModifiedValue(character.passive.insight)}
                                                    </Text>
                                                </TooltipGrid>
                                            </Grid>
                                            <Text paddingX={3} mt={2} fontSize={1} color="grayscale.2">
                                                {sensesToString(character.senses)}
                                            </Text>
                                        </MotionInOverlayCard>
                                        <MotionInOverlayCard
                                            layout
                                            p={2}
                                            flexDirection="column"
                                            fullWidth
                                            mb={2}
                                            borderRadius={3}
                                            boxShadowSize="none">
                                            <Text textAlign="center" fontSize={1}>
                                                PROFICIENCIES &amp; LANGUAGES
                                            </Text>
                                            <Box fullWidth paddingX={3} flexDirection="column" alignItems="flex-start">
                                                <Text color="grayscale.2">ARMOR</Text>
                                                <Text>{armorProficienciesToString(character.armorProficiencies)}</Text>

                                                <Text mt={2} color="grayscale.2">
                                                    WEAPONS
                                                </Text>
                                                <Text>
                                                    {weaponProficienciesToString(character.weaponProficiencies)}
                                                </Text>

                                                <Text mt={2} color="grayscale.2">
                                                    TOOLS
                                                </Text>
                                                <Text>
                                                    {toolProficienciesToString(
                                                        [character.toolProficiencies],
                                                        rules,
                                                        character.proficiencyBonus
                                                    )}
                                                </Text>

                                                <Text mt={2} color="grayscale.2">
                                                    LANGUAGES
                                                </Text>
                                                <Text>{character.languages.join(", ")}</Text>
                                            </Box>
                                        </MotionInOverlayCard>
                                        <MotionInOverlayCard
                                            layout
                                            p={2}
                                            flexDirection="column"
                                            fullWidth
                                            mb={2}
                                            borderRadius={3}
                                            boxShadowSize="none">
                                            <Text textAlign="center" fontSize={1}>
                                                SPEED
                                            </Text>
                                            <Grid
                                                fullWidth
                                                px={3}
                                                gridTemplateColumns="min-content auto"
                                                css={{ gap: `0 ${theme.space[3]}px` }}>
                                                {character.speed.walk && (
                                                    <React.Fragment>
                                                        <Text color="grayscale.2">WALK</Text>
                                                        <TooltipText
                                                            tooltip={getModifiedValueTooltip(character.speed.walk)}>
                                                            {resolveModifiedValue(character.speed.walk)}
                                                        </TooltipText>
                                                    </React.Fragment>
                                                )}

                                                {character.speed.fly && (
                                                    <React.Fragment>
                                                        <Text color="grayscale.2">FLY</Text>
                                                        <TooltipText
                                                            tooltip={getModifiedValueTooltip(character.speed.fly)}>
                                                            {resolveModifiedValue(character.speed.fly)}
                                                        </TooltipText>
                                                    </React.Fragment>
                                                )}

                                                {character.speed.climb && (
                                                    <React.Fragment>
                                                        <Text color="grayscale.2">CLIMB</Text>
                                                        <TooltipText
                                                            tooltip={getModifiedValueTooltip(character.speed.climb)}>
                                                            {resolveModifiedValue(character.speed.climb)}
                                                        </TooltipText>
                                                    </React.Fragment>
                                                )}

                                                {character.speed.swim && (
                                                    <React.Fragment>
                                                        <Text color="grayscale.2">SWIM</Text>
                                                        <TooltipText
                                                            tooltip={getModifiedValueTooltip(character.speed.swim)}>
                                                            {resolveModifiedValue(character.speed.swim)}
                                                        </TooltipText>
                                                    </React.Fragment>
                                                )}

                                                {character.speed.burrow && (
                                                    <React.Fragment>
                                                        <Text color="grayscale.2">BURROW</Text>
                                                        <TooltipText
                                                            tooltip={getModifiedValueTooltip(character.speed.burrow)}>
                                                            {resolveModifiedValue(character.speed.burrow)}
                                                        </TooltipText>
                                                    </React.Fragment>
                                                )}
                                            </Grid>
                                        </MotionInOverlayCard>
                                        {(character.damageImmunities ||
                                            character.resistances ||
                                            character.vulnerabilities ||
                                            character.conditionImmunities) && (
                                            <MotionInOverlayCard
                                                layout
                                                p={2}
                                                flexDirection="column"
                                                fullWidth
                                                mb={2}
                                                borderRadius={3}
                                                boxShadowSize="none">
                                                <Text textAlign="center" fontSize={1}>
                                                    DEFENSES
                                                </Text>
                                                <Box
                                                    fullWidth
                                                    paddingX={3}
                                                    flexDirection="column"
                                                    alignItems="flex-start">
                                                    {mapKeyedList(character.damageImmunities, (o, i, k) => {
                                                        const keys = Object.keys(o);
                                                        return (
                                                            !!keys.length && (
                                                                <React.Fragment key={k}>
                                                                    <Markdown>
                                                                        {damageTypesToMarkdown("Immune", o)}
                                                                    </Markdown>
                                                                </React.Fragment>
                                                            )
                                                        );
                                                    })}
                                                    {mapKeyedList(character.resistances, (o, i, k) => {
                                                        const keys = Object.keys(o);
                                                        return (
                                                            !!keys.length && (
                                                                <React.Fragment key={k}>
                                                                    <Markdown>
                                                                        {damageTypesToMarkdown("Resistant", o)}
                                                                    </Markdown>
                                                                </React.Fragment>
                                                            )
                                                        );
                                                    })}
                                                    {mapKeyedList(character.vulnerabilities, (o, i, k) => {
                                                        const keys = Object.keys(o);
                                                        return (
                                                            !!keys.length && (
                                                                <React.Fragment key={k}>
                                                                    <Markdown>
                                                                        {damageTypesToMarkdown("Vulnerable", o)}
                                                                    </Markdown>
                                                                </React.Fragment>
                                                            )
                                                        );
                                                    })}
                                                    {mapKeyedList(character.conditionImmunities, (o, i, k) => (
                                                        <React.Fragment key={k}>
                                                            <Markdown>{`Immune to ${creatureConditions
                                                                .filter(c => o[c])
                                                                .join(", ")}${
                                                                o.condition ? ` (${o.condition})` : ""
                                                            }`}</Markdown>
                                                        </React.Fragment>
                                                    ))}
                                                </Box>
                                            </MotionInOverlayCard>
                                        )}
                                        <MotionInOverlayCard
                                            layout
                                            p={2}
                                            flexDirection="column"
                                            fullWidth
                                            mb={2}
                                            borderRadius={3}
                                            boxShadowSize="none"
                                            alignItems="stretch">
                                            <Text textAlign="center" fontSize={1}>
                                                CONDITIONS
                                            </Text>
                                            <Box paddingX={3} alignItems="stretch" flexDirection="column">
                                                <ConditionEditor creature={character} token={token} />
                                            </Box>
                                        </MotionInOverlayCard>
                                        <AnimatePresence initial={false}>
                                            {character.effects && (
                                                <MotionInOverlayCard
                                                    layout
                                                    p={2}
                                                    flexDirection="column"
                                                    fullWidth
                                                    mb={2}
                                                    borderRadius={3}
                                                    boxShadowSize="none"
                                                    alignItems="stretch"
                                                    initial={defaultInitial}
                                                    animate={defaultAnimate}
                                                    exit={defaultExit}>
                                                    <MotionText layout textAlign="center" fontSize={1}>
                                                        EFFECTS
                                                    </MotionText>
                                                    <AnimatePresence initial={false}>
                                                        {mapKeyedList(character.effects, (o, i, key) => (
                                                            <AppliedAbilityEffectItem
                                                                key={key}
                                                                token={token}
                                                                resolvedCreature={character}
                                                                effect={o}
                                                                effectKey={key}
                                                            />
                                                        ))}
                                                    </AnimatePresence>
                                                </MotionInOverlayCard>
                                            )}
                                        </AnimatePresence>
                                    </MotionBox>
                                )}
                                {page === CharacterSheetPage.Actions && (
                                    <MotionBox
                                        key="Actions"
                                        layout
                                        flexDirection="column"
                                        fullWidth
                                        initial={sectionInitial}
                                        animate={sectionAnimate}
                                        exit={sectionExit}>
                                        <ActionsPage character={character} token={token} />
                                    </MotionBox>
                                )}
                                {page === CharacterSheetPage.Spells && (
                                    <MotionBox
                                        key="Spells"
                                        layout
                                        flexDirection="column"
                                        fullWidth
                                        initial={sectionInitial}
                                        animate={sectionAnimate}
                                        exit={sectionExit}>
                                        <SpellsPage character={character} token={token} />
                                    </MotionBox>
                                )}
                                {page === CharacterSheetPage.Equipment && (
                                    <MotionBox
                                        key="Equipment"
                                        layout
                                        flexDirection="column"
                                        fullWidth
                                        initial={sectionInitial}
                                        animate={sectionAnimate}
                                        exit={sectionExit}>
                                        <EquipmentPage character={character} token={token} />
                                    </MotionBox>
                                )}
                                {page === CharacterSheetPage.Features && (
                                    <MotionBox
                                        key="Features"
                                        layout
                                        flexDirection="column"
                                        fullWidth
                                        initial={sectionInitial}
                                        animate={sectionAnimate}
                                        exit={sectionExit}>
                                        <FeaturesPage character={character} />
                                    </MotionBox>
                                )}
                            </AnimatePresence>
                        </ScrollableTest>
                    </MotionBox>
                )}
            </AnimatePresence>
        </React.Fragment>
    );
};
