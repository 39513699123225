/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useCallback } from "react";
import {
    IGameSystem,
    Token,
    TokenTemplate,
    TokenAdornerProps,
    resolveToken,
    Location,
    Campaign,
    CampaignRole,
    Light,
    IMenuItem,
    CustomMarkdownNode,
    PlayerSection,
    SearchableSetting,
    LogHeader,
    isTokenTemplate,
    AnnotationAdornerProps,
    CombatParticipant,
    AnnotationPlacementTemplate,
    isToken,
    isLocation,
    TokenAppearance,
    Session,
    WithLevel,
    SidebarPanelState,
    DragPreview,
    GmSection,
    ErrorHandler,
    ResolvedToken,
    getTokenType,
    LocationSummary,
    UserInfo,
} from "../../store";
import {
    isDnD5EToken,
    DnD5ECharacterTemplate,
    isDnD5ETokenTemplate,
    isDnD5ECharacterTemplate,
    DnD5EMonsterTemplate,
    DnD5ECharacterToken,
    isDnD5ECharacterToken,
    DnD5ECampaign,
    resolveCampaignSources,
    DnD5EMonsterToken,
    isDnD5EMonsterToken,
    damageTypes,
    DamageType,
    isDnD5EAnnotation,
    AttackType,
    DnD5EAnnotation,
    tokenTemplateFilterSetting,
    defaultUnitsPerGrid,
    NamedRuleRef,
    Ability,
    DnD5EToken,
    fromRuleKey,
    msPerRound,
    getRuleKey,
    NamedRuleStore,
    DnD5ETokenTemplate,
    monsterToTokenTemplate,
    getCachedRuleStore,
    getRuleStore,
    isDnD5EMonsterTemplate,
} from "./common";
import {
    isAbilityCheck,
    isAttackRoll,
    isDamageRoll,
    isHitPoints,
    isRechargeRoll,
    isSavingThrow,
    isSkillCheck,
} from "./logentries";
import { CharacterSheet, CharacterSheetPage, useCharacterSheet } from "./components/CharacterSheet";
import { TokenTools } from "./components/TokenTools";
import { TokenListItem } from "./components/TokenListItem";
import {
    CharacterRuleSet,
    CharacterRuleStore,
    createRuleSet,
    isCharacter,
    isMonster,
    resolveTokenCreature,
    resolveCharacter,
    Character,
    CreatureCondition,
    SkillDetails,
    fullResolveTokenCreature,
    resolveModifiedValue,
    Feature,
    filterMonsterTemplates,
    MovementType,
    getResolvedMovementSpeeds,
    ResolvedCharacter,
    ResolvedMonster,
    creatureTypeToString,
} from "./creature";
import { DnD5eCampaignContext, useRules } from "./components/hooks";
import { useCampaign, useDispatch, useLocation, useRole, useSelection, useUser } from "../../components/contexts";
import { TokenAdorner } from "./components/TokenAdorner";
import { LogEntryDetails } from "./components/AbilityCheckDetails";
import { MotionBox, sectionInitial, sectionAnimate, sectionExit } from "../../components/motion";
import { AnimatePresence } from "framer-motion";
import { Box, Text } from "../../components/primitives";
import { Action, Dispatch } from "redux";
import { LocalSearchable } from "../../localsearchable";
import { isSpell, Spell } from "./spells";
import { getAbility } from "./abilities";
import { SpellInfo } from "./components/SpellInfo";
import systems from "../index";
import { DraggedItems, ResolvedItem, resolveItem } from "./items";
import { FeatureExpander } from "./components/FeatureExpander";
import { ReactComponent as DnDLogo } from "./components/5e D&D Logo.svg";
import { ItemInfo } from "./components/ItemInfo";
import { addCombatParticipants } from "../../actions/location";
import { RollForInitiative } from "./components/RollForInitiative";
import { getFileName, useEvent, useKeyboardShortcut, useLocalSetting } from "../../components/utils";
import {
    SkillPopup,
    ConditionPopup,
    SpellPopup,
    ActionPopup,
    ItemPopup,
    AttackPopup,
    RechargePopup,
    DamagePopup,
    RollPopup,
    FeaturePopup,
    MonsterPopup,
} from "./components/Popups";
import { areArraysEqual, Event } from "../../common";
import { reduceCampaign } from "./reducers/campaign";
import { allCampaignSettings } from "./components/settings/CampaignSettings";
import { getSelectedTokens } from "../../components/selection";
import { DiceTools } from "./components/DiceTools";
import { AnnotationAction } from "../../actions/common";
import { Markdown } from "../../components/markdown";
import { CreatureContext } from "./components/contexts";
import { AnnotationAdorner } from "./components/AnnotationAdorner";
import { Annotation } from "../../annotations";
import { reduceAnnotation } from "./reducers/annotation";
import { CombatTrackerItem } from "./components/CombatTrackerItem";
import { GridPosition, LocalPixelPosition, PositionType } from "../../position";
import { GlobalPathState, ILocalGrid, pointsEqual } from "../../grid";
import { CombatActions } from "./components/CombatActions";
import { getTransformEffectKey } from "./reducers/creature";
import { MonsterSheet } from "./components/monster/MonsterSheet";
import { findPathAStar } from "../../astar";
import { cantorPairSigned } from "../../pathfinder";
import { MarkdownActions } from "../../components/MarkdownActions";
import { getCreatureEditor } from "./components/CreatureEditor";
import { addItems } from "./actions/inventory";
import { Compendium, CompendiumJumpTo, CompendiumTools } from "./components/Compendium";
import { SearchCategoryResults, useVttApp } from "../../components/common";
import { reduceToken } from "./reducers/tokens";
import JSZip from "jszip";
import { fileName, getFilesForFolder } from "../../export";
import { CompendiumPage } from "./components/common";
import { EncounterBuilder } from "./components/EncounterBuilder";
import EncounterIcon from "../../components/icons/Encounter";
import { MonsterSheetPage, useMonsterSheet } from "./components/monster/MonsterSheetContent";

const LocationDetails: FunctionComponent<{}> = () => {
    return <EncounterBuilder />;
};

export const TokenDetails: FunctionComponent<{ token?: Token | TokenTemplate; isPanel?: boolean }> = React.memo(
    ({ token, isPanel }) => {
        const { campaign } = useCampaign();
        const role = useRole();
        const user = useUser();
        const rules = useRules();
        const dndtoken = isDnD5EToken(token)
            ? resolveToken(campaign, token)
            : isDnD5ETokenTemplate(token)
            ? token
            : undefined;
        const resolvedCreature = dndtoken ? fullResolveTokenCreature(dndtoken, campaign, rules) : undefined;

        const canView = dndtoken != null && (role === "GM" || dndtoken.owner === user.id);

        return (
            <AnimatePresence mode="wait" initial={false}>
                {canView && dndtoken && resolvedCreature && (
                    <MotionBox
                        key={resolvedCreature.name}
                        fullWidth
                        fullHeight
                        flexDirection="column"
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}>
                        <CreatureContext.Provider value={dndtoken}>
                            {isMonster(resolvedCreature) && (
                                <MonsterSheet monster={resolvedCreature} token={dndtoken as DnD5EMonsterToken} />
                            )}
                            {isCharacter(resolvedCreature) && (
                                <CharacterSheet
                                    character={resolvedCreature}
                                    token={dndtoken as ResolvedToken<DnD5ECharacterToken>}
                                    isPanel={isPanel}
                                />
                            )}
                        </CreatureContext.Provider>
                    </MotionBox>
                )}
                {!canView && (
                    <MotionBox
                        key="__noPermissions"
                        p={3}
                        mt={isPanel ? 5 : 0}
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}>
                        <Text fontStyle="italic">You do not have permission to view or edit this item.</Text>
                    </MotionBox>
                )}
            </AnimatePresence>
        );
    }
);

const ItemSearchResult: FunctionComponent<{ item: ResolvedItem }> = ({ item }) => {
    const onDragStart = useCallback(() => {
        return { items: [item] };
    }, [item]);

    return <ItemInfo item={item} isInOverlay onDragStart={onDragStart} />;
};

const TokenTemplatePanelContent: FunctionComponent<{
    tokenId: string;
    getSystemTemplate: (id: string) => DnD5ETokenTemplate | undefined;
}> = ({ tokenId, getSystemTemplate }) => {
    const { campaign } = useCampaign();
    const tokenTemplate = campaign.tokens[tokenId] ?? getSystemTemplate(tokenId);
    if (!tokenTemplate) {
        return <React.Fragment></React.Fragment>;
    }

    return <TokenDetails token={tokenTemplate} isPanel />;
};

const CampaignContent: FunctionComponent<{}> = React.memo(() => {
    const { campaign, location } = useLocation();
    const { primary, secondary } = useSelection();
    const {
        mode,
        isSearchExpanded,
        panels,
        isPropertiesExpanded,
        setIsPropertiesExpanded,
        propertiesPage,
        setPropertiesPage,
    } = useVttApp();
    const role = useRole();
    const user = useUser();
    const rules = useRules();

    let combatToken: ResolvedToken<DnD5EToken> | undefined;
    if (isLocation(location)) {
        // Get the selected token. If we can find a single token, then we can show its combat options.
        const selectedTokens = getSelectedTokens(primary, location, secondary);
        if (selectedTokens.length === 1 && isDnD5EToken(selectedTokens[0])) {
            combatToken = resolveToken(campaign, selectedTokens[0]);
        }
    }

    const characterSheet = useCharacterSheet();
    const monsterSheet = useMonsterSheet();

    useKeyboardShortcut("i", () => {
        if (
            isPropertiesExpanded &&
            propertiesPage === characterSheetSection &&
            characterSheet.page === CharacterSheetPage.Equipment
        ) {
            setIsPropertiesExpanded(false);
        } else {
            const token = getTokenForPlayer(user, location, primary, secondary);
            if (isDnD5ECharacterToken(token)) {
                setIsPropertiesExpanded(true);
                setPropertiesPage(characterSheetSection);
                characterSheet.setPage(CharacterSheetPage.Equipment);
            }
        }
    });
    useKeyboardShortcut("c", () => {
        const token = getTokenForPlayer(user, location, primary, secondary);
        if (isDnD5ECharacterToken(token)) {
            if (
                isPropertiesExpanded &&
                propertiesPage === characterSheetSection &&
                characterSheet.page === CharacterSheetPage.CoreAbilities
            ) {
                setIsPropertiesExpanded(false);
            } else {
                setIsPropertiesExpanded(true);
                setPropertiesPage(characterSheetSection);
                characterSheet.setPage(CharacterSheetPage.CoreAbilities);
            }
        } else if (isDnD5EMonsterToken(token)) {
            if (
                isPropertiesExpanded &&
                propertiesPage === characterSheetSection &&
                monsterSheet.page === MonsterSheetPage.CoreAbilities
            ) {
                setIsPropertiesExpanded(false);
            } else {
                setIsPropertiesExpanded(true);
                setPropertiesPage(characterSheetSection);
                monsterSheet.setPage(MonsterSheetPage.CoreAbilities);
            }
        }
    });
    useKeyboardShortcut("m", () => {
        const token = getTokenForPlayer(user, location, primary, secondary);
        if (isDnD5ECharacterToken(token)) {
            if (
                isPropertiesExpanded &&
                propertiesPage === characterSheetSection &&
                characterSheet.page === CharacterSheetPage.Spells
            ) {
                setIsPropertiesExpanded(false);
            } else {
                setIsPropertiesExpanded(true);
                setPropertiesPage(characterSheetSection);
                characterSheet.setPage(CharacterSheetPage.Spells);
            }
        } else if (isDnD5EMonsterToken(token)) {
            if (
                isPropertiesExpanded &&
                propertiesPage === characterSheetSection &&
                monsterSheet.page === MonsterSheetPage.Spells
            ) {
                setIsPropertiesExpanded(false);
            } else {
                const monster = fullResolveTokenCreature(token, campaign, rules) as ResolvedMonster;
                if (monster.spellcasting) {
                    setIsPropertiesExpanded(true);
                    setPropertiesPage(characterSheetSection);
                    monsterSheet.setPage(MonsterSheetPage.Spells);
                }
            }
        }
    });
    useKeyboardShortcut("g", () => {
        const token = getTokenForPlayer(user, location, primary, secondary);
        if (isDnD5ECharacterToken(token)) {
            if (
                isPropertiesExpanded &&
                propertiesPage === characterSheetSection &&
                characterSheet.page === CharacterSheetPage.Actions
            ) {
                setIsPropertiesExpanded(false);
            } else {
                setIsPropertiesExpanded(true);
                setPropertiesPage(characterSheetSection);
                characterSheet.setPage(CharacterSheetPage.Actions);
            }
        } else if (isDnD5EMonsterToken(token)) {
            if (
                isPropertiesExpanded &&
                propertiesPage === characterSheetSection &&
                monsterSheet.page === MonsterSheetPage.Actions
            ) {
                setIsPropertiesExpanded(false);
            } else {
                setIsPropertiesExpanded(true);
                setPropertiesPage(characterSheetSection);
                monsterSheet.setPage(MonsterSheetPage.Actions);
            }
        }
    });

    return (
        <AnimatePresence mode="wait">
            {combatToken &&
                mode === "play" &&
                (role === "GM" || combatToken.owner === user.id) &&
                panels.length < 2 && (
                    <Box
                        key={combatToken.id}
                        gridArea="leftinnerbottom"
                        position="relative"
                        zIndex={1}
                        pl={isSearchExpanded ? undefined : 3}
                        pb={3}
                        css={{ pointerEvents: "none" }}>
                        <CombatActions token={combatToken} />
                    </Box>
                )}
        </AnimatePresence>
    );
});

// Max height set because Firefox has a strange behaviour where the size of the log keeps increasing, and setting the max height stops it.
var SelectionLogoElement = React.memo(() => <DnDLogo style={{ margin: -12, maxHeight: 48, fill: "currentcolor" }} />);

var GlobalLogoElement = React.memo(() => (
    <DnDLogo style={{ fill: "currentcolor", transform: "scale(1.5)" }} width="24" height="24" />
));

const MyLogo: FunctionComponent<{}> = () => {
    return <SelectionLogoElement />;
};

function getTokenForPlayer(
    user: UserInfo,
    location: Location | LocationSummary | undefined,
    primary: string[],
    secondary: string[]
) {
    // TODO: If the user is a player, show:
    // 1) The first selected token that is controlled by the player.
    // 2) Always fall back to the player's primary token.
    const tokens = isLocation(location) ? getSelectedTokens(primary, location, secondary) : undefined;
    const token = tokens?.length ? tokens[0] : undefined;

    return token;
}

const DnDPlayerSection: FunctionComponent<{}> = () => {
    const { location } = useLocation();
    const { primary, secondary } = useSelection();
    const user = useUser();

    return <TokenDetails token={getTokenForPlayer(user, location, primary, secondary)} />;
};

function getTokenAppearanceForRules(
    token: Token,
    campaign: Campaign,
    location: Location,
    rules: CharacterRuleSet
): TokenAppearance {
    // If the token has a transform effect applied, the appearance might be different.
    if (isDnD5EToken(token)) {
        const creature = resolveTokenCreature(token, campaign, rules);
        if (creature) {
            const effectKey = getTransformEffectKey(creature, rules);
            if (effectKey) {
                const appearance = creature.effects![effectKey].transform!.appearance;
                return appearance ?? token;
            }
        }
    }

    return token;
}

function getAttackInfo(label: string | undefined, bonus: string | undefined) {
    let finalBonus = 0;
    var bs = bonus ?? label;
    if (bs != null) {
        var b = parseInt(bs, 10);
        if (!isNaN(b)) {
            finalBonus = b;
        }
    }

    return {
        label:
            label === finalBonus.toString(10) && finalBonus > 0
                ? `+${label}`
                : label ?? (finalBonus > 0 ? `+${finalBonus}` : finalBonus.toString()),
        bonus: finalBonus,
    };
}

function getRechargeValue(label: string | undefined, value: string | undefined) {
    let v: number | undefined;
    if (value == null) {
        if (label != null && typeof label === "string") {
            v = parseInt(label, 10);
        }
    } else {
        v = parseInt(value, 10);
    }

    if (v == null || isNaN(v) || v < 1 || v > 6) {
        v = 6;
    }

    return {
        label: label ?? v.toString(),
        value: v,
    };
}

function updateAttributes<T extends { name: string; displayName?: string; source?: string }>(
    attributes: {
        [name: string]: string | undefined;
        label?: string | undefined;
    },
    prop: string,
    ...rules: NamedRuleStore<T>[]
) {
    const name = attributes[prop] ?? attributes.id ?? attributes.name ?? attributes.label ?? "";
    const ruleKey = fromRuleKey(name);

    for (let ruleStore of rules) {
        const rule = ruleKey ? ruleStore.get(ruleKey) : ruleStore.getByName(name);
        if (rule) {
            attributes.id = ruleKey ? name : getRuleKey(rule);
            attributes.label =
                !attributes.label || attributes.label === attributes.id
                    ? rule.displayName ?? rule.name
                    : attributes.label;
            delete attributes[prop];
            delete attributes.name;
        }
    }

    return attributes;
}

const characterSheetSection: PlayerSection = {
    id: "charactersheet",
    label: "Character sheet",
    renderGlyph: () => <MyLogo />,
    render: () => <DnDPlayerSection />,
};

class DnD5eSystem implements IGameSystem {
    // private _creatureMetadata: Map<string, CreatureMetadata> | undefined;
    private _monsterTokenMap: { [id: string]: DnD5EMonsterTemplate } | undefined;

    private _sources: string[] | undefined;
    private _campaignRuleStore: CharacterRuleStore | undefined;
    private _rules: CharacterRuleSet;

    private _spellsSearchable: LocalSearchable<Spell & { key: string }, () => JSX.Element> | undefined;
    private _conditionsSearchable: LocalSearchable<CreatureCondition, () => JSX.Element> | undefined;
    private _skillsSearchable: LocalSearchable<SkillDetails, () => JSX.Element> | undefined;
    private _actionsSearchable:
        | LocalSearchable<Ability & NamedRuleRef & { key: string }, () => JSX.Element>
        | undefined;
    private _itemsSearchable: LocalSearchable<ResolvedItem & { key: string }, () => JSX.Element> | undefined;
    private _featuresSearchable: LocalSearchable<Feature & { key: string }, () => JSX.Element> | undefined;
    private _featsSearchable: LocalSearchable<Feature & { key: string }, () => JSX.Element> | undefined;

    private _playerSectionsLocation: PlayerSection[] = [
        {
            id: "encounter",
            label: "Encounter",
            renderGlyph: () => <EncounterIcon size={32 as any} />,
            render: () => <LocationDetails />,
        },
    ];
    private _playerSectionsToken: PlayerSection[] = [characterSheetSection, ...this._playerSectionsLocation];

    private _globalSections: GmSection[] = [
        {
            id: "dnd5e",
            label: "D&D",
            labelLowerCase: "D&D",
            roles: ["GM", "Player"],
            renderGlyph: () => <GlobalLogoElement />,
            render: () => <Compendium />,
            renderTools: () => <CompendiumTools />,
        },
    ];

    private _rulesChanged = new Event<CharacterRuleSet>();

    id = "dnd5e";
    defaultUnit = "ft";
    defaultUnitsPerGrid = defaultUnitsPerGrid;
    defaultLight = {
        innerRadius: 4, // Default is a standard D&D 5e torch - 20ft bright light, 20ft dim light.
        outerRadius: 8,
        brightness: 1,
        color: "#FFFFFF",
    };

    dropTypes = ["DnD5E_Item"];

    timePerCombatRound = msPerRound;

    constructor() {
        this._rules = createRuleSet([]);
    }

    // HANDLED
    // {@spell name}
    // {@condition <name>}
    // {@skill <name>}
    // {@action <name>}
    // {@item <name>|<source>}

    // NOT HANDLED:
    // {@background <name>}
    // {@dice [d4|d6|d8|d10|d12|d20]}
    // {@creature <nameorkey>|<label?>}

    // Format:
    // :item[label]{item=Longsword}
    // :attack[+4]{type=melee bonus=4} //TODO: Include the name too? (i.e. longsword, beak, claw, etc)
    private readonly _customNodes: CustomMarkdownNode[] = [
        {
            name: "spell",
            type: "inline",
            attributes: {
                spell: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                return updateAttributes(attributes, "spell", this._rules.spells);
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <SpellPopup ref={ref} label={label} name={id}>
                        {children}
                    </SpellPopup>
                );
            },
        },
        {
            name: "skill",
            type: "inline",
            attributes: {
                skill: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                const name = attributes.skill || attributes.id || attributes.name || attributes.label || "";
                const skill = this._rules.skills[name.toLowerCase()] as SkillDetails;
                if (skill) {
                    if (!attributes.label) {
                        attributes.label = skill.label;
                    }

                    attributes.id = name.toLowerCase();
                    delete attributes.skill;
                    delete attributes.name;
                }

                return attributes;
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <SkillPopup ref={ref} label={label} name={id}>
                        {children}
                    </SkillPopup>
                );
            },
        },
        {
            name: "condition",
            type: "inline",
            attributes: {
                condition: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                const name = attributes.condition || attributes.id || attributes.name || attributes.label || "";
                const condition = this._rules.conditions.get(name?.toLowerCase());
                if (condition) {
                    attributes.label = attributes.label ?? condition.name;
                    attributes.id = condition.name;
                    delete attributes.condition;
                    delete attributes.name;
                    return attributes;
                }

                const status = this._rules.statuses.getByName(name);
                if (status) {
                    attributes.label = attributes.label ?? status.name;
                    attributes.id = status.name;
                    delete attributes.condition;
                    delete attributes.name;
                }

                return attributes;
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <ConditionPopup ref={ref} label={label} name={id}>
                        {children}
                    </ConditionPopup>
                );
            },
        },
        {
            name: "action",
            type: "inline",
            attributes: {
                action: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                return updateAttributes(attributes, "action", this._rules.actions);
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <ActionPopup ref={ref} label={label} name={id}>
                        {children}
                    </ActionPopup>
                );
            },
        },
        {
            name: "item",
            type: "inline",
            attributes: {
                item: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                return updateAttributes(attributes, "item", this._rules.items.items);
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <ItemPopup ref={ref} label={label} name={id}>
                        {children}
                    </ItemPopup>
                );
            },
        },
        {
            name: "attack",
            type: "inline",
            attributes: {
                type: { default: undefined },
                bonus: { default: undefined },
            },
            updateAttributes: attributes => {
                if (!attributes.label) {
                    const info = getAttackInfo(undefined, attributes.bonus);
                    if (info.label) {
                        attributes.label = info.label;
                    }
                }

                return attributes;
            },
            render: ({ label, type, bonus, ref, children }) => {
                const info = getAttackInfo(label, bonus);
                return (
                    <AttackPopup ref={ref} label={info.label} type={type as AttackType} bonus={info.bonus}>
                        {children}
                    </AttackPopup>
                );
            },
        },
        {
            name: "damage",
            type: "inline",
            attributes: {
                roll: { default: undefined },
                type: { default: undefined },
            },
            updateAttributes: attributes => {
                attributes.label = attributes.label ?? attributes.roll;
                return attributes;
            },
            render: ({ label, roll, type, ref, children }) => {
                return (
                    <DamagePopup
                        ref={ref}
                        label={label}
                        type={
                            type != null && damageTypes.indexOf(type as DamageType) >= 0
                                ? (type as DamageType)
                                : "bludgeoning"
                        }
                        roll={roll ?? label ?? ""}>
                        {children}
                    </DamagePopup>
                );
            },
        },
        {
            name: "recharge",
            type: "inline",
            attributes: {
                value: { default: undefined },
            },
            updateAttributes: attributes => {
                if (!attributes.label) {
                    const recharge = getRechargeValue(undefined, attributes.value);
                    attributes.label = recharge.label;
                }

                return attributes;
            },
            render: ({ label, value, ref, children }) => {
                const info = getRechargeValue(label, value);
                return (
                    <RechargePopup ref={ref} label={info.label} value={info.value}>
                        {children}
                    </RechargePopup>
                );
            },
        },
        {
            name: "roll",
            type: "inline",
            attributes: {
                roll: { default: undefined },
            },
            updateAttributes: attributes => {
                attributes.label = attributes.label ?? attributes.roll;
                return attributes;
            },
            render: ({ label, roll, ref, children }) => {
                return (
                    <RollPopup ref={ref} label={label ?? roll} roll={roll ?? label}>
                        {children}
                    </RollPopup>
                );
            },
        },
        {
            name: "feature",
            type: "inline",
            attributes: {
                feature: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                return updateAttributes(attributes, "feature", this._rules.features, this._rules.feats);
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <FeaturePopup ref={ref} label={label} name={id}>
                        {children}
                    </FeaturePopup>
                );
            },
        },
        {
            name: "monster",
            type: "inline",
            attributes: {
                monster: { default: undefined },
                id: { default: undefined },
                name: { default: undefined },
            },
            updateAttributes: attributes => {
                return updateAttributes(attributes, "monster", this._rules.monsters);
            },
            render: ({ label, id, ref, children }) => {
                return (
                    <MonsterPopup ref={ref} label={label} name={id}>
                        {children}
                    </MonsterPopup>
                );
            },
        },
    ];

    private resolveRuleSet(sources: string[], campaignRules: CharacterRuleStore | undefined) {
        const ruleStores: CharacterRuleStore[] = [];
        for (let source of sources) {
            const ruleStore = getCachedRuleStore(source);
            if (ruleStore) {
                ruleStores.push(ruleStore);
            }
        }

        if (campaignRules) {
            ruleStores.push(campaignRules);
        }

        // Resolve the IDs again now. We need to work out if the SRD is really required.
        const ids = ruleStores.map(o => o.id).filter(o => o.toLowerCase() !== "srd");
        const resolvedIds = resolveCampaignSources(ids);
        ruleStores.length = 0;
        for (let id of resolvedIds) {
            const ruleStore = getCachedRuleStore(id);
            if (ruleStore) {
                ruleStores.push(ruleStore);
            }
        }

        return createRuleSet(ruleStores);
    }

    private async updateCampaignRules(campaign: DnD5ECampaign): Promise<CharacterRuleSet> {
        let campaignSources = campaign.dnd5e?.sources;

        const sources = resolveCampaignSources(campaignSources);
        if (
            this._rules &&
            areArraysEqual(sources, this._sources) &&
            this._campaignRuleStore === campaign.dnd5e?.ruleset
        ) {
            return this._rules;
        }

        this._sources = sources;
        this._campaignRuleStore = campaign.dnd5e?.ruleset;

        // TODO: If another user has joined the campaign, they might not (probably don't) have the same ruleset.
        // What we need to do is store the rulesets as urls rather than IDs so that other players can download them.
        // The well known ones, such as SRD or in the future rulesets from a store, could stay as an ID.

        // TODO: Await fetching the user's personal list of rule stores.
        // Actually, we don't need to do this here if we have stored the rulesets as urls instead of IDs. We need to
        // do this in the ruleset picker, though.

        const responses = this._sources.map(async o => {
            return getRuleStore(o);
        });

        const results = await Promise.allSettled(responses);

        this._rules = this.resolveRuleSet(this._sources!, this._campaignRuleStore);
        this.onRuleSetChanged(this._rules);

        delete this._spellsSearchable;
        delete this._conditionsSearchable;
        delete this._skillsSearchable;
        delete this._actionsSearchable;
        delete this._itemsSearchable;
        delete this._featuresSearchable;
        delete this._monsterTokenMap;

        this._rulesChanged.trigger(this._rules);

        // TODO: Do something with these results? Log any errors?
        for (let result of results) {
            if (result.status === "rejected") {
                console.error(result.reason);
            }
        }

        return this._rules;
    }

    private onRuleSetChanged(ruleSet: CharacterRuleSet) {
        const torch = ruleSet.items.items.get({ name: "Torch", source: "PHB" });
        if (torch) {
            torch.lights = [{ type: "torch" }];
        }
    }

    async search(query: string, role: CampaignRole) {
        const results: SearchCategoryResults<any>[] = [];

        if (!this._spellsSearchable) {
            this._spellsSearchable = new LocalSearchable<Spell & { key: string }, () => JSX.Element>(
                this._rules.spells.all,
                {
                    idField: "key",
                    searchableFields: ["name", "school", "displayName"],
                    toResult: spell => () => {
                        return <SpellInfo isInOverlay spell={spell!} />;
                    },
                }
            );
        }

        const spells = this._spellsSearchable.search(query);
        results.push({
            categoryId: "spells",
            categoryLabel: "Spells",
            results: spells,
            jumpTo: () => <CompendiumJumpTo key="dnd5e_spells" page={CompendiumPage.Spells} />,
        });

        if (!this._conditionsSearchable) {
            this._conditionsSearchable = new LocalSearchable<CreatureCondition, () => JSX.Element>(
                Array.from(this._rules.conditions.values()),
                {
                    idField: "name",
                    searchableFields: ["name"],
                    toResult: condition => () => {
                        return (
                            <FeatureExpander isInOverlay title={condition.name}>
                                <MarkdownActions mb={2} markdown={`:condition[${condition.name}]`} />
                                <Markdown>{condition.content}</Markdown>
                            </FeatureExpander>
                        );
                    },
                }
            );
        }

        const conditions = await this._conditionsSearchable.search(query);
        results.push({
            categoryId: "conditions",
            categoryLabel: "Conditions",
            results: conditions,
        });

        if (!this._skillsSearchable) {
            this._skillsSearchable = new LocalSearchable<SkillDetails, () => JSX.Element>(
                Object.values(this._rules.skills),
                {
                    idField: "label",
                    searchableFields: ["label"],
                    toResult: skill => () => {
                        return (
                            <FeatureExpander isInOverlay title={skill.label}>
                                <MarkdownActions mb={2} markdown={`:skill[${skill.label}]`} />
                                <Markdown>{skill.content}</Markdown>
                            </FeatureExpander>
                        );
                    },
                }
            );
        }

        const skills = await this._skillsSearchable.search(query);
        results.push({
            categoryId: "skills",
            categoryLabel: "Skills",
            results: skills,
        });

        if (!this._actionsSearchable) {
            this._actionsSearchable = new LocalSearchable<Ability & NamedRuleRef & { key: string }, () => JSX.Element>(
                this._rules.actions.all,
                {
                    idField: "key",
                    searchableFields: ["name"],
                    toResult: action => () => {
                        return (
                            <FeatureExpander isInOverlay title={action.name}>
                                <MarkdownActions mb={2} markdown={`:action{id="${getRuleKey(action)}"}`} />
                                <Markdown>{action.content}</Markdown>
                            </FeatureExpander>
                        );
                    },
                }
            );
        }

        const actions = await this._actionsSearchable.search(query);
        results.push({
            categoryId: "actions",
            categoryLabel: "Actions",
            results: actions,
        });

        if (!this._itemsSearchable) {
            this._itemsSearchable = new LocalSearchable<ResolvedItem & { key: string }, () => JSX.Element>(
                this._rules.items.items.allResolved,
                {
                    idField: "key",
                    searchableFields: ["name"],
                    toResult: item => () => {
                        return <ItemSearchResult item={item} />;
                    },
                }
            );
        }

        const items = await this._itemsSearchable.search(query);
        results.push({
            categoryId: "items",
            categoryLabel: "Items",
            results: items,
            jumpTo: () => <CompendiumJumpTo key="dnd5e_items" page={CompendiumPage.Items} />,
        });

        if (!this._featuresSearchable) {
            this._featuresSearchable = new LocalSearchable<Feature & { key: string }, () => JSX.Element>(
                this._rules.features.all,
                {
                    idField: "key",
                    searchableFields: ["name", "content"],
                    toResult: feature => () => {
                        return (
                            <FeatureExpander isInOverlay title={feature.name}>
                                <MarkdownActions mb={2} markdown={`:feature{id="${getRuleKey(feature)}"}`} />
                                <Markdown>{feature.content}</Markdown>
                            </FeatureExpander>
                        );
                    },
                }
            );
        }

        const features = await this._featuresSearchable.search(query);
        results.push({
            categoryId: "features",
            categoryLabel: "Features",
            results: features,
        });

        if (!this._featsSearchable) {
            this._featsSearchable = new LocalSearchable<Feature & { key: string }, () => JSX.Element>(
                this._rules.feats.all,
                {
                    idField: "key",
                    searchableFields: ["name", "content"],
                    toResult: feature => () => {
                        return (
                            <FeatureExpander isInOverlay title={feature.name}>
                                <MarkdownActions mb={2} markdown={`:feature{id="${getRuleKey(feature)}"}`} />
                                <Markdown>{feature.content}</Markdown>
                            </FeatureExpander>
                        );
                    },
                }
            );
        }

        const feats = await this._featsSearchable.search(query);
        results.push({
            categoryId: "feats",
            categoryLabel: "Feats",
            results: feats,
            jumpTo: () => <CompendiumJumpTo key="dnd5e_feats" page={CompendiumPage.Feats} />,
        });

        return results;
    }

    getDragPreview(
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        type: string,
        data: any,
        dispatch: Dispatch,
        dropPoint?: LocalPixelPosition
    ): DragPreview[] | undefined {
        if (type === "DnD5E_Item") {
            // Items can be dropped onto any character token.
            const tokens = Object.values(location.tokens);

            const draggedItems = data as DraggedItems;
            const previews: DragPreview[] = [];
            for (let token of tokens) {
                if (isDnD5ECharacterToken(token)) {
                    const appearance = this.getTokenAppearance(token, campaign, location);
                    const gridPoint = grid.toGridPoint(token.pos);
                    const hover = dropPoint != null && pointsEqual(gridPoint, grid.toGridPoint(dropPoint));
                    previews.push({
                        shape: grid.toLocalPoints(gridPoint, appearance.scale ?? 1),
                        hover: hover,
                        feedback: hover
                            ? `Drop to add ${
                                  draggedItems.items.length === 1
                                      ? draggedItems.items[0].name
                                      : `${draggedItems.items.length} items`
                              } to ${this.getDisplayName(token, campaign)}'s inventory`
                            : undefined,
                        onDrop: () => {
                            dispatch(
                                addItems(campaign, location, [], {
                                    items: draggedItems.items,
                                    from: draggedItems.token,
                                    to: token,
                                })
                            );
                        },
                    });
                }
            }

            return previews;
        }

        return undefined;
    }

    getTokenTemplates() {
        if (!this._monsterTokenMap) {
            this._monsterTokenMap = {};
            const monsters = this._rules.monsters.all;
            for (let i = 0; i < monsters.length; i++) {
                const o = monsters[i];
                if (!o.mainForm) {
                    const tokenTemplate = monsterToTokenTemplate(o);
                    this._monsterTokenMap[tokenTemplate.templateId] = tokenTemplate;
                }
            }
        }

        return this._monsterTokenMap;
    }

    getDisplayName(token: Token | TokenTemplate, campaign: Campaign) {
        if (isDnD5EToken(token) || isDnD5ETokenTemplate(token)) {
            const creature = resolveTokenCreature(token, campaign, this._rules);
            return creature?.name;
        }

        // TODO: This should probably be somewhere generic rather than dnd5e.
        if (token.imageUri) {
            return getFileName(token.imageUri);
        }

        return undefined;
    }

    getLightsForToken(token: Token, campaign: Campaign): Light[] | undefined {
        const lights: Light[] = [];
        //ARGH need to resolve the token inventory too.
        if (isDnD5ECharacterToken(token)) {
            const creature = resolveTokenCreature(token, campaign, this._rules) as Character;
            if (creature) {
                const character = resolveCharacter(creature, campaign, this._rules);

                const inventoryKeys = Object.keys(character.inventory);
                for (let i = 0; i < inventoryKeys.length; i++) {
                    const item = character.inventory[inventoryKeys[i]];
                    if (item!.active) {
                        const resolvedItem = resolveItem(item, this._rules.items);
                        if (resolvedItem.lights && resolvedItem.lights.length) {
                            lights.push(...resolvedItem.lights);
                        }
                    }
                }
            }
        }

        return lights;
    }

    getTokenTemplateSearchFields() {
        return [
            {
                name: "name",
                boost: 10,
                extractor: o => {
                    return isDnD5ETokenTemplate(o) ? o.dnd5e.name : "";
                },
            },
            {
                name: "creatureType",
                boost: 1,
                extractor: o => {
                    return isDnD5EMonsterTemplate(o)
                        ? o.dnd5e.creatureType
                            ? creatureTypeToString(o.dnd5e.creatureType)
                            : ""
                        : "";
                },
            },
        ];
        //     // "type", "subtype", "size"
        //     return [{
        //         name: "name",
        //         boost: 10,
        //         extractor: o => (o as DnD5EToken).dnd5e?.name ?? ""
        //     }, {
        //         name: "type",
        //         extractor: o => (o as DnD5EToken).dnd5e?.type ?? ""
        //     }, {
        //         name: "subtype",
        //         extractor: o => (o as DnD5EToken).dnd5e?.subtype ?? ""
        //     }, {
        //         name: "size",
        //         extractor: o => (o as DnD5EToken).dnd5e?.size ?? ""
        //     }];
    }

    getTokenContextMenuItems(
        target: ResolvedToken | TokenTemplate | undefined,
        selection: Token[],
        campaign: Campaign,
        location?: Location
    ) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const addOverlay = useVttApp(state => state.addOverlay);
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const dispatch = useDispatch();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const role = useRole();
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const user = useUser();

        const items: IMenuItem[] = [];
        if (target) {
            if (isDnD5ECharacterTemplate(target)) {
                items.push({
                    label: "Edit…",
                    disabled: !campaign.tokens[target.templateId],
                    onClick: () => addOverlay(getCreatureEditor(target.templateId)),
                });
                // } else if (isDnD5EMonsterTemplate(target)) {
                //     items.push({
                //         label: "Clone",
                //         onClick: () => {
                //             const clonedCreature = copyState(target.dnd5e, {
                //                 name: `${target.dnd5e.name} (cloned)`,
                //                 source: campaign.id,
                //             });
                //             var clonedTemplate = copyState(target, {
                //                 templateId: nanoid(),
                //                 dnd5e: clonedCreature,
                //             });

                //             dispatch(addTokenTemplate(campaign.id, clonedTemplate));
                //         },
                //     });
                //     items.push({
                //         label: "Edit…",
                //         disabled: !campaign.tokens[target.templateId],
                //         onClick: () => addOverlay(getCreatureEditor(target.templateId)),
                //     });
            } else {
                if (role === "GM") {
                    const alreadyInCombat = selection.every(o => location!.combat?.participants[o.id]);
                    if (!alreadyInCombat) {
                        items.push({
                            label: "Add to combat tracker",
                            onClick: () =>
                                dispatch(
                                    addCombatParticipants(
                                        campaign.id,
                                        location!.id,
                                        selection.map(o => o.id)
                                    )
                                ),
                        });
                    }
                }

                const isOwnerOrGM = role === "GM" || target.owner === user.id;
                if (isOwnerOrGM) {
                    if (isDnD5ECharacterToken(target)) {
                        let tokenOrTemplate: DnD5ECharacterToken | DnD5ECharacterTemplate = target;
                        if (target.templateId && !target.ignoreTemplate) {
                            const template = campaign.tokens[target.templateId];
                            if (template && isDnD5ECharacterTemplate(template)) {
                                tokenOrTemplate = template;
                            }
                        }

                        const id = isTokenTemplate(tokenOrTemplate) ? tokenOrTemplate.templateId : tokenOrTemplate.id;
                        items.push({
                            label: "Edit…",
                            onClick: () => addOverlay(getCreatureEditor(id, location?.id)),
                        });
                    } else if (isDnD5EMonsterToken(target)) {
                        // TODO: What about monsters that we want to be treated like characters? i.e. the BBEG dragon that we cloned from
                        // an ancient red dragon and tweaked - we want this to be treated like a character, i.e. changes to the instance
                        // are made to the template.
                        var resolvedTarget = resolveToken(campaign, target);
                        items.push({
                            label: "Edit…",
                            onClick: () => addOverlay(getCreatureEditor(resolvedTarget.id, location?.id)),
                        });
                    }
                }
            }
        }

        return items;
    }

    getTokenRange(token: Token, campaign: Campaign, location: Location): number | undefined {
        // If the creature is not in combat then it can just move anywhere, no restrictions required.
        if (location.combat?.participants[token.id] && isDnD5EToken(token)) {
            let speed = token.dnd5e.combatTurn?.movement;
            if (!speed) {
                const creature = fullResolveTokenCreature(token, campaign, this._rules);
                speed = creature != null ? getResolvedMovementSpeeds(creature) : undefined;
            }

            if (speed) {
                // Return the max one for now - in the future we may convert to moving only with a particular movement type at any one
                // time, so that we can deal with effects that might not effect some types of movement (i.e. flying over difficult
                // terrain, etc). For now we'll leave that to the DM.
                let maxSpeed = 0;
                for (let movementType in speed) {
                    const s = speed[movementType as MovementType];
                    if (s != null && s > maxSpeed) {
                        maxSpeed = s;
                    }
                }

                return maxSpeed / (location.unitsPerGrid ?? this.defaultUnitsPerGrid);
            }
        }

        return undefined;
    }

    getTokenAppearance(token: Token, campaign: Campaign, location: Location): TokenAppearance {
        return getTokenAppearanceForRules(token, campaign, location, this._rules);
    }

    useTokenAppearance(token: Token, campaign: Campaign, location: Location): TokenAppearance {
        /* eslint-disable react-hooks/rules-of-hooks */
        const rules = useRules();
        /* eslint-enable react-hooks/rules-of-hooks */

        return getTokenAppearanceForRules(token, campaign, location, rules);
    }

    getTokenDarkvision(token: Token, campaign: Campaign, location: Location) {
        if (isDnD5EToken(token)) {
            let creature = fullResolveTokenCreature(token, campaign, this._rules);
            creature = creature?.transformedInto ?? creature;
            if (creature) {
                const dv = resolveModifiedValue(creature.senses.darkvision);
                return dv / defaultUnitsPerGrid;
            }
        }

        return 0;
    }

    renderTokenTemplate(tokenTemplate: TokenTemplate) {
        if (!isDnD5ETokenTemplate(tokenTemplate)) {
            return null;
        }

        return <TokenListItem token={tokenTemplate} />;
    }

    getTokenTemplatePanel(tokenTemplate: TokenTemplate): SidebarPanelState | undefined {
        if (!isDnD5ETokenTemplate(tokenTemplate)) {
            return undefined;
        }

        return {
            id: tokenTemplate.templateId,
            width: 400, // TODO: This should be the same width as the rhs panel?
            children: () => (
                <TokenTemplatePanelContent
                    tokenId={tokenTemplate.templateId}
                    getSystemTemplate={id => {
                        const templates = this.getTokenTemplates();
                        return templates?.[id];
                    }}
                />
            ),
        };
    }

    getContexts(): React.Context<any>[] {
        return [DnD5eCampaignContext];
    }

    renderContexts = ({ children }) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const rules = useEvent(this._rulesChanged, this._rules);
        /* eslint-enable react-hooks/rules-of-hooks */

        return (
            <DnD5eCampaignContext.Provider
                value={{
                    rules: rules,
                }}>
                {children}
            </DnD5eCampaignContext.Provider>
        );
    };

    renderCampaign = ({ children }) => {
        /* eslint-disable react-hooks/rules-of-hooks */
        const { campaign } = useCampaign();
        const rules = useEvent(this._rulesChanged, this._rules);

        this.updateCampaignRules(campaign as DnD5ECampaign);

        return (
            <DnD5eCampaignContext.Provider
                value={{
                    rules: rules,
                }}>
                <React.Fragment>{children}</React.Fragment>
            </DnD5eCampaignContext.Provider>
        );
    };

    renderCampaignContent() {
        return <CampaignContent />;
    }

    renderTokenTemplates() {
        /* eslint-disable react-hooks/rules-of-hooks */
        useLocalSetting(tokenTemplateFilterSetting);
        return <React.Fragment></React.Fragment>;
        /* eslint-enable react-hooks/rules-of-hooks */
    }

    filterTokenTemplates(allTokens: TokenTemplate[], campaign: Campaign, searchTerm?: string) {
        const filter = tokenTemplateFilterSetting.getValue();

        if (filter) {
            if (filter.light) {
                let lights = allTokens.filter(o => o.light != null);
                return {
                    tokenTemplates: lights,
                    filterMessage: searchTerm
                        ? `Showing only light sources matching "${searchTerm}"`
                        : "Showing only light sources",
                };
            }

            if (filter.monster) {
                let monsters = filterMonsterTemplates(allTokens, filter.monster);

                return {
                    tokenTemplates: monsters,
                    filterMessage: searchTerm
                        ? `Showing only monsters matching "${searchTerm}"`
                        : "Showing only monsters",
                };
            }

            if (filter.pc) {
                let pcs = allTokens.filter(o => {
                    if (!(isDnD5ECharacterTemplate(o) && o.owner != null)) {
                        return false;
                    }

                    // TODO: Further filtering by specific attributes.
                    return true;
                });

                return {
                    tokenTemplates: pcs,
                    filterMessage: searchTerm ? `Showing only PCs matching "${searchTerm}"` : "Showing only PCs",
                };
            }

            if (filter.npc) {
                let npcs = allTokens.filter(o => {
                    if (!(isDnD5ECharacterTemplate(o) && o.owner == null)) {
                        return false;
                    }

                    // TODO: Further filtering by specific attributes.
                    return true;
                });

                return {
                    tokenTemplates: npcs,
                    filterMessage: searchTerm ? `Showing only NPCs matching "${searchTerm}"` : "Showing only NPCs",
                };
            }
        }

        return {
            tokenTemplates: allTokens,
        };
    }

    renderTokenTools() {
        return <TokenTools />;
    }

    renderDiceTools(props) {
        return <DiceTools {...props} />;
    }

    renderGetInitiative(token: ResolvedToken, setInitiative: (initiative: number[]) => void) {
        if (!isDnD5EToken(token)) {
            return null;
        }

        return <RollForInitiative token={token} setInitiative={setInitiative} />;
    }

    renderCombatTrackerItem(token: ResolvedToken, isCurrent: boolean, participant: CombatParticipant) {
        return isDnD5EToken(token) ? (
            <CombatTrackerItem token={token} isCurrent={isCurrent} participant={participant} />
        ) : undefined;
    }

    renderAnnotationTemplateDetails?(
        template: AnnotationPlacementTemplate<Annotation>,
        campaign: Campaign,
        location: Location
    ) {
        if (!template.annotation.tokenId || !(template.annotation as any).dnd5e) {
            return null;
        }

        const annotation = template.annotation as Omit<DnD5EAnnotation, "id" | "userId">;

        const token = location.tokens[annotation.tokenId!];
        if (!token) {
            return null;
        }

        const tokenName = this.getDisplayName(token, campaign);
        const ability = getAbility(annotation, campaign, location, this._rules);
        if (ability) {
            if (isSpell(ability)) {
                return <Markdown>{`${tokenName} is casting ${ability.name}`}</Markdown>;
            } else {
                if (ability.attack === "mw" || ability.attack === "rw") {
                    return <Markdown>{`${tokenName} is attacking with ${ability.name}`}</Markdown>;
                } else {
                    return <Markdown>{`${tokenName} is using ability ${ability.name}`}</Markdown>;
                }
            }
        }

        return null;
    }

    getGlobalSections() {
        return this._globalSections;
    }

    getPlayerSections(selection: { primary: string[]; secondary: string[] }, location: Location) {
        const tokens = getSelectedTokens(selection.primary, location, selection.secondary);
        if (tokens.some(o => isDnD5EToken(o))) {
            return this._playerSectionsToken;
        }

        return this._playerSectionsLocation;
    }

    renderTokenAdorners(props: TokenAdornerProps) {
        const { token, ...newProps } = props;
        if (!isDnD5EToken(token)) {
            return [];
        }

        return [<TokenAdorner key={token.id + "_dnd5e_status"} token={token} {...newProps} />];
    }

    renderAnnotationAdorners(props: AnnotationAdornerProps) {
        const { annotation, ...newProps } = props;
        if (!isDnD5EAnnotation(annotation)) {
            return [];
        }

        return [<AnnotationAdorner key={annotation.id + "_dnd5e"} annotation={annotation} {...newProps} />];
    }

    renderLogHeader(logEntry: LogHeader, token: Token | TokenTemplate) {
        if (isDnD5EToken(token) || isDnD5ETokenTemplate(token)) {
            if (
                isAbilityCheck(logEntry) ||
                isSkillCheck(logEntry) ||
                isSavingThrow(logEntry) ||
                isAttackRoll(logEntry) ||
                isDamageRoll(logEntry) ||
                isHitPoints(logEntry) ||
                isRechargeRoll(logEntry)
            ) {
                return <LogEntryDetails logEntry={logEntry} token={token} />;
            }
        }

        return undefined;
    }

    reduceToken(
        token: Token | TokenTemplate,
        action: Action,
        session: Session,
        location: Location,
        isTargetted: boolean
    ) {
        return reduceToken(token, action, session, location, this._rules, isTargetted);
    }

    reduceAnnotation(
        annotation: Annotation,
        action: Action,
        session: Session,
        location: Location,
        isTargetted: boolean
    ): Annotation | undefined {
        return reduceAnnotation(annotation, action as AnnotationAction, session, location, this._rules, isTargetted);
    }

    reduceCampaign(campaign: Campaign, action: Action): Campaign {
        return reduceCampaign(campaign as DnD5ECampaign, action, this._rules);
    }

    getMarkdownNodes() {
        return this._customNodes;
    }

    getCampaignSettings(): SearchableSetting[] {
        return allCampaignSettings;
    }

    async importFile(
        zip: JSZip,
        processJson: <T extends Object>(o: T) => T,
        errorHandler: ErrorHandler
    ): Promise<string[]> {
        const messages: string[] = [];

        const rulesets: CharacterRuleStore[] = [];
        const rulesetFiles = getFilesForFolder(zip, "dnd5e/rulesets");
        for (let rulesetFile of rulesetFiles) {
            // Pull the ruleset out of the zip file and process it to resolve any media URIs.
            const rulesetText = await rulesetFile.async("text");
            const ruleset = processJson(JSON.parse(rulesetText) as CharacterRuleStore);

            const blob = new Blob([JSON.stringify(ruleset)], { type: "text/plain" });

            // Got the ruleset that we want to upload to the user's library.
            const formData = new FormData();
            formData.append("file", blob, fileName(rulesetFile.name));

            const response = await fetch("api/systems/dnd5e/rulesets/upload", {
                method: "POST",
                body: formData,
            });

            if (errorHandler.handleResponse(response, "Could not import ruleset " + ruleset.name + ".")) {
                rulesets.push(ruleset);
            }
        }

        if (rulesets.length > 1) {
            messages.push(`${rulesets.length} rulesets were successfully imported.`);
        } else if (rulesets.length > 0) {
            messages.push(`The ruleset ${rulesets[0].name} was successfully imported.`);
        }

        return messages;
    }

    getTraversalCost(
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        token: Token | undefined,
        a: WithLevel<GridPosition>,
        b: WithLevel<GridPosition>,
        from: WithLevel<GridPosition>,
        to: WithLevel<GridPosition>,
        state: GlobalPathState<WithLevel<GridPosition>>,
        nodeState?: any
    ): number | { cost: number; compareCost?: number; nodeState?: any } {
        // Fetch the tokens once and store them in state for the rest of the pathfinding.
        let tokens: Token[];
        let appearance: TokenAppearance[];
        let creatures: (ResolvedMonster | ResolvedCharacter | undefined)[];

        let creature: ResolvedMonster | ResolvedCharacter | undefined;
        if (!state["creature"]) {
            if (isDnD5EToken(token)) {
                creature = fullResolveTokenCreature(token, campaign, this._rules);
            }
        } else {
            creature = state["creature"];
        }

        if (!state["tokens"]) {
            tokens = Object.values(location.tokens);
            appearance = tokens.map(o => this.getTokenAppearance(o, campaign, location));
            creatures = tokens.map(o => {
                if (isDnD5EToken(o)) {
                    return fullResolveTokenCreature(o, campaign, this._rules);
                }

                return undefined;
            });
            state["tokens"] = tokens;
            state["appearance"] = appearance;
            state["creatures"] = creatures;
        } else {
            tokens = state["tokens"];
            appearance = state["appearance"];
            creatures = state["creatures"];
        }

        if (token) {
            for (let i = 0; i < tokens.length; i++) {
                const t = tokens[i];
                const a = appearance[i];
                const c = creatures[i];

                // Dead creatures essentially don't exist as far as path finding is concerned.
                if (getTokenType(campaign, t) === "creature" && !c?.isDead && t.pos.level === b.level) {
                    if (t.id !== token.id && (t.isPlayerVisible == null || t.isPlayerVisible)) {
                        const gridPos = t.pos.type === PositionType.Grid ? t.pos : grid.toGridPoint(t.pos, a.scale);
                        if (grid.contains(b, gridPos, a.scale)) {
                            // TODO: Under some rules can creatures enter other creatures spaces?
                            // You can move through a nonhostile creature's space. In contrast, you can move through a hostile creature's space only
                            // if the creature is at least two sizes larger or smaller than you. Remember that another creature's space is difficult
                            // terrain for you.

                            return -1;
                        }
                    }
                }
            }
        }

        let cost = 1;

        let r = this.applyDiagonals(cost, a, b, campaign, state, nodeState);
        if (creature && creature.moveCost > 0) {
            // For every 1 unit that this movement costs, we should add moveCost more.
            if (typeof r === "number") {
                const creatureMoveCost = r * creature.moveCost;
                r += creatureMoveCost;
            } else {
                const creatureMoveCost = r.cost * creature.moveCost;
                r.cost += creatureMoveCost;
                if (r.compareCost != null) {
                    r.compareCost += creatureMoveCost;
                }
            }
        }

        // TODO: Difficult terrain, etc.
        // Note that the creature's movecost and the difficult terrain cost should apply additively.
        // So, if the distance moved cost is 2 (i.e. it's a diagonal), the difficult terrain cost is 2, and the creatures moveCost is 2,
        // then the total cost should be 10 (2 for the movement, 4 for difficult terrain, 4 for creature movecost).

        return r;
    }

    getGridRange(
        campaign: Campaign,
        location: Location,
        grid: ILocalGrid,
        from: Token | GridPosition,
        to: Token | GridPosition
    ): number {
        let fromPos: GridPosition;
        let fromScale: number | undefined;
        let toPos: GridPosition;
        let toScale: number | undefined;
        if (isToken(from)) {
            const fromAppearance = getTokenAppearanceForRules(from, campaign, location, this._rules);
            fromPos = grid.toGridPoint(from.pos, fromAppearance.scale);
            fromScale = fromAppearance.scale;
        } else {
            fromPos = from;
        }

        if (isToken(to)) {
            const toAppearance = getTokenAppearanceForRules(to, campaign, location, this._rules);
            toPos = grid.toGridPoint(to.pos, toAppearance.scale);
            toScale = toAppearance.scale;
        } else {
            toPos = to;
        }

        // TODO: This ignores levels. This might not turn out to be a problem, but if it is then PathFinder should be used instead.
        const path = findPathAStar(fromPos, toPos, {
            identity: node => {
                // Use a pairing function to combine the x and y into a single unique number. See:
                // https://en.wikipedia.org/wiki/Pairing_function
                return cantorPairSigned(node.x, node.y);
            },
            heuristic: (a, b) => {
                var d1 = Math.abs(a.x - b.x);
                var d2 = Math.abs(a.y - b.y);
                return d1 + d2;
            },
            traversalCost: (a, b, from, to, state, nodeState) => {
                // Squares that are within the source or target tokens don't count.
                // astar doesn't seem to work correctly with 0 cost, so we instead make use of the compareCost, which enables us to use
                // that cost for the algorithm, but a different value for the final path cost, then we make the compareCost for non-free
                // squares so high that they are basically free.
                // Two types of movement are considered free:
                // 1) Heading into a square that is inside the origin area (area occupied by the origin token)
                // 2) Heading out of a square inside the destination area (area occupid by the destination token)
                // This is because we want to start counting cost from the first square out of the origin area, and stop counting after
                // the first square in the destination area.
                if (grid.contains(b, fromPos, fromScale) || grid.contains(a, toPos, toScale)) {
                    return {
                        cost: 0,
                        compareCost: 1,
                        nodeState: nodeState ? Object.assign({}, nodeState) : undefined,
                    };
                }

                const cost = this.applyDiagonals(1, a, b, campaign, state, nodeState);
                if (typeof cost === "number") {
                    return { cost: cost, compareCost: 10000 };
                }

                cost.compareCost = cost.cost * 10000;
                return cost;
            },
            getNeighbours: node => grid.getNeighbours(node),
            state: {},
        });

        return path?.cost ?? 0;
    }

    private applyDiagonals(
        cost: number,
        a: GridPosition,
        b: GridPosition,
        campaign: Campaign,
        state: GlobalPathState<GridPosition>,
        nodeState?: any
    ): { cost: number; compareCost?: number; nodeState?: any } | number {
        if ((campaign as DnD5ECampaign).dnd5e?.diagonals ?? true) {
            // TODO: We're assuming a square grid here, might not be true in future? Might need to be able to check what type of grid is being used?
            // Every second diagonal movement costs 10 ft (i.e. 2 grid squares) instead of 1.
            let diagonals =
                (nodeState?.["diagonals"] as number | undefined) ??
                state.previousPath?.pathState?.[state.previousPath.pathState.length - 1]?.["diagonals"] ??
                0;
            if (a.x !== b.x && a.y !== b.y) {
                // If cost is a multiple of 2, then we don't need to alternate costs, we can just apply the cost per square.
                // i.e. if cost is 2, then we can just charge 3 or each square, instead of alternating 2 and 4.
                // If the cost is 4, we can charge 6 for each square.
                if (cost % 2 === 0) {
                    cost = cost * 1.5;
                } else {
                    if (diagonals % 2 > 0) {
                        cost *= 2;
                    }

                    diagonals++;
                }
            }

            return { cost: cost, nodeState: { diagonals: diagonals } };
        }

        return cost;
    }
}

systems["dnd5e"] = () => {
    const dnd5eCampaign = new DnD5eSystem();
    return dnd5eCampaign;
};
