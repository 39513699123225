import { crToXp, Monster, ResolvedCharacter, ResolvedMonster } from "./creature";

export enum EncounterDifficulty {
    Trivial = 0,
    Easy = 1,
    Medium = 2,
    Hard = 3,
    Deadly = 4,
}

export function encounterDifficultyToString(difficulty: EncounterDifficulty) {
    switch (difficulty) {
        case EncounterDifficulty.Trivial:
            return "Trivial";
        case EncounterDifficulty.Easy:
            return "Easy";
        case EncounterDifficulty.Medium:
            return "Medium";
        case EncounterDifficulty.Hard:
            return "Hard";
        case EncounterDifficulty.Deadly:
            return "Deadly";
    }
}

export interface Encounter {
    party: ResolvedCharacter[];
    monsters: ResolvedMonster[];

    difficulty: EncounterDifficulty;

    monsterXpTotal: number;

    thresholds: {
        easy: number;
        medium: number;
        hard: number;
        deadly: number;
    };
}

const xpThresholdsByCharacterLevel: number[][] = [
    [25, 50, 75, 100],
    [50, 100, 150, 200],
    [75, 150, 225, 400],
    [125, 250, 375, 500],
    [250, 500, 750, 1100],
    [300, 600, 900, 1400],
    [350, 750, 1100, 1700],
    [450, 900, 1400, 2100],
    [550, 1100, 1600, 2400],
    [600, 1200, 1900, 2800],
    [800, 1600, 2400, 3600],
    [1000, 2000, 3000, 4500],
    [1100, 2200, 3400, 5100],
    [1250, 2500, 3800, 5700],
    [1400, 2800, 4300, 6400],
    [1600, 3200, 4800, 7200],
    [2000, 3900, 5900, 8800],
    [2100, 4200, 6300, 9500],
    [2400, 4900, 7300, 10900],
    [2800, 5700, 8500, 12700],
];

const encounterMultipliers: number[][] = [
    [1, 1],
    [2, 1.5],
    [6, 2],
    [10, 2.5],
    [14, 3],
    [Number.MAX_VALUE, 4],
];

function getMonstersXp(party: ResolvedCharacter[], monsters: (ResolvedMonster | Monster)[]): number {
    // Get the total XP value of all the monsters.
    let monsterXp = 0;
    for (let monster of monsters) {
        // TODO: Show a warning if a monster doesn't have a defined CR?
        monsterXp += crToXp(monster.cr ?? 0);
    }

    // Apply the multiplier for multiple monsters.
    for (let i = 0; i < encounterMultipliers.length; i++) {
        if (monsters.length <= encounterMultipliers[i][0]) {
            // Take into account the number of characters in the party.
            if (party.length < 3) {
                i = Math.min(encounterMultipliers.length - 1, i + 1);
            } else if (party.length > 5) {
                i = Math.max(i - 1, 0);
            }

            monsterXp *= encounterMultipliers[i][1];
            break;
        }
    }

    return monsterXp;
}

function getEncounterDifficulty(
    thresholds: {
        easy: number;
        medium: number;
        hard: number;
        deadly: number;
    },
    monsterXp: number
) {
    // Work out the difficulty based on the monster XP and the party XP thresholds.
    let difficulty = EncounterDifficulty.Trivial;
    if (monsterXp >= thresholds.easy) {
        if (monsterXp >= thresholds.medium) {
            if (monsterXp >= thresholds.hard) {
                if (monsterXp >= thresholds.deadly) {
                    difficulty = EncounterDifficulty.Deadly;
                } else {
                    difficulty = EncounterDifficulty.Hard;
                }
            } else {
                difficulty = EncounterDifficulty.Medium;
            }
        } else {
            difficulty = EncounterDifficulty.Easy;
        }
    }

    return difficulty;
}

export function resolveEncounter(party: ResolvedCharacter[], monsters: ResolvedMonster[]): Encounter {
    // Work out the XP thresholds based on the party's levels.
    let easyThreshold = 0;
    let mediumThreshold = 0;
    let hardThreshold = 0;
    let deadlyThreshold = 0;
    for (let character of party) {
        const i = character.level - 1;
        easyThreshold += xpThresholdsByCharacterLevel[i][0];
        mediumThreshold += xpThresholdsByCharacterLevel[i][1];
        hardThreshold += xpThresholdsByCharacterLevel[i][2];
        deadlyThreshold += xpThresholdsByCharacterLevel[i][3];
    }

    const thresholds = {
        easy: easyThreshold,
        medium: mediumThreshold,
        hard: hardThreshold,
        deadly: deadlyThreshold,
    };

    // Get the total XP value of all the monsters.
    const monsterXp = getMonstersXp(party, monsters);
    const difficulty = getEncounterDifficulty(thresholds, monsterXp);

    return {
        party: party,
        monsters: monsters,
        difficulty: difficulty,

        monsterXpTotal: monsterXp,
        thresholds: thresholds,
    };
}

export function generateEncounterMonsters(
    encounter: Encounter,
    desiredDifficulty: EncounterDifficulty,
    monsters: Monster[]
): Monster[] {
    const newMonsters: Monster[] = [];

    let availableMonsters: Monster[] = [];
    const weakMonsters: Monster[] = [];
    const weakThreshold = encounter.thresholds.medium / 20;
    for (let i = 0; i < monsters.length; i++) {
        if (monsters[i].cr != null) {
            const xp = crToXp(monsters[i].cr!);
            if (xp < weakThreshold) {
                weakMonsters.push(monsters[i]);
            } else {
                availableMonsters.push(monsters[i]);
            }
        }
    }

    let currentDifficulty = encounter.difficulty;
    while (availableMonsters.length > 0 && currentDifficulty < desiredDifficulty) {
        // Encounter isn't difficult enough yet. Add some random monsters from the selection we have been given.
        const monsterIndex = Math.floor(Math.random() * availableMonsters.length);

        const monster = availableMonsters[monsterIndex];
        if (monster.cr == null) {
            // Monsters with no CR specified can't be used in encounter generation as we don't know what XP value to
            // assign to them. Although there are rules for working out a monster's CR out there...
            availableMonsters.splice(monsterIndex, 1);
        } else {
            // Try to add a few of the same monster if possible, rather than having a smattering of random monsters.
            const amount = Math.ceil(Math.random() * 4);
            for (let i = 0; i < amount; i++) {
                const newMonsterXp = getMonstersXp(encounter.party, [...encounter.monsters, ...newMonsters, monster]);
                const newDifficulty = getEncounterDifficulty(encounter.thresholds, newMonsterXp);
                if (newDifficulty === desiredDifficulty) {
                    if (
                        newDifficulty === EncounterDifficulty.Deadly &&
                        newMonsterXp >= encounter.thresholds.deadly * 2
                    ) {
                        // The desired difficulty is deadly, but this is more than twice the deadly threshold, which is probably
                        // a bit too much. Treat this the same as jumping another difficulty level.
                        availableMonsters.splice(monsterIndex, 1);
                        break;
                    } else {
                        // We've hit the desired difficulty.
                        currentDifficulty = newDifficulty;
                        newMonsters.push(monster);
                        break;
                    }
                } else if (newDifficulty > desiredDifficulty) {
                    // Oops, gone too far. This monster isn't going to work.
                    availableMonsters.splice(monsterIndex, 1);
                } else {
                    // Still not enough. Keep adding new monsters.
                    currentDifficulty = newDifficulty;
                    newMonsters.push(monster);
                }
            }
        }

        // If we've run out of available monsters, but we still need to fill in the encounter some more,
        // then maybe we can consider monsters that would otherwise be too weak.
        if (availableMonsters.length === 0 && currentDifficulty < desiredDifficulty) {
            availableMonsters = weakMonsters;
        }
    }

    return newMonsters;
}
