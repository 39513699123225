import { AnyAction } from "redux";
import sessionReducer from "./session";
import { VTTStore, UserInfo, Profile } from "../store";
import { copyState } from "./common";

const initialUserInfo: UserInfo = {
    id: "",
};

const userInfoReducer = (state = initialUserInfo, action: AnyAction) => {
    if (action.type === "Authenticate") {
        const profile = action.payload as Profile;
        state = copyState(state, { id: profile.userId, profile: profile });
    }

    return state;
};

const rootReducer = (state: VTTStore | undefined, action: AnyAction): VTTStore => {
    const userInfo = userInfoReducer(state ? state.userInfo : undefined, action);
    return {
        userInfo: userInfo,
        sessions: sessionReducer(state ? state.sessions : {}, action, userInfo),
    };
};

export default rootReducer;
