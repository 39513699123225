/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Text } from "../../../components/primitives";
import { theme } from "../../../design";
import { FunctionComponent } from "react";
import { KeyedList, KeyedListItem, mapKeyedList } from "../../../common";
import { attackTypeToString, MonsterMultiattackPart } from "../creature";

export const MultiattackOption: FunctionComponent<{
    option: {
        key: string;
        parts: KeyedListItem<KeyedList<MonsterMultiattackPart>>;
    };
}> = ({ option }) => {
    return (
        <Box
            key={option.key}
            flexDirection="column"
            alignItems="flex-start"
            borderRadius={3}
            bg="grayscale.9"
            fullWidth
            p={2}
            css={{
                ":hover": {
                    background: theme.colors.grayscale[7],
                },
            }}>
            {mapKeyedList(option.parts, (o, i, k) => {
                let b: string;
                if (o.abilities) {
                    b = o.abilities.join(", ");
                } else if (o.type) {
                    b = attackTypeToString(o.type);
                } else {
                    b = "unknown";
                }

                return (
                    <Text key={k}>
                        {o.amount ?? 1} {b}
                    </Text>
                );
            })}
        </Box>
    );
};
