/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent, useState } from "react";
import { CurrencyType, CURRENCY_TYPES, ResolvedCharacter, getCurrencyTotal } from "../creature";
import { Box, Text, Input, BoxProps } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import { LobotomizedBox } from "../../../components/common";

const CurrencyBox: FunctionComponent<{
    type: CurrencyType;
    amount: number;
    delta: number | undefined;
    onDeltaChanged: (delta: number) => void;
}> = ({ type, amount, delta, onDeltaChanged }) => {
    return (
        <Box flexDirection="column" flexShrink={1} alignItems="stretch">
            <Text textAlign="center" fontWeight="bold">
                {type.name}
            </Text>
            <Text fontSize={2} textAlign="center">
                {amount ?? 0}
            </Text>
            <Input
                value={delta != null ? delta : ""}
                variant="number"
                min={0}
                onChange={e => {
                    onDeltaChanged(e.target.valueAsNumber);
                }}
            />
        </Box>
    );
};

export const currencyFormatter = Intl.NumberFormat(undefined, { maximumFractionDigits: 2 });

export const CurrencyEditor: FunctionComponent<
    {
        character: ResolvedCharacter;
        onChange: (delta: { [abbr: string]: number }) => void;
    } & BoxProps
> = ({ character, onChange, ...boxProps }) => {
    const [currencyDelta, setCurrencyDelta] = useState<{
        [abbr: string]: number;
    }>({});

    const addTotal = getCurrencyTotal(currencyDelta);
    const minusTotal = getCurrencyTotal(currencyDelta, character.currency);

    return (
        <Box flexDirection="column" fullWidth {...(boxProps as unknown as any)}>
            <LobotomizedBox flexDirection="row" mb={2} fullWidth>
                {CURRENCY_TYPES.map(o => (
                    <CurrencyBox
                        key={o.abbreviation}
                        type={o}
                        amount={character.currency[o.abbreviation]}
                        delta={currencyDelta[o.abbreviation]}
                        onDeltaChanged={d => {
                            const newDelta = Object.assign({}, currencyDelta);
                            if (isNaN(d)) {
                                delete newDelta[o.abbreviation];
                            } else {
                                newDelta[o.abbreviation] = d;
                            }

                            setCurrencyDelta(newDelta);
                        }}
                    />
                ))}
            </LobotomizedBox>
            <LobotomizedBox flexDirection="row" fullWidth px={5}>
                <Button
                    size="s"
                    css={{ flexGrow: 1, flexBasis: 0 }}
                    variant="success"
                    onClick={() => {
                        onChange(currencyDelta);
                        setCurrencyDelta({});
                    }}>
                    +{currencyFormatter.format(addTotal)}GP
                </Button>
                <Button
                    size="s"
                    css={{ flexGrow: 1, flexBasis: 0 }}
                    variant="danger"
                    onClick={() => {
                        const deltaRemove = Object.assign({}, currencyDelta);
                        for (let abbr in deltaRemove) {
                            deltaRemove[abbr] = -deltaRemove[abbr];
                        }

                        onChange(deltaRemove);
                        setCurrencyDelta({});
                    }}>
                    -{currencyFormatter.format(minusTotal)}GP
                </Button>
                <Button
                    size="s"
                    disabled={addTotal === 0}
                    css={{ flexGrow: 1, flexBasis: 0 }}
                    onClick={() => setCurrencyDelta({})}>
                    ×
                </Button>
            </LobotomizedBox>
        </Box>
    );
};
