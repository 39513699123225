/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { Box, Grid, Heading, Text } from "../../../../components/primitives";
import {
    ResolvedMonster,
    alignmentToString,
    crToString,
    creatureTypeToString,
    damageTypesToMarkdown,
    getCoreAbilityAbbr,
    modifierFromAbilityScore,
    resolveModifiedValue,
    senseTypeToString,
    skillToString,
    skills,
} from "../../creature";
import { diceBagToExpression } from "../../../../store";
import { CoreAbility, formatModifier } from "../../common";
import { theme } from "../../../../design";
import { keyedListToArray, mapKeyedList } from "../../../../common";
import { Markdown } from "../../../../components/markdown";

const AbilityScoreNode: FunctionComponent<{ monster: ResolvedMonster; ability: CoreAbility }> = ({
    monster,
    ability,
}) => {
    return (
        <Box flexDirection="column">
            <Text fontWeight="bold">{getCoreAbilityAbbr(ability)}</Text>
            <Text>{resolveModifiedValue(monster[ability])}</Text>
            <Text>({formatModifier(modifierFromAbilityScore(resolveModifiedValue(monster[ability])))})</Text>
        </Box>
    );
};

function addSavingThrow(savingThrows: string[], monster: ResolvedMonster, ability: CoreAbility) {
    if (monster.resolvedFrom.savingThrows?.[ability]) {
        savingThrows.push(
            `${getCoreAbilityAbbr(ability)} ${formatModifier(resolveModifiedValue(monster.savingThrows[ability]))}`
        );
    }
}

export const StatBlock: FunctionComponent<{ monster: ResolvedMonster }> = ({ monster }) => {
    const walkSpeed = resolveModifiedValue(monster.speed.walk);
    const swimSpeed = resolveModifiedValue(monster.speed.swim);
    const flySpeed = resolveModifiedValue(monster.speed.fly);
    const climbSpeed = resolveModifiedValue(monster.speed.climb);
    const burrowSpeed = resolveModifiedValue(monster.speed.burrow);

    const speeds: string[] = [];
    if (walkSpeed > 0) {
        speeds.push(`${walkSpeed} ft.`);
    }

    if (flySpeed > 0) {
        speeds.push(`fly ${flySpeed} ft.`);
    }

    if (swimSpeed > 0) {
        speeds.push(`swim ${swimSpeed} ft.`);
    }

    if (climbSpeed > 0) {
        speeds.push(`climb ${climbSpeed} ft.`);
    }

    if (burrowSpeed > 0) {
        speeds.push(`burrow ${burrowSpeed} ft.`);
    }

    const senses: string[] = [];
    const blindsightDist = resolveModifiedValue(monster.senses.blindsight);
    const darkvisionDist = resolveModifiedValue(monster.senses.darkvision);
    const tremorDist = resolveModifiedValue(monster.senses.tremorsense);
    const truesightDist = resolveModifiedValue(monster.senses.truesight);
    if (blindsightDist > 0) {
        senses.push(`${senseTypeToString("blindsight")} ${blindsightDist} ft.`);
    }

    if (darkvisionDist > 0) {
        senses.push(`${senseTypeToString("darkvision")} ${darkvisionDist} ft.`);
    }

    if (tremorDist > 0) {
        senses.push(`${senseTypeToString("tremorsense")} ${tremorDist} ft.`);
    }

    if (truesightDist > 0) {
        senses.push(`${senseTypeToString("truesight")} ${truesightDist} ft.`);
    }

    senses.push(`Passive Perception ${monster.passivePerception}`);

    const savingThrows: string[] = [];
    addSavingThrow(savingThrows, monster, "strength");
    addSavingThrow(savingThrows, monster, "dexterity");
    addSavingThrow(savingThrows, monster, "constitution");
    addSavingThrow(savingThrows, monster, "intelligence");
    addSavingThrow(savingThrows, monster, "wisdom");
    addSavingThrow(savingThrows, monster, "charisma");

    const skillsText: string[] = [];
    for (let skill of skills) {
        if (monster.resolvedFrom.skills?.[skill] != null) {
            skillsText.push(
                `${skillToString(skill)} ${formatModifier(resolveModifiedValue(monster.skillChecks[skill]))}`
            );
        }
    }

    const immunitiesMd: string[] = [];
    const imm = keyedListToArray(monster.damageImmunities);
    if (imm != null) {
        for (let immunity of imm) {
            immunitiesMd.push(damageTypesToMarkdown(undefined, immunity));
        }
    }

    const vulnerabilitiesMd: string[] = [];
    const vuln = keyedListToArray(monster.vulnerabilities);
    if (vuln != null) {
        for (let vulnerability of vuln) {
            vulnerabilitiesMd.push(damageTypesToMarkdown(undefined, vulnerability));
        }
    }

    const resistancesMd: string[] = [];
    const res = keyedListToArray(monster.resistances);
    if (res != null) {
        for (let resistance of res) {
            resistancesMd.push(damageTypesToMarkdown(undefined, resistance));
        }
    }

    return (
        <Grid gridTemplateColumns="1fr 1fr">
            <Box
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                mr={2}
                css={{ gap: theme.space[0] }}>
                <Heading as="h4">{monster.name}</Heading>
                <Text mb={2} fontStyle="italic" color="grayscale.2">
                    {monster.size ?? "Medium"}
                    {monster.creatureType && " " + creatureTypeToString(monster.creatureType)}
                    {monster.alignment && ", " + alignmentToString(monster.alignment)}
                </Text>
                <div>
                    <Markdown>{`**Armor Class** ${monster.ac?.value ?? "?"} ${
                        monster.ac?.from ? ` (${monster.ac.from})` : ""
                    }`}</Markdown>
                </div>
                <div>
                    <span css={{ fontWeight: "bold" }}>Hit Points</span> {monster.maxHpInfo.average ?? "?"}
                    {monster.maxHpInfo.dice && ` (${diceBagToExpression(monster.maxHpInfo.dice)})`}
                </div>
                <div>
                    <span css={{ fontWeight: "bold" }}>Speed</span> {speeds.join(", ")}
                </div>
                <Grid
                    my={2}
                    gridTemplateColumns={"1fr 1fr 1fr 1fr 1fr 1fr"}
                    gridTemplateRows="auto"
                    css={{ alignSelf: "stretch" }}>
                    <AbilityScoreNode monster={monster} ability="strength" />
                    <AbilityScoreNode monster={monster} ability="dexterity" />
                    <AbilityScoreNode monster={monster} ability="constitution" />
                    <AbilityScoreNode monster={monster} ability="intelligence" />
                    <AbilityScoreNode monster={monster} ability="wisdom" />
                    <AbilityScoreNode monster={monster} ability="charisma" />
                </Grid>
                {savingThrows.length > 0 && (
                    <div>
                        <span css={{ fontWeight: "bold" }}>Saving Throws</span> {savingThrows.join(", ")}
                    </div>
                )}
                {skillsText.length > 0 && (
                    <div>
                        <span css={{ fontWeight: "bold" }}>Skills</span> {skillsText.join(", ")}
                    </div>
                )}
                {immunitiesMd.length > 0 && <Markdown>{`**Damage Immunities** ${immunitiesMd.join(", ")}`}</Markdown>}
                {vulnerabilitiesMd.length > 0 && (
                    <Markdown>{`**Damage Vulnerabilities** ${vulnerabilitiesMd.join(", ")}`}</Markdown>
                )}
                {resistancesMd.length > 0 && (
                    <Markdown>{`**Damage Resistances** ${resistancesMd.join(", ")}`}</Markdown>
                )}
                <div>
                    <span css={{ fontWeight: "bold" }}>Senses</span> {senses.join(", ")}
                </div>
                {monster.languages && (
                    <div>
                        <span css={{ fontWeight: "bold" }}>Languages</span> {monster.languages.join(", ")}
                    </div>
                )}
                {monster.cr && (
                    <div>
                        <span css={{ fontWeight: "bold" }}>Challenge</span> {crToString(monster.cr)}
                    </div>
                )}
                {mapKeyedList(monster.traits, (o, i, k) => {
                    return <Markdown key={k} mt={2}>{`**${o.name}** ${o.content}`}</Markdown>;
                })}
            </Box>
            <Box
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="flex-start"
                ml={2}
                css={{ gap: theme.space[1] }}>
                <Heading as="h6">Actions</Heading>
                {mapKeyedList(monster.abilities, (o, i, k) => {
                    if (o.time?.unit === "legendary") {
                        return undefined;
                    }

                    return <Markdown key={k} mt={2}>{`**${o.name}** ${o.content}`}</Markdown>;
                })}

                {monster.legendaryActions && (
                    <React.Fragment>
                        <Heading as="h6" mt={2}>
                            Legendary Actions
                        </Heading>
                        {mapKeyedList(monster.abilities, (o, i, k) => {
                            if (o.time?.unit !== "legendary") {
                                return undefined;
                            }

                            return <Markdown key={k} mt={2}>{`**${o.name}** ${o.content}`}</Markdown>;
                        })}
                    </React.Fragment>
                )}
            </Box>
        </Grid>
    );
};
