/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { FunctionComponent } from "react";
import { resolveCreature, resolveModifiedValue, resolveTokenCreature } from "../creature";
import { useRules } from "./hooks";
import { useDispatch, useLocation } from "../../../components/contexts";
import { RollButtonHorizontal } from "./RollButton";
import { AppliedAbilityEffectSource, DnD5EToken } from "../common";
import { Box } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import SurprisedIcon from "../../../components/icons/Surprised";
import { setSurprised } from "../actions/token";

export const RollForInitiative: FunctionComponent<{
    token: DnD5EToken;
    setInitiative: (initiative: number[]) => void;
}> = ({ token, setInitiative }) => {
    const rules = useRules();
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();

    const creature = resolveTokenCreature(token, campaign, rules);
    let initiativeBonus = 0;
    let dex = 0;
    let extraRolls: { roll: string; reason: string; source?: AppliedAbilityEffectSource }[] | undefined;
    let adv: "adv" | "dis" | undefined;
    const resolvedCreature = creature ? resolveCreature(creature, campaign, rules) : undefined;
    if (resolvedCreature) {
        initiativeBonus = resolveModifiedValue(resolvedCreature.initiativeBonus);
        dex = resolveModifiedValue(resolvedCreature.dexterity);
        extraRolls = resolvedCreature.initiativeBonus.extraRolls;

        if (
            !!resolvedCreature.initiativeBonus.advantage?.length !==
            !!resolvedCreature.initiativeBonus.disadvantage?.length
        ) {
            adv = resolvedCreature.initiativeBonus.advantage?.length ? "adv" : "dis";
        }
    }

    return (
        <Box flexDirection="row" alignItems="flex-start">
            <RollButtonHorizontal
                css2={{ width: "auto" }}
                data={{ type: "initiative" }}
                advantage={adv}
                extraRolls={extraRolls}
                modifier={initiativeBonus}
                onRoll={async roll => {
                    const finalResult = await roll.confirmed;
                    setInitiative([finalResult.result, dex]);
                }}>
                Roll for initiative
            </RollButtonHorizontal>

            {resolvedCreature && (
                <Button
                    ml={2}
                    tooltip="Surprised"
                    tooltipDirection="up"
                    tooltipAlignment="end"
                    toggled={resolvedCreature.combatTurn?.surprised}
                    shape="square"
                    onClick={() => {
                        dispatch(setSurprised(campaign, location, [token], !resolvedCreature.combatTurn?.surprised));
                    }}>
                    <SurprisedIcon />
                </Button>
            )}
        </Box>
    );
};
