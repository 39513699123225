/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent } from "react";
import { TokenAppearance } from "../../../store";
import { ApplicableAbilityEffect, AppliedAbilityEffectChoices, CreatureTransformation } from "../common";
import { ResolvedMonster, ResolvedCharacter } from "../creature";
import { MonsterPicker } from "./monster/MonsterPicker";

export const AppliedAbilityEffectChoicesEditor: FunctionComponent<{
    applicableEffect: ApplicableAbilityEffect;
    choices?: AppliedAbilityEffectChoices;
    target?: ResolvedMonster | ResolvedCharacter;
    onChoicesUpdated: (delta: Partial<AppliedAbilityEffectChoices>) => void;
}> = ({ applicableEffect, choices, target, onChoicesUpdated }) => {
    return (
        <React.Fragment>
            {applicableEffect?.transform?.filter && (
                <MonsterPicker
                    key="transformPicker"
                    hint={
                        target
                            ? "Select a creature to transform the target into"
                            : "Select a creature to transform into"
                    }
                    monster={choices?.transform?.templateId}
                    setMonster={m => {
                        // Apply this monster choice to the ability effect.
                        let appearance: TokenAppearance;
                        if (m.imageUri) {
                            appearance = {
                                imageUri: m.imageUri,
                                canRotate: m.canRotate,
                                defaultRotation: m.defaultRotation,
                                scale: m.scale,
                                renderScale: m.renderScale,
                            };
                        } else {
                            const image = m.images?.[0];
                            if (image) {
                                appearance = {
                                    imageUri: image.uri,
                                    canRotate: image.canRotate,
                                    defaultRotation: image.rotation,
                                    scale: m.scale,
                                    renderScale: m.renderScale,
                                };
                            } else {
                                appearance = {
                                    scale: m.scale,
                                };
                            }
                        }

                        const c: CreatureTransformation = {
                            templateId: m.templateId,
                            creature: { name: m.dnd5e.name, source: m.dnd5e.source },
                            appearance: appearance,
                        };

                        onChoicesUpdated({ transform: c });
                    }}
                    filter={applicableEffect.transform.filter}
                    target={target}
                />
            )}
        </React.Fragment>
    );
};
