import {
    AnnotationAction,
    createTokensOrTokenTemplatesAction,
    TokensAction,
    TokenTemplatesAction,
} from "../../../actions/common";
import { Point } from "../../../position";
import { Campaign, DiceRoll, getModifyId, Location, Token, UserInfo } from "../../../store";
import { AppliedAbilityEffectChoices, AttackOptionResult, DamageType, DnD5EAnnotation, DnD5EToken } from "../common";
import { ResolvedAfterAttackOption, ResolvedBeforeAttackOption } from "../creature";

export interface DnD5EAnnotationAction extends AnnotationAction, TokensAction {
    props: {
        campaignId: string;
        locationId: string;
        annotationId: string;
        effectId?: string;
        instanceId?: string;
        option?: ResolvedBeforeAttackOption | ResolvedAfterAttackOption;
        tokens: string[];
        [key: string]: any;
    };
}

/**
 * Creates an annotation action.
 * @param name The name of the action.
 * @param campaign The campaign.
 * @param location The location.
 * @param annotation The annotation.
 * @param effect The ID of the ability effect this action is concerned with.
 * @param targets The targets that this action is concerned with.
 * @param instanceId The ID of the result instance that this action is concerned with. This is only relevant if a single target is specified, and that single target has been targetted multiple times by the same ability. If not specified, "0" is assumed.
 * @param payload The payload.
 * @returns
 */
function createAnnotationAction(
    name: string,
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string | undefined,
    targets: Token | string | Token[] | string[] | undefined,
    instanceId: string | undefined,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    payload: any
): DnD5EAnnotationAction {
    const targetIds =
        targets != null
            ? Array.isArray(targets)
                ? targets.map((o: Token | string) => (typeof o === "string" ? o : o.id))
                : [typeof targets === "string" ? targets : targets.id]
            : undefined;
    return {
        type: name,
        props: {
            campaignId: typeof campaign === "string" ? campaign : campaign.id,
            locationId: typeof location === "string" ? location : location?.id,
            annotationId: typeof annotation === "string" ? annotation : annotation.id,
            effectId: effect,
            instanceId: instanceId,
            option: option,
            tokens: targetIds ?? [],
        },
        payload: payload,
    };
}

export function choosePerLevelDamageType(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    damageType: DamageType
) {
    return createAnnotationAction(
        "DnD5E_AnnotationChoosePerLevelDamageType",
        campaign,
        location,
        annotation,
        effect,
        undefined,
        undefined,
        undefined,
        damageType
    );
}

export function chooseAnnotationDamageType(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    damageType: DamageType
) {
    return createAnnotationAction(
        "DnD5E_AnnotationChooseDamageType",
        campaign,
        location,
        annotation,
        effect,
        undefined,
        undefined,
        undefined,
        damageType
    );
}

export function applyHealing(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    target: Token | string | undefined,
    instanceId: string | undefined,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    diceRoll: DiceRoll
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyHealing",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        option,
        diceRoll
    );
}

export function applyDamage(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    target: Token | string | undefined,
    instanceId: string | undefined,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    diceRoll: DiceRoll,
    damageType: DamageType
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyDamage",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        option,
        { diceRoll, damageType }
    );
}

export function applySavingThrow(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string | undefined,
    target: Token | string,
    instanceId: string | undefined,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    diceRoll: DiceRoll
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplySavingThrow",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        option,
        diceRoll
    );
}

export function modifyOptionResult(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string | undefined,
    target: Token | string,
    instanceId: string,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption,
    delta: AttackOptionResult
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyModifyOption",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        option,
        delta
    );
}

export function applyAttackRoll(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    target: Token | string,
    instanceId: string | undefined,
    diceRoll: DiceRoll
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyAttackRoll",
        campaign,
        location,
        annotation,
        undefined,
        target,
        instanceId,
        undefined,
        diceRoll
    );
}

export function applyAcModifier(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    target: Token | string,
    instanceId: string | undefined,
    acModifier: number | undefined
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyAcModifier",
        campaign,
        location,
        annotation,
        undefined,
        target,
        instanceId,
        undefined,
        acModifier
    );
}

export function applyAttackHit(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    target: Token | string,
    instanceId: string | undefined,
    isHit: "crit_hit" | "hit" | "miss" | "crit_miss"
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyAttackHit",
        campaign,
        location,
        annotation,
        undefined,
        target,
        instanceId,
        undefined,
        isHit
    );
}

export function applyResistance(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    target: Token | string,
    instanceId: string | undefined,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    damageType: DamageType,
    resistance: number | undefined
) {
    return createAnnotationAction(
        "DnD5E_AnnotationApplyResistance",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        option,
        { [damageType]: resistance }
    );
}

export function setExclusion(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    target: Token | string,
    instanceId: string | undefined,
    isExcluded: boolean | undefined
) {
    return createAnnotationAction(
        "DnD5E_AnnotationSetExclusion",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        undefined,
        isExcluded
    );
}

export function applyEffects(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    targets: DnD5EToken[],
    source: DnD5EToken | undefined
): AnnotationAction & (TokensAction | TokenTemplatesAction) & { props: { targets: string[] } } {
    var tokenAction = createTokensOrTokenTemplatesAction(
        "DnD5E_AnnotationApply",
        undefined,
        campaign,
        location,
        source ? [source, ...targets] : targets,
        undefined,
        false,
        true
    ) as DnD5EAnnotationAction & (TokensAction | TokenTemplatesAction) & { props: { targets: string[] } };
    tokenAction.props.annotationId = typeof annotation === "string" ? annotation : annotation.id;
    tokenAction.props.targets = targets.map(o => getModifyId(o));
    return tokenAction;
}

export function setAttackOption(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    target: Token | string | undefined,
    instanceId: string | undefined,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    isEnabled: boolean
) {
    return createAnnotationAction(
        "DnD5E_SetAttackOption",
        campaign,
        location,
        annotation,
        undefined,
        target,
        instanceId,
        option,
        isEnabled
    );
}

export function moveAnnotation(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    user: UserInfo,
    pos: Point
) {
    return createAnnotationAction(
        "DnD5E_AnnotationMove",
        campaign,
        location,
        annotation,
        undefined,
        undefined,
        undefined,
        undefined,
        { user: user, pos: pos }
    );
}

export function applyAnnotationAppliedEffectChoices(
    campaign: Campaign | string,
    location: Location | string,
    annotation: DnD5EAnnotation,
    effect: string,
    target: Token | string,
    instanceId: string,
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption | undefined,
    choices: AppliedAbilityEffectChoices
) {
    return createAnnotationAction(
        "DnD5E_AnnotationAppliedEffectChoices",
        campaign,
        location,
        annotation,
        effect,
        target,
        instanceId,
        option,
        choices
    );
}
