/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState } from "react";
import { Box, Image } from "../../../components/primitives";
import { useDispatch, useLocalGrid, useLocation, useLocationLevel } from "../../../components/contexts";
import {
    defaultAnimate,
    defaultExit,
    defaultInitial,
    MotionBox,
    MotionCard,
    MotionGrid,
    MotionOverlayCard,
    MotionOverlayToolbar,
} from "../../../components/motion";
import {
    Ability,
    abilityTimeToString,
    ApplicableAbilityEffect,
    applicableEffectHasChoices,
    applicableEffectHasUnmadeChoices,
    AppliedAbilityEffectChoices,
    DnD5EToken,
    getRuleKey,
    isNamedRuleRef,
} from "../common";
import {
    abilityMeetsCondition,
    canUseCombatAbility,
    fullResolveTokenCreature,
    getMovementSpeeds,
    getUnarmedWeapon,
    getWeaponDamage,
    isCharacter,
    MonsterAbility,
    MovementType,
    movementTypeToString,
    ResolvedCharacter,
    ResolvedMonster,
    resolveModifiedValue,
    resolveMovementSpeeds,
} from "../creature";
import { useRules } from "./hooks";
import { Badge } from "../../../components/Badge";
import { Button } from "../../../components/Button";
import { isLocation, Location, ResolvedToken } from "../../../store";
import { applyConditions, changeMovementForTurn, setMultiattackOption } from "../actions/token";
import { AnimatePresence, LayoutGroup } from "framer-motion";
import {
    getItemsInInventory,
    isWeapon,
    ResolvedInventoryWeapon,
    ResolvedInventoryItem,
    ItemType,
    filterItem,
} from "../items";
import { getAnnotationTemplate, getWeaponAttack } from "../abilities";
import { ToolbarButton } from "../../../components/Toolbar";
import { BoxPopup } from "../../../components/TextPopup";
import { Markdown } from "../../../components/markdown";
import { usePopper } from "react-popper";
import { theme } from "../../../design";
import { AppliedAbilityEffectChoicesEditor } from "./AppliedAbilityEffectChoicesEditor";
import { CombatResources } from "./CombatResources";
import { renderIconForAbility, renderIconForWeapon, useDispatchAbility, useWeaponAttackOptions } from "./common";
import { resolveUri, tokenImageSize, useVttApp } from "../../../components/common";
import { ItemListItem } from "./ItemInfo";
import { ListBox } from "../../../components/ListBox";
import { MultiattackOption } from "./MultiattackOption";
import { KeyedListItem, keyedListToKeyArray, mapKeyedList } from "../../../common";
import ProneIcon from "../../../components/icons/Prone";

const iconSize = 30;

const WeaponAttackButton: FunctionComponent<{
    token: ResolvedToken<DnD5EToken>;
    character: ResolvedCharacter;
    weapon: ResolvedInventoryWeapon & ResolvedInventoryItem;
    handed?: 1 | 2;
}> = ({ token, character, weapon, handed }) => {
    const { location, levelKey } = useLocationLevel();
    const grid = useLocalGrid();
    const { annotationPlacementTemplateChanged } = useVttApp();
    const rules = useRules();

    const criticalRange = character.criticalRange?.[weapon.type === ItemType.Melee ? "mw" : "rw"];

    const attacksMade = character.combatTurn?.attacks
        ? Object.values(character.combatTurn.attacks).reduce((p, c) => p + c, 0)
        : 0;
    const attacksRemaining = character.attacks - attacksMade;
    const button = (
        <ToolbarButton
            tooltipDirection="up"
            disabled={
                !isLocation(location) ||
                !character.canTakeActions ||
                (location.combat?.participants?.[token.id] &&
                    character.combatTurn?.action &&
                    (character.combatTurn?.attacks == null || attacksRemaining === 0))
            }
            onClick={() => {
                const template = getAnnotationTemplate(
                    location as Location,
                    levelKey!,
                    character,
                    token,
                    grid,
                    weapon,
                    attacksRemaining,
                    criticalRange,
                    handed,
                    character.ignoreLongRangePenalty,
                    true
                );
                annotationPlacementTemplateChanged.trigger(template);
            }}>
            {renderIconForWeapon(weapon, iconSize)}
            {handed != null && <Badge bg="grayscale.7">{handed}H</Badge>}
        </ToolbarButton>
    );

    // *Melee Weapon Attack* **+4** to hit, reach 5 ft., one target. *Hit:* **1d4+2** slashing damage.
    // *Ranged Weapon Attack* **+4** to hit, range 30/120 ft., one target. *Hit:* **1d4+2** slashing damage.
    const attack = getWeaponAttack(weapon, character.attacks, criticalRange, handed, true);
    let markdown = `### ${attack.name}\n*${character.attacks > 1 ? `${character.attacks} attacks` : "1 attack"}*\n\n`;

    if (attack.attack === "mw" || attack.attack === "mw,rw") {
        markdown += `*Melee Weapon Attack* **${(attack.attackModifier ?? 0) >= 0 ? "+" : ""}${
            attack.attackModifier ?? 0
        }** to hit, reach ${attack.reach ?? 5} ft. *Hit:* **${getWeaponDamage(weapon, rules, character, handed)}** ${
            weapon.dmgType
        } damage.\n`;
    }

    if (attack.attack === "rw" || attack.attack === "mw,rw") {
        markdown += `*Ranged Weapon Attack* **${(attack.attackModifier ?? 0) >= 0 ? "+" : ""}${
            attack.attackModifier ?? 0
        }** to hit, range ${attack.rangeNear}/${attack.range} ft. *Hit:* **${getWeaponDamage(
            weapon,
            rules,
            character,
            handed
        )}** ${weapon.dmgType} damage.\n`;
    }

    return (
        <BoxPopup content={button} delay={500}>
            <Markdown>{markdown}</Markdown>
        </BoxPopup>
    );
};

const AbilityButton: FunctionComponent<{
    token: ResolvedToken<DnD5EToken>;
    creature: ResolvedMonster | ResolvedCharacter;
    ability: Ability;
    abilityKey: string;
}> = ({ token, creature, ability, abilityKey }) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();
    const rules = useRules();

    let { icon, isToggled } = renderIconForAbility(ability, creature, rules, iconSize);

    // Work out if the ability has any choices to make before performing it.
    // We only want to do this if it won't end up using an annotation - which usually means it's an ability
    // that only affects the caster.
    // TODO: This thinks that the types of o.applied is not assignable to the applicableEffectHasChoices parameter, despite them having identical types.
    // I don't have time for this right now.
    const [choices, setChoices] = useState<{
        [effectKey: string]: AppliedAbilityEffectChoices;
    }>({});
    const effectKeys = keyedListToKeyArray(ability.effects);
    const hasEffectChoices =
        !ability.aoe &&
        !ability.attack &&
        effectKeys?.some(o => applicableEffectHasChoices(ability.effects![o].applied as any, rules));
    const hasUnmadeEffectChoices =
        hasEffectChoices &&
        effectKeys?.some(o => applicableEffectHasUnmadeChoices(ability.effects![o].applied as any, choices[o], rules));

    const [isChoicesOpen, setIsChoicesOpen] = useState(false);

    const [referenceElement, setReferenceElement] = React.useState<HTMLElement | null>(null);
    const [popperElement, setPopperElement] = React.useState<HTMLElement | null>(null);
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        placement: "top",
        modifiers: [
            { name: "offset", options: { offset: [0, theme.space[2]] } },
            { name: "preventOverflow", options: { padding: theme.space[3] } },
        ],
    });

    const multiattackOptions = (ability as MonsterAbility).attackOptions
        ? mapKeyedList((ability as MonsterAbility).attackOptions, (o, i, k) => ({
              key: k,
              parts: o,
          }))
        : undefined;
    const hasMultiattackChoice = (multiattackOptions?.length ?? 0) > 1;

    const dispatchAbility = useDispatchAbility(token, creature);

    const { doWeaponAttack, items } = useWeaponAttackOptions(token, creature, ability, abilityKey, rules);
    const hasWeaponChoice = items != null && doWeaponAttack != null;

    let disabled = !canUseCombatAbility(location, token, creature, ability, abilityKey);
    const button = (
        <React.Fragment>
            <ToolbarButton
                ref={hasEffectChoices || hasWeaponChoice || hasMultiattackChoice ? setReferenceElement : undefined}
                isToggled={isToggled}
                disabled={disabled}
                onClick={() => {
                    if (hasWeaponChoice) {
                        if (items && items.length > 1) {
                            setIsChoicesOpen(!isChoicesOpen);
                        } else if (items && items.length === 1) {
                            doWeaponAttack!(items[0]);
                        }
                    } else if (hasEffectChoices || hasMultiattackChoice) {
                        setIsChoicesOpen(!isChoicesOpen);
                    } else {
                        dispatchAbility?.(ability, abilityKey, choices);
                    }
                }}>
                {icon}
            </ToolbarButton>
            <AnimatePresence>
                {isChoicesOpen && (
                    <Box ref={setPopperElement} {...attributes.popper} style={styles.popper}>
                        <MotionCard
                            p={3}
                            layout="size"
                            maxWidth={600}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            flexDirection="column"
                            bg="grayscale.9"
                            borderRadius={3}>
                            {hasWeaponChoice && (
                                <ListBox
                                    flexDirection="column"
                                    alignItems="stretch"
                                    items={items!}
                                    itemKey={item => {
                                        return item.id + (item.handed ?? "");
                                    }}
                                    onSelectionChanged={items => {
                                        if (items?.length) {
                                            doWeaponAttack!(items[0]);
                                            setIsChoicesOpen(false);
                                        }
                                    }}>
                                    {({ item }) => (
                                        <ItemListItem
                                            key={item.id}
                                            item={item}
                                            canSelect
                                            isDynamicWidth
                                            character={isCharacter(creature) ? creature : undefined}
                                        />
                                    )}
                                </ListBox>
                            )}
                            {hasEffectChoices && (
                                <React.Fragment>
                                    {mapKeyedList(ability.effects, (o, i, k) => {
                                        const applicableEffect = isNamedRuleRef(o.applied)
                                            ? rules.effects.get(o.applied)
                                            : (o.applied as ApplicableAbilityEffect);
                                        if (!applicableEffect) {
                                            return undefined;
                                        }

                                        return (
                                            <AppliedAbilityEffectChoicesEditor
                                                key={k}
                                                applicableEffect={applicableEffect}
                                                choices={choices[k]}
                                                onChoicesUpdated={delta => {
                                                    const newEffectChoices = Object.assign({}, choices[k], delta);
                                                    const newChoices = Object.assign({}, choices, {
                                                        [k]: newEffectChoices,
                                                    });
                                                    setChoices(newChoices);
                                                }}
                                            />
                                        );
                                    })}
                                    <Box fullWidth>
                                        <Button
                                            fullWidth
                                            mt={2}
                                            onClick={() => dispatchAbility?.(ability, abilityKey, choices)}
                                            disabled={hasUnmadeEffectChoices}>
                                            Use {ability.name}
                                        </Button>
                                    </Box>
                                </React.Fragment>
                            )}
                            {multiattackOptions != null && (
                                <ListBox
                                    flexDirection="column"
                                    alignItems="stretch"
                                    items={multiattackOptions}
                                    itemKey={item => {
                                        return item.key;
                                    }}
                                    onSelectionChanged={items => {
                                        if (items?.length) {
                                            dispatch(
                                                setMultiattackOption(
                                                    campaign,
                                                    location,
                                                    token,
                                                    abilityKey,
                                                    items[0].key
                                                )
                                            );
                                            setIsChoicesOpen(false);
                                        }
                                    }}>
                                    {({ item }) => <MultiattackOption option={item} />}
                                </ListBox>
                            )}
                        </MotionCard>
                    </Box>
                )}
            </AnimatePresence>
        </React.Fragment>
    );

    const resourceCost = ability.resourceCost
        ? Object.keys(ability.resourceCost)
              .map(o => `${ability.resourceCost![o]} ${o}`)
              .join(", ")
        : undefined;
    const cost = ability.time
        ? resourceCost
            ? `${abilityTimeToString(ability.time)}, ${resourceCost}`
            : abilityTimeToString(ability.time)
        : resourceCost;
    return (
        <BoxPopup content={button} delay={500} disabled={isChoicesOpen}>
            <Markdown>{`### ${ability.name}${cost ? `\n*${cost}*` : ""}${
                ability.content ? `\n\n${ability.content}` : ""
            }`}</Markdown>
        </BoxPopup>
    );
};

function sortAbility(
    ability: Ability,
    element: JSX.Element,
    actions: JSX.Element[],
    bonuses: JSX.Element[],
    reactions: JSX.Element[],
    others: JSX.Element[]
) {
    if (ability.time?.unit === "action") {
        actions.push(element);
    } else if (ability.time?.unit === "bonus") {
        bonuses.push(element);
    } else if (ability.time?.unit === "reaction") {
        reactions.push(element);
    } else {
        if (ability.isAttackAction) {
            actions.push(element);
        } else {
            others.push(element);
        }
    }
}

export const CombatActions: FunctionComponent<{ token: ResolvedToken<DnD5EToken> }> = React.memo(({ token }) => {
    const { campaign, location } = useLocation();
    const rules = useRules();
    const dispatch = useDispatch();

    let imageUri: string | undefined;
    let creature = fullResolveTokenCreature(token, campaign, rules);
    if (!creature) {
        return <React.Fragment></React.Fragment>;
    } else if (creature.transformedInto) {
        imageUri = creature.transformedInto.transform.appearance?.imageUri;
        creature = creature.transformedInto;
    }

    if (!imageUri) {
        let metadata = token.images?.find(o => o.uri === token.imageUri);
        imageUri = metadata?.thumbnailUri ?? metadata?.uri ?? token.imageUri;
    }

    const actionButtons: JSX.Element[] = [];
    const bonusButtons: JSX.Element[] = [];
    const reactionButtons: JSX.Element[] = [];
    const otherButtons: JSX.Element[] = [];

    if (isCharacter(creature)) {
        // Get the equipped weapons that can be used to attack.
        const weapons = getItemsInInventory(creature.inventory).filter(o => o.active && isWeapon(o)) as KeyedListItem<
            ResolvedInventoryWeapon & ResolvedInventoryItem
        >[];

        // If the character has no other equipped weapons, or if they have some kind of effect that enhances unarmed
        // strikes, then include the unarmed strike weapon.
        if (weapons.length === 0) {
            weapons.push(getUnarmedWeapon(creature, rules));
        } else if (creature.itemModifiers) {
            const unarmedWeapon = getUnarmedWeapon(creature, rules);
            if (creature.itemModifiers.some(o => filterItem(unarmedWeapon, o.filter, rules.items))) {
                weapons.push(unarmedWeapon);
            }
        }

        for (let weapon of weapons) {
            if (weapon.properties?.some(o => o.abbreviation === "V")) {
                actionButtons.push(
                    <WeaponAttackButton
                        key={weapon.id + "_1H"}
                        token={token}
                        character={creature}
                        handed={1}
                        weapon={weapon}
                    />
                );
                actionButtons.push(
                    <WeaponAttackButton
                        key={weapon.id + "_2H"}
                        token={token}
                        character={creature}
                        handed={2}
                        weapon={weapon}
                    />
                );
            } else {
                actionButtons.push(
                    <WeaponAttackButton key={weapon.id} token={token} character={creature} weapon={weapon} />
                );
            }
        }
    }

    const abilityKeys = keyedListToKeyArray(creature.abilities);
    if (abilityKeys) {
        for (let abilityKey of abilityKeys) {
            const ability = creature.abilities![abilityKey];
            if (abilityMeetsCondition(creature, ability, rules)) {
                sortAbility(
                    ability,
                    <AbilityButton
                        key={abilityKey}
                        abilityKey={abilityKey}
                        token={token}
                        creature={creature}
                        ability={ability}
                    />,
                    actionButtons,
                    bonusButtons,
                    reactionButtons,
                    otherButtons
                );
            }
        }
    }

    // Also include any generic actions.
    const genericActions = rules.actions.all.filter(o => o.effects || o.weaponAttack);
    for (let genericAction of genericActions) {
        if (abilityMeetsCondition(creature, genericAction, rules)) {
            sortAbility(
                genericAction,
                <AbilityButton
                    key={genericAction.name}
                    abilityKey={getRuleKey(genericAction)}
                    token={token}
                    creature={creature}
                    ability={genericAction}
                />,
                actionButtons,
                bonusButtons,
                reactionButtons,
                otherButtons
            );
        }
    }

    const maxMovementSpeeds = getMovementSpeeds(creature);
    const movementSpeeds =
        creature?.combatTurn?.movement ?? (maxMovementSpeeds ? resolveMovementSpeeds(maxMovementSpeeds) : undefined);
    const isInCombat = isLocation(location) && location.combat?.participants[token.id];

    let standUpCost = maxMovementSpeeds?.walk ? resolveModifiedValue(maxMovementSpeeds.walk) / 2 : 0;

    return (
        <LayoutGroup>
            <MotionBox
                layout="position"
                flexDirection="row"
                alignItems="flex-end"
                transition={{ type: "tween" }}
                initial={{ x: -30, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: -30, opacity: 0 }}>
                <Box width={tokenImageSize} height={tokenImageSize} borderRadius={3} mr={2} bg="grayscale.9">
                    <Image src={resolveUri(imageUri)} responsive fullWidth fullHeight draggable={false} />
                </Box>
                <Box flexDirection="column" alignItems="flex-start">
                    <AnimatePresence initial={false}>
                        {isInCombat && movementSpeeds && (
                            <MotionOverlayCard
                                key="movement"
                                layout
                                initial={{ x: -30, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: -30, opacity: 0 }}
                                borderRadius={4}
                                mb={2}
                                flexDirection="row"
                                p={2}
                                css={{ pointerEvents: "all" }}>
                                <MotionGrid
                                    layout="position"
                                    gridTemplateColumns="auto 1fr auto auto auto"
                                    css={{ gap: theme.space[2], alignItems: "center" }}>
                                    {(Object.keys(movementSpeeds) as MovementType[]).map(o => {
                                        const current = movementSpeeds[o as MovementType]!;
                                        const max = resolveModifiedValue(maxMovementSpeeds?.[o]!);
                                        return (
                                            <React.Fragment key={o}>
                                                <Button
                                                    size="s"
                                                    onClick={() =>
                                                        dispatch(
                                                            changeMovementForTurn(campaign, location, token, o, -5)
                                                        )
                                                    }>
                                                    -
                                                </Button>
                                                <span>{movementTypeToString(o as MovementType)}</span>
                                                <span
                                                    css={{
                                                        fontWeight: "bold",
                                                        textAlign: "right",
                                                        color:
                                                            current < max
                                                                ? current <= 0
                                                                    ? theme.colors.guidance.error[1]
                                                                    : theme.colors.guidance.warning[1]
                                                                : theme.colors.guidance.success[1],
                                                    }}>
                                                    {current}
                                                </span>
                                                <span css={{ textAlign: "right" }}>
                                                    {max != null ? `(${max})` : ""}
                                                </span>
                                                <Button
                                                    size="s"
                                                    onClick={() =>
                                                        dispatch(changeMovementForTurn(campaign, location, token, o, 5))
                                                    }>
                                                    +
                                                </Button>
                                            </React.Fragment>
                                        );
                                    })}
                                </MotionGrid>

                                <AnimatePresence>
                                    {creature?.conditions?.prone && standUpCost <= (movementSpeeds.walk ?? 0) && (
                                        <MotionBox
                                            layout
                                            ml={2}
                                            alignSelf="flex-start"
                                            initial={defaultInitial}
                                            animate={defaultAnimate}
                                            exit={defaultExit}>
                                            <Button
                                                size="s"
                                                tooltip="Stand up"
                                                tooltipDirection="up"
                                                toggled
                                                onClick={() => {
                                                    // Remove the prone condition at the cost of half the creature's max movement.
                                                    // TODO: This should really be done in one dispatch by a dedicated action...
                                                    dispatch(
                                                        changeMovementForTurn(
                                                            campaign,
                                                            location,
                                                            token,
                                                            undefined,
                                                            -standUpCost
                                                        )
                                                    );
                                                    dispatch(
                                                        applyConditions(campaign, location, [token], {
                                                            prone: undefined,
                                                        })
                                                    );
                                                }}>
                                                <ProneIcon size={18 as any} />
                                            </Button>
                                        </MotionBox>
                                    )}
                                </AnimatePresence>
                            </MotionOverlayCard>
                        )}
                        {isInCombat && (
                            <MotionOverlayCard
                                key="resources"
                                layout
                                initial={{ x: -30, opacity: 0 }}
                                animate={{ x: 0, opacity: 1 }}
                                exit={{ x: -30, opacity: 0 }}
                                borderRadius={4}
                                mb={2}
                                flexDirection="row"
                                css={{ pointerEvents: "all" }}
                                p={2}>
                                <CombatResources token={token} creature={creature} />
                            </MotionOverlayCard>
                        )}
                    </AnimatePresence>

                    <Box flexDirection="row" css={{ gap: theme.space[2], pointerEvents: "all" }}>
                        {actionButtons.length > 0 && (
                            <MotionOverlayToolbar layout prominent>
                                {actionButtons}
                            </MotionOverlayToolbar>
                        )}

                        {bonusButtons.length > 0 && (
                            <MotionOverlayToolbar layout prominent>
                                {bonusButtons}
                            </MotionOverlayToolbar>
                        )}

                        {reactionButtons.length > 0 && (
                            <MotionOverlayToolbar layout prominent>
                                {reactionButtons}
                            </MotionOverlayToolbar>
                        )}

                        {otherButtons.length > 0 && (
                            <MotionOverlayToolbar layout prominent>
                                {otherButtons}
                            </MotionOverlayToolbar>
                        )}
                    </Box>
                </Box>
            </MotionBox>
        </LayoutGroup>
    );
});
