/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, PropsWithChildren, useMemo, useState } from "react";
import { useRules } from "./hooks";
import { Box, Heading, Label, Scrollable, Select, Text, Truncate } from "../../../components/primitives";
import { CustomSvg, ExtractProps, LobotomizedBox, SidebarButton, useVttApp } from "../../../components/common";
import SpellbookIcon from "../../../components/icons/Spellbook";
import InventoryIcon from "../../../components/icons/Inventory";
import DragonIcon from "../../../components/icons/Dragon";
import { AnimatePresence } from "framer-motion";
import { AnimatedListItem, MotionBox, sectionAnimate, sectionExit, sectionInitial } from "../../../components/motion";
import { ListBox, ListItem } from "../../../components/ListBox";
import {
    Background,
    CharacterClass,
    CharacterSubclass,
    Feature,
    Monster,
    MonsterSpell,
    Race,
    ResolvedCharacter,
    ResolvedClassLevels,
    ResolvedMonster,
    Subrace,
    armorProficienciesToString,
    canLearnSpell,
    coreAbilitiesToString,
    filterMonsters,
    fullResolveTokenCreature,
    getCoreAbilityLabel,
    itemProficienciesToString,
    resolveClass,
    resolveClassSpellList,
    skillProficienciesToString,
    weaponProficienciesToString,
} from "../creature";
import { theme } from "../../../design";
import {
    DnD5ECharacterTemplate,
    DnD5ECharacterToken,
    DnD5EMonsterTemplate,
    DnD5EMonsterToken,
    MergedRule,
    NamedRuleRef,
    fromRuleKey,
    getRuleKey,
    isDnD5ECharacterTemplate,
    isDnD5ECharacterToken,
    isDnD5EMonsterTemplate,
    isDnD5EMonsterToken,
    monsterToTokenTemplate,
} from "../common";
import { Tag } from "../../../components/Tag";
import {
    SidebarPanelOptions,
    SidebarPanelState,
    TokenTemplate,
    diceTypeToAverage,
    diceTypeToMax,
    getToken,
} from "../../../store";
import { ShowMore } from "./ShowMore";
import { Markdown } from "../../../components/markdown";
import { FeatureExpander } from "./FeatureExpander";
import { BasicExpander } from "./BasicExpander";
import { Pages } from "../../../components/Sidebar";
import { Message } from "../../../components/Message";
import { SearchResult } from "../../../localsearchable";
import { MarkdownActions } from "../../../components/MarkdownActions";
import DwarfIcon from "../../../components/icons/Dwarf";
import GlowingHandsIcon from "../../../components/icons/GlowingHands";
import StarsIcon from "../../../components/icons/Stars";
import JourneyIcon from "../../../components/icons/Journey";
import { Spacer } from "../../../components/Spacer";
import { ResolvedItem } from "../items";
import { ItemActions, ItemDetails as ItemDetailsCore } from "./ItemInfo";
import { Button } from "../../../components/Button";
import { CompendiumPage, renderIconForItem, useCompendium } from "./common";
import { useAppState, useCampaign, useDispatch, useLocation } from "../../../components/contexts";
import { TokenTemplateHost, TokenTemplateListItem } from "../../../components/Sidebar/TokenTemplateListItem";
import { Spell } from "../spells";
import { SpellDetails, getSpellTags } from "./SpellInfo";
import { MonsterFilters } from "./TokenTools";
import { DraggableBox } from "../../../components/draggable";
import { copyState } from "../../../reducers/common";
import { modifyCharacter, modifyMonster } from "../actions/token";
import { keyedListToArray, mapKeyedList } from "../../../common";

const palettes = ["blues", "cyans", "greens", "yellows", "oranges", "reds", "purples", "violets"];
const tagPalettes: { [tag: string]: string } = {};

function getPaletteForTag(tag: string): string {
    let palette = tagPalettes[tag];
    if (palette != null) {
        return palette;
    }

    let n = 0;
    for (let i = 0; i < tag.length; i++) {
        n += tag.charCodeAt(i);
    }

    n = n % palettes.length;
    palette = palettes[n];
    tagPalettes[tag] = palette;
    return palette;
}

const RuleTags: FunctionComponent<
    PropsWithChildren<{
        item: MergedRule<NamedRuleRef>;
        otherTags?: string[];
    }> &
        ExtractProps<typeof Box>
> = ({ item, otherTags, children, ...props }) => {
    // TODO: Get access to the original packages so that we can get at their metadata and show the actual package name as
    // a tooltip on the tags. Might have to actually add that metadata to begin with though, and expose the packages that
    // a ruleset is built from via the CharacterRuleSet.
    const childCount = React.Children.count(children);
    return (
        <Box flexDirection="row" {...props}>
            <Tag bg={`${getPaletteForTag(item.source)}.7`} color={`${getPaletteForTag(item.source)}.0`} mr={1}>
                {item.source}
            </Tag>
            {item.contributingSources != null &&
                item.contributingSources.map(o => {
                    const palette = getPaletteForTag(o);
                    return (
                        <Tag key={o} bg={`${palette}.7`} color={`${palette}.0`} mr={1}>
                            {o}
                        </Tag>
                    );
                })}
            {otherTags && (
                <React.Fragment>
                    <Spacer direction="vertical" mr={1} minHeight={theme.space[3]} />
                    {otherTags.map(o => {
                        const palette = getPaletteForTag(o);
                        return (
                            <Tag key={o} bg={`${palette}.7`} color={`${palette}.0`} mr={1}>
                                {o}
                            </Tag>
                        );
                    })}
                </React.Fragment>
            )}
            {childCount > 0 && <Spacer direction="vertical" mr={1} minHeight={theme.space[3]} />}
            {children}
        </Box>
    );
};

const RuleListItem: FunctionComponent<{
    item: { name: string; source?: string };
    selected?: boolean;
    active?: boolean;
    focused?: boolean;
}> = ({ item, ...props }) => {
    return (
        <ListItem {...props}>
            <MotionBox layout="position" flexDirection="column" alignItems="flex-start">
                {item.name}
                {item.source && <RuleTags item={item as NamedRuleRef} />}
            </MotionBox>
        </ListItem>
    );
};

const SpellTags: FunctionComponent<
    {
        item: Spell;
    } & ExtractProps<typeof Box>
> = ({ item, ...props }) => {
    let tags: string[] | undefined;

    return (
        <RuleTags item={item as NamedRuleRef} otherTags={tags} {...props}>
            {getSpellTags(item)}
        </RuleTags>
    );
};

const SpellListItem: FunctionComponent<{
    spell: Spell;
    selected?: boolean;
    active?: boolean;
    focused?: boolean;
}> = ({ spell, ...props }) => {
    return (
        <ListItem {...props}>
            <DraggableBox
                fullWidth
                draggableId={getRuleKey(spell)}
                dragOverlay
                type="DnD5E_Spell"
                data={spell}
                layout="position"
                flexDirection="column"
                alignItems="flex-start">
                {spell.displayName ?? spell.name}
                <Text mb={1} color="grayscale.2" fontSize={0}>{`${
                    spell.level === 0 ? "Cantrip" : `Level ${spell.level}`
                } • ${spell.school}`}</Text>
                <SpellTags item={spell} />
            </DraggableBox>
        </ListItem>
    );
};

const ItemTags: FunctionComponent<
    {
        item: ResolvedItem;
    } & ExtractProps<typeof Box>
> = ({ item, ...props }) => {
    let tags: string[] | undefined;

    if (item.properties && item.properties.length) {
        tags = [];
        for (let i = 0; i < item.properties.length; i++) {
            tags.push(item.properties[i].abbreviation);
        }
    }

    return <RuleTags item={item as NamedRuleRef} otherTags={tags} {...props} />;
};

const ItemListItem: FunctionComponent<{
    item: ResolvedItem;
    selected?: boolean;
    active?: boolean;
    focused?: boolean;
}> = ({ item, ...props }) => {
    return (
        <ListItem {...props}>
            <DraggableBox
                fullWidth
                draggableId={getRuleKey(item)}
                dragOverlay
                type="DnD5E_Item"
                data={{ items: [item] }}
                justifyContent="flex-start">
                <MotionBox layout="position" flexShrink={1}>
                    <Box width={theme.space[6]} height={theme.space[6]} bg="grayscale.7" borderRadius={3}>
                        {renderIconForItem(item, theme.space[5])}
                    </Box>

                    <Box pl={3} flexDirection="column" alignItems="flex-start" flexShrink={1}>
                        <Truncate lines={1}>{item.name}</Truncate>
                        {item.source && <ItemTags item={item} />}
                    </Box>
                </MotionBox>
            </DraggableBox>
        </ListItem>
    );
};

const ClassListItem: FunctionComponent<{
    item: CharacterClass;
    selected?: boolean;
    active?: boolean;
    focused?: boolean;
}> = ({ item, ...props }) => {
    return (
        <ListItem {...props}>
            <MotionBox
                layout="position"
                width={theme.space[6]}
                height={theme.space[6]}
                bg="grayscale.7"
                borderRadius={3}>
                {item.icon && (
                    <CustomSvg
                        css={{
                            fill: "currentColor",
                            width: theme.space[6],
                            height: theme.space[6],
                        }}
                        p={2}
                        svg={item.icon}
                    />
                )}
            </MotionBox>

            <MotionBox layout="position" pl={3} flexDirection="column" alignItems="flex-start">
                <RuleTags item={item} />
                {item.name}
            </MotionBox>
        </ListItem>
    );
};

const SubclassDetails: FunctionComponent<{ item: CharacterSubclass }> = ({ item }) => {
    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name}
            </Heading>
            <RuleTags px={3} item={item} pb={2} />
            <Scrollable px={3} pb={3} minimal>
                {item.summary && <Markdown>{item.summary?.content}</Markdown>}
                <BasicExpander mt={4} title="Class Features" defaultIsExpanded>
                    <LobotomizedBox flexDirection="column" py={2}>
                        {mapKeyedList(item.features, (f, fi, k) => (
                            <AnimatedListItem key={k} index={fi} fullWidth>
                                <FeatureExpander
                                    isInOverlay
                                    title={f.name}
                                    subtitle={f.level != null ? `Level ${f.level}` : "Optional feature"}>
                                    <MarkdownActions markdown={`:feature[${getRuleKey(f)}]`} />
                                    {f.content && <Markdown>{f.content}</Markdown>}
                                </FeatureExpander>
                            </AnimatedListItem>
                        ))}
                    </LobotomizedBox>
                </BasicExpander>
            </Scrollable>
        </Box>
    );
};

const ClassDetails: FunctionComponent<{ item: CharacterClass }> = ({ item }) => {
    const rules = useRules();

    const { addPanel, clearPanels } = useVttApp();

    // If the fluff text starts with the name of the class as a header, then strip it off - we're already showing it ourselves.
    let fluff = item.fluff?.trimLeft();
    if (fluff?.startsWith("### " + item.name)) {
        const i = fluff.indexOf("\n");
        if (i >= 0) {
            fluff = fluff.substring(i + 1);
        }
    }

    const toolsStr = itemProficienciesToString(item.toolProficiencies, item.toolProficiencyChoice, rules);

    const subclasses = useMemo(() => keyedListToArray(item.subclasses), [item.subclasses]);
    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : subclasses.findIndex(o => getRuleKey(o) === selectedItem);

    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name}
            </Heading>
            <RuleTags px={3} item={item} pb={2} />
            <Scrollable px={3} pb={3} minimal>
                {fluff && (
                    <ShowMore>
                        <Markdown>
                            {item.icon && (
                                <CustomSvg
                                    css={{
                                        fill: "currentColor",
                                        float: "left",
                                        marginTop: "1em",
                                    }}
                                    pr={2}
                                    pb={2}
                                    width={theme.space[7]}
                                    svg={item.icon}
                                />
                            )}

                            {fluff}
                        </Markdown>
                    </ShowMore>
                )}
                <BasicExpander mt={fluff ? 4 : 0} title="Hit Points" defaultIsExpanded>
                    <Markdown>
                        {`**Hit Dice:** :roll[1${
                            item.hitDie
                        }] per ${item.name.toLocaleLowerCase()} level\n\n**Hit Points at 1st Level:** ${diceTypeToMax(
                            item.hitDie
                        )} + your Constitution modifier\n\n**Hit Points at Higher Levels:** :roll[1${
                            item.hitDie
                        }] (${diceTypeToAverage(
                            item.hitDie
                        )}) + your Constitution modifier per ${item.name.toLocaleLowerCase()} level after 1st`}
                    </Markdown>
                </BasicExpander>
                <BasicExpander mt={4} title="Proficiencies" defaultIsExpanded>
                    <Markdown>
                        {`**Armor:** ${armorProficienciesToString(
                            item.armorProficiencies
                        )}\n\n**Weapons:** ${weaponProficienciesToString(item.weaponProficiencies)}${
                            toolsStr ? `\n\n**Tools:** ${toolsStr}` : ""
                        }\n\n**Saving throws:** ${coreAbilitiesToString(
                            item.savingThrowProficiencies
                        )}\n\n**Skills:** ${skillProficienciesToString(
                            item.skillProficiencies,
                            item.skillProficiencyChoice
                        )}`}
                    </Markdown>
                </BasicExpander>
                {/* TODO: Starting equipment? */}
                <BasicExpander mt={4} title="Class features">
                    <LobotomizedBox flexDirection="column" py={2}>
                        {mapKeyedList(item.features, (f, fi, k) => (
                            <AnimatedListItem key={k} index={fi} fullWidth>
                                <FeatureExpander isInOverlay title={f.name} subtitle={`Level ${f.level}`}>
                                    <MarkdownActions markdown={`:feature[${getRuleKey(f)}]`} />
                                    {f.content && <Markdown>{f.content}</Markdown>}
                                </FeatureExpander>
                            </AnimatedListItem>
                        ))}
                    </LobotomizedBox>
                </BasicExpander>
                {/* TODO: More stuff - spells slots? */}
                <BasicExpander mt={4} title={item.subclassTitle + "s"} defaultIsExpanded>
                    <ListBox<CharacterSubclass>
                        selectActive
                        css={{
                            position: "relative",
                            flexDirection: "column",
                            alignItems: "stretch",
                            gap: theme.space[2],
                        }}
                        paddingY={3}
                        fullWidth
                        items={subclasses}
                        selectedItems={selectedIndex < 0 ? undefined : [subclasses[selectedIndex]]}
                        itemKey={o => getRuleKey(o)}
                        onSelectionChanged={o => {
                            setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                            if (o.length) {
                                addPanel({
                                    id: getRuleKey(o[0]),
                                    children: () => <SubclassDetails item={o[0]} />,
                                    width: theme.space[13],
                                    type: "subclass",
                                    clearType: "subclass",
                                    addAfter: getRuleKey(item),
                                });
                            } else {
                                clearPanels();
                            }
                        }}>
                        {({ item, index, selected, active, focused }) => {
                            return (
                                <AnimatedListItem key={item.name} index={index} fullWidth borderRadius={3}>
                                    <RuleListItem item={item} selected={selected} active={active} focused={focused} />
                                </AnimatedListItem>
                            );
                        }}
                    </ListBox>
                </BasicExpander>
            </Scrollable>
        </Box>
    );
};

const ClassesPage: FunctionComponent<{}> = () => {
    const rules = useRules();

    const { searchTerm, addPanel, clearPanels } = useVttApp();

    const classes = useMemo(() => {
        return rules.classes.all.toSorted((a, b) => a.name.localeCompare(b.name));
    }, [rules.classes]).filter(o =>
        searchTerm === "" ? true : o.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : classes.findIndex(o => getRuleKey(o) === selectedItem);

    return (
        <Scrollable fullWidth fullHeight minimal py={3}>
            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only classes matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <ListBox<CharacterClass>
                selectActive
                css={{
                    position: "relative",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: theme.space[2],
                }}
                py={3}
                px={3}
                fullWidth
                items={classes}
                selectedItems={selectedIndex < 0 ? undefined : [classes[selectedIndex]]}
                itemKey={o => getRuleKey(o)}
                onSelectionChanged={o => {
                    setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                    if (o.length) {
                        addPanel({
                            id: getRuleKey(o[0]),
                            children: () => <ClassDetails item={o[0]} />,
                            width: theme.space[13],
                        });
                    } else {
                        clearPanels();
                    }
                }}>
                {({ item, index, selected, active, focused }) => {
                    return (
                        <AnimatedListItem key={item.name} index={index} fullWidth borderRadius={3}>
                            <ClassListItem item={item} selected={selected} active={active} focused={focused} />
                        </AnimatedListItem>
                    );
                }}
            </ListBox>
        </Scrollable>
    );
};

const BackgroundDetails: FunctionComponent<{ item: Background }> = ({ item }) => {
    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name}
            </Heading>
            <RuleTags px={3} item={item} pb={2} />
            <Scrollable px={3} pb={3} minimal>
                <Markdown>
                    {item.content}
                    {mapKeyedList(item.backgroundContent, o =>
                        o.name ? `\n### ${o.name}\n${o.content?.trimLeft() ?? ""}` : o.content ?? ""
                    )}
                </Markdown>
            </Scrollable>
        </Box>
    );
};

const BackgroundsPage: FunctionComponent<{}> = () => {
    const rules = useRules();

    const { searchTerm, addPanel, clearPanels } = useVttApp();

    const backgrounds = useMemo(() => {
        return rules.backgrounds.all.toSorted((a, b) => a.name.localeCompare(b.name));
    }, [rules.backgrounds]).filter(o =>
        searchTerm === "" ? true : o.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : backgrounds.findIndex(o => getRuleKey(o) === selectedItem);

    return (
        <Scrollable fullWidth fullHeight minimal py={3}>
            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only backgrounds matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <ListBox<Background>
                selectActive
                css={{
                    position: "relative",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: theme.space[2],
                }}
                py={3}
                px={3}
                fullWidth
                items={backgrounds}
                selectedItems={selectedIndex < 0 ? undefined : [backgrounds[selectedIndex]]}
                itemKey={o => getRuleKey(o)}
                onSelectionChanged={o => {
                    setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                    if (o.length) {
                        addPanel({
                            id: getRuleKey(o[0]),
                            children: () => <BackgroundDetails item={o[0]} />,
                            width: theme.space[13],
                        });
                    } else {
                        clearPanels();
                    }
                }}>
                {({ item, index, selected, active, focused }) => {
                    return (
                        <AnimatedListItem key={item.name} index={index} fullWidth borderRadius={3}>
                            <RuleListItem item={item} selected={selected} active={active} focused={focused} />
                        </AnimatedListItem>
                    );
                }}
            </ListBox>
        </Scrollable>
    );
};

const FeatureDetails: FunctionComponent<{ item: Feature }> = ({ item }) => {
    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name}
            </Heading>
            {item.source && <RuleTags px={3} item={item as NamedRuleRef} pb={2} />}
            <MarkdownActions mx={3} mb={2} markdown={`:feature[${getRuleKey(item)}]`} />
            <Scrollable px={3} pb={3} minimal>
                <Markdown>{item.content}</Markdown>
            </Scrollable>
        </Box>
    );
};

const FeatsPage: FunctionComponent<{}> = () => {
    const rules = useRules();

    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();

    const featResults =
        searchTerm !== ""
            ? ((searchResults.find(o => o.categoryId === "dnd5e_feats")?.results ?? []) as SearchResult<
                  Feature,
                  () => JSX.Element
              >[])
            : undefined;
    const feats = featResults != null ? featResults.map(o => o.originalItem) : rules.feats.all;

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : feats.findIndex(o => getRuleKey(o) === selectedItem);

    return (
        <Scrollable fullWidth fullHeight minimal py={3}>
            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only feats matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <ListBox<Feature>
                selectActive
                css={{
                    position: "relative",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: theme.space[2],
                }}
                py={3}
                px={3}
                fullWidth
                items={feats}
                selectedItems={selectedIndex < 0 ? undefined : [feats[selectedIndex]]}
                itemKey={o => getRuleKey(o)}
                onSelectionChanged={o => {
                    setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                    if (o.length) {
                        addPanel({
                            id: getRuleKey(o[0]),
                            children: () => <FeatureDetails item={o[0]} />,
                        });
                    } else {
                        clearPanels();
                    }
                }}>
                {({ item, index, selected, active, focused }) => {
                    return (
                        <AnimatedListItem key={item.name} index={index} fullWidth borderRadius={3}>
                            <RuleListItem item={item} selected={selected} active={active} focused={focused} />
                        </AnimatedListItem>
                    );
                }}
            </ListBox>
        </Scrollable>
    );
};

const SubraceDetails: FunctionComponent<{ item: Subrace; race: Race }> = ({ item, race }) => {
    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name} {race.name}
            </Heading>
            <RuleTags px={3} item={item} pb={2} />
            <Scrollable px={3} pb={3} minimal>
                {item.fluff && <Markdown>{item.fluff}</Markdown>}
                <BasicExpander mt={4} title={`${item.name} ${race.name} Traits`} defaultIsExpanded>
                    <LobotomizedBox flexDirection="column" py={2}>
                        {mapKeyedList(item.traits, (f, fi, k) => (
                            <AnimatedListItem key={k} index={fi} fullWidth justifyContent="flex-start">
                                <Markdown>{`***${f.name}.*** ${f.content}`}</Markdown>
                            </AnimatedListItem>
                        ))}
                    </LobotomizedBox>
                </BasicExpander>
            </Scrollable>
        </Box>
    );
};

const RaceDetails: FunctionComponent<{ item: Race }> = ({ item }) => {
    // If the fluff text starts with the name of the class as a header, then strip it off - we're already showing it ourselves.
    let fluff = item.fluff?.trimLeft();
    if (fluff?.startsWith("### " + item.name)) {
        const i = fluff.indexOf("\n");
        if (i >= 0) {
            fluff = fluff.substring(i + 1);
        }
    }

    const { addPanel, clearPanels } = useVttApp();

    const subraces = useMemo(() => keyedListToArray(item.subraces), [item.subraces]);

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex =
        selectedItem == null || !subraces ? -1 : subraces.findIndex(o => getRuleKey(o) === selectedItem);

    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name}
            </Heading>
            <RuleTags px={3} item={item} pb={2} />
            <Scrollable px={3} pb={3} minimal>
                {fluff && (
                    <ShowMore>
                        <Markdown>{fluff}</Markdown>
                    </ShowMore>
                )}
                <BasicExpander defaultIsExpanded mt={4} title={`${item.name} Traits`}>
                    <LobotomizedBox flexDirection="column" py={2}>
                        {mapKeyedList(item.traits, (f, fi, k) => (
                            <AnimatedListItem key={k} index={fi} fullWidth justifyContent="flex-start">
                                <Markdown>{`***${f.name}.*** ${f.content}`}</Markdown>
                            </AnimatedListItem>
                        ))}
                    </LobotomizedBox>
                </BasicExpander>
                {subraces && subraces.length > 0 && (
                    <BasicExpander defaultIsExpanded mt={4} title={`${item.name} Subspecies`}>
                        <ListBox<Subrace>
                            selectActive
                            css={{
                                position: "relative",
                                flexDirection: "column",
                                alignItems: "stretch",
                                gap: theme.space[2],
                            }}
                            paddingY={3}
                            fullWidth
                            items={subraces}
                            selectedItems={selectedIndex < 0 ? undefined : [subraces[selectedIndex]]}
                            itemKey={o => getRuleKey(o)}
                            onSelectionChanged={o => {
                                setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                                if (o.length) {
                                    addPanel({
                                        id: getRuleKey(o[0]),
                                        children: () => <SubraceDetails item={o[0]} race={item} />,
                                        width: theme.space[13],
                                        type: "subrace",
                                        clearType: "subrace",
                                        addAfter: getRuleKey(item),
                                    });
                                } else {
                                    clearPanels();
                                }
                            }}>
                            {({ item, index, selected, active, focused }) => {
                                return (
                                    <AnimatedListItem key={item.name} index={index} fullWidth borderRadius={3}>
                                        <RuleListItem
                                            item={item}
                                            selected={selected}
                                            active={active}
                                            focused={focused}
                                        />
                                    </AnimatedListItem>
                                );
                            }}
                        </ListBox>
                    </BasicExpander>
                )}
            </Scrollable>
        </Box>
    );
};

const RacesPage: FunctionComponent<{}> = () => {
    const rules = useRules();

    const { searchTerm, addPanel, clearPanels } = useVttApp();

    // const raceResults =
    //     searchTerm != ""
    //         ? ((searchResults.find(o => o.categoryId === "dnd5e_races")?.results ?? []) as SearchResult<
    //               Feature,
    //               () => JSX.Element
    //           >[])
    //         : undefined;
    // const races = raceResults != null ? raceResults.map(o => o.originalItem) : rules.races.all;
    const races = useMemo(() => {
        return rules.races.all.toSorted((a, b) => a.name.localeCompare(b.name));
    }, [rules.races]).filter(o => (searchTerm === "" ? true : o.name.toLowerCase().includes(searchTerm.toLowerCase())));

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : races.findIndex(o => getRuleKey(o) === selectedItem);

    return (
        <Scrollable fullWidth fullHeight minimal py={3}>
            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only species matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <ListBox<Race>
                selectActive
                css={{
                    position: "relative",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: theme.space[2],
                }}
                py={3}
                px={3}
                fullWidth
                items={races}
                selectedItems={selectedIndex < 0 ? undefined : [races[selectedIndex]]}
                itemKey={o => getRuleKey(o)}
                onSelectionChanged={o => {
                    setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                    if (o.length) {
                        addPanel({
                            id: getRuleKey(o[0]),
                            children: () => <RaceDetails item={o[0]} />,
                            width: theme.space[13],
                        });
                    } else {
                        clearPanels();
                    }
                }}>
                {({ item, index, selected, active, focused }) => {
                    return (
                        <AnimatedListItem key={item.name} index={index} fullWidth borderRadius={3}>
                            <RuleListItem item={item} selected={selected} active={active} focused={focused} />
                        </AnimatedListItem>
                    );
                }}
            </ListBox>
        </Scrollable>
    );
};

const ItemDetails: FunctionComponent<{ item: ResolvedItem }> = ({ item }) => {
    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {item.name}
            </Heading>
            <ItemTags px={3} item={item} mb={2} />
            <ItemActions px={3} item={item} mb={2} />
            <Scrollable px={3} pb={3} minimal>
                <ItemDetailsCore item={item} />
            </Scrollable>
        </Box>
    );
};

const defaultMaxItems = 30;

const ItemsPage: FunctionComponent<{}> = () => {
    const rules = useRules();

    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();

    const itemResults =
        searchTerm !== ""
            ? ((searchResults.find(o => o.categoryId === "dnd5e_items")?.results ?? []) as SearchResult<
                  ResolvedItem,
                  () => JSX.Element
              >[])
            : undefined;
    const items = itemResults != null ? itemResults.map(o => o.originalItem) : rules.items.items.allResolved;

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : items.findIndex(o => getRuleKey(o) === selectedItem);

    const [maxItems, setMaxItems] = useState(defaultMaxItems);

    return (
        <Scrollable fullWidth fullHeight minimal py={3}>
            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only items matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <ListBox<ResolvedItem>
                selectActive
                css={{
                    position: "relative",
                    flexDirection: "column",
                    alignItems: "stretch",
                    gap: theme.space[2],
                }}
                py={3}
                px={3}
                fullWidth
                items={items.slice(0, maxItems)}
                selectedItems={selectedIndex < 0 ? undefined : [items[selectedIndex]]}
                itemKey={o => getRuleKey(o)}
                onSelectionChanged={o => {
                    setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                    if (o.length) {
                        addPanel(getItemPanel(o[0]));
                    } else {
                        clearPanels();
                    }
                }}>
                {({ item, index, selected, active, focused }) => {
                    return (
                        <AnimatedListItem key={item.name} index={index % defaultMaxItems} fullWidth borderRadius={3}>
                            <ItemListItem item={item} selected={selected} active={active} focused={focused} />
                        </AnimatedListItem>
                    );
                }}
            </ListBox>
            {maxItems < items.length && (
                <Button
                    mx={3}
                    key="loadmore"
                    variant="tertiary"
                    onClick={() => setMaxItems(maxItems + defaultMaxItems)}>
                    {items.length - maxItems} more…
                </Button>
            )}
        </Scrollable>
    );
};

const SpellDetailsPanel: FunctionComponent<{ spell: Spell }> = ({ spell }) => {
    const { focusedToken } = useAppState();
    const spellFilter = useCompendium(state => state.spellFilter);
    const { campaign, location } = useLocation();
    const rules = useRules();
    const dispatch = useDispatch();
    const token = getToken(campaign, location?.id, focusedToken);
    const character =
        isDnD5ECharacterToken(token) || isDnD5ECharacterTemplate(token)
            ? (fullResolveTokenCreature(token, campaign, rules) as ResolvedCharacter | undefined)
            : undefined;
    const monster =
        isDnD5EMonsterToken(token) || isDnD5EMonsterTemplate(token)
            ? (fullResolveTokenCreature(token, campaign, rules) as ResolvedMonster | undefined)
            : undefined;

    let classLevels: ResolvedClassLevels | undefined;
    let className: string | undefined;
    if (character && character.classes) {
        // Find the class level data that applies to learning this spell.
        classLevels = Object.values(character.classes).find(o => {
            const characterClass = o.classData.originalClass;

            const isClassSame =
                characterClass.name === spellFilter?.characterClass?.name &&
                characterClass.source === spellFilter?.characterClass?.source;
            const isSubclassCompatible =
                isClassSame &&
                (spellFilter?.characterSubclass == null ||
                    (o.classData.subclass?.name === spellFilter?.characterSubclass?.name &&
                        o.classData.subclass?.source === spellFilter?.characterSubclass?.source));

            if (isSubclassCompatible) {
                className = o.classData.name;
            } else {
                return false;
            }

            return canLearnSpell(spell, o);
        });
    }

    return (
        <Box fullWidth fullHeight flexDirection="column" alignItems="flex-start">
            <Heading p={3} as="h4" css={{ textOverflow: "ellipsis", overflow: "hidden", width: "100%" }} pr={7}>
                {spell.name}
            </Heading>
            <RuleTags px={3} item={spell} mb={2} />
            <Scrollable px={3} pb={3} minimal>
                <SpellDetails spell={spell} />

                {character && (
                    <React.Fragment>
                        <Button
                            mt={3}
                            fullWidth
                            disabled={classLevels == null}
                            onClick={() => {
                                const oldClassInfo = classLevels!.resolvedFrom;
                                const newSpells = oldClassInfo.knownSpells ? oldClassInfo.knownSpells.slice() : [];
                                newSpells.push({
                                    name: spell.name,
                                    source: spell.source,
                                });

                                const newClassInfo = copyState(oldClassInfo, {
                                    knownSpells: newSpells,
                                });
                                const newClasses = copyState(character.resolvedFrom.classes, {
                                    [classLevels!.resolvedFrom.class.name]: newClassInfo,
                                });
                                dispatch(
                                    modifyCharacter(
                                        campaign,
                                        location,
                                        [token as DnD5ECharacterToken | DnD5ECharacterTemplate],
                                        { classes: newClasses }
                                    )
                                );
                            }}>
                            {className ? `Learn as ${className} (${character.name})` : `Learn (${character.name})`}
                        </Button>
                    </React.Fragment>
                )}
                {monster && (
                    <React.Fragment>
                        {mapKeyedList(monster.spellcasting, (o, i, k) => {
                            return (
                                <Button
                                    key={k}
                                    mt={3}
                                    fullWidth
                                    disabled={!!o.spells[getRuleKey(spell)]}
                                    onClick={() => {
                                        const monsterSpell: MonsterSpell = {
                                            level: spell.level,
                                        };
                                        const newSpells = copyState(o.spells, { [getRuleKey(spell)]: monsterSpell });
                                        const newSpellcasting = copyState(o, { spells: newSpells });
                                        const newSpellcastings = copyState(monster.spellcasting!, {
                                            [k]: newSpellcasting,
                                        });
                                        dispatch(
                                            modifyMonster(
                                                campaign,
                                                location,
                                                [token as DnD5EMonsterToken | DnD5EMonsterTemplate],
                                                { spellcasting: newSpellcastings }
                                            )
                                        );
                                    }}>
                                    Add to {monster.name} ({getCoreAbilityLabel(o.ability)})
                                </Button>
                            );
                        })}
                    </React.Fragment>
                )}
            </Scrollable>
        </Box>
    );
};

export function getSpellPanel(spell: Spell): SidebarPanelState & SidebarPanelOptions {
    return {
        id: getRuleKey(spell),
        children: () => <SpellDetailsPanel spell={spell} />,
        width: theme.space[13],
    };
}

export function getItemPanel(item: ResolvedItem): SidebarPanelState & SidebarPanelOptions {
    return {
        id: getRuleKey(item),
        children: () => <ItemDetails item={item} />,
    };
}

const spellLevels = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const noSearchResults = [];

const SpellsPage: FunctionComponent<{}> = () => {
    const rules = useRules();

    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();

    const { spellFilter, setSpellFilter } = useCompendium();

    const spellResults =
        searchTerm !== ""
            ? ((searchResults.find(o => o.categoryId === "dnd5e_spells")?.results ?? noSearchResults) as SearchResult<
                  Spell,
                  () => JSX.Element
              >[])
            : undefined;

    const resolvedClassList = useMemo(() => {
        if (spellFilter?.characterClass) {
            const resolvedClass = resolveClass(spellFilter.characterClass, spellFilter.characterSubclass);
            return resolveClassSpellList(resolvedClass, rules) ?? [];
        }

        return rules.spells.all;
    }, [spellFilter?.characterClass, spellFilter?.characterSubclass, rules]);

    const filteredSpells = useMemo(() => {
        let filteredSpells = spellResults != null ? spellResults.map(o => o.originalItem) : rules.spells.all;
        if (spellFilter?.characterClass != null) {
            filteredSpells =
                spellResults != null
                    ? spellResults
                          .filter(o =>
                              resolvedClassList.find(
                                  r => o.originalItem.name === r.name && o.originalItem.source === r.source
                              )
                          )
                          .map(o => o.originalItem)
                    : resolvedClassList;
        }

        if (spellFilter?.level != null) {
            filteredSpells = filteredSpells.filter(o => {
                if (spellFilter?.levelMode === "max") {
                    return o.level <= spellFilter!.level!;
                }

                return o.level === spellFilter!.level!;
            });
        }

        return filteredSpells;
    }, [spellFilter, rules, spellResults, resolvedClassList]);

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : filteredSpells.findIndex(o => getRuleKey(o) === selectedItem);

    const [maxItems, setMaxItems] = useState(defaultMaxItems);

    return (
        <Box fullWidth fullHeight flexDirection="column">
            <MotionBox fullWidth css={{ gap: theme.space[2] }} px={3}>
                <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
                    <Label>Class</Label>
                    <Select
                        fullWidth
                        value={spellFilter?.characterClass ? getRuleKey(spellFilter?.characterClass) : ""}
                        onChange={e => {
                            const key = fromRuleKey(e.target.value);
                            const cls = key ? rules.classes.get(key) : undefined;
                            setSpellFilter(
                                spellFilter
                                    ? { ...spellFilter, characterClass: cls, characterSubclass: undefined }
                                    : { characterClass: cls }
                            );
                        }}>
                        <option value="">--</option>
                        {rules.classes.all.map(o => {
                            if (o.spellcastingProgression == null || o.spellcastingProgression <= 0) {
                                return undefined;
                            }

                            const key = getRuleKey(o);
                            return (
                                <option key={key} value={key}>
                                    {o.name}
                                </option>
                            );
                        })}
                    </Select>
                </Box>
                <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
                    <Label>Subclass</Label>
                    <Select
                        disabled={spellFilter?.characterClass == null}
                        fullWidth
                        value={spellFilter?.characterSubclass ? getRuleKey(spellFilter?.characterSubclass) : ""}
                        onChange={e => {
                            const subclassesKeyedList = spellFilter?.characterClass?.subclasses;
                            const subclasses = keyedListToArray(subclassesKeyedList) as CharacterSubclass[] | undefined;
                            const cls = subclasses?.find(o => getRuleKey(o) === e.target.value);
                            setSpellFilter({ ...spellFilter, characterSubclass: cls });
                        }}>
                        <option value="">--</option>
                        {spellFilter?.characterClass &&
                            mapKeyedList(spellFilter.characterClass.subclasses, o => {
                                const key = getRuleKey(o);
                                return (
                                    <option key={key} value={key}>
                                        {o.name}
                                    </option>
                                );
                            })}
                    </Select>
                </Box>
            </MotionBox>

            <Box flexDirection="row" justifyContent="flex-start" px={3} mt={3} mb={3}>
                {spellLevels.map(o => (
                    <Button
                        key={o}
                        size="s"
                        mr={2}
                        toggled={spellFilter?.level === o}
                        onClick={() => setSpellFilter(spellFilter ? { ...spellFilter, level: o } : { level: o })}>
                        {o}
                    </Button>
                ))}
                <Button
                    key="equal"
                    size="s"
                    variant="tertiary"
                    mr={1}
                    toggled={(spellFilter?.levelMode ?? "equals") === "equals"}
                    onClick={() => setSpellFilter(spellFilter ? { ...spellFilter, levelMode: undefined } : undefined)}>
                    =
                </Button>
                <Button
                    key="lt_equal"
                    size="s"
                    variant="tertiary"
                    toggled={spellFilter?.levelMode === "max"}
                    onClick={() =>
                        setSpellFilter(spellFilter ? { ...spellFilter, levelMode: "max" } : { levelMode: "max" })
                    }>
                    {"≤"}
                </Button>
            </Box>

            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} mb={2} variant="info" flex="0 1 auto" fullWidth>
                            Showing only spells matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <Scrollable fullWidth fullHeight minimal pb={3}>
                <ListBox<Spell>
                    selectActive
                    css={{
                        position: "relative",
                        flexDirection: "column",
                        alignItems: "stretch",
                        gap: theme.space[2],
                    }}
                    py={3}
                    px={3}
                    fullWidth
                    items={filteredSpells.slice(0, maxItems)}
                    selectedItems={selectedIndex < 0 ? undefined : [filteredSpells[selectedIndex]]}
                    itemKey={o => getRuleKey(o)}
                    onSelectionChanged={o => {
                        setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                        if (o.length) {
                            addPanel(getSpellPanel(o[0]));
                        } else {
                            clearPanels();
                        }
                    }}>
                    {({ item, index, selected, active, focused }) => {
                        return (
                            <AnimatedListItem
                                key={item.name}
                                index={index % defaultMaxItems}
                                fullWidth
                                borderRadius={3}>
                                <SpellListItem spell={item} selected={selected} active={active} focused={focused} />
                            </AnimatedListItem>
                        );
                    }}
                </ListBox>
                {maxItems < filteredSpells.length && (
                    <Button
                        mx={3}
                        key="loadmore"
                        variant="tertiary"
                        onClick={() => setMaxItems(maxItems + defaultMaxItems)}>
                        {filteredSpells.length - maxItems} more…
                    </Button>
                )}
            </Scrollable>
        </Box>
    );
};

const MonstersPage: FunctionComponent<{}> = () => {
    const { system } = useCampaign();
    const rules = useRules();

    const { searchTerm, searchResults, addPanel, clearPanels } = useVttApp();

    const { monsterFilter, setMonsterFilter } = useCompendium();

    const monsterResults =
        searchTerm !== ""
            ? (
                  (searchResults.find(o => o.categoryId === Pages.Tokens)?.results ?? []) as SearchResult<
                      TokenTemplate,
                      () => JSX.Element
                  >[]
              ).filter(o => isDnD5EMonsterTemplate(o.originalItem))
            : undefined;
    let items = useMemo(() => {
        if (monsterResults != null) {
            return monsterResults.map(o => (o.originalItem as DnD5EMonsterTemplate).dnd5e);
        }

        return rules.monsters.all.filter(o => !o.mainForm);
    }, [rules, monsterResults]);

    if (monsterFilter) {
        items = filterMonsters(items, monsterFilter);
    }

    const [selectedItem, setSelectedItem] = useState<string>();
    const selectedIndex = selectedItem == null ? -1 : items.findIndex(o => getRuleKey(o) === selectedItem);

    const [maxItems, setMaxItems] = useState(defaultMaxItems);

    return (
        <Box fullWidth fullHeight flexDirection="column">
            <MonsterFilters filter={monsterFilter ?? {}} setFilter={setMonsterFilter} mb={3} mt={3} />

            <AnimatePresence initial={false}>
                {searchTerm !== "" && (
                    <AnimatedListItem key="filtermsg" fullWidth>
                        <Message alignSelf="stretch" mx={3} variant="info" flex="0 1 auto" fullWidth>
                            Showing only monsters matching "{searchTerm}"
                        </Message>
                    </AnimatedListItem>
                )}
            </AnimatePresence>

            <Scrollable fullWidth fullHeight minimal px={3} pb={3}>
                <TokenTemplateHost>
                    <ListBox<Monster>
                        selectActive
                        css={{
                            position: "relative",
                            flexDirection: "column",
                            alignItems: "stretch",
                            gap: theme.space[2],
                        }}
                        py={3}
                        fullWidth
                        items={items.slice(0, maxItems)}
                        selectedItems={selectedIndex < 0 ? undefined : [items[selectedIndex]]}
                        itemKey={o => getRuleKey(o)}
                        onSelectionChanged={o => {
                            setSelectedItem(o && o.length ? getRuleKey(o[0]) : undefined);
                            if (o.length) {
                                const panel = system.getTokenTemplatePanel?.(monsterToTokenTemplate(o[0]));
                                if (panel) {
                                    addPanel(panel);
                                }
                            } else {
                                clearPanels();
                            }
                        }}>
                        {({ item, index, selected, active, focused }) => {
                            return (
                                <AnimatedListItem
                                    key={item.name}
                                    index={index % defaultMaxItems}
                                    fullWidth
                                    borderRadius={3}>
                                    <TokenTemplateListItem
                                        tokenTemplate={monsterToTokenTemplate(item)}
                                        isSelected={selected}
                                        isActive={active}
                                        isFocused={focused}
                                    />
                                </AnimatedListItem>
                            );
                        }}
                    </ListBox>
                </TokenTemplateHost>
                {maxItems < items.length && (
                    <Button
                        mx={3}
                        key="loadmore"
                        variant="tertiary"
                        onClick={() => setMaxItems(maxItems + defaultMaxItems)}>
                        {items.length - maxItems} more…
                    </Button>
                )}
            </Scrollable>
        </Box>
    );
};

export const CompendiumJumpTo: FunctionComponent<{ page: CompendiumPage }> = ({ page }) => {
    const { setIsSearchExpanded } = useVttApp();
    const { setPage } = useCompendium();
    const { searchPropertiesSections, setSearchPropertiesSection } = useAppState();

    return (
        <Button
            size="s"
            onClick={() => {
                const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                if (searchPage) {
                    setSearchPropertiesSection(searchPage);
                    setPage(page);
                    setIsSearchExpanded(true);
                }
            }}>
            {page}
        </Button>
    );
};

export const CompendiumTools: FunctionComponent<{}> = () => {
    const { page, setPage } = useCompendium();

    const clearPanels = useVttApp(state => state.clearPanels);
    const setPageAndClearPanels = (page: CompendiumPage) => {
        setPage(page);
        clearPanels();
    };

    return (
        <LobotomizedBox fullWidth px={3} justifyContent="flex-start" flexShrink={1} flexGrow={0}>
            <SidebarButton
                toggled={page === CompendiumPage.Classes}
                onClick={() => setPageAndClearPanels(CompendiumPage.Classes)}
                tooltip="Classes">
                <GlowingHandsIcon />
            </SidebarButton>
            <SidebarButton
                toggled={page === CompendiumPage.Races}
                onClick={() => setPageAndClearPanels(CompendiumPage.Races)}
                tooltip="Species">
                <DwarfIcon />
            </SidebarButton>
            <SidebarButton
                toggled={page === CompendiumPage.Backgrounds}
                onClick={() => setPageAndClearPanels(CompendiumPage.Backgrounds)}
                tooltip="Backgrounds">
                <JourneyIcon />
            </SidebarButton>
            <SidebarButton
                toggled={page === CompendiumPage.Feats}
                onClick={() => setPageAndClearPanels(CompendiumPage.Feats)}
                tooltip="Feats">
                <StarsIcon />
            </SidebarButton>
            <SidebarButton
                toggled={page === CompendiumPage.Items}
                onClick={() => setPageAndClearPanels(CompendiumPage.Items)}
                tooltip="Items">
                <InventoryIcon />
            </SidebarButton>
            <SidebarButton
                toggled={page === CompendiumPage.Monsters}
                onClick={() => setPageAndClearPanels(CompendiumPage.Monsters)}
                tooltip="Bestiary">
                <DragonIcon />
            </SidebarButton>
            <SidebarButton
                toggled={page === CompendiumPage.Spells}
                onClick={() => setPageAndClearPanels(CompendiumPage.Spells)}
                tooltip="Spells">
                <SpellbookIcon />
            </SidebarButton>
        </LobotomizedBox>
    );
};

export const Compendium: FunctionComponent<{}> = () => {
    const page = useCompendium(state => state.page);

    return (
        <Box fullWidth fullHeight flexDirection="column" justifyContent="flex-start">
            <AnimatePresence initial={false} mode="wait">
                <MotionBox
                    key={page}
                    fullWidth
                    flexGrow={1}
                    initial={sectionInitial}
                    animate={sectionAnimate}
                    exit={sectionExit}>
                    {page === CompendiumPage.Classes && <ClassesPage />}
                    {page === CompendiumPage.Races && <RacesPage />}
                    {page === CompendiumPage.Backgrounds && <BackgroundsPage />}
                    {page === CompendiumPage.Feats && <FeatsPage />}
                    {page === CompendiumPage.Items && <ItemsPage />}
                    {page === CompendiumPage.Monsters && <MonstersPage />}
                    {page === CompendiumPage.Spells && <SpellsPage />}
                </MotionBox>
            </AnimatePresence>
        </Box>
    );
};
