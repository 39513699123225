/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, Theme } from "@emotion/react";
import React, { FunctionComponent, ReactElement, RefAttributes, useState } from "react";
import { Button } from "../../../components/Button";
import { Spacer } from "../../../components/Spacer";
import { withTooltip } from "../../../components/Tooltip";
import { ActorType, challengeRatings, crToString, terrainTypes, terrainTypeToString } from "../creature";
import { useCampaign, useDispatch, useRole, useUser } from "../../../components/contexts";
import { PlusIcon } from "@radix-ui/react-icons";
import { addTokenTemplate } from "../../../actions/campaign";
import { DnD5ECharacterTemplate, MonsterFilter, TokenTemplateFilter, tokenTemplateFilterSetting } from "../common";
import DragonIcon from "../../../components/icons/Dragon";
import { Box, Label, Select } from "../../../components/primitives";
import CharacterSheetIcon from "../../../components/icons/CharacterSheet";
import { ExtractProps, LobotomizedBox, useVttApp } from "../../../components/common";
import { TagButton } from "../../../components/TagButton";
import { useForceUpdate, useLocalSetting } from "../../../components/utils";
import { theme } from "../../../design";
import LanternIcon from "../../../components/icons/Lantern";
import {
    defaultAnimate,
    defaultExit,
    defaultInitial,
    MotionBox,
    MotionLobotomizedBox,
} from "../../../components/motion";
import { AnimatePresence } from "framer-motion";
import { nanoid } from "nanoid";
import { getCreatureEditor } from "./CreatureEditor";

interface TokenTemplateFilterButtonProps {
    type: keyof TokenTemplateFilter;
    palette: keyof Theme["colors"];
    previewedType: keyof TokenTemplateFilter | undefined;
    setPreviewedType: (type: keyof TokenTemplateFilter | undefined) => void;
    icon: ReactElement;
}

const TokenTemplateFilterButton: FunctionComponent<TokenTemplateFilterButtonProps & RefAttributes<HTMLElement>> =
    React.forwardRef<HTMLElement, TokenTemplateFilterButtonProps>(
        ({ palette, type, previewedType, setPreviewedType, icon }, ref) => {
            const [filter, setFilter] = useLocalSetting(tokenTemplateFilterSetting);

            let isToggled = false;
            if (previewedType) {
                isToggled = previewedType === type;
            } else if (!filter || filter[type]) {
                // There's no filter (so we're showing this type) or we're filtering by this type.
                isToggled = true;
            }

            return (
                <TagButton
                    ref={ref}
                    palette={!isToggled && filter?.[type] == null ? "grayscale" : palette}
                    toggled={isToggled}
                    onHover={h => setPreviewedType(h ? type : undefined)}
                    onClick={() => setFilter(filter?.[type] != null ? undefined : { [type]: {} })}>
                    {icon}
                </TagButton>
            );
        }
    );

const TooltipTokenTemplateFilterButton = withTooltip(TokenTemplateFilterButton);

export const MonsterFilters: FunctionComponent<
    {
        filter: MonsterFilter;
        setFilter: (filter: MonsterFilter | undefined) => void;
    } & ExtractProps<typeof MotionBox>
> = ({ filter, setFilter, ...props }) => {
    return (
        <MotionBox fullWidth css={{ gap: theme.space[2] }} px={3} {...props}>
            <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
                <Label>CR</Label>
                <Select
                    fullWidth
                    value={filter.cr}
                    onChange={e => {
                        setFilter(
                            Object.assign({}, filter, {
                                cr: e.target.value === "" ? undefined : parseFloat(e.target.value),
                            })
                        );
                    }}>
                    <option value="">--</option>
                    {challengeRatings.map(o => (
                        <option key={o.toString(10)} value={o.toString(10)}>
                            {crToString(o)}
                        </option>
                    ))}
                </Select>
            </Box>
            <Box flexDirection="column" alignItems="flex-start" flexGrow={1}>
                <Label>Environment</Label>
                <Select
                    fullWidth
                    value={filter.environment}
                    onChange={e => {
                        setFilter(
                            Object.assign({}, filter, {
                                environment: e.target.value === "" ? undefined : e.target.value,
                            })
                        );
                    }}>
                    <option value="">--</option>
                    {terrainTypes.map(o => (
                        <option key={o} value={o}>
                            {terrainTypeToString(o)}
                        </option>
                    ))}
                </Select>
            </Box>
        </MotionBox>
    );
};

export const TokenTools: FunctionComponent<{}> = () => {
    const role = useRole();
    const user = useUser();
    const dispatch = useDispatch();
    const { campaign } = useCampaign();

    const searchTerm = useVttApp(state => state.searchTerm);

    const [filter, setFilter] = useLocalSetting(tokenTemplateFilterSetting);
    const [previewedType, setPreviewedType] = useState<keyof TokenTemplateFilter>();

    const isMonsterHighlighted = filter?.monster && (previewedType == null || previewedType === "monster");

    const forceUpdate = useForceUpdate();

    const addOverlay = useVttApp(state => state.addOverlay);

    return (
        <Box flexDirection="column" fullWidth>
            <MotionLobotomizedBox layout fullWidth zIndex={1} px={3}>
                <LobotomizedBox flexDirection="row" justifyContent="flex-start" alignItems="flex-start" flexGrow={1}>
                    <Button onClick={() => setFilter(undefined)} disabled={filter == null}>
                        ⭯
                    </Button>

                    <Spacer direction="vertical" minHeight={theme.space[4]} />

                    <Box position="relative">
                        <MotionBox
                            position="absolute"
                            layout
                            bottom={isMonsterHighlighted ? (theme.radii[3] as number) - theme.space[3] : 0}
                            fullWidth
                            animate={{ opacity: isMonsterHighlighted ? 1 : 0 }}
                            height={theme.space[2] + (theme.radii[3] as number) * 2}
                            borderBottomLeftRadius={3}
                            borderBottomRightRadius={3}
                            bg="reds.7"
                        />
                        <Box borderRadius={3} zIndex={1}>
                            <TooltipTokenTemplateFilterButton
                                tooltip="Monsters"
                                palette="reds"
                                type="monster"
                                previewedType={previewedType}
                                setPreviewedType={setPreviewedType}
                                icon={<DragonIcon />}
                            />
                        </Box>
                    </Box>
                    <TooltipTokenTemplateFilterButton
                        tooltip="Player characters"
                        palette="cyans"
                        type="pc"
                        previewedType={previewedType}
                        setPreviewedType={setPreviewedType}
                        icon={<React.Fragment>PC</React.Fragment>}
                    />
                    <TooltipTokenTemplateFilterButton
                        tooltip="Non player characters"
                        palette="blues"
                        type="npc"
                        previewedType={previewedType}
                        setPreviewedType={setPreviewedType}
                        icon={<React.Fragment>NPC</React.Fragment>}
                    />
                    <TooltipTokenTemplateFilterButton
                        tooltip="Light sources"
                        palette="yellows"
                        type="light"
                        previewedType={previewedType}
                        setPreviewedType={setPreviewedType}
                        icon={<LanternIcon />}
                    />
                </LobotomizedBox>

                <AnimatePresence initial={false}>
                    {/* {searchTerm === "" && (
                        <MotionBox
                            key="createNewMonster"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Button
                                shape="square"
                                tooltip="Create new monster"
                                alignSelf="flex-end"
                                variant="tertiary"
                                onClick={() => {
                                    const id = nanoid();
                                    const monster: Monster = {
                                        name: "New monster",
                                        source: campaign.id,
                                        type: ActorType.Monster,
                                        maxHpInfo: {},
                                        size: "Medium",
                                    };
                                    dispatch(
                                        addTokenTemplate(campaign.id, {
                                            templateId: id,
                                            dnd5e: monster,
                                        } as DnD5EMonsterTemplate)
                                    );

                                    addOverlay(getCreatureEditor(id));
                                }}>
                                <DragonIcon />
                                <Box position="absolute" right="0" bottom="0">
                                    <PlusIcon fill="currentcolor" />
                                </Box>
                            </Button>
                        </MotionBox>
                    )} */}
                    {searchTerm === "" && (
                        <MotionBox
                            key="createNewCharacter"
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Button
                                shape="square"
                                tooltip="Create new character"
                                alignSelf="flex-end"
                                variant="tertiary"
                                onClick={() => {
                                    const id = nanoid();
                                    dispatch(
                                        addTokenTemplate(campaign.id, {
                                            templateId: id,
                                            owner: role === "Player" ? user.id : undefined,
                                            dnd5e: {
                                                name: "New character",
                                                type: ActorType.Character,
                                                isIncomplete: true,
                                            },
                                        } as DnD5ECharacterTemplate)
                                    );

                                    addOverlay(getCreatureEditor(id));
                                }}>
                                <CharacterSheetIcon />
                                <Box position="absolute" right="0" bottom="0">
                                    <PlusIcon fill="currentcolor" />
                                </Box>
                            </Button>
                        </MotionBox>
                    )}
                </AnimatePresence>
            </MotionLobotomizedBox>

            <Box position="relative" fullWidth>
                <AnimatePresence onExitComplete={forceUpdate}>
                    {filter?.monster && (
                        <MonsterFilters
                            filter={filter.monster}
                            setFilter={mf =>
                                setFilter({
                                    monster: mf,
                                })
                            }
                            mt={2}
                            pt={2}
                            initial={{ y: -theme.space[1], opacity: 0 }}
                            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                            exit={{ y: -theme.space[1], opacity: 0 }}
                            borderTopColor={theme.colors.reds[7]}
                            borderWidth={0}
                            borderTopWidth={4}
                            borderStyle="solid"
                        />
                    )}
                </AnimatePresence>
            </Box>
        </Box>
    );
};
