/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useMemo, useState } from "react";
import {
    useAppState,
    useDispatch,
    useLocalGrid,
    useLocationLevel,
    useSelection,
    useViewport,
} from "../../../components/contexts";
import { Box, Grid, Label, Link, Radio, Text } from "../../../components/primitives";
import { Location, ResolvedToken, Token, TokenTemplate, WithLevel, isLocation, resolveToken } from "../../../store";
import {
    Alignment,
    Monster,
    ResolvedCharacter,
    ResolvedMonster,
    crToString,
    crToXp,
    filterMonsters,
    fullResolveTokenCreature,
    terrainTypes,
} from "../creature";
import {
    DnD5EMonsterTemplate,
    createMonsterToken,
    isDnD5ECharacterToken,
    isDnD5EMonsterTemplate,
    isDnD5EMonsterToken,
    monsterToTokenTemplate,
    sizeToScale,
} from "../common";
import { useRules } from "./hooks";
import {
    EncounterDifficulty,
    encounterDifficultyToString,
    generateEncounterMonsters,
    resolveEncounter,
} from "../encounter";
import { Tag } from "../../../components/Tag";
import { TokenImage } from "../../../components/TokenImage";
import { Spacer } from "../../../components/Spacer";
import { Button } from "../../../components/Button";
import { theme } from "../../../design";
import { GridPosition, PositionType } from "../../../position";
import { localPoint } from "../../../grid";
import { addToken } from "../../../actions/location";
import { PathFinder } from "../../../pathfinder";
import { ScrollableTest } from "../../../components/ScrollableTest";
import { useVttApp } from "../../../components/common";
import { CompendiumPage, useCompendium } from "./common";
import { LocalSearchable, SearchResult } from "../../../localsearchable";
import { Pages } from "../../../components/Sidebar";
import { AnimatePresence, motion } from "framer-motion";
import { MotionBox, MotionGrid, defaultAnimate, defaultExit, defaultInitial } from "../../../components/motion";

export const EncounterBuilder: FunctionComponent<{}> = () => {
    const { system, campaign, location, levelKey } = useLocationLevel();
    const rules = useRules();
    const grid = useLocalGrid();
    const dispatch = useDispatch();
    const vp = useViewport();
    const { primary, setSelection } = useSelection();

    const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
    const isSearchExpanded = useVttApp(state => state.isSearchExpanded);
    const searchResults = useVttApp(state => state.searchResults);
    const searchTerm = useVttApp(state => state.searchTerm);
    const { searchPropertiesSection, searchPropertiesSections, setSearchPropertiesSection } = useAppState();
    const { setPage, page, monsterFilter } = useCompendium();

    const participants = useMemo(() => {
        const party: { character: ResolvedCharacter; token: ResolvedToken }[] = [];
        const monsters: { monster: ResolvedMonster; token: ResolvedToken }[] = [];

        if (isLocation(location)) {
            // The party is all the tokens that are assigned to a player.
            const allTokens = Object.values(location.tokens);
            for (let token of allTokens) {
                if (isDnD5ECharacterToken(token)) {
                    const resolvedToken = resolveToken(campaign, token);
                    if (resolvedToken.owner != null && campaign.players[resolvedToken.owner]?.role !== "GM") {
                        party.push({
                            character: fullResolveTokenCreature(resolvedToken, campaign, rules) as ResolvedCharacter,
                            token: resolvedToken,
                        });
                    }
                } else if (isDnD5EMonsterToken(token)) {
                    const resolvedToken = resolveToken(campaign, token);
                    if (resolvedToken.owner == null || campaign.players[resolvedToken.owner]?.role === "GM") {
                        monsters.push({
                            monster: fullResolveTokenCreature(resolvedToken, campaign, rules) as ResolvedMonster,
                            token: resolvedToken,
                        });
                    }
                }
            }
        }

        return { party: party, monsters: monsters };
    }, [location, campaign, rules]);

    const encounter = useMemo(() => {
        return resolveEncounter(
            participants.party.map(o => o.character),
            participants.monsters.map(o => o.monster)
        );
    }, [participants]);

    const [desiredDifficulty, setDesiredDifficulty] = useState(encounter.difficulty);
    const isGenerateDisabled = false;

    const filterByCompendium =
        isSearchExpanded && searchPropertiesSection.id === "dnd5e" && page === CompendiumPage.Monsters;

    const max = Math.max(encounter.thresholds.deadly, encounter.monsterXpTotal);
    const pct = encounter.monsterXpTotal / max;
    const deadlyPct = encounter.thresholds.deadly / max;
    const hardPct = encounter.thresholds.hard / max;
    const mediumPct = encounter.thresholds.medium / max;
    const easyPct = encounter.thresholds.easy / max;

    const isInvalid = encounter.thresholds.deadly === 0;

    let bg: string;
    if (isInvalid) {
        bg = theme.colors.greens[6];
    } else {
        switch (encounter.difficulty) {
            case EncounterDifficulty.Trivial:
            case EncounterDifficulty.Easy:
            case EncounterDifficulty.Medium:
                bg = theme.colors.greens[6];
                break;
            case EncounterDifficulty.Hard:
                bg = theme.colors.oranges[6];
                break;
            case EncounterDifficulty.Deadly:
                bg = theme.colors.reds[6];
                break;
        }
    }

    return (
        <ScrollableTest minimal fullWidth fullHeight p={3}>
            <Box flexDirection="column" fullWidth alignItems="flex-start">
                <Label>Encounter difficulty</Label>

                <Box
                    fullWidth
                    mb={2}
                    flexDirection="row"
                    bg="grayscale.7"
                    role="progressbar"
                    title="Encounter difficulty"
                    aria-valuenow={encounter.monsterXpTotal}
                    aria-valuemin={0}
                    aria-valuemax={max}
                    css={{
                        minHeight: theme.space[3],
                        position: "relative",
                        borderRadius: theme.radii[4],
                        display: "flex",
                        flex: "1 0 0%",
                        overflow: "hidden",
                        "&::before": {
                            position: "absolute",
                            content: "''",
                            height: "100%",
                            width: "100%",
                            left: 0,
                            borderRadius: theme.radii[4],
                            transformOrigin: "0 50%",
                            transition: "transform 100ms ease-out",
                            zIndex: 1,
                            backgroundColor: bg,
                            transform: `translateX(${-100 + pct * 100}%)`,
                        },
                    }}>
                    {/* Put in some markers for the various thresholds */}
                    <Box
                        fullWidth
                        css={{
                            position: "absolute",
                            left: 0,
                            zIndex: 2,
                            transform: `translateX(${easyPct * 100}%)`,
                            transition: "transform 100ms ease-out",
                            "&::after": {
                                position: "absolute",
                                content: "''",
                                left: 0,
                                height: theme.space[3],
                                width: 2,
                                backgroundColor: theme.colors.greens[4],
                                transform: `translateX(-50%)`,
                            },
                        }}
                    />
                    <Box
                        fullWidth
                        css={{
                            position: "absolute",
                            left: 0,
                            zIndex: 2,
                            transform: `translateX(${mediumPct * 100}%)`,
                            transition: "transform 100ms ease-out",
                            "&::after": {
                                position: "absolute",
                                content: "''",
                                left: 0,
                                height: theme.space[3],
                                width: 2,
                                backgroundColor: theme.colors.greens[4],
                                transform: `translateX(-50%)`,
                            },
                        }}
                    />
                    <Box
                        fullWidth
                        css={{
                            position: "absolute",
                            left: 0,
                            zIndex: 2,
                            transform: `translateX(${hardPct * 100}%)`,
                            transition: "transform 100ms ease-out",
                            "&::after": {
                                position: "absolute",
                                content: "''",
                                left: 0,
                                height: theme.space[3],
                                width: 2,
                                backgroundColor: theme.colors.oranges[4],
                                transform: `translateX(-50%)`,
                            },
                        }}
                    />
                    {encounter.difficulty === EncounterDifficulty.Deadly && (
                        <Box
                            fullWidth
                            css={{
                                position: "absolute",
                                left: 0,
                                zIndex: 2,
                                transform: `translateX(${deadlyPct * 100}%)`,
                                transition: "transform 100ms ease-out",
                                "&::after": {
                                    position: "absolute",
                                    content: "''",
                                    left: 0,
                                    height: theme.space[3],
                                    width: 2,
                                    backgroundColor: theme.colors.reds[4],
                                    transform: `translateX(-50%)`,
                                },
                            }}
                        />
                    )}
                </Box>

                <Text fontSize={3}>
                    {isInvalid ? "No encounter" : encounterDifficultyToString(encounter.difficulty)}
                </Text>

                <Grid gridTemplateColumns="1fr 1fr" fullWidth mt={3} css={{ alignItems: "flex-start" }}>
                    <Box flexDirection="column" alignItems="stretch">
                        <Label>Party</Label>
                        <Spacer direction="horizontal" mb={2} css={{ alignSelf: "center" }} />
                        <AnimatePresence>
                            {participants.party.map(o => {
                                return (
                                    <MotionBox
                                        key={o.token.id}
                                        layout
                                        mb={2}
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        css={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSelection([o.token.id]);
                                        }}
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}>
                                        <TokenImage token={o.token} selected={primary.includes(o.token.id)} />
                                        <Box alignItems="flex-start" flexDirection="column" ml={2} flexShrink={1}>
                                            <Label>{o.character.name}</Label>
                                            <Tag bg="greens.7" color="greens.0">
                                                Level {o.character.level}
                                            </Tag>
                                        </Box>
                                    </MotionBox>
                                );
                            })}
                        </AnimatePresence>
                    </Box>
                    <Box flexDirection="column" alignItems="stretch">
                        <Label>Monsters</Label>
                        <Spacer direction="horizontal" mb={2} css={{ alignSelf: "center" }} />
                        <AnimatePresence>
                            {participants.monsters.map(o => {
                                return (
                                    <MotionBox
                                        key={o.token.id}
                                        layout
                                        mb={2}
                                        alignItems="flex-start"
                                        justifyContent="flex-start"
                                        css={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSelection([o.token.id]);
                                        }}
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}>
                                        <TokenImage token={o.token} selected={primary.includes(o.token.id)} />
                                        <Box alignItems="flex-start" flexDirection="column" ml={2} flexShrink={1}>
                                            <Label>{o.monster.name}</Label>
                                            {o.monster.cr != null && (
                                                <Box
                                                    justifyContent="flex-start"
                                                    flexWrap="wrap"
                                                    css={{ gap: theme.space[1] }}>
                                                    <Tag bg="yellows.7" color="yellows.0">
                                                        CR{crToString(o.monster.cr)}
                                                    </Tag>
                                                    <Tag bg="oranges.7" color="oranges.0">
                                                        {crToXp(o.monster.cr)} XP
                                                    </Tag>
                                                </Box>
                                            )}
                                        </Box>
                                    </MotionBox>
                                );
                            })}
                        </AnimatePresence>
                    </Box>
                </Grid>
                <MotionGrid layout gridTemplateColumns="1fr 1fr" fullWidth css={{ alignItems: "flex-start" }}>
                    <Box flexDirection="column">
                        <Spacer direction="horizontal" mb={2} />
                        <Grid gridTemplateColumns="1fr 1fr" fullWidth>
                            <Text color="guidance.success.1">Trivial</Text>
                            <Text>0 XP</Text>
                            <Text color="guidance.success.1">Easy</Text>
                            <Text>{encounter.thresholds.easy} XP</Text>
                            <Text color="guidance.success.1">Medium</Text>
                            <Text>{encounter.thresholds.medium} XP</Text>
                            <Text color="guidance.warning.1">Hard</Text>
                            <Text>{encounter.thresholds.hard} XP</Text>
                            <Text color="guidance.error.1">Deadly</Text>
                            <Text>{encounter.thresholds.deadly} XP</Text>
                        </Grid>
                    </Box>
                    <Box flexDirection="column">
                        <Spacer direction="horizontal" mb={2} />
                        <Text>
                            {encounter.difficulty === EncounterDifficulty.Trivial
                                ? `${encounter.monsterXpTotal} XP`
                                : "--"}
                        </Text>
                        <Text>
                            {encounter.difficulty === EncounterDifficulty.Easy
                                ? `${encounter.monsterXpTotal} XP`
                                : "--"}
                        </Text>
                        <Text>
                            {encounter.difficulty === EncounterDifficulty.Medium
                                ? `${encounter.monsterXpTotal} XP`
                                : "--"}
                        </Text>
                        <Text>
                            {encounter.difficulty === EncounterDifficulty.Hard
                                ? `${encounter.monsterXpTotal} XP`
                                : "--"}
                        </Text>
                        <Text>
                            {encounter.difficulty === EncounterDifficulty.Deadly
                                ? `${encounter.monsterXpTotal} XP`
                                : "--"}
                        </Text>
                    </Box>
                </MotionGrid>
                <MotionBox layout mt={4} flexDirection="column" fullWidth alignItems="flex-start">
                    <MotionBox layout>
                        <Label>Desired difficulty</Label>
                    </MotionBox>
                    <MotionBox
                        layout
                        flexDirection="row"
                        css={{ gap: theme.space[2] }}
                        fullWidth
                        justifyContent="flex-start"
                        mt={1}
                        mb={2}>
                        <Radio
                            name="encounter_difficulty"
                            id="encounter_difficulty_easy"
                            label="Easy"
                            disabled={isGenerateDisabled}
                            checked={desiredDifficulty === EncounterDifficulty.Easy}
                            onChange={e => {
                                if (e.target.checked) {
                                    setDesiredDifficulty(EncounterDifficulty.Easy);
                                }
                            }}
                        />
                        <Radio
                            name="encounter_difficulty"
                            id="encounter_difficulty_medium"
                            label="Medium"
                            disabled={isGenerateDisabled}
                            checked={desiredDifficulty === EncounterDifficulty.Medium}
                            onChange={e => {
                                if (e.target.checked) {
                                    setDesiredDifficulty(EncounterDifficulty.Medium);
                                }
                            }}
                        />
                        <Radio
                            name="encounter_difficulty"
                            id="encounter_difficulty_hard"
                            label="Hard"
                            disabled={isGenerateDisabled}
                            checked={desiredDifficulty === EncounterDifficulty.Hard}
                            onChange={e => {
                                if (e.target.checked) {
                                    setDesiredDifficulty(EncounterDifficulty.Hard);
                                }
                            }}
                        />
                        <Radio
                            name="encounter_difficulty"
                            id="encounter_difficulty_deadly"
                            label="Deadly"
                            disabled={isGenerateDisabled}
                            checked={desiredDifficulty === EncounterDifficulty.Deadly}
                            onChange={e => {
                                if (e.target.checked) {
                                    setDesiredDifficulty(EncounterDifficulty.Deadly);
                                }
                            }}
                        />
                    </MotionBox>
                    <AnimatePresence mode="popLayout">
                        {!filterByCompendium && !participants.monsters.length && (
                            <motion.div
                                layout
                                key="random"
                                css={{ marginBottom: theme.space[2] }}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                Encounter generation will pick monsters randomly.
                            </motion.div>
                        )}
                        {!filterByCompendium && participants.monsters.length && (
                            <motion.div
                                layout
                                key="existing"
                                css={{ marginBottom: theme.space[2] }}
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                Encounter generation will attempt to use monsters similar to those already in the
                                encounter.
                            </motion.div>
                        )}
                        {!filterByCompendium && (
                            <motion.div
                                layout
                                key="compendium_closed"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                <Link
                                    onClick={() => {
                                        setIsSearchExpanded(true);
                                        const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                                        if (searchPage) {
                                            setSearchPropertiesSection(searchPage);
                                            setPage(CompendiumPage.Monsters);
                                        }
                                    }}>
                                    Open the bestiary
                                </Link>{" "}
                                to have more control over the monsters that will be used by encounter generation.
                            </motion.div>
                        )}
                        {filterByCompendium && (
                            <motion.div
                                layout
                                key="compendium_open"
                                initial={defaultInitial}
                                animate={defaultAnimate}
                                exit={defaultExit}>
                                The monsters used by encounter generation will be filtered by the current bestiary
                                filter.
                            </motion.div>
                        )}
                    </AnimatePresence>
                    <MotionBox layout>
                        <Button
                            mt={2}
                            disabled={
                                isGenerateDisabled ||
                                encounter.difficulty >= desiredDifficulty ||
                                !isLocation(location) ||
                                !vp?.totalSize
                            }
                            onClick={() => {
                                const pf = new PathFinder(campaign, location as Location, vp!.totalSize!, grid, system);

                                let monsters: Monster[] | undefined;
                                if (filterByCompendium) {
                                    // The bestiary compendium page is open, so use whatever filters have been applied to that.
                                    // That could be search results, and it could be other direct filters.
                                    const monsterResults =
                                        searchTerm !== ""
                                            ? (
                                                  (searchResults.find(o => o.categoryId === Pages.Tokens)?.results ??
                                                      []) as SearchResult<TokenTemplate, () => JSX.Element>[]
                                              ).filter(o => isDnD5EMonsterTemplate(o.originalItem))
                                            : undefined;

                                    if (monsterResults != null) {
                                        monsters = monsterResults.map(
                                            o => (o.originalItem as DnD5EMonsterTemplate).dnd5e
                                        );
                                    } else {
                                        monsters = rules.monsters.all;
                                    }

                                    if (monsterFilter) {
                                        monsters = filterMonsters(monsters, monsterFilter);
                                    }
                                }

                                // Find the existing monster to base everything on, if there is one.
                                // Try to find the highest CR monster, as that's probably what everything will be clustered around.
                                let pos: WithLevel<GridPosition>;
                                const existingMonsters = participants.monsters.toSorted((a, b) => {
                                    return (a.monster.cr ?? 0) - (b.monster.cr ?? 0);
                                });
                                const existingMonster = existingMonsters.find(
                                    o => o.token.pos.type === PositionType.Grid
                                );
                                if (existingMonster != null) {
                                    pos = existingMonster.token.pos as WithLevel<GridPosition>;

                                    if (!filterByCompendium) {
                                        // If there is an existing monster and we don't have a more specific filter, then we should
                                        // base further monsters off that one.
                                        const name = existingMonster.monster.name;

                                        // Do a search on the name. If there are several monsters that match the filter, then that's
                                        // likely to be a good way to find things (i.e. "Bandit Captain" will find "Bandit" and vice
                                        // versa, which would be an excellent way to fill out the encounter)
                                        const monstersSearchable = new LocalSearchable(
                                            Object.values(system.getTokenTemplates()),
                                            {
                                                idField: "templateId",
                                                searchableFields: system.getTokenTemplateSearchFields(),
                                                toResult: o => o,
                                            }
                                        );

                                        const results = monstersSearchable.search(name);
                                        if (results.length > 1) {
                                            // Found more than one matching result by name, it's probably a good idea to use these.
                                            monsters = (
                                                results.filter(o =>
                                                    isDnD5EMonsterTemplate(o.originalItem)
                                                ) as SearchResult<DnD5EMonsterTemplate, DnD5EMonsterTemplate>[]
                                            ).map(o => o.originalItem.dnd5e);
                                        } else {
                                            monsters = [];
                                        }

                                        // Work out the closest alignments to the existing creature.
                                        let filterAlignments: Alignment[] | undefined;
                                        if (
                                            existingMonster.monster.alignment &&
                                            existingMonster.monster.alignment !== "Any"
                                        ) {
                                            switch (existingMonster.monster.alignment) {
                                                case "LG":
                                                    filterAlignments = ["LG", "NG", "CG", "LN"];
                                                    break;
                                                case "CG":
                                                    filterAlignments = ["CG", "LG", "NG", "CN"];
                                                    break;
                                                case "NG":
                                                    filterAlignments = ["NG", "LG", "CG", "N"];
                                                    break;
                                                case "N":
                                                    filterAlignments = undefined;
                                                    break;
                                                case "CN":
                                                    filterAlignments = undefined;
                                                    break;
                                                case "LN":
                                                    filterAlignments = undefined;
                                                    break;
                                                case "CE":
                                                    filterAlignments = ["CE", "NE", "LE", "CN"];
                                                    break;
                                                case "NE":
                                                    filterAlignments = ["NE", "CE", "LE", "N"];
                                                    break;
                                                case "LE":
                                                    filterAlignments = ["LE", "CE", "NE", "LN"];
                                                    break;
                                            }
                                        }

                                        const filterEnvironments = existingMonster.monster.environments
                                            ? terrainTypes.filter(o => existingMonster.monster.environments?.[o])
                                            : undefined;

                                        let filteredMonsters = filterMonsters(rules.monsters.all, {
                                            environment: filterEnvironments,
                                            creatureType: existingMonster.monster.creatureType,
                                            alignment: filterAlignments,
                                        });
                                        if (filteredMonsters.length < 2) {
                                            // Not enough matches, try a less restrictive filter.
                                            filteredMonsters = filterMonsters(rules.monsters.all, {
                                                creatureType: existingMonster.monster.creatureType,
                                                alignment: filterAlignments,
                                            });
                                            if (filteredMonsters.length < 2) {
                                                filteredMonsters = filterMonsters(rules.monsters.all, {
                                                    alignment: filterAlignments,
                                                });
                                                if (filteredMonsters.length < 2) {
                                                    filteredMonsters = filterMonsters(rules.monsters.all, {
                                                        environment: filterEnvironments,
                                                    });
                                                }
                                            }
                                        }

                                        monsters.push(...filteredMonsters);

                                        // If after all that we didn't find at least 2 options, just leave it open for anything.
                                        if (monsters.length < 2) {
                                            monsters = undefined;
                                        }
                                    }
                                } else {
                                    // Just pick the middle of the map somewhere.
                                    // TODO: This could get tricky when the current level is NOT the default level, where we might end up putting
                                    // monsters outside where they should go (imagine the level being the second story of a small building).
                                    // What we really need is the bounds of the current level.
                                    const p = grid.toGridPoint(
                                        localPoint(pf.size.x + pf.size.width / 2, pf.size.y + pf.size.height / 2)
                                    ) as WithLevel<GridPosition>;
                                    p.level = levelKey ?? (location as Location).defaultLevel;
                                    pos = p;
                                }

                                // Generate some monsters to fill out the encounter to the desired difficulty.
                                const monstersToAdd = generateEncounterMonsters(
                                    encounter,
                                    desiredDifficulty,
                                    monsters ?? rules.monsters.all.filter(o => !o.mainForm)
                                );

                                // Now that we've got a position to start with, find another spot NEAR that starting position, that isn't taken
                                // by anything else, and has a valid (short) path to the original position. This stops us (hopefully) from adding
                                // monsters on the other side of walls, chasms, etc.

                                // Start with a grid around the central pos, excluding any positions that are already occupied by tokens.
                                const maxDist = 4;
                                const positions: WithLevel<GridPosition>[] = [];
                                const invalidPositions: WithLevel<GridPosition>[] = [];
                                const allGridTokens = Object.values((location as Location).tokens).filter(
                                    o => o.pos.type === PositionType.Grid && o.pos.level === pos.level
                                );
                                for (let x = pos.x - maxDist; x <= pos.x + maxDist; x++) {
                                    for (let y = pos.y - maxDist; y <= pos.y + maxDist; y++) {
                                        const gridPos: WithLevel<GridPosition> = {
                                            type: PositionType.Grid,
                                            x: x,
                                            y: y,
                                            level: pos.level,
                                        };
                                        if (
                                            !allGridTokens.some(o => {
                                                const appearance =
                                                    system.getTokenAppearance?.(o, campaign, location as Location) ?? o;
                                                return grid.contains(gridPos, o.pos as GridPosition, appearance.scale);
                                            })
                                        ) {
                                            positions.push(gridPos);
                                        } else {
                                            invalidPositions.push(gridPos);
                                        }
                                    }
                                }

                                // Now we're ready to start placing tokens.
                                const tokensToAdd: Token[] = [];
                                for (let monster of monstersToAdd) {
                                    const monsterScale = sizeToScale(monster.size);

                                    let monsterPos: WithLevel<GridPosition> | undefined;
                                    const positionsForMonster = positions.slice();
                                    while (!monsterPos && positionsForMonster.length) {
                                        // Pick a random position from the valid list of positions.
                                        const i = Math.floor(Math.random() * positionsForMonster.length);

                                        // Check to make sure that all of the spaces that will be taken up by the token are available.
                                        // If not, this isn't a valid position for this token.
                                        if (
                                            invalidPositions.some(o =>
                                                grid.contains(o, positionsForMonster[i], monsterScale)
                                            )
                                        ) {
                                            // If the monster was placed here, part of it would be overlapping with an invalid location.
                                            // This means that we can't use it for this monster.
                                            // It could still be used for OTHER monsters, though, potentially.
                                            positionsForMonster.splice(i, 1);
                                        } else {
                                            // The area is free, the token CAN be placed there. However, we only want to do that if we can
                                            // find a path to the central pos, so we don't put this token in a different building etc.
                                            const path = pf.findMovementPath(undefined, positionsForMonster[i], pos);
                                            if (path && path.cost <= maxDist) {
                                                monsterPos = positionsForMonster[i];
                                            } else {
                                                // This isn't a valid position to add to.
                                                const positionToRemove = positionsForMonster[i];
                                                positionsForMonster.splice(i, 1);
                                                positions.splice(positions.indexOf(positionToRemove), 1);
                                            }
                                        }
                                    }

                                    // If there's nowhere left to add monsters, just bail.
                                    if (!monsterPos) {
                                        break;
                                    }

                                    const monsterTemplate: DnD5EMonsterTemplate = monsterToTokenTemplate(monster);
                                    const monsterToken = createMonsterToken(
                                        monsterTemplate,
                                        campaign,
                                        rules,
                                        monsterPos
                                    );
                                    tokensToAdd.push(monsterToken);

                                    // Before we go on to placing the NEXT monster, make sure we've removed any positions that the new
                                    // monster occupies.
                                    for (let i = 0; i < positions.length; i++) {
                                        if (
                                            grid.contains(positions[i], monsterToken.pos as GridPosition, monsterScale)
                                        ) {
                                            invalidPositions.push(...positions.splice(i, 1));
                                            i--;
                                        }
                                    }
                                }

                                // TODO: Modify to allow adding multiple tokens at once.
                                for (let tokenToAdd of tokensToAdd) {
                                    dispatch(addToken(campaign.id, (location as Location).id, tokenToAdd));
                                }

                                // Select the newly added tokens.
                                setSelection(tokensToAdd.map(o => o.id));
                            }}>
                            Generate encounter
                        </Button>
                    </MotionBox>
                </MotionBox>
            </Box>
        </ScrollableTest>
    );
};
