import { Action } from "redux";
import { PayloadAction } from "../../../actions/common";
import { LibraryItem } from "../../../library";
import { copyState } from "../../../reducers/common";
import { Token, TokenTemplate, Location, Session } from "../../../store";
import { isDnD5EToken, isDnD5ETokenTemplate } from "../common";
import { CharacterRuleSet, resolveTokenCreature } from "../creature";
import { reduceCreature, reduceCreatureUntargetted } from "./creature";

export function reduceToken(
    token: Token | TokenTemplate,
    action: Action,
    session: Session,
    location: Location,
    rules: CharacterRuleSet,
    isTargetted: boolean
) {
    const campaign = session.campaign;
    if (isDnD5EToken(token) || isDnD5ETokenTemplate(token)) {
        const creature = resolveTokenCreature(token, campaign, rules);

        if (isTargetted) {
            if (action.type === "DnD5E_SetTokenImage") {
                const libraryItem = (action as PayloadAction).payload as LibraryItem | undefined;
                if (libraryItem) {
                    const images = token.images?.slice() ?? [];
                    const i = images.findIndex(o => o.uri === libraryItem.uri);
                    const image = {
                        uri: libraryItem.uri,
                        canRotate: libraryItem.metadata.canRotate,
                        rotation: libraryItem.metadata.rotation,
                        thumbnailUri: libraryItem.metadata.thumbnailUri,
                    };
                    if (i >= 0) {
                        images[i] = image;
                    } else {
                        images.push(image);
                    }

                    return copyState(token, {
                        images: images,
                        imageUri: libraryItem.uri,
                        canRotate:
                            libraryItem.metadata.canRotate == null || libraryItem.metadata.canRotate
                                ? undefined
                                : false,
                        defaultRotation: libraryItem.metadata.rotation,
                        renderScale: libraryItem.metadata.renderScale,
                    });
                } else {
                    return copyState(token, {
                        imageUri: undefined,
                        canRotate: undefined,
                        defaultRotation: undefined,
                        renderScale: undefined,
                    });
                }
            } else {
                const newCreature = reduceCreature(token, creature, action, session, location, rules);
                if (newCreature !== token.dnd5e) {
                    const newToken = copyState(token, { dnd5e: newCreature });
                    return newToken;
                }
            }
        } else {
            const newCreature = reduceCreatureUntargetted(token, creature, action, session, location, rules);
            if (newCreature !== token.dnd5e) {
                const newToken = copyState(token, { dnd5e: newCreature });
                return newToken;
            }
        }
    }

    return token;
}
