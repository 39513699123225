import { Action } from "redux";
import {
    Session,
    SessionPlayer,
    SessionConnection,
    SessionLocation,
    SessionPlaylist,
    AudioType,
    Location,
    Playlist,
    tracksToArray,
    IGameSystem,
    LocationSummary,
    isLocation,
    UserInfo,
} from "../store";
import campaignReducer from "./campaign";
import { copyState, reduceAsyncAction, reduceDictionary } from "./common";
import {
    CampaignAction,
    isCampaignAction,
    isLocationAction,
    isLocationPlaylistAction,
    LocationAction,
    LocationPlaylistAction,
} from "../actions/common";
import jsonPatch from "fast-json-patch";
import systems from "../systems";
import { reduceNextCombatTurn } from "./location";

const playersReducer = (state: { [id: string]: SessionPlayer } = {}, action: Action) => {
    return state;
};

const playlistReducer = (
    state: SessionPlaylist | undefined,
    action: LocationPlaylistAction,
    playlist: Playlist | undefined
) => {
    const currentCount = action.props.trackCount;

    // If the currentCount matches the one already in the state, then we can continue. If not,
    // then another player has already done this and we can ignore it.
    if ((currentCount == null || currentCount === (state?.trackCount ?? 0)) && playlist) {
        if (action.type === "PlayNextTrack" || (action.type === "RemoveAudioTrack" && state?.id === action.payload)) {
            // Get the next track, increment the trackCount.
            const tracks = tracksToArray(playlist.tracks);
            let currentIndex = state && state.id ? tracks.findIndex(o => o.id === state.id) : -1;
            currentIndex++;
            if (currentIndex >= tracks.length) {
                currentIndex = 0;
            }

            return {
                id: tracks[currentIndex].id,
                startTime: Date.now(),
                trackCount: (state?.trackCount ?? 0) + 1,
            } as SessionPlaylist;
        } else if (action.type === "PlayTrack" && playlist.tracks[action.payload]) {
            return {
                id: action.payload,
                startTime: Date.now(),
                trackCount: (state?.trackCount ?? 0) + 1,
            };
        }
    }

    return state;
};

const locationReducer = (
    state: SessionLocation | undefined,
    action: LocationAction,
    location: Location | LocationSummary
) => {
    if (isLocation(location)) {
        if (isLocationPlaylistAction(action)) {
            const playlistType = action.props.playlistType;
            if (playlistType === AudioType.Music) {
                const sessionPlaylist = playlistReducer(state?.music, action, location.music);
                if (sessionPlaylist !== state?.music) {
                    return state ? copyState(state, { music: sessionPlaylist }) : { music: sessionPlaylist };
                }
            }
        }
    }

    return state;
};

function reduceSetGameTime(state: Session, speed: number) {
    const oldTime = state.time;

    // We're changing speed, so we have to update the current game time and actual time, so that it doesn't
    // throw the calculation for the current time off (we calculate the current game time by adding time to
    // the difference between the game's last real time update and now to the game time, adjusted for game
    // speed).
    const now = Date.now();
    const diff = (now - oldTime.realTime) * oldTime.speed;
    const newTime = copyState(oldTime, {
        speed: speed,
        gameTime: oldTime.gameTime + diff,
        realTime: now,
    });
    return copyState(state, { time: newTime });
}

const sessionReducer = (
    state: Session,
    action: CampaignAction,
    sessionConnection: SessionConnection,
    user?: UserInfo
): Session => {
    if (action.type === "ApplySessionPatch") {
        let result = jsonPatch.applyPatch(state, action.payload, false, false);
        return result.newDocument;
    } else if (action.type === "RollbackSessionState") {
        return action.payload;
    } else if (action.type === "SetGameTimeSpeed") {
        if (!state.combatLocation) {
            var speed = action.payload as number;
            return reduceSetGameTime(state, speed);
        }
    } else if (action.type === "AddGameTime") {
        if (!state.combatLocation) {
            var amount = action.payload as number;
            const oldTime = state.time;
            const newTime = copyState(oldTime, { gameTime: oldTime.gameTime + amount });
            return copyState(state, { time: newTime });
        }
    } else if (action.type === "NextCombatTurn") {
        // If we're going to go to a new round, then we need to increment the game time NOW, before we
        // reduce the locations/tokens/annotations etc, because they could have effects that will expire
        // on the start of their turn.
        if (state.combatLocation) {
            const location = state.campaign.locations[state.combatLocation];
            if (isLocation(location) && location.combat?.round != null) {
                const roundBefore = location.combat.round;
                const updatedLocation = reduceNextCombatTurn(location, state.campaign);
                const roundAfter = updatedLocation.combat?.round;
                if (roundBefore != null && roundAfter != null) {
                    // If the turn is different from the previous turn, then we advance the clock by 6 seconds (or whatever the correct amount of
                    // time for a round in the current system is).
                    const diff = roundAfter - roundBefore;
                    if (diff !== 0) {
                        const oldTime = state.time;
                        const newTime = copyState(oldTime, {
                            gameTime: oldTime.gameTime + diff * sessionConnection.system!.timePerCombatRound,
                        });
                        state = copyState(state, { time: newTime });
                        sessionConnection = copyState(sessionConnection, { session: state });
                    }
                }
            }
        }
    }

    let players = playersReducer(state.players, action);
    let campaign = campaignReducer(state.campaign, action, sessionConnection, user);

    let locations: { [locationId: string]: SessionLocation } | undefined = state.locations;
    if (isLocationAction(action)) {
        // Get the actual location, the session location reducer will need it.
        const actualLocation = state.campaign.locations[action.props.locationId];
        if (actualLocation) {
            locations =
                reduceDictionary(
                    state.locations,
                    (state, action) => locationReducer(state, action, actualLocation),
                    action,
                    [action.props.locationId]
                ) ?? {};
        }
    }

    // Apply any of the changes so far to the state. We can then use the updated state to check for other changes we might need to make.
    state =
        players !== state.players || campaign !== state.campaign || locations !== state.locations
            ? Object.assign({}, state, { players, campaign, locations })
            : state;

    if (action.type === "AddCombatParticipants") {
        // If a combat has just started, update the current combat location for the session.
        const locationId = (action as LocationAction).props.locationId;
        if (state.combatLocation !== locationId) {
            state = reduceSetGameTime(state, 0);
            state = copyState(state, { combatLocation: locationId });
        }
    } else if (action.type === "RemoveCombatParticipants" || action.type === "EndCombat") {
        // If a combat has ended, update the current combat location for the session.
        const locationId = (action as LocationAction).props.locationId;
        if (state.combatLocation === locationId) {
            const location = state.campaign.locations[locationId];
            if (isLocation(location) && !location.combat) {
                state = copyState(state, { combatLocation: undefined });
            }
        }
    }

    return state;
};

const sessionConnectionReducer = (
    state: SessionConnection,
    action: CampaignAction,
    user?: UserInfo
): SessionConnection => {
    state = reduceAsyncAction("JoinSession", state, action, "isJoiningSession", a => {
        const session = a.payload as Session;
        const systemId = session.campaign?.system ?? "dnd5e";
        const system = (systems[systemId] ? systems[systemId]() : systems["dnd5e"]()) as IGameSystem;
        return {
            session: session,
            isDisconnected: undefined,
            isServerError: undefined,
            isConnected: true,
            api: a.props ? a.props.api() : undefined,
            system: system,
        };
    });

    state = reduceAsyncAction("LeaveSession", state, action, "isLeavingSession", a => {
        return { session: undefined, isConnected: false };
    });
    state = reduceAsyncAction(
        "SendSessionChanges",
        state,
        action,
        "isSendingChanges",
        a => {
            const pendingChanges = a.payload as CampaignAction[];
            return {
                hasPendingChanges: !!pendingChanges.length,
                pendingChangesCount: pendingChanges.length,
                sendingChangesCount: 0,
            };
        },
        undefined,
        a => {
            return {
                hasPendingChanges: true,
                pendingChangesCount: a.payload.length,
                sendingChangesCount: a.payload.length,
            };
        }
    );

    if (action.type === "Disconnected") {
        return copyState(state, { isDisconnected: true, isConnected: undefined, session: undefined });
    }

    let newState: SessionConnection | undefined;
    if (state.session) {
        let newSession = sessionReducer(state.session, action, state, user);
        if (newSession !== state.session) {
            newState = copyState(state, { session: newSession });
        }
    }

    return newState ? newState : state;
};

const campaignSessionReducer = (
    state: { [campaignId: string]: SessionConnection } = {},
    action: Action,
    user?: UserInfo
): { [campaignId: string]: SessionConnection } => {
    let newState: { [campaignId: string]: SessionConnection } | undefined;
    if (isCampaignAction(action)) {
        if (action.type === "InitSession") {
            let newState = Object.assign({}, state);
            newState[action.props.campaignId] = {
                isJoiningSession: false,
                isLeavingSession: false,
                isSendingChanges: false,
                sendingChangesCount: 0,
                hasPendingChanges: false,
                pendingChangesCount: 0,
            };
            return newState;
        }

        let campaignIds = Object.getOwnPropertyNames(state);
        for (let i = 0; i < campaignIds.length; i++) {
            let campaignId = campaignIds[i];
            if (action.props.campaignId === campaignId) {
                let session = state[campaignId];
                let reducedSession = sessionConnectionReducer(session, action, user);
                if (reducedSession !== session) {
                    if (!newState) {
                        newState = Object.assign({}, state);
                    }

                    newState[campaignId] = reducedSession;
                }
            }
        }
    }

    return newState ? newState : state;
};

export default campaignSessionReducer;
