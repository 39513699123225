/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Checkbox, Grid, Heading, Radio, Text } from "../../../../components/primitives";
import { asField, CheckboxField, IAsFieldProps } from "../../../../components/Form";
import React, { ChangeEvent, FunctionComponent, useEffect, useRef, useState } from "react";
import { Loading, LobotomizedBox } from "../../../../components/common";
import { useCampaign, useDispatch } from "../../../../components/contexts";
import { useErrorHandler, useIsMounted } from "../../../../components/utils";
import { SearchableSetting } from "../../../../store";
import { DnD5ECampaign, getAvailableRuleSets, resolveCampaignSources } from "../../common";
import { CharacterRuleStoreSummary } from "../../creature";
import { modifyCampaign, setRuleSets } from "../../actions/campaign";
import { useRules } from "../hooks";
import { Button } from "../../../../components/Button";
import { Cross1Icon } from "@radix-ui/react-icons";
import { theme } from "../../../../design";
import { ModalDialog } from "../../../../components/modal";
import FocusTrap from "focus-trap-react";
import { importCompleted } from "../../../../export";
import { Markdown } from "../../../../components/markdown";

export const allCampaignSettings: SearchableSetting[] = [
    {
        id: "dnd5e_rulesets",
        label: "Rule sources",
        tags: ["dnd5e", "campaign"],
        render: () => <RuleSourceSetting />,
    },
    {
        id: "dnd5e_criticalhit",
        label: "Critical hits",
        tags: ["dnd5e", "campaign", "roll", "dice"],
        render: () => <CriticalHitSetting />,
    },
    {
        id: "dnd5e_diagonals",
        label: "Optional rule: diagonals",
        tags: ["dnd5e", "campaign", "movement"],
        render: () => <DiagonalsSetting />,
    },
    {
        id: "dnd5e_monsterhp",
        label: "Monster HP",
        tags: ["dnd5e", "campaign", "monster", "hp", "hit point"],
        render: () => <MonsterHpSetting />,
    },
];

interface RuleSetListProps {
    disabled?: boolean;
}

const RuleSetList: FunctionComponent<RuleSetListProps> = ({ disabled }) => {
    const { campaign } = useCampaign();
    const rules = useRules();
    const errorHandler = useErrorHandler();

    const dispatch = useDispatch();
    const isMounted = useIsMounted();

    const [pendingDelete, setPendingDelete] = useState<CharacterRuleStoreSummary>();
    const lastPendingDelete = useRef<CharacterRuleStoreSummary>();
    if (pendingDelete != null) {
        lastPendingDelete.current = pendingDelete;
    }

    // Private rule sets are all the sets that are available directly to this user.
    // The campaign can have other rulesets that were added by different users.
    const [privateRuleSets, setPrivateRuleSets] = useState<CharacterRuleStoreSummary[]>();
    const [privateRuleSetsTrigger, setPrivateRuleSetsTrigger] = useState(0);
    useEffect(() => {
        (async () => {
            const rules = await getAvailableRuleSets();
            if (isMounted()) {
                setPrivateRuleSets(rules);
            }
        })();

        // Trigger getting the available rulesets again when an import completes, in
        // case any new rulesets have been added in the import.
        const handler = () => {
            setPrivateRuleSetsTrigger(privateRuleSetsTrigger + 1);
        };
        importCompleted.on(handler);
        return () => {
            importCompleted.off(handler);
        };
    }, [privateRuleSetsTrigger, isMounted]);

    // Account for the fact that the campaign could be using rulesets that are NOT in the list of available
    // rulesets, because they are in another user's private stuff.
    const availableRuleSets = privateRuleSets ? [...privateRuleSets] : [];
    for (let sourceStore of rules.createdFrom) {
        if (sourceStore.uri && !availableRuleSets.some(o => o.uri === sourceStore.uri)) {
            availableRuleSets.push(sourceStore);
        }
    }

    const resolvedRuleSets = resolveCampaignSources(
        rules.createdFrom.map(o => o.id).filter(id => id.toLowerCase() !== "srd")
    );
    const onChangeForRuleSet = (o: CharacterRuleStoreSummary) => {
        if (disabled || o.id.toLowerCase() === "srd") {
            return;
        }

        const rs = rules.createdFrom.map(o => o.uri ?? o.id.toLowerCase());
        const isActive = resolvedRuleSets.indexOf(o.id.toLowerCase()) >= 0;
        if (isActive) {
            dispatch(
                setRuleSets(
                    campaign as DnD5ECampaign,
                    rs.filter(id => (o.uri != null ? id !== o.uri : id !== o.id.toLowerCase()))
                )
            );
        } else {
            dispatch(setRuleSets(campaign as DnD5ECampaign, [...rs, o.uri ?? o.id.toLowerCase()]));
        }
    };
    return (
        <React.Fragment>
            {availableRuleSets != null && (
                <Grid
                    gridTemplateColumns="1fr auto"
                    gridTemplateRows={`${theme.space[4]}px`}
                    css={{ gap: theme.space[2] }}>
                    {availableRuleSets.map(o => (
                        <React.Fragment key={o.id}>
                            <Checkbox
                                gridColumn={1}
                                label={o.name}
                                disabled={disabled || o.id.toLowerCase() === "srd"}
                                checked={resolvedRuleSets.indexOf(o.id.toLowerCase()) >= 0}
                                onChange={() => onChangeForRuleSet(o)}
                                onClick={() => onChangeForRuleSet(o)}
                            />
                            {o.uri != null && availableRuleSets.some(a => a.uri === o.uri) && (
                                <Button
                                    title={`Delete ${o.name}`}
                                    gridColumn={2}
                                    size="s"
                                    variant="tertiary"
                                    justifySelf="flex-end"
                                    onClick={() => {
                                        setPendingDelete(o);
                                    }}>
                                    <Cross1Icon />
                                </Button>
                            )}
                            {o.attribution && (
                                <Box css={{ fontSize: theme.fontSizes[0] }} gridColumn="1 / span 2">
                                    <Markdown>{":::note\n" + o.attribution + "\n:::\n"}</Markdown>
                                </Box>
                            )}
                        </React.Fragment>
                    ))}
                </Grid>
            )}
            {!availableRuleSets && <Loading />}

            <ModalDialog
                onRequestClose={() => {
                    setPendingDelete(undefined);
                }}
                isOpen={pendingDelete != null}
                style={{
                    content: {
                        width: theme.space[12],
                    },
                }}>
                <FocusTrap focusTrapOptions={{ initialFocus: "#modalDefault", allowOutsideClick: true }}>
                    <Box flexDirection="column" p={3} maxWidth={theme.space[13]} alignItems="flex-start">
                        <Heading
                            as="h3"
                            mb={3}
                            css={{ textOverflow: "ellipsis", maxWidth: "100%", overflow: "hidden" }}>
                            Delete {lastPendingDelete.current?.name}?
                        </Heading>
                        <Text>
                            This will delete {lastPendingDelete.current?.name} from your account. Any of your other
                            campaigns that use it may not function correctly.
                        </Text>
                        <LobotomizedBox justifyContent="flex-end" mt={3} fullWidth>
                            <Button
                                disabled={pendingDelete == null}
                                id="modalDefault"
                                variant="primary"
                                onClick={async () => {
                                    if (pendingDelete) {
                                        // Get the file to delete from the uri.
                                        const parts = pendingDelete.uri!.split("/");
                                        const fileName = parts[parts.length - 1];

                                        if (
                                            errorHandler.handleResponse(
                                                await fetch(
                                                    "api/systems/dnd5e/rulesets/delete?id=" +
                                                        encodeURIComponent(fileName),
                                                    {
                                                        method: "DELETE",
                                                    }
                                                )
                                            )
                                        ) {
                                            const rs = rules.createdFrom.map(o => o.uri ?? o.id.toLowerCase());
                                            dispatch(
                                                setRuleSets(
                                                    campaign as DnD5ECampaign,
                                                    rs.filter(id =>
                                                        pendingDelete.uri != null
                                                            ? id !== pendingDelete.uri
                                                            : id !== pendingDelete.id.toLowerCase()
                                                    )
                                                )
                                            );

                                            setPendingDelete(undefined);
                                            setPrivateRuleSets(
                                                privateRuleSets?.filter(o => o.uri !== pendingDelete.uri)
                                            );
                                        }
                                    }
                                }}>
                                Delete
                            </Button>
                            <Button
                                disabled={pendingDelete == null}
                                variant="secondary"
                                onClick={() => setPendingDelete(undefined)}>
                                Keep
                            </Button>
                        </LobotomizedBox>
                    </Box>
                </FocusTrap>
            </ModalDialog>
        </React.Fragment>
    );
};

const RuleSetListField = asField<HTMLDivElement, RuleSetListProps & IAsFieldProps>(RuleSetList);

const RuleSourceSetting: FunctionComponent<{}> = () => {
    return (
        <RuleSetListField
            label="Rule sources"
            hint="Select the source material that is enabled for this campaign."
            required
        />
    );
};

const CriticalHitType: FunctionComponent<{}> = () => {
    const { campaign } = useCampaign();
    const dispatch = useDispatch();

    const dndcampaign = campaign as DnD5ECampaign;
    const critical = dndcampaign.dnd5e?.criticalHitType ?? "double_dice";

    return (
        <LobotomizedBox fullWidth flexDirection="column" alignItems="flex-start">
            <Radio
                name="criticalHitType"
                value="criticalHitType_doubledice"
                label="Double the number of dice rolled"
                checked={critical === "double_dice"}
                onChange={() => dispatch(modifyCampaign(dndcampaign, { criticalHitType: "double_dice" }))}
                onClick={() => dispatch(modifyCampaign(dndcampaign, { criticalHitType: "double_dice" }))}
            />
            <Radio
                name="criticalHitType"
                value="criticalHitType_doublevalue"
                label="Double the dice roll result"
                checked={critical === "double_result"}
                onChange={() => dispatch(modifyCampaign(dndcampaign, { criticalHitType: "double_result" }))}
                onClick={() => dispatch(modifyCampaign(dndcampaign, { criticalHitType: "double_result" }))}
            />
        </LobotomizedBox>
    );
};

const CriticalHitTypeField = asField<HTMLDivElement, IAsFieldProps>(CriticalHitType);

const CriticalHitSetting: FunctionComponent<{}> = () => {
    return <CriticalHitTypeField label="Critical hits" required />;
};

const DiagonalsSetting: FunctionComponent<{}> = () => {
    const { campaign } = useCampaign();
    const dispatch = useDispatch();

    const dndcampaign = campaign as DnD5ECampaign;
    return (
        <CheckboxField
            id="dnd5e_diagonals"
            label="Optional rule: diagonals"
            hint="Every second diagonal movement counts as 10 instead of 5 feet."
            checked={dndcampaign.dnd5e?.diagonals ?? true}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(modifyCampaign(dndcampaign, { diagonals: e.target.checked }));
            }}
        />
    );
};

const MonsterHpSetting: FunctionComponent<{}> = () => {
    const { campaign } = useCampaign();
    const dispatch = useDispatch();

    const dndcampaign = campaign as DnD5ECampaign;
    return (
        <CheckboxField
            id="dnd5e_monsterhp"
            label="Monster HP"
            hint="Roll the max HP for monsters when they are created, rather than using the average max HP."
            checked={dndcampaign.dnd5e?.rollMonsterHp ?? true}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                dispatch(modifyCampaign(dndcampaign, { rollMonsterHp: e.target.checked }));
            }}
        />
    );
};
