/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useEffect, useId, useMemo, useRef, useState } from "react";
import { AnimationFile, AnimationGroup, AnimationSequence, AnimationStep, Token } from "../../../store";
import { useFileUrlWithProgress, useForceUpdate } from "../../../components/utils";
import {
    useAnimationLighting,
    useLocalGrid,
    useTokenOverrides,
    useValidatedLocation,
} from "../../../components/contexts";
import { WithOverride } from "../../../common";
import { ZIndexes } from "../../../components/LocationStage/common";
import { LocalPixelPosition, Size } from "../../../position";
import { useFrame } from "@react-three/fiber";
import {
    Annotation,
    getAnnotationPos,
    isConeAnnotation,
    isLineAreaAnnotation,
    isRectAnnotation,
    isTargettedAnnotation,
} from "../../../annotations";
import { angle, degToRad, distanceBetween, pointAlongLine } from "../../../grid";
import { easeIn, easeOut, usePresence } from "framer-motion";
import { applyOverrides } from "../../../reducers/common";
import { easeInOut } from "framer-motion";

function getFileCount(step: AnimationStep): number | undefined {
    if (step.animationsByRange) {
        const values = Object.values(step.animationsByRange);
        if (values.length) {
            const count = values[0].files.length;
            for (let i = 1; i < values.length; i++) {
                if (values[i].files.length !== count) {
                    return undefined;
                }
            }

            return count;
        }
    }

    return step.animations?.files.length;
}

const AnimationStepNode: FunctionComponent<{
    step: AnimationStep;
    onComplete: () => void;
    completeWhenPossible: boolean;
    isStarted: boolean;
    fileIndex?: number;
    targetIndex?: number;
    source?: WithOverride<Token>;
    target?: WithOverride<Token>;
    annotation?: Annotation;
}> = ({ step, onComplete, fileIndex, source, target, targetIndex, completeWhenPossible, annotation, isStarted }) => {
    const grid = useLocalGrid();

    const [videoSize, setVideoSize] = useState<Size>();
    const { campaign, location } = useValidatedLocation();
    const { tokenOverrides } = useTokenOverrides();
    const id = useId();

    const isStartedRef = useRef<boolean>(false);

    const levelKey = annotation?.pos?.level ?? source?.pos?.level ?? target?.pos?.level ?? location.defaultLevel;

    // TODO: Think about this - if loading is required, this means that the delay time is basically ignored because
    // the loading time happens in the delay time - which means that all the (for e.g.) missiles for a magic missile
    // happen at once instead of slightly staggered.
    const startTimeRef = useRef<number>();
    isStartedRef.current = isStarted;
    if (isStarted && startTimeRef.current == null) {
        startTimeRef.current = Date.now();
    }

    const [isComplete, setIsComplete] = useState(false);

    const delay = useMemo(() => {
        const minDelay = step.minDelay ?? 0;
        const maxDelay = step.maxDelay ?? 0;

        let finalDelay: number;
        if (maxDelay <= minDelay) {
            finalDelay = maxDelay;
        } else {
            finalDelay = Math.random() * (maxDelay - minDelay) + minDelay;
        }

        return finalDelay * 1000;

        // Delay can't change after the step is started.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isStarted]);

    const { file, group, distance, sourcePoint, targetPoint } = useMemo<{
        file?: AnimationFile;
        group?: AnimationGroup;
        distance?: number;
        sourcePoint?: LocalPixelPosition;
        targetPoint?: LocalPixelPosition;
    }>(() => {
        let sourcePoint: LocalPixelPosition | undefined;
        let targetPoint: LocalPixelPosition | undefined;
        let distance: number | undefined;
        if (source && target) {
            // Targetted annotations are far more complicated, we're animating something that travels from the source to
            // one or more targets.
            // Get the start and end positions.
            sourcePoint = grid.toLocalCenterPoint(source.pos, source.scale);
            targetPoint = grid.toLocalCenterPoint(target.pos, target.scale);
            distance = distanceBetween(sourcePoint, targetPoint) / location.tileSize.width;
        } else if (annotation && annotation.tokenId && step.animationsByRange) {
            // If the annotation is specified and the step has files specified by range, then we assume that it is meant
            // to animate from the annotation's caster to the annotation position.
            const casterToken = location.tokens[annotation.tokenId];
            if (casterToken) {
                const caster = applyOverrides(casterToken, tokenOverrides);
                sourcePoint = grid.toLocalCenterPoint(caster.pos, caster.scale);
                targetPoint = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
                distance = distanceBetween(sourcePoint, targetPoint) / location.tileSize.width;
            }
        }

        // If the step is looping, and we should be completing ASAP, then just don't start.
        if (step.loop?.from === 0 && completeWhenPossible) {
            return { file: undefined, distance, sourcePoint, targetPoint };
        }

        let group: AnimationGroup | undefined;
        if (distance != null && step.animationsByRange) {
            // There is a distance, and the step provides distances, so get the closest one.
            const ranges = Object.keys(step.animationsByRange);
            const diffs = ranges.map(o => {
                const range = parseInt(o);
                if (isNaN(range)) {
                    return NaN;
                }

                return Math.abs(distance! - range);
            });

            let lowestDiff = Number.POSITIVE_INFINITY;
            let lowestIndex = -1;
            for (let i = 0; i < diffs.length; i++) {
                if (diffs[i] < lowestDiff) {
                    lowestIndex = i;
                    lowestDiff = diffs[i];
                }
            }

            if (lowestIndex >= 0) {
                group = step.animationsByRange[ranges[lowestIndex]];
            }
        }

        if (group == null) {
            if (step.animations) {
                group = step.animations;
            } else {
                return { file: undefined, group, distance, sourcePoint, targetPoint };
            }
        }

        if (targetIndex != null) {
            // TODO: Have a randomised index offset?
            return {
                file: group.files[targetIndex % group.files.length],
                group,
                distance,
                sourcePoint,
                targetPoint,
            };
        }

        if (fileIndex != null) {
            if ((group.files[fileIndex]?.loop ?? group.loop ?? step.loop)?.from === 0 && completeWhenPossible) {
                return { file: undefined, distance, sourcePoint, targetPoint };
            }

            return { file: group.files[fileIndex], group, distance, sourcePoint, targetPoint };
        }

        // TODO: Technically this random could be cached, as this could run multiple times if the step/distance changes.
        return {
            file: group.files[Math.floor(Math.random() * group.files.length)],
            group,
            distance,
            sourcePoint,
            targetPoint,
        };

        // This stuff deliberately can't change after the animation is mounted.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loop = file?.loop ?? group?.loop ?? step.loop;

    const isPlayingRef = useRef<boolean>(false);
    const isLoadedRef = useRef<boolean>(false);
    const video = useMemo(() => {
        if (file) {
            const v = document.createElement("video");
            v.style.display = "none";
            v.crossOrigin = "anonymous";
            v.autoplay = false;
            v.loop = false;
            document.body.appendChild(v);
            v.onloadeddata = () => {
                setVideoSize({ width: v.videoWidth, height: v.videoHeight });
                isLoadedRef.current = true;

                if (startTimeRef.current != null && Date.now() - startTimeRef.current > delay) {
                    v.play();
                }
            };

            return v;
        }

        // Deliberately not able to change after mounting.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [fileUri] = useFileUrlWithProgress(file?.uri, () => `Failed to download animation file.`);
    useEffect(() => {
        if (video && fileUri) {
            video.src = fileUri;
        }
    }, [fileUri, video]);

    const animationLighting = useAnimationLighting();

    var updateLights = () => {
        const lights = file?.lights ?? group?.lights ?? step.lights;
        if (pos && lights && isPlayingRef.current) {
            for (let i = 0; i < lights.length; i++) {
                const light = lights[i];

                let brightness = light.light.brightness;
                let position = 0;
                if (light.keyframes) {
                    const currentTime = video?.currentTime;
                    const duration = video?.duration;
                    if (currentTime != null && duration != null) {
                        // Work out where we are in the keyframes.
                        let lastKeyframe: (typeof light.keyframes)[0] | undefined;
                        let nextKeyframe: (typeof light.keyframes)[0] | undefined;
                        for (let j = 0; j < light.keyframes.length; j++) {
                            const keyframe = light.keyframes[j];
                            if (currentTime >= keyframe.time) {
                                lastKeyframe = keyframe;
                            } else if (currentTime < keyframe.time) {
                                nextKeyframe = keyframe;
                                break;
                            }
                        }

                        // Interpolate between the two keyframes.
                        if (lastKeyframe != null && nextKeyframe != null) {
                            const keyframeDuration = nextKeyframe.time - lastKeyframe.time;
                            const keyframeTime = (currentTime - lastKeyframe.time) / keyframeDuration;

                            const lastBrightness = lastKeyframe.props.brightness ?? brightness ?? 1;
                            const nextBrightness = nextKeyframe.props.brightness ?? brightness ?? 1;

                            let ease: number;
                            if (nextKeyframe.easing === "linear") {
                                ease = keyframeTime;
                            } else if (nextKeyframe.easing === "easeIn") {
                                ease = easeIn(keyframeTime);
                            } else if (nextKeyframe.easing === "easeOut") {
                                ease = easeOut(keyframeTime);
                            } else {
                                ease = easeInOut(keyframeTime);
                            }

                            brightness = lastBrightness + (nextBrightness - lastBrightness) * ease;

                            if (sourcePoint && targetPoint) {
                                const lastPosition = lastKeyframe.props.position ?? 0;
                                const nextPosition = nextKeyframe.props.position ?? 0;
                                position = lastPosition + (nextPosition - lastPosition) * ease;
                            }
                        } else if (lastKeyframe != null) {
                            brightness = lastKeyframe.props.brightness;
                            position = lastKeyframe.props.position ?? 0;
                        }
                    }
                } else if (sourcePoint && targetPoint) {
                    const currentTime = video?.currentTime;
                    const duration = video?.duration;
                    if (currentTime != null && duration != null) {
                        position = currentTime / duration;
                    }
                }

                const lightId = id + "_" + i;
                const existingLight = animationLighting.lights[lightId];

                let lightPos: LocalPixelPosition;
                if (sourcePoint && targetPoint) {
                    lightPos = pointAlongLine(
                        sourcePoint,
                        targetPoint,
                        distanceBetween(sourcePoint, targetPoint) * position
                    );
                } else {
                    lightPos = pos;
                }

                if (
                    !existingLight ||
                    light.keyframes ||
                    existingLight.pos.x !== lightPos.x ||
                    existingLight.pos.y !== lightPos.y ||
                    existingLight.pos.level !== levelKey
                ) {
                    animationLighting.setLight(
                        Object.assign({}, light.light, {
                            id: id + "_" + i,
                            brightness: brightness,
                            pos: Object.assign({}, lightPos, { level: levelKey }),
                        })
                    );
                }
            }
        }
    };

    useFrame(() => {
        if (
            startTimeRef.current != null &&
            video &&
            isLoadedRef.current &&
            !isPlayingRef.current &&
            !isComplete &&
            Date.now() - startTimeRef.current > delay
        ) {
            video.play();
        }

        if (video && isPlayingRef.current) {
            updateLights();

            if (loop) {
                if (!completeWhenPossible && loop?.to != null && video.currentTime >= loop.to) {
                    video.currentTime = loop.from;
                }

                // TODO: This'll cause a jump if the completeWhenPossible flag is set part way between the loop from and the
                // loop to - maybe that'll be acceptable to get the immediate response, maybe we'll have to make it another
                // flag later.
                if (completeWhenPossible && loop && video.currentTime >= loop.from) {
                    if (loop.to != null) {
                        if (video.currentTime < loop.to) {
                            video.currentTime = loop.to;
                        }
                    } else {
                        setIsComplete(true);
                        onComplete();
                    }
                }
            }
        }
    });

    useEffect(() => {
        if (video) {
            video.onplaying = () => {
                isPlayingRef.current = true;
                updateLights();
            };

            video.onended = () => {
                if (!completeWhenPossible && loop) {
                    video.currentTime = loop.from;
                    video.play();
                } else {
                    isPlayingRef.current = false;
                    setIsComplete(true);
                    onComplete();
                }
            };

            video.onerror = () => {
                console.error("Video playback failed!");
                setIsComplete(true);
                onComplete();
            };
        }

        // Deliberately unchanged.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loop, completeWhenPossible, video]);

    const tileSize = file?.tileSize ?? group?.tileSize ?? step.tileSize ?? 100;
    const tileWidth = (videoSize?.width ?? 0) / tileSize;
    const tileHeight = (videoSize?.height ?? 0) / tileSize;

    let width = tileWidth * location.tileSize.width;
    let height = tileHeight * location.tileSize.height;
    let pos: LocalPixelPosition | undefined;
    let rotation = 0;
    let offsetX = 0;
    let offsetY = 0;
    if (sourcePoint && targetPoint && distance != null) {
        // Targetted annotation, which defaults to behaving as a projectile.
        if (step.at == null || step.at === "projectile") {
            // Work out from the video size where the start and end anchor points are, or get them directly from the animation config.
            const startXPixels = file?.sourceX ?? group?.sourceX ?? step?.sourceX;
            const startX = startXPixels != null ? (startXPixels / tileSize) * location.tileSize.width : height / 2;
            const endXPixels = file?.targetX ?? group?.targetX ?? step?.targetX;
            const endX = endXPixels != null ? (endXPixels / tileSize) * location.tileSize.width : width - startX;

            // Stretch and then rotate those start and end points so that they fit the source and target positions.
            const stretch = (distance * location.tileSize.width) / (endX - startX);
            width *= stretch;
            if (file?.preserveAspect ?? group?.preserveAspect ?? step.preserveAspect) {
                height *= stretch;
            }

            rotation = degToRad(-angle(sourcePoint, targetPoint));

            offsetX = width / 2 - startX * stretch;

            pos = sourcePoint;
        } else if (step.at === "target") {
            pos = targetPoint;
        } else {
            pos = sourcePoint;
        }
    } else if (annotation) {
        if (step.at == null || step.at === "target") {
            pos = getAnnotationPos(annotation, campaign, location, grid, tokenOverrides);
            if (isConeAnnotation(annotation)) {
                rotation = degToRad(-(annotation.rotation ?? 0) - 90);
                offsetX = width / 2;
            } else if (isRectAnnotation(annotation)) {
                width = annotation.width;
                height = annotation.height;
                offsetX = width / 2;
                offsetY = height / 2;
            } else if (isLineAreaAnnotation(annotation)) {
                width = Math.max(
                    Math.min(annotation.length, annotation.maxLength ?? Number.POSITIVE_INFINITY),
                    annotation.minLength ?? 0
                );

                if (tileWidth > 0 && (file?.preserveAspect ?? group?.preserveAspect ?? step.preserveAspect)) {
                    const stretch = width / (tileWidth * location.tileSize.width);
                    height *= stretch;
                } else {
                    height = annotation.width;
                }

                offsetX = width / 2;
                rotation = degToRad(-(annotation.rotation ?? 0) - 90);
            }
        } else {
            // Projectiles are not supported here, there is no source and target to animate between.
            pos = sourcePoint;
        }
    } else if (source) {
        pos = grid.toLocalCenterPoint(source.pos, source.scale);
    }

    // Clean up the video when this element is removed.
    useEffect(() => {
        // If the video hasn't been created by now, then we're never going to.
        // Either there was a problem finding a valid file, or this is a looping animation and we're already meant to complete.
        if (!video) {
            setIsComplete(true);
            onComplete();
        }

        return () => {
            const lights = file?.lights ?? group?.lights ?? step.lights;
            if (lights) {
                for (let i = 0; i < lights.length; i++) {
                    animationLighting.removeLight(id + "_" + i);
                }
            }

            video?.remove();
        };

        // Deliberately not updated after initial mount.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [video]);

    useEffect(() => {
        if (isPlayingRef.current) {
            updateLights();
        }
    });

    const z = step.zIndex ?? ZIndexes.Overlay;
    return (
        <React.Fragment>
            {video && pos && isStarted && !isComplete && (
                <mesh position={[pos.x, -pos.y, z]} rotation={[0, 0, rotation]}>
                    <mesh position={[offsetX, -offsetY, 0]} renderOrder={z}>
                        <planeGeometry args={[width, height]} />
                        <meshBasicMaterial transparent>
                            {/* <meshBasicMaterial color="red" /> */}
                            <videoTexture attach="map" args={[video]} />
                        </meshBasicMaterial>
                    </mesh>
                </mesh>
            )}
        </React.Fragment>
    );
};

export const AnimationSequenceNode: FunctionComponent<{
    animation: AnimationSequence;
    onComplete: () => void;
    completeWhenPossible: boolean;
    source: WithOverride<Token> | undefined;
    target?: WithOverride<Token> | undefined;
    targetIndex?: number;
}> = ({ animation, onComplete, source, target, targetIndex, completeWhenPossible }) => {
    // TODO: Work out the source/targets of the animation, pass that with the context.
    // Once we have a source/target then we can place the animation and hopefully get it playing for the first time.

    // TODO: Make sure animations don't get played on the wrong levels.

    // First we analyse the animation, work out how we're selecting animation files, etc.
    const fileIndex = useMemo(() => {
        // If every step has the same number of files to choose from, then we assume that we choose a random index and then stick with
        // it throughout the animation. Could add something to AnimationSequence to specify this manually if it becomes an issue.
        let fileCount: number | undefined;
        if (animation.steps.length !== 0) {
            fileCount = getFileCount(animation.steps[0]);
            if (fileCount != null) {
                for (let i = 1; i < animation.steps.length; i++) {
                    if (getFileCount(animation.steps[i]) !== fileCount) {
                        fileCount = undefined;
                        break;
                    }
                }
            }
        }

        return fileCount == null ? undefined : Math.floor(Math.random() * fileCount);
    }, [animation]);

    const [pos, setPos] = useState(0);
    return (
        <group>
            {animation.steps.map((o, i) => (
                <AnimationStepNode
                    key={i}
                    step={o}
                    fileIndex={fileIndex}
                    isStarted={pos >= i}
                    source={source}
                    target={target}
                    targetIndex={targetIndex}
                    annotation={animation.annotation}
                    completeWhenPossible={completeWhenPossible}
                    onComplete={() => {
                        const newPos = i + 1;
                        if (newPos >= animation.steps.length) {
                            // The final animation step has completed, this animation is done.
                            onComplete();
                        } else {
                            // Start the next animation step.
                            setPos(newPos);
                        }
                    }}
                />
            ))}
        </group>
    );
};

export const Animations: FunctionComponent<{
    source: WithOverride<Token> | undefined;
    animations: (AnimationSequence & { key: string })[];
}> = ({ animations, source }) => {
    const forceUpdate = useForceUpdate();
    const { location } = useValidatedLocation();
    const { tokenOverrides } = useTokenOverrides();
    const animationsInProgress = useMemo(
        () =>
            new Map<
                string,
                AnimationSequence & {
                    key: string;
                    isCompleting?: boolean;
                    target?: WithOverride<Token>;
                    targetIndex?: number;
                }
            >(),
        []
    );

    const [isPresent, safeToRemove] = usePresence();

    // Add any new animations.
    for (let i = 0; i < animations.length; i++) {
        const annotation = animations[i].annotation;
        if (isTargettedAnnotation(annotation)) {
            // If the annotation is targetted copy the animation, one for each target instance.
            var targetIds = Object.keys(annotation.targets);
            let targetIndex = 0;
            for (let j = 0; j < targetIds.length; j++) {
                const instanceCount = annotation.targets[targetIds[j]];

                // Find the target.
                const token = location.tokens[targetIds[j]];
                if (token) {
                    const tokenWithOverrides = applyOverrides(token, tokenOverrides);

                    for (let k = 0; k < instanceCount; k++) {
                        // Each instance (each copy for that matter, but most importantly each copy with the same
                        // target) should have its own index, so that they don't completely overlap.
                        const key = animations[i].key + "_" + targetIndex;
                        animationsInProgress.set(
                            key,
                            Object.assign({}, animations[i], {
                                key: key,
                                target: tokenWithOverrides,
                                targetIndex: targetIndex,
                            })
                        );
                        targetIndex++;
                    }
                }
            }
        } else {
            animationsInProgress.set(animations[i].key, animations[i]);
        }
    }

    // Mark any animations that are not listed any more as completing.
    const entries = Array.from(animationsInProgress.entries());
    for (let entry of entries) {
        if (!isPresent || !animations.some(o => o.key === entry[0])) {
            animationsInProgress.set(entry[0], Object.assign({}, entry[1], { isCompleting: true }));
        }
    }

    if (animationsInProgress.size === 0 && safeToRemove) {
        safeToRemove();
    }

    return (
        <React.Fragment>
            {Array.from(animationsInProgress.values()).map(o => (
                <AnimationSequenceNode
                    key={o.key}
                    animation={o}
                    completeWhenPossible={!!o.isCompleting}
                    source={source}
                    target={o.target}
                    targetIndex={o.targetIndex}
                    onComplete={() => {
                        animationsInProgress.delete(o.key);
                        if (animationsInProgress.size === 0) {
                            safeToRemove?.();
                        }

                        forceUpdate();
                    }}
                />
            ))}
        </React.Fragment>
    );
};
