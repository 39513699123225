import { Middleware, Dispatch } from "redux";
import { isCampaignAction, CampaignAction, asyncActionCreator } from "./actions/common";
import {
    VTTStore,
    Session,
    MessageLogEntry,
    DiceRollLogEntry,
    ISessionApi,
    RollOptions,
    LogEntryOptions,
} from "./store";
import { Operation, compare, getValueByPointer } from "fast-json-patch";
import { HubConnection, HubConnectionBuilder } from "@aspnet/signalr";
import { Point, Position } from "./position";
import { Event, cloudStorageProps } from "./common";
import { RtcSession } from "./webrtc";

/**
 * Contract for handling connections between a VTT client and a server, so that it can be substituted for testing.
 */
export interface ISessionConnection extends ISessionApi {
    sendPatches(patches: Operation[], sequence: number): Promise<number>;
    onApplyPatches(handler: (patches: Operation[], sequence: number) => void): void;
    start(): Promise<{ session: Session; sequence: number }>;
    stop(): Promise<void>;
    disconnected: Event<Error | undefined>;
}

export interface SessionConnectionFactory {
    (campaignId: string): ISessionConnection;
}

export const signalRSessionFactory = (campaignId: string) =>
    new SignalRSession(new HubConnectionBuilder().withUrl("/vtthub").build(), campaignId);

/**
 * SignalR implementation of session connections.
 * This is the default implementation that is used during runtime.
 */
class SignalRSession implements ISessionConnection {
    private _campaignId: string;
    private _connection: HubConnection;
    private _pinged: Event<{ player: string; location: string; pos: Position }>;
    private _rolled: Event<{ unconfirmed: DiceRollLogEntry; confirm: () => Promise<void> }>;
    private _fowReset: Event<{ locationId: string; levels: string[] }>;
    private _rtc: RtcSession;
    private _disconnected: Event<Error | undefined>;
    private _patchError: Event<Error>;

    constructor(connection: HubConnection, campaignId: string) {
        this._connection = connection;
        this._campaignId = campaignId;
        this._pinged = new Event();
        this._rolled = new Event();
        this._fowReset = new Event();
        this._disconnected = new Event();
        this._patchError = new Event();
        this._rtc = new RtcSession(connection);

        this._connection.on("onPinged", (player: string, location: string, pos: Position) => {
            console.log(`Ping by player ${player} in location ${location} at position ${pos.x}, ${pos.y}`);
            this._pinged.trigger({ player: player, location: location, pos: pos });
        });
        this._connection.on("onFowReset", (locationId: string, levels: string[]) => {
            console.log(`FOW reset for location ${locationId}. Levels are ${levels.join(", ")}.`);
            this._fowReset.trigger({ locationId: locationId, levels });
        });
        this._connection.onclose(e => this._disconnected.trigger(e));
    }

    get pinged() {
        return this._pinged;
    }

    get rolled() {
        return this._rolled;
    }

    get fowReset() {
        return this._fowReset;
    }

    get rtc() {
        return this._rtc;
    }

    disconnect() {
        this._connection.stop();
    }

    get disconnected() {
        return this._disconnected;
    }

    get patchError() {
        return this._patchError;
    }

    async sendPatches(patches: Operation[], sequence: number) {
        return await this._connection.invoke("applyPatches", patches, sequence);
    }

    onApplyPatches(handler: (patches: Operation[], sequence: number) => void) {
        this._connection.on("applyPatches", handler);
    }

    async start() {
        await this._connection.start();
        return (await this._connection.invoke("join", this._campaignId)) as { session: Session; sequence: number };
    }

    async stop() {
        await this._connection.stop();
    }

    async roll(expression: string, options?: RollOptions) {
        const log = (await this._connection.invoke("roll", expression, options)) as DiceRollLogEntry & {
            state?: any;
            confirmationId: number;
        };
        return {
            unconfirmed: log,
            confirmed: new Promise<DiceRollLogEntry>((resolve, reject) => {
                const rollData = {
                    unconfirmed: log,
                    confirm: async () => {
                        try {
                            const confirmedRoll = (await this._connection.invoke(
                                "confirmRoll",
                                log.confirmationId
                            )) as DiceRollLogEntry;
                            resolve(confirmedRoll);
                        } catch (e) {
                            reject(e);
                        }
                    },
                };
                this._rolled.trigger(rollData);
            }),
        };
    }

    async sendMessage(message: string, options?: LogEntryOptions) {
        return (await this._connection.invoke("sendMessage", message, options)) as MessageLogEntry;
    }

    async loadOlderSessions(time: number) {
        return await this._connection.invoke("loadOlderSessions", time);
    }

    async ping(location: string, pos: Position) {
        await this._connection.invoke("ping", location, pos);
    }

    async resetFow(locationId: string, levelKey: string, userId?: string) {
        await this._connection.invoke("resetFow", locationId, levelKey, userId);
    }

    async updateFow(locationId: string, levelKey: string, fow: Point[][], userId?: string) {
        await this._connection.invoke("updateFow", locationId, levelKey, fow, userId);
    }

    async getFow(locationId: string, levelKey: string) {
        return await this._connection.invoke("getFow", locationId, levelKey);
    }
}

/**
 * Handles the interaction between the client and server and keeps them in sync.
 * The session middleware sends actions that affect a campaign here, where they are converted
 * into patches and sent to the server. This class is responsible for making sure patches sent
 * by the server for a particular campaign are applied in the correct order, including rewinding
 * store state and redispatching actions that have not been successfully converted to patches
 * and accepted by the server yet.
 */
export class CampaignSession {
    private _connection: ISessionConnection;
    private _campaignId: string;
    private _getStore: () => VTTStore;
    private _dispatch: Dispatch<CampaignAction>;
    private _sequence: number;
    private _pendingActions: { oldState: Session; action: CampaignAction; newState: Session }[];
    private _pendingPatches: { patches: Operation[]; sequence: number }[];

    private _sendPatchesPromise: Promise<void> | undefined;
    private _lastVerifiedState: Session | undefined;

    constructor(
        connection: ISessionConnection,
        campaignId: string,
        getStore: () => VTTStore,
        dispatch: Dispatch<CampaignAction>
    ) {
        this._connection = connection;
        this._campaignId = campaignId;
        this._getStore = getStore;
        this._dispatch = dispatch;
        this._sequence = -1;
        this._pendingActions = [];
        this._pendingPatches = [];

        connection.onApplyPatches((patches: Operation[], sequence: number) => {
            console.log(`Received patch from server at sequence ${sequence}.`);
            this._pendingPatches.push({ patches: patches, sequence: sequence });
            this._pendingPatches.sort((a, b) => a.sequence - b.sequence);

            // for (let i = 0; i < patches.length; i++) {
            //     console.log(`${patches[i].op} -- ${patches[i].path} -- ${JSON.stringify(patches[i]["value"])}`);
            // }

            if (!this.processPendingPatches()) {
                console.warn(
                    `Received patch for sequence ${sequence}, but our current sequence is only ${this._sequence}. Storing patch to be applied when possible.`
                );
            }
        });

        connection.disconnected.on(e => {
            if (e) {
                console.error("Disconnected. Error was " + e?.message);
            }

            this._dispatch({
                type: "Disconnected",
                props: { campaignId: this._campaignId, error: e },
            });
        });
    }

    get api(): ISessionApi {
        return this._connection;
    }

    async start(): Promise<Session> {
        console.log(`Connection started, joining campaign ${this._campaignId}...`);
        const { session, sequence } = await this._connection.start();
        this._sequence = sequence;
        this._lastVerifiedState = session;

        console.log(
            `Successfully joined campaign ${this._campaignId}, initial session information acquired at sequence ${sequence}.`
        );

        cloudStorageProps.baseUri = session.storageUri;

        // We might have received an update before the join completed - but we have to delay this because it won't have any effect until
        // the caller has had a chance to deal with the session we're returning here.
        setTimeout(() => this.processPendingPatches(), 0);
        return session;
    }

    async stop() {
        await this._connection.stop();
        console.log(`Left the session for campaign ${this._campaignId}.`);
    }

    addAction(oldState: Session, action: CampaignAction, newState: Session) {
        // Keep track of the actions that have occurred since the last successful commit to the server.
        this._pendingActions.push({ oldState: oldState, action: action, newState: newState });
        this.processPendingActions();
    }

    private processPendingActions() {
        if (this._sendPatchesPromise || !this._pendingActions.length) {
            return;
        }

        this._sendPatchesPromise = this.sendPatches();
    }

    /**
     * Processes any pending patches received from the server.
     * @returns True if the first patch has the next sequence number; otherwise false.
     */
    private processPendingPatches(): boolean {
        // Check we're going to apply at least one patch before rolling back and reapplying pending changes.
        if (this._pendingPatches.length && this._pendingPatches[0].sequence === this._sequence + 1) {
            // If we have any pending actions, we need to roll them back before applying the patch.
            if (this._pendingActions.length) {
                this._dispatch({
                    type: "RollbackSessionState",
                    props: { campaignId: this._campaignId },
                    payload: this._pendingActions[0].oldState,
                });
            }

            // Apply patches until we are out of patches or we don't have the patch for the next sequence number.
            for (
                let i = 0;
                i < this._pendingPatches.length && this._pendingPatches[i].sequence === this._sequence + 1;
                i++
            ) {
                let currentPatch = this._pendingPatches[i];

                // Apply the patch that was sent from the server.
                this._dispatch({
                    type: "ApplySessionPatch",
                    props: {
                        campaignId: this._campaignId,
                    },
                    payload: currentPatch.patches,
                });

                // Patches are applied, update our internal sequence number to match.
                this._sequence = currentPatch.sequence;
                this._pendingPatches.splice(i, 1);
                i--;

                console.log(
                    `Successfully applied ${currentPatch.patches.length} changes in patch sent from server, new sequence is ${currentPatch.sequence}.`
                );
            }

            this._lastVerifiedState = this._getStore().sessions[this._campaignId].session;

            // Reapply the pending actions, adjusting the state
            if (this._pendingActions.length) {
                let session = this._lastVerifiedState;
                for (let i = 0; i < this._pendingActions.length; i++) {
                    let pendingAction = this._pendingActions[i];

                    if (session) {
                        pendingAction.oldState = session;
                        this._dispatch(pendingAction.action);

                        session = this._getStore().sessions[this._campaignId].session;
                        if (session) {
                            pendingAction.newState = session;
                        } else {
                            console.error(
                                `Session not found after reapplying pending action of type ${pendingAction.action.type} after applying patch from server.`
                            );
                        }
                    } else {
                        console.error(
                            `Session not found before reapplying pending action of type ${pendingAction.action.type} after applying patch from server.`
                        );
                    }
                }
            }

            // We've applied patches and our sequence number has been updated, so we may have some pending changes that can now be successfully sent.
            this.processPendingActions();
            return true;
        }

        return false;
    }

    private async sendPatches() {
        let pendingActionsCount = this._pendingActions.length;
        let pendingSequence = this._sequence;
        let firstAction = this._pendingActions[0];
        let lastAction = this._pendingActions[this._pendingActions.length - 1];
        let patches = compare(firstAction.oldState, lastAction.newState);

        if (patches) {
            // The server side treats nulls the same as undefined - it strips them altogether. To prevent the two getting out of sync, we treat any patch that replaces a value
            // with null as removing that value.
            for (let i = 0; i < patches.length; i++) {
                const patch = patches[i];

                if (patch.op === "replace" && patch.value == null) {
                    const valueHostPath = patch.path.substring(0, patch.path.lastIndexOf("/"));
                    const valueHost = getValueByPointer(firstAction.oldState, valueHostPath);
                    if (Array.isArray(valueHost) && patch.value === null) {
                        // If the value is being replaced in an array, then let it be. If we remove things from
                        // the middle (or end) of arrays, it can be problematic.
                    } else if (getValueByPointer(firstAction.oldState, patch.path) == null) {
                        // If the old value was ALSO null or undefined, then we want to ignore this altogether.
                        patches.splice(i, 1);
                    } else {
                        patches[i] = {
                            op: "remove",
                            path: patch.path,
                        };
                    }
                }
            }
        }

        if (patches?.length) {
            // console.log(`Found ${patches.length} changes to the session for campaign ${lastAction.newState.campaign.id}, sending at sequence ${this._sequence}.`);
            // for (let patch of patches) {
            //     console.log(`${patch.op}: ${patch.path} ${JSON.stringify(patch["value"])}`);
            // }

            // Dispatch an action to say that we've started to send patches, using the same pattern as asyncActionCreator so that we can use reduceAsyncAction to handle it in the reducer.
            this._dispatch({
                type: "SendSessionChanges:start",
                props: { campaignId: this._campaignId },
                payload: this._pendingActions.map(o => o.action),
            });

            // Send the patch to the server to be applied.
            let oldSequence = this._sequence;

            try {
                let sequence = await this._connection.sendPatches(patches, oldSequence);
                this._sendPatchesPromise = undefined;

                if (sequence < 0) {
                    // Patch was rejected because our sequence number was incorrect.
                    console.warn(
                        `Sent ${patches.length} patch operations at sequence ${oldSequence} for campaign ${this._campaignId}, but they were rejected.`
                    );

                    if (this._sequence !== pendingSequence) {
                        // The sequence has changed since the patch was sent, so we might be able to successfully apply the patches now.
                        this.processPendingActions();
                    }
                } else {
                    // The patch applied successfully, everything is good.
                    this._sequence = sequence;
                    this._pendingActions.splice(0, pendingActionsCount);

                    // The last good state is the state before any new pending actions, or the current state if there are no pending actions.
                    this._lastVerifiedState = this._pendingActions.length
                        ? this._pendingActions[0].oldState
                        : this._getStore().sessions[this._campaignId].session;

                    // console.log(`Sent ${patches.length} patch operations at sequence ${oldSequence} for campaign ${this._campaignId}.`);

                    // Check for more pending changes.
                    this.processPendingActions();
                }

                // The payload of the action is the remaining pending patches, so that the reducer can tell if there are still any pending.
                this._dispatch({
                    type: "SendSessionChanges:success",
                    props: { campaignId: this._campaignId },
                    payload: this._pendingActions.map(o => o.action),
                });
            } catch (error) {
                // There was an error applying the patches, which means that we must necessarily abandon them all and roll back to
                // the last verified good state.
                this._dispatch({
                    type: "RollbackSessionState",
                    props: { campaignId: this._campaignId },
                    payload: this._lastVerifiedState,
                });

                // We can't just replay the problem actions, that could just put us into an infinite loop.
                // Also, any actions patches that have been added since the problem one will be dependent on
                // the problem action, so we'll have to roll them back and lose them too.
                this._pendingActions.splice(0, this._pendingActions.length);

                this._connection.patchError.trigger(error as Error);

                this._dispatch({
                    type: "SendSessionChanges:error",
                    error: error,
                    props: { campaignId: this._campaignId },
                });

                this._sendPatchesPromise = undefined;
            }
        } else {
            // The pending patches didn't amount to any actual change.
            this._pendingActions.splice(0, pendingActionsCount);
            console.warn(
                `The pending actions for campaign ${this._campaignId} did not result in any state change. No patches were sent.`
            );
            await new Promise<void>(resolve => resolve());
            this._sendPatchesPromise = undefined;
        }
    }
}

export const createSessionMiddleware: (connectionFactory: SessionConnectionFactory) => Middleware<{}, VTTStore> =
    (connectionFactory: SessionConnectionFactory) => store => {
        let connections: { [campaignId: string]: CampaignSession } = {};

        // TODO: Fix typing of next.
        return (next: any) => action => {
            let oldState = store.getState();

            if (isCampaignAction(action)) {
                let campaignId = action.props.campaignId;
                if (action.type === "JoinSession") {
                    const session = store.getState().sessions[campaignId];
                    // Ignore connection attempts when we're already connected or in the process of connecting.
                    if (!session.isConnected && !session.isJoiningSession) {
                        return next(
                            asyncActionCreator(
                                "JoinSession",
                                async () => {
                                    // Join (or create) the active session for this campaign.
                                    var connection = new CampaignSession(
                                        connectionFactory(campaignId),
                                        campaignId,
                                        () => store.getState(),
                                        next
                                    );
                                    connections[campaignId] = connection;

                                    return await connection.start();
                                },
                                { campaignId: campaignId, api: () => connections[campaignId].api }
                            )
                        );
                    }
                } else if (action.type === "LeaveSession") {
                    return next(
                        asyncActionCreator(
                            "LeaveSession",
                            async () => {
                                let connection = connections[campaignId];
                                if (!connection) {
                                    return;
                                }

                                await connection.stop();
                                delete connections[campaignId];
                            },
                            { campaignId: campaignId }
                        )
                    );
                }
            }

            let result = next(action);

            // This is a campaign action, so it may have changed the state of a connected session.
            // If it did, we need to report the change to the server to save/distribute.
            if (isCampaignAction(action)) {
                let state = store.getState();

                let sessionIds = Object.getOwnPropertyNames(state.sessions);
                for (let i = 0; i < sessionIds.length; i++) {
                    let sessionConnection = state.sessions[sessionIds[i]];
                    let session = sessionConnection ? sessionConnection.session : undefined;
                    if (session && session) {
                        let oldSessionConnection = oldState.sessions[sessionIds[i]];
                        let oldSession = oldSessionConnection ? oldSessionConnection.session : undefined;
                        if (oldSession && oldSession !== session) {
                            let connection = connections[session.campaign.id];
                            if (connection) {
                                connection.addAction(oldSession, action, session);
                            } else {
                                console.warn(
                                    `A change was made to the campaign ${session.campaign.id}, but no connection was present to persist it.`
                                );
                            }
                        }
                    }
                }
            }

            return result;
        };
    };
