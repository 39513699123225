/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useEffect, useMemo, useState } from "react";
import { Box, Input } from "../../../../components/primitives";
import { Button } from "../../../../components/Button";
import { TokenTemplate } from "../../../../store";
import { DnD5EMonsterTemplate, isDnD5EMonsterTemplate, MonsterFilter } from "../../common";
import {
    AnimatedListItem,
    defaultAnimate,
    defaultExit,
    defaultInitial,
    MotionCard,
} from "../../../../components/motion";
import ReactDOM from "react-dom";
import { ScrollableTest } from "../../../../components/ScrollableTest";
import { AnimatePresence } from "framer-motion";
import FocusTrap from "focus-trap-react";
import { useCampaign } from "../../../../components/contexts";
import { MonsterListItem } from "../TokenListItem";
import styled from "@emotion/styled";
import { filterMonsterTemplates, ResolvedCharacter, ResolvedMonster } from "../../creature";
import { tokenImageSize, useMaxSizePopper } from "../../../../components/common";
import { escapeRegExp } from "../../../../common";
import { ListBox } from "../../../../components/ListBox";
import { theme } from "../../../../design";

const AutoHeightButton = styled(Button)`
    height: auto;
    width: 100%;
    padding-top: ${props => props.theme.space[2]}px;
    padding-bottom: ${props => props.theme.space[2]}px;
`;

const SelectButton = styled.button`
    height: auto;
    width: 100%;
    color: ${props => props.theme.colors.foreground};
    padding-top: ${props => props.theme.space[2]}px;
    padding-bottom: ${props => props.theme.space[2]}px;
    padding-right: 32px;
    border: 2px solid;
    border-color: ${props => props.theme.colors.grayscale[7]};
    background: ${props => props.theme.colors.grayscale[9]};
    border-radius: ${props => props.theme.radii[3]}px;
    position: relative;
    min-width: ${props => props.theme.space[11]}px;

    &.open,
    &:focus {
        outline: 0;
        border-color: ${props => props.theme.colors.guidance.focus};
    }

    &::after {
        content: "";
        position: absolute;
        top: 0px;
        right: 4px;
        width: 24px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' preserveAspectRatio='xMinYMid' fill='hsl(216, 6%, 49%)' %3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
        z-index: 1;
        pointer-events: none;
    }
`;

export const MonsterButton: FunctionComponent<{
    monster: DnD5EMonsterTemplate;
    onClick: () => void;
}> = ({ monster, onClick }) => {
    return (
        <AutoHeightButton onClick={onClick}>
            <MonsterListItem monster={monster} dragDisabled />
        </AutoHeightButton>
    );
};

const defaultLoadedCount = 30;

export const MonsterPicker: FunctionComponent<{
    monster: DnD5EMonsterTemplate | string | undefined;
    setMonster: (monster: DnD5EMonsterTemplate) => void;
    filter: MonsterFilter;
    target?: ResolvedMonster | ResolvedCharacter;
    hint?: string;
}> = ({ monster, setMonster, filter, target, hint }) => {
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLElement | null>(null);
    const { styles, attributes } = useMaxSizePopper(referenceElement, popperElement);
    const [isOpen, setIsOpen] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const [isShowing, setIsShowing] = useState(false);

    const { campaign, system } = useCampaign();
    const [nameFilter, setNameFilter] = useState<string | undefined>(undefined);
    const [loadedCount, setLoadedCount] = useState(defaultLoadedCount);
    useEffect(() => {
        setLoadedCount(defaultLoadedCount);
    }, [nameFilter]);

    const systemTemplatesTemplatesDictionary = system.getTokenTemplates();
    const systemTemplates = useMemo(
        () => Object.values(systemTemplatesTemplatesDictionary),
        [systemTemplatesTemplatesDictionary]
    );

    // This basically just delays loading the main content of the popup by a render, which allows popper to have all the layout
    // sorted out before framer motion starts measuring things. Stops everything moving around.
    useEffect(() => {
        setIsShowing((isOpen && !!systemTemplates) || isClosing);
    }, [isOpen, systemTemplates, isClosing]);

    const allTemplates = useMemo(() => {
        const campaignTemplates = Object.values(campaign.tokens).filter(o =>
            isDnD5EMonsterTemplate(o)
        ) as DnD5EMonsterTemplate[];
        return systemTemplates
            ? [
                  ...campaignTemplates,
                  ...(Object.values(systemTemplates).filter(o => isDnD5EMonsterTemplate(o)) as DnD5EMonsterTemplate[]),
              ]
            : campaignTemplates;
    }, [campaign.tokens, systemTemplates]);
    const filteredTemplates = useMemo(() => {
        let ft = filterMonsterTemplates(allTemplates, filter, target);
        if (nameFilter) {
            const regx = new RegExp(escapeRegExp(nameFilter), "i");
            ft = ft.filter((o: DnD5EMonsterTemplate) => o.dnd5e.name.search(regx) >= 0);
        }

        return ft;
    }, [allTemplates, filter, target, nameFilter]);
    var finalTemplates = filteredTemplates.slice(0, loadedCount);

    let monsterTemplate: DnD5EMonsterTemplate | undefined;
    if (typeof monster === "string") {
        // This is the ID, look it up.
        let t: TokenTemplate | undefined = campaign.tokens[monster];
        if (t) {
            monsterTemplate = isDnD5EMonsterTemplate(t) ? t : undefined;
        } else {
            t = systemTemplatesTemplatesDictionary?.[monster];
            if (t) {
                monsterTemplate = isDnD5EMonsterTemplate(t) ? t : undefined;
            }
        }
    } else {
        monsterTemplate = monster;
    }

    // TODO: Have tried animating the monsterTemplate content here, but for some reason the new element never animates in.
    // Seems like it must be a framer motion bug, try again with a new version.
    return (
        <React.Fragment>
            <SelectButton
                disabled={systemTemplates == null}
                ref={setReferenceElement}
                className={isOpen || isClosing ? "open" : undefined}
                onClick={() => {
                    setIsOpen(!isClosing && !isOpen);
                }}>
                {!monsterTemplate && (
                    <Box key="select" height={tokenImageSize} color="grayscale.2">
                        {hint ?? "Select a creature"}
                    </Box>
                )}
                {monsterTemplate && (
                    <Box key={monsterTemplate.templateId}>
                        <MonsterListItem monster={monsterTemplate} dragDisabled />
                    </Box>
                )}
            </SelectButton>
            {ReactDOM.createPortal(
                <AnimatePresence>
                    {isOpen && systemTemplates && (
                        <Box zIndex={1000} ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                            <FocusTrap
                                focusTrapOptions={{
                                    onDeactivate: () => {
                                        setIsClosing(true);
                                        setIsOpen(false);
                                    },
                                    onPostDeactivate: () => {
                                        setIsClosing(false);
                                        setIsShowing(false);
                                    },
                                    escapeDeactivates: e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        return true;
                                    },
                                    returnFocusOnDeactivate: true,
                                    clickOutsideDeactivates: true,
                                }}>
                                <MotionCard
                                    width={600}
                                    fullHeight
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}
                                    bg="background"
                                    borderRadius={3}>
                                    <Input
                                        value={nameFilter}
                                        mx={3}
                                        mt={3}
                                        mb={2}
                                        placeholder="Filter monsters by name"
                                        onChange={e => setNameFilter(e.target.value ? e.target.value : undefined)}
                                    />
                                    {isShowing && (
                                        <React.Fragment>
                                            <ScrollableTest px={3} py={2} minimal scrollDirection="vertical">
                                                <ListBox<DnD5EMonsterTemplate>
                                                    css={{
                                                        gap: theme.space[2],
                                                        marginTop: -theme.space[2],
                                                        paddingTop: theme.space[2],
                                                    }}
                                                    alignItems="stretch"
                                                    flexDirection="column"
                                                    items={finalTemplates}
                                                    itemKey={o => o.templateId}
                                                    fullWidth
                                                    onSelectionChanged={o => {
                                                        setMonster(o[0]);
                                                        setIsClosing(true);
                                                        setIsOpen(false);
                                                    }}>
                                                    {({ item, index, selected, active, focused }) => (
                                                        <AnimatedListItem
                                                            borderRadius={3}
                                                            bg={
                                                                active
                                                                    ? focused
                                                                        ? "grayscale.7"
                                                                        : "grayscale.8"
                                                                    : undefined
                                                            }
                                                            key={item.templateId}>
                                                            <MonsterListItem
                                                                monster={item}
                                                                dragDisabled
                                                                tokenImageBg={
                                                                    active
                                                                        ? focused
                                                                            ? "grayscale.7"
                                                                            : "grayscale.8"
                                                                        : undefined
                                                                }
                                                            />
                                                        </AnimatedListItem>
                                                    )}
                                                </ListBox>
                                                {finalTemplates.length < filteredTemplates.length && (
                                                    <Button
                                                        key="loadmore"
                                                        variant="tertiary"
                                                        onClick={() =>
                                                            setLoadedCount(loadedCount + defaultLoadedCount)
                                                        }>
                                                        {filteredTemplates.length - loadedCount} more…
                                                    </Button>
                                                )}
                                            </ScrollableTest>
                                        </React.Fragment>
                                    )}
                                </MotionCard>
                            </FocusTrap>
                        </Box>
                    )}
                </AnimatePresence>,
                document.querySelector("#vtt_main")!
            )}
        </React.Fragment>
    );
};
