/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState } from "react";
import { Box, Text, Link } from "../../../components/primitives";
import {
    useAnnotationCache,
    useClipper,
    useDiceBag,
    useDispatch,
    useLocalGrid,
    useLocation,
    useSelection,
    useTokenOverrides,
    useValidatedLocation,
} from "../../../components/contexts";
import {
    Ability,
    AbilityDamage,
    AbilityEffect,
    advantageOrDisadvantage,
    AppliedAbilityEffect,
    DnD5EAnnotation,
    DnD5EToken,
    isDnD5EAnnotation,
    isDnD5ETokenTemplate,
} from "../common";
import { useRules } from "./hooks";
import {
    CombatParticipant,
    DiceBagTerm,
    ResolvedToken,
    addDiceBags,
    getModifyId,
    parseDiceBag,
    reduceDiceBags,
} from "../../../store";
import { GridPosition } from "../../../position";
import { isExcluded } from "../reducers/creature";
import { isTargettedAnnotation } from "../../../annotations";
import { getAbility } from "../abilities";
import {
    fullResolveTokenCreature,
    isCharacter,
    isMonster,
    ResolvedCharacter,
    ResolvedMonster,
    resolveModifiedValue,
} from "../creature";
import { applyDeathSave, applyRechargeRoll, markSavingThrowRoll } from "../actions/token";
import { mapKeyedList } from "../../../common";
import { Markdown } from "../../../components/markdown";

function isInArea(token: DnD5EToken, area: GridPosition[]) {
    for (let i = 0; i < area.length; i++) {
        if (area[i].x === token.pos.x && area[i].y === token.pos.y) {
            return true;
        }
    }

    return false;
}

const CombatTrackerAnnotationTrigger: FunctionComponent<{
    token: DnD5EToken;
    annotation: DnD5EAnnotation;
    ability: Ability;
    effect: AbilityEffect<AbilityDamage>;
    effectKey: string;
    instanceId: string;
}> = ({ token, annotation, ability, effect, effectKey, instanceId }) => {
    const { setSelection } = useSelection();

    const isApplied = annotation.dnd5e.targetEffects?.[getModifyId(token)]?.[instanceId]?.[effectKey]?.applied;

    return (
        <Box>
            <Link
                clean
                color="accent.4"
                style={{
                    textDecoration: isApplied ? "line-through" : undefined,
                    cursor: "pointer",
                }}
                onClick={() => {
                    setSelection([annotation.id]);
                }}>
                {ability.name}
            </Link>
        </Box>
    );
};

const SavingThrowTrigger: FunctionComponent<{
    token: ResolvedToken<DnD5EToken>;
    creature: ResolvedCharacter | ResolvedMonster;
    effect: AppliedAbilityEffect;
    effectKey: string;
}> = ({ token, creature, effect, effectKey }) => {
    const { setDice } = useDiceBag();
    const { campaign, location } = useLocation();
    const [isRolling, setIsRolling] = useState(false);
    const dispatch = useDispatch();

    const ability = effect.savingThrow!;
    let adv = advantageOrDisadvantage(
        creature.savingThrows[ability].advantage,
        creature.savingThrows[ability].disadvantage
    );

    let term: DiceBagTerm;
    if (adv === "adv") {
        term = { amount: 2, keep: 1 };
    } else if (adv === "dis") {
        term = { amount: 2, keep: -1 };
    } else {
        term = { amount: 1 };
    }

    const extraRolls = creature.savingThrows[ability].extraRolls;
    const modifier = resolveModifiedValue(creature.savingThrows[ability]);

    const isApplied = creature.combatTurn?.savingThrows?.[effectKey] != null;
    return (
        <Box>
            <Link
                clean
                color="accent.4"
                disabled={isRolling}
                style={{
                    textDecoration: isApplied ? "line-through" : undefined,
                    cursor: "pointer",
                }}
                onClick={() => {
                    setIsRolling(true);

                    setDice(
                        addDiceBags(
                            {
                                d20: [term],
                                modifier: modifier,
                                options: {
                                    location: isDnD5ETokenTemplate(token) ? undefined : location?.id,
                                    token: isDnD5ETokenTemplate(token) ? token.templateId : token?.id,
                                    data: {
                                        savingThrow: ability,
                                        effect: effectKey,
                                        dc: effect.savingThrowDc,
                                    },
                                },
                                onRolled: roll => {
                                    roll.confirmed
                                        .then(o => {
                                            // Roll completed, store it on the creature's combat turn.
                                            const isSuccess = o.result >= (o.data.dc ?? 0);
                                            dispatch(
                                                markSavingThrowRoll(campaign, location, token, {
                                                    [effectKey]: isSuccess,
                                                })
                                            );
                                        })
                                        .finally(() => {
                                            setIsRolling(false);
                                        });
                                },
                                onCancelled: () => {
                                    setIsRolling(false);
                                },
                            },
                            reduceDiceBags(extraRolls?.map(o => parseDiceBag(o.roll)))
                        )
                    );
                }}>
                Save against {effect.name}
            </Link>
        </Box>
    );
};

const DeathSavingThrow: FunctionComponent<{
    creature: ResolvedCharacter;
    token: DnD5EToken;
}> = ({ creature, token }) => {
    const { campaign, location } = useValidatedLocation();
    const { setDice } = useDiceBag();
    const dispatch = useDispatch();

    return (
        <Box>
            <Link
                clean
                color="accent.4"
                style={{
                    textDecoration: creature.combatTurn?.deathSavingThrow ? "line-through" : undefined,
                    cursor: "pointer",
                }}
                onClick={() => {
                    const modifier = resolveModifiedValue(creature.savingThrows.death);
                    let adv = advantageOrDisadvantage(
                        creature.savingThrows.death.advantage,
                        creature.savingThrows.death.disadvantage
                    );

                    let term: DiceBagTerm;
                    if (adv === "adv") {
                        term = { amount: 2, keep: 1 };
                    } else if (adv === "dis") {
                        term = { amount: 2, keep: -1 };
                    } else {
                        term = { amount: 1 };
                    }

                    setDice({
                        d20: [term],
                        modifier: modifier,
                        options: {
                            data: { savingThrow: "death" },
                            location: location.id,
                            token: token.id,
                        },
                        onRolled: async roll => {
                            const logEntry = await roll.confirmed;
                            dispatch(
                                applyDeathSave(campaign, location, token, {
                                    expression: logEntry.expression,
                                    result: logEntry.result,
                                    terms: logEntry.terms,
                                })
                            );
                        },
                    });
                }}>
                Make a death saving throw
            </Link>
        </Box>
    );
};

export const CombatTrackerItem: FunctionComponent<{
    token: ResolvedToken<DnD5EToken>;
    isCurrent?: boolean;
    participant: CombatParticipant;
}> = ({ token, isCurrent, participant }) => {
    const { campaign, location } = useValidatedLocation();
    const { getGridPoints } = useAnnotationCache();
    const rules = useRules();
    const grid = useLocalGrid();
    const clipper = useClipper();
    const { tokenOverrides } = useTokenOverrides();
    const dispatch = useDispatch();

    const { setDice } = useDiceBag();

    const annotationTriggersStart: {
        annotation: DnD5EAnnotation;
        ability: Ability;
        effect: AbilityEffect<AbilityDamage>;
        effectKey: string;
        instanceId: string;
    }[] = [];
    const annotationTriggersEnd: {
        annotation: DnD5EAnnotation;
        ability: Ability;
        effect: AbilityEffect<AbilityDamage>;
        effectKey: string;
        instanceId: string;
    }[] = [];
    const savingThrowTriggersStart: {
        effectKey: string;
        effect: AppliedAbilityEffect;
    }[] = [];
    const savingThrowTriggersEnd: {
        effectKey: string;
        effect: AppliedAbilityEffect;
    }[] = [];

    const creature = fullResolveTokenCreature(token, campaign, rules);

    // If the creature has effects that allow them to save against them during their turn, then remind
    // the player to do so!
    if (isCurrent && creature?.effects) {
        for (let effectKey in creature.effects) {
            const effect = creature.effects[effectKey];
            if (effect.savingThrow) {
                if (effect.savingThrowTrigger?.targetStartOfTurn) {
                    savingThrowTriggersStart.push({
                        effectKey,
                        effect,
                    });
                }

                if (effect.savingThrowTrigger?.targetEndOfTurn) {
                    savingThrowTriggersEnd.push({
                        effectKey,
                        effect,
                    });
                }
            }
        }
    }

    if (isCurrent && clipper) {
        const annotationKeys = Object.keys(location.annotations);
        for (let key of annotationKeys) {
            const annotation = location.annotations[key];
            if (isDnD5EAnnotation(annotation)) {
                const ability = getAbility(annotation, campaign, location, rules);
                if (ability && ability.effects) {
                    const abilityEffectKeys = Object.keys(ability.effects);
                    for (let abilityEffectKey of abilityEffectKeys) {
                        const abilityEffect = ability.effects[abilityEffectKey];

                        if (abilityEffect.trigger?.targetStartOfTurn || abilityEffect.trigger?.targetEndOfTurn) {
                            // This annotation potentially has the current token as a target - work out what the annotation's targets are.
                            let count: number;
                            if (isTargettedAnnotation(annotation)) {
                                // The annotation is targetted at specific tokens.
                                count = annotation.targets[token.id] ?? 0;
                            } else {
                                const gridPoints = getGridPoints(
                                    annotation,
                                    campaign,
                                    location,
                                    grid,
                                    tokenOverrides,
                                    clipper
                                );
                                count = gridPoints && isInArea(token, gridPoints) ? 1 : 0;
                            }

                            if (count > 0) {
                                for (let i = 0; i < count; i++) {
                                    // For each potential instance, check if this token has been excluded. If it hasn't, then
                                    // this token is a target of this spell, which has a start of turn or end of turn trigger.
                                    // We can display this information to remind the players.
                                    const instanceId = i.toString(10);
                                    if (
                                        !isExcluded(
                                            annotation,
                                            token,
                                            ability,
                                            abilityEffectKey,
                                            instanceId,
                                            campaign,
                                            location,
                                            rules
                                        )
                                    ) {
                                        if (abilityEffect.trigger?.targetStartOfTurn) {
                                            annotationTriggersStart.push({
                                                annotation,
                                                ability,
                                                effect: abilityEffect,
                                                effectKey: abilityEffectKey,
                                                instanceId: instanceId,
                                            });
                                        } else {
                                            annotationTriggersEnd.push({
                                                annotation,
                                                ability,
                                                effect: abilityEffect,
                                                effectKey: abilityEffectKey,
                                                instanceId: instanceId,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    let rechargeElement: React.ReactNode | undefined;
    if (isCurrent && isMonster(creature) && creature.abilities) {
        // Monsters can have abilities that can recharge at start of turn by rolling high enough on a d6.
        rechargeElement = mapKeyedList(creature.abilities, (o, i, k) => {
            if (o.rechargeOn == null || !o.isRecharging) {
                return undefined;
            }

            const result = creature.combatTurn?.recharges?.[k];

            // A result of 0 signifies that the ability was used this turn, so no recharge roll should be made.
            if (result === 0) {
                return undefined;
            }

            return (
                <Box key={k}>
                    <Link
                        clean
                        color="accent.4"
                        style={{
                            textDecoration: result != null ? "line-through" : undefined,
                            cursor: "pointer",
                        }}
                        onClick={() => {
                            setDice({
                                d6: [{ amount: 1 }],
                                options: {
                                    location: location.id,
                                    token: token.id,
                                    data: {
                                        abilityKey: k,
                                        rechargeOn: o.rechargeOn,
                                    },
                                },
                                onRolled: async ({ confirmed }) => {
                                    const result = await confirmed;
                                    dispatch(applyRechargeRoll(campaign, location, token, k, o, result));
                                },
                            });
                        }}>
                        <Markdown>{`Roll to recharge ${o.name}`}</Markdown>
                    </Link>
                </Box>
            );
        });
    }

    return (
        <Box flexDirection="column" fullWidth alignItems="flex-start">
            {(annotationTriggersStart.length > 0 || savingThrowTriggersStart.length > 0) && (
                <Text color="grayscale.2" fontSize={0}>
                    At start of turn
                </Text>
            )}
            {rechargeElement}
            {savingThrowTriggersStart.map(o => (
                <SavingThrowTrigger token={token} creature={creature!} {...o} />
            ))}
            {annotationTriggersStart.map(o => (
                <CombatTrackerAnnotationTrigger key={"sot_" + o.annotation.id} {...o} token={token} />
            ))}
            {isCurrent && isCharacter(creature) && creature.dying && !creature.isDead && (
                <DeathSavingThrow creature={creature} token={token} />
            )}
            {(annotationTriggersEnd.length > 0 || savingThrowTriggersEnd.length > 0) && (
                <Text color="grayscale.2" fontSize={0}>
                    At end of turn
                </Text>
            )}
            {savingThrowTriggersEnd.map(o => (
                <SavingThrowTrigger token={token} creature={creature!} {...o} />
            ))}
            {annotationTriggersEnd.map(o => (
                <CombatTrackerAnnotationTrigger key={"eot_" + o.annotation.id} {...o} token={token} />
            ))}
        </Box>
    );
};
