import { Action } from "redux";
import { PayloadAction } from "../../../actions/common";
import { copyState } from "../../../reducers/common";
import { DnD5ECampaign } from "../common";
import { CharacterRuleSet } from "../creature";

export function reduceCampaign(campaign: DnD5ECampaign, action: Action, rules: CharacterRuleSet) {
    if (action.type === "DnD5E_SetRuleSets") {
        const dnd5e = Object.assign({}, campaign.dnd5e, { sources: (action as PayloadAction).payload });
        return copyState(campaign, { dnd5e: dnd5e });
    } else if (action.type === "DnD5E_ModifyCampaignProperties") {
        const dnd5e = copyState(campaign.dnd5e ?? {}, (action as PayloadAction).payload);
        return copyState(campaign, { dnd5e: dnd5e });
    }

    return campaign;
}
