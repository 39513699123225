/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, PropsWithChildren, ReactNode, useState } from "react";
import { Box, Text, Heading } from "../../../components/primitives";
import { Tag } from "../../../components/Tag";
import { TextPopup, defaultPopupDelay } from "../../../components/TextPopup";
import { AttackType, DamageType, fromRuleKey, monsterToTokenTemplate } from "../common";
import { useRules } from "./hooks";
import { SpellDetails } from "./SpellInfo";
import { CreatureCondition, CreatureStatus, Feature, SkillDetails, resolveMonster } from "../creature";
import { resolveItem } from "../items";
import { ItemDetails } from "./ItemInfo";
import { Markdown } from "../../../components/markdown";
import { useAppState, useCampaign, useDiceBag, useLocation } from "../../../components/contexts";
import { theme } from "../../../design";
import { useCreature } from "./contexts";
import { AttackRollLogEntry, DamageRollLogEntry, RechargeRollLogEntry } from "../logentries";
import { isTokenTemplate, parseDiceBag } from "../../../store";
import { useVttApp } from "../../../components/common";
import { CompendiumPage, useCompendium } from "./common";
import { getItemPanel, getSpellPanel } from "./Compendium";
import { StatBlock } from "./monster/StatBlock";

export const SpellPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: string; name: string }>>(
    ({ label, name, children }, ref) => {
        const rules = useRules();

        const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
        const setCompendiumPage = useCompendium(state => state.setPage);
        const addPanel = useVttApp(state => state.addPanel);
        const { setSearchPropertiesSection, searchPropertiesSections } = useAppState();

        const spellKey = fromRuleKey(name);
        const spell = spellKey ? rules.spells.get(spellKey) : rules.spells.getByName(name);
        if (spell) {
            return (
                <TextPopup
                    ref={ref}
                    text={children}
                    key={name}
                    delay={defaultPopupDelay}
                    onClick={() => {
                        setIsSearchExpanded(true);
                        const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                        if (searchPage) {
                            setSearchPropertiesSection(searchPage);
                            setCompendiumPage(CompendiumPage.Spells);
                            addPanel(getSpellPanel(spell));
                        }
                    }}>
                    <Box flexDirection="column" alignItems="flex-start">
                        <Box flexDirection="row">
                            <Heading as="h6" mr={2}>
                                {spell.displayName ?? spell.name}
                            </Heading>
                            {spell.duration?.isConcentration && (
                                <Tag key="C" mr={1} bg="greens.7" color="greens.0" css={{ overflow: "visible" }}>
                                    C
                                </Tag>
                            )}
                            {spell.isRitual && (
                                <Tag key="R" mr={1} bg="oranges.7" color="oranges.0" css={{ overflow: "visible" }}>
                                    R
                                </Tag>
                            )}
                        </Box>
                        <Text mb={2} color="grayscale.4" fontSize={0} lineHeight={0}>{`${
                            spell.level === 0 ? "Cantrip" : `Level ${spell.level}`
                        } • ${spell.school}`}</Text>
                        <SpellDetails spell={spell} nonInteractive />
                    </Box>
                </TextPopup>
            );
        }

        return <strong ref={ref}>{children}</strong>;
    }
);

export const SkillPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: string; name: string }>>(
    ({ name, children }, ref) => {
        const rules = useRules();
        const skill = rules.skills[name?.toLowerCase()] as SkillDetails;
        if (skill) {
            return (
                <TextPopup ref={ref} text={children} key={name} delay={defaultPopupDelay}>
                    <Box flexDirection="column" alignItems="flex-start">
                        <Heading mb={2} as="h6">
                            {skill.label}
                        </Heading>
                        <Markdown>{skill.content}</Markdown>
                    </Box>
                </TextPopup>
            );
        }

        return <strong ref={ref}>{children}</strong>;
    }
);

export const ConditionPopupContent: FunctionComponent<{
    condition: CreatureCondition;
}> = ({ condition }) => {
    return (
        <Box flexDirection="column" alignItems="flex-start">
            <Heading mb={2} as="h6">
                {condition.name}
            </Heading>
            {condition.content && condition.content.length && <Markdown>{condition.content}</Markdown>}
        </Box>
    );
};

export const StatusPopupContent: FunctionComponent<{
    status: CreatureStatus;
}> = ({ status }) => {
    return (
        <Box flexDirection="column" alignItems="flex-start">
            <Heading mb={2} as="h6">
                {status.name}
            </Heading>
            {status.content && status.content.length && <Markdown>{status.content}</Markdown>}
        </Box>
    );
};

export const ConditionPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: ReactNode; name: string }>>(
    ({ name, children }, ref) => {
        const rules = useRules();
        const condition = rules.conditions.get(name?.toLowerCase());
        if (condition) {
            return (
                <TextPopup ref={ref} text={children} key={name} delay={defaultPopupDelay}>
                    <ConditionPopupContent condition={condition} />
                </TextPopup>
            );
        }

        let status = rules.statuses.getByName(name);
        if (!status) {
            let statusRef = fromRuleKey(name);
            if (statusRef) {
                status = rules.statuses.get(statusRef);
            }
        }

        if (status) {
            return (
                <TextPopup ref={ref} text={children} key={name} delay={defaultPopupDelay}>
                    <StatusPopupContent status={status} />
                </TextPopup>
            );
        }

        return <strong ref={ref}>{children}</strong>;
    }
);

export const ActionPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: ReactNode; name: string }>>(
    ({ label, name, children }, ref) => {
        const rules = useRules();

        const ruleKey = fromRuleKey(name);
        let action = ruleKey ? rules.actions.get(ruleKey) : rules.actions.getByName(name);
        if (action) {
            return (
                <TextPopup ref={ref} text={children} key={name} delay={defaultPopupDelay}>
                    <Box flexDirection="column" alignItems="flex-start">
                        <Heading mb={2} as="h6">
                            {action.name}
                        </Heading>
                        {action.content && action.content.length && <Markdown>{action.content}</Markdown>}
                    </Box>
                </TextPopup>
            );
        }

        return <strong ref={ref}>{children}</strong>;
    }
);

export const ItemPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: string; name: string }>>(
    ({ label, name, children }, ref) => {
        const rules = useRules();

        const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
        const setCompendiumPage = useCompendium(state => state.setPage);
        const addPanel = useVttApp(state => state.addPanel);
        const { setSearchPropertiesSection, searchPropertiesSections } = useAppState();

        // Find the item
        const itemKey = fromRuleKey(name);
        const item = itemKey ? rules.items.items.get(itemKey) : rules.items.items.getByName(name);
        if (item) {
            const resolvedItem = resolveItem(item, rules.items);
            return (
                <TextPopup
                    ref={ref}
                    text={children}
                    key={name}
                    delay={defaultPopupDelay}
                    onClick={() => {
                        setIsSearchExpanded(true);
                        const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                        if (searchPage) {
                            setSearchPropertiesSection(searchPage);
                            setCompendiumPage(CompendiumPage.Items);
                            addPanel(getItemPanel(resolvedItem));
                        }
                    }}>
                    <Box flexDirection="column" alignItems="flex-start">
                        <Heading as="h6" mb={2}>
                            {resolvedItem.name}
                        </Heading>
                        <ItemDetails item={resolvedItem} />
                    </Box>
                </TextPopup>
            );
        }

        return <strong ref={ref}>{label ?? name}</strong>;
    }
);

export const AttackPopup = React.forwardRef<
    HTMLElement,
    PropsWithChildren<{ label?: string; type?: AttackType; bonus: number }>
>(({ label, type, bonus, children }, ref) => {
    const [isHover, setIsHover] = useState(false);
    const { location } = useLocation();
    const token = useCreature();
    const { setDice } = useDiceBag();

    return (
        <span
            ref={ref}
            onPointerEnter={() => setIsHover(true)}
            onPointerLeave={() => setIsHover(false)}
            css={{
                fontWeight: "bold",
                color: isHover ? theme.colors.guidance.focus : undefined,
                cursor: "pointer",
            }}
            onClick={() => {
                setDice(
                    parseDiceBag(
                        "1d20" + (bonus === 0 ? "" : bonus < 0 ? bonus.toString(10) : `+${bonus.toString(10)}`),
                        {
                            data: { attack: type },
                            location: location?.id,
                            token: isTokenTemplate(token) ? token.templateId : token?.id,
                        } as AttackRollLogEntry
                    )
                );
            }}>
            {children}
        </span>
    );
});

export const DamagePopup = React.forwardRef<
    HTMLElement,
    PropsWithChildren<{ label?: string; type?: DamageType; roll: string }>
>(({ type, roll, children }, ref) => {
    const [isHover, setIsHover] = useState(false);
    const { location } = useLocation();
    const token = useCreature();
    const { setDice } = useDiceBag();

    return (
        <span
            ref={ref}
            onPointerEnter={() => setIsHover(true)}
            onPointerLeave={() => setIsHover(false)}
            css={{
                fontWeight: "bold",
                color: isHover ? theme.colors.guidance.focus : undefined,
                cursor: "pointer",
            }}
            onClick={() => {
                setDice(
                    parseDiceBag(roll, {
                        data: { dmgType: type },
                        location: location?.id,
                        token: isTokenTemplate(token) ? token.templateId : token?.id,
                    } as DamageRollLogEntry)
                );
            }}>
            {children}
        </span>
    );
});

export const RechargePopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: ReactNode; value: number }>>(
    ({ label, value, children }, ref) => {
        const [isHover, setIsHover] = useState(false);
        const { location } = useLocation();
        const token = useCreature();
        const { setDice } = useDiceBag();

        return (
            <span
                ref={ref}
                onPointerEnter={() => setIsHover(true)}
                onPointerLeave={() => setIsHover(false)}
                css={{
                    fontWeight: "bold",
                    color: isHover ? theme.colors.guidance.focus : undefined,
                    cursor: "pointer",
                }}
                onClick={() => {
                    setDice({
                        d6: [{ amount: 1 }],
                        options: {
                            data: { rechargeOn: value },
                            location: location?.id,
                            token: isTokenTemplate(token) ? token.templateId : token?.id,
                        } as RechargeRollLogEntry,
                    });
                }}>
                {children}
            </span>
        );
    }
);

export const RollPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: string; roll?: string }>>(
    ({ label, roll, children }, ref) => {
        const [isHover, setIsHover] = useState(false);
        const { location } = useLocation();
        const token = useCreature();
        const { setDice } = useDiceBag();

        return (
            <span
                ref={ref}
                onPointerEnter={() => setIsHover(true)}
                onPointerLeave={() => setIsHover(false)}
                css={{
                    fontWeight: "bold",
                    color: isHover ? theme.colors.guidance.focus : undefined,
                    cursor: "pointer",
                }}
                onClick={() => {
                    if (roll) {
                        setDice(
                            parseDiceBag(roll, {
                                location: location?.id,
                                token: isTokenTemplate(token) ? token.templateId : token?.id,
                            })
                        );
                    }
                }}>
                {children}
            </span>
        );
    }
);

export const FeaturePopupContent: FunctionComponent<{ feature: Feature }> = ({ feature }) => {
    return (
        <Box flexDirection="column" alignItems="flex-start">
            <Heading mb={2} as="h6">
                {feature.name}
            </Heading>
            {feature.content && feature.content.length && <Markdown>{feature.content}</Markdown>}
        </Box>
    );
};

export const FeaturePopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: ReactNode; name: string }>>(
    ({ label, name, children }, ref) => {
        const rules = useRules();

        const ruleKey = fromRuleKey(name);
        const feature = ruleKey
            ? rules.features.get(ruleKey) ?? rules.feats.get(ruleKey)
            : rules.features.getByName(name) ?? rules.feats.getByName(name);

        if (feature) {
            return (
                <TextPopup ref={ref} text={children} key={name} delay={defaultPopupDelay}>
                    <FeaturePopupContent feature={feature} />
                </TextPopup>
            );
        }

        return <strong ref={ref}>{label ?? name}</strong>;
    }
);

export const MonsterPopup = React.forwardRef<HTMLElement, PropsWithChildren<{ label?: ReactNode; name: string }>>(
    ({ label, name, children }, ref) => {
        const rules = useRules();
        const { system, campaign } = useCampaign();

        const setIsSearchExpanded = useVttApp(state => state.setIsSearchExpanded);
        const addPanel = useVttApp(state => state.addPanel);
        const { searchPropertiesSections, setSearchPropertiesSection } = useAppState();
        const setCompendiumPage = useCompendium(state => state.setPage);

        const ruleKey = fromRuleKey(name);
        const monster = ruleKey ? rules.monsters.get(ruleKey) : rules.monsters.getByName(name);
        if (monster) {
            const resolvedMonster = resolveMonster(monster, campaign, rules);
            return (
                <TextPopup
                    ref={ref}
                    text={children}
                    key={name}
                    delay={defaultPopupDelay}
                    onClick={() => {
                        const panel = system.getTokenTemplatePanel?.(monsterToTokenTemplate(monster));
                        if (panel) {
                            setIsSearchExpanded(true);
                            const searchPage = searchPropertiesSections.current.find(o => o.id === "dnd5e");
                            if (searchPage) {
                                setSearchPropertiesSection(searchPage);
                                setCompendiumPage(CompendiumPage.Monsters);

                                addPanel(panel);
                            }
                        }
                    }}>
                    <StatBlock monster={resolvedMonster} />
                </TextPopup>
            );
        }

        return <strong ref={ref}>{label ?? name}</strong>;
    }
);
