/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { ChangeEvent, FunctionComponent, ReactElement, ReactNode, useEffect, useRef, useState } from "react";
import {
    Ability,
    AbilityDamage,
    AbilityEffect,
    AbilityInstanceResult,
    ApplicableAbilityEffect,
    AppliedAbilityEffect,
    AttackModifiers,
    AttackOptionResult,
    CoreAbility,
    DamageType,
    damageTypes,
    DnD5EAnnotation,
    DnD5ECampaign,
    DnD5EToken,
    durationToString,
    evaluateCreatureValue,
    ExpressionableValue,
    formatModifier,
    getBoundsPoint,
    getClosestBoundsPoints,
    getRuleKey,
    getSingleAttackType,
    getTimeUntil,
    isDnD5EToken,
    isNamedRuleRef,
    RelativeToBoundsPoint,
} from "../common";
import {
    addDiceBags,
    AnimationSequence,
    AnnotationAdornerProps,
    Campaign,
    CampaignRole,
    DiceBag,
    diceBagRequiresRoll,
    diceBagToExpression,
    DiceRoll,
    getGridTokensAt,
    getModifyId,
    getResolvedToken,
    Location,
    parseDiceBag,
    ResolvedToken,
    resolveToken,
    Token,
    visitDiceBagTerms,
} from "../../../store";
import {
    AnimatedList,
    AnimatedListItem,
    defaultAnimate,
    defaultExit,
    defaultInitial,
    MotionBox,
    MotionLobotomizedBox,
    MotionOverlayCard,
    MotionText,
} from "../../../components/motion";
import { useRules } from "./hooks";
import { Box, Heading, Text, Image, Checkbox, Select } from "../../../components/primitives";
import { Button } from "../../../components/Button";
import { PercentageBar } from "../../../components/PercentageBar";
import { Spacer } from "../../../components/Spacer";
import {
    useAnnotationCache,
    useClipper,
    useDiceBag,
    useDispatch,
    useLocalGrid,
    useValidatedLocation,
    useRole,
    useTokenOverrides,
    useUser,
    useSession,
    useLocation,
} from "../../../components/contexts";
import { LobotomizedBox, resolveUri, tokenImageSize } from "../../../components/common";
import {
    applyAcModifier,
    applyAnnotationAppliedEffectChoices,
    applyAttackHit,
    applyAttackRoll,
    applyDamage,
    applyEffects,
    applyHealing,
    applyResistance,
    applySavingThrow,
    chooseAnnotationDamageType,
    choosePerLevelDamageType,
    modifyOptionResult,
    moveAnnotation,
    setAttackOption,
    setExclusion,
} from "../actions/annotation";
import { distanceBetween, ILocalGrid, localPoint, localRect, screenPoint, translate } from "../../../grid";
import {
    fullResolveTokenCreature,
    isMonster,
    isCharacter,
    ResolvedCharacter,
    ResolvedMonster,
    DamageTypes,
    attackTypeToString,
    resolveModifiedValue,
    BeforeAttackOption,
    isProficient,
    AttackOptionBase,
    Feature,
    modifierFromAbilityScore,
    getAttackModifiers,
    shouldApplyAttackModifiers,
    resolveTokenCreature,
    Creature,
    CharacterRuleSet,
    creatureMatchesFilter,
    ResolvedBeforeAttackOption,
    ResolvedAfterAttackOption,
    getAbilityDc,
    canPayResourceCosts,
} from "../creature";
import { applyOverrides } from "../../../reducers/common";
import { MonsterSavingThrowBox } from "./monster/MonsterSavingThrowBox";
import { CharacterSavingThrowBox } from "./character/CharacterSavingThrowBox";
import { isSpell, Spell, SpellDamage, SpellEffect } from "../spells";
import { SpellInfo } from "./SpellInfo";
import { AnimatePresence, LayoutGroup, animate, useIsPresent, useMotionValue } from "framer-motion";
import { theme } from "../../../design";
import { useProfiles } from "../../../components/utils";
import ProfileAvatar from "../../../components/ProfileAvatar";
import { getDefaultResistance, isExcluded } from "../reducers/creature";
import { DiceRollResult } from "../../../components/DiceRollResult";
import { GridPosition, LocalPixelPosition, LocalRect, Point, ScreenPixelPosition, Size } from "../../../position";
import { FeatureExpander } from "./FeatureExpander";
import { Markdown } from "../../../components/markdown";
import { Annotation, isTargettedAnnotation } from "../../../annotations";
import { RollButtonHorizontal } from "./RollButton";
import { getAbilityForCreature } from "../abilities";
import { AdvantageBox } from "./AdvantageBox";
import { isResolvedWeapon, isWeapon } from "../items";
import { HtmlAdorner, useHtmlAdorner } from "../../../components/LocationStage/HtmlAdorner";
import { AppliedAbilityEffectChoicesEditor } from "./AppliedAbilityEffectChoicesEditor";
import { markLegendaryResistance } from "../actions/token";
import { WithOverride, keyedListToArray, keyedListToKeyArray } from "../../../common";
import useMeasure from "react-use-measure";
import { flushSync } from "react-dom";
import { Animations } from "./AnimationSequence";
import { DamageRollLogEntry } from "../logentries";

function getTargets(
    coverage: GridPosition[],
    campaign: Campaign,
    location: Location,
    grid: ILocalGrid,
    role: CampaignRole
) {
    const targets: ResolvedToken<DnD5EToken>[] = [];
    for (let point of coverage) {
        var tokens = getGridTokensAt(location, point, grid, role);
        for (let token of tokens) {
            if (isDnD5EToken(token) && targets.findIndex(o => o.id === token.id) < 0) {
                targets.push(resolveToken(campaign, token));
            }
        }
    }

    return targets;
}

function isHitOrCrit(attackRoll: DiceRoll, ac: number, criticalRange: number | undefined): boolean {
    const result = isHit(attackRoll, ac, criticalRange);
    return result === "crit_hit" || result === "hit";
}

function isHit(
    attackRoll: DiceRoll,
    ac: number,
    criticalRange: number | undefined
): "crit_hit" | "hit" | "miss" | "crit_miss" {
    // First check for a crit using the raw rolled number.
    const d20 = attackRoll.terms.find(o => !o.isExcluded && o.type === "d20");
    if (!d20) {
        console.warn("Could not evaluate attack roll, d20 roll not found: " + JSON.stringify(attackRoll));
        return "miss";
    }

    if (d20.result >= (criticalRange ?? 20)) {
        return "crit_hit";
    } else if (d20.result === 1) {
        return "crit_miss";
    }

    // Not a critical hit or miss, so evaluate the full result against the AC.
    return attackRoll.result >= ac ? "hit" : "miss";
}

const SavingThrowHelper = React.forwardRef<
    HTMLDivElement,
    {
        isDragging?: boolean;
        isAttackerOwner: boolean;
        isTargetOwner: boolean;
        ability: CoreAbility;
        creature?: ResolvedMonster | ResolvedCharacter;
        annotation: Annotation;
        token: DnD5EToken;
        dc: number | undefined;
        roll: DiceRoll | undefined;
        onRoll: (roll: DiceRoll) => void;
    }
>(({ isDragging, isAttackerOwner, isTargetOwner, ability, creature, annotation, token, dc, roll, onRoll }, ref) => {
    const { campaign, location } = useLocation();
    const dispatch = useDispatch();
    const role = useRole();

    const isSaveSuccess = dc != null && roll != null && roll.result >= dc;
    return (
        <MotionBox
            ref={ref}
            layout={!isDragging}
            fullWidth
            flexDirection="column"
            alignItems="stretch"
            initial={defaultInitial}
            animate={defaultAnimate}
            exit={defaultExit}>
            {isTargetOwner && !roll && (
                <React.Fragment>
                    <Text>
                        Make a <b>{ability}</b> saving throw:
                    </Text>
                    <Box flexDirection="row" css={{ gap: theme.space[2] }} alignItems="flex-start">
                        {isMonster(creature) && (
                            <MonsterSavingThrowBox
                                ability={ability}
                                monster={creature}
                                notify={{ notSelected: annotation.id }}
                                token={token}
                                onRoll={async roll => {
                                    const result = await roll.confirmed;
                                    onRoll(result);
                                }}
                            />
                        )}
                        {isCharacter(creature) && (
                            <CharacterSavingThrowBox
                                ability={ability}
                                character={creature}
                                notify={{ notSelected: annotation.id }}
                                token={token}
                                onRoll={async roll => {
                                    const result = await roll.confirmed;
                                    onRoll({
                                        expression: result.expression,
                                        result: result.result,
                                        terms: result.terms,
                                    });
                                }}
                            />
                        )}
                        <Button
                            onClick={() => {
                                onRoll({
                                    expression: "0",
                                    terms: [],
                                    result: -100,
                                });
                            }}
                            variant="danger">
                            Fail
                        </Button>
                    </Box>
                </React.Fragment>
            )}
            {!isTargetOwner && !roll && <React.Fragment>Waiting for saving throw…</React.Fragment>}
            {roll != null && (
                <MotionBox
                    display="block"
                    layout={isDragging ? false : "position"}
                    flexDirection="column"
                    alignItems="flex-start"
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}>
                    <MotionText layout={!isDragging} css={{ display: "inline" }}>
                        Saving throw
                        {role === "GM" && dc != null ? ` (DC ${dc})` : ""}:{" "}
                    </MotionText>
                    <MotionText
                        layout={!isDragging}
                        css={{ display: "inline" }}
                        color={isSaveSuccess ? "guidance.success.1" : "guidance.error.1"}>
                        {roll.result > -100 && roll.result < 100 ? roll.result : ""}{" "}
                        {isSaveSuccess
                            ? roll.result >= 100
                                ? "auto success"
                                : "(success)"
                            : roll.result <= -100
                            ? "auto failure"
                            : "(failure)"}
                    </MotionText>
                    <AnimatePresence>
                        {!isSaveSuccess &&
                            isMonster(creature) &&
                            creature.legendaryResistances != null &&
                            (creature.usedLegendaryResistances ?? 0) < creature.legendaryResistances && (
                                <MotionBox
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}
                                    flexDirection="column"
                                    fullWidth
                                    alignItems="flex-start">
                                    <PercentageBar
                                        fullWidth
                                        total={creature.legendaryResistances}
                                        complete={
                                            creature.legendaryResistances - (creature.usedLegendaryResistances ?? 0)
                                        }
                                        mt={2}
                                        heading={
                                            <Box>
                                                <Text
                                                    as="span"
                                                    fontWeight="bold"
                                                    css={{ lineHeight: theme.lineHeights[3] }}>
                                                    {creature.legendaryResistances -
                                                        (creature.usedLegendaryResistances ?? 0)}{" "}
                                                    / {creature.legendaryResistances}
                                                </Text>
                                                <Text as="span" ml={1} css={{ lineHeight: theme.lineHeights[3] }}>
                                                    remaining
                                                </Text>
                                            </Box>
                                        }
                                    />
                                    <Button
                                        variant="success"
                                        fullWidth
                                        mt={2}
                                        onClick={() => {
                                            dispatch(markLegendaryResistance(campaign, location, [token]));
                                            onRoll({
                                                expression: "0",
                                                terms: [],
                                                result: 100,
                                            });
                                        }}>
                                        Use legendary resistance
                                    </Button>
                                </MotionBox>
                            )}
                    </AnimatePresence>
                </MotionBox>
            )}
        </MotionBox>
    );
});

// TODO: Use this when working out whether the apply button should be enabled too.
function isAttackOptionComplete(
    option: AttackOptionBase,
    stage: "beforeDamage" | "all",
    rules: CharacterRuleSet,
    results?: AttackOptionResult
) {
    if (option.spellSlotCost && !option.spellSlotCost.level && !results?.spellSlotCost) {
        return false;
    }

    if (stage === "beforeDamage") {
        return true;
    }

    if (option.target) {
        const targetEffect = isNamedRuleRef(option.target) ? rules.effects.get(option.target) : option.target;
        if (targetEffect?.savingThrow && !results?.savingThrow) {
            return false;
        }
    }

    return true;
}

function evaluateAttackOptionDamage(
    creature: ResolvedCharacter | undefined,
    optionDamage: ExpressionableValue<string>,
    target: ResolvedCharacter | ResolvedMonster | undefined,
    results: AttackOptionResult | undefined
) {
    const dmg = evaluateCreatureValue(creature, optionDamage, {
        target: target,
        attackOption: results,
    });
    return dmg as string;
}

const AttackOptionBox: FunctionComponent<{
    annotation: DnD5EAnnotation;
    isDragging?: boolean;
    disabled?: boolean;
    token: Token;
    creature?: ResolvedCharacter;
    target: ResolvedToken<DnD5EToken>;
    targetCreature?: ResolvedCharacter | ResolvedMonster;
    isAttackerOwner: boolean;
    ability: Ability;
    instanceId: string;
    option: ResolvedBeforeAttackOption | ResolvedAfterAttackOption;
}> = ({
    annotation,
    isDragging,
    token,
    creature,
    targetCreature,
    target,
    isAttackerOwner,
    ability,
    instanceId,
    option,
    disabled,
}) => {
    const { campaign, location } = useValidatedLocation();
    const id = getModifyId(target);
    const dispatch = useDispatch();
    const rules = useRules();
    const role = useRole();
    const user = useUser();

    const appliedInstance = annotation.dnd5e.targetEffects?.[id]?.[instanceId];

    const isTargetOwner = role === "GM" || target.owner === user.id;

    const targetEffect = isNamedRuleRef(option.target) ? rules.effects.get(option.target) : option.target;
    const results = appliedInstance?.options?.[getRuleKey(option.feature)]?.[option.key];
    const isEnabled = results != null;

    const effects: string[] = [];
    var beforeAttackOption = option as BeforeAttackOption;
    if (beforeAttackOption.attack != null && beforeAttackOption.attack !== 0) {
        effects.push(
            `${beforeAttackOption.attack > 0 ? "+" + beforeAttackOption.attack : beforeAttackOption.attack} to attack`
        );
    }

    if (option.damage != null) {
        for (let damageKey in option.damage) {
            const optionDamage = option.damage[damageKey] as ExpressionableValue<string>;

            // Check that we have everything we need before proceeding.
            if (
                typeof optionDamage === "object" &&
                optionDamage.expression &&
                !isAttackOptionComplete(option, "beforeDamage", rules, results)
            ) {
                // TODO: If we don't we just show a noncommittal message instead of an actual damage roll?
            } else {
                const dmg = evaluateAttackOptionDamage(creature, optionDamage, targetCreature, results);
                effects.push(
                    `${dmg.startsWith("-") ? dmg : "+" + dmg}${damageKey === "weapon" ? "" : " " + damageKey} damage`
                );
            }
        }
    }

    if (beforeAttackOption.advantage) {
        effects.push("advantage");
    }

    // TODO: CHECK IF WE CAN PAY THE SPELL SLOT COST?! We won't let you select a spell slot if you don't have one available I guess?
    // What if the spell slot if dedicated, so you don't need to select one? What then?

    const canPayCosts = creature != null && canPayResourceCosts(creature, option.resourceCost);
    return (
        <Box flexDirection="column" fullWidth mb={2} alignItems="flex-start">
            {isAttackerOwner && (
                <Checkbox
                    id={annotation.id + "~~" + instanceId + "~~" + getRuleKey(option.feature) + "~~" + option.key}
                    checked={isEnabled}
                    disabled={disabled || (!canPayCosts && !isEnabled)}
                    label={option.feature.name}
                    onClick={() => {
                        dispatch(setAttackOption(campaign, location, annotation, id, instanceId, option, !isEnabled));
                    }}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        dispatch(setAttackOption(campaign, location, annotation, id, instanceId, option, !isEnabled));
                    }}
                />
            )}
            <AnimatePresence mode="popLayout">
                {isEnabled && option.spellSlotCost && option.spellSlotCost?.level == null && isAttackerOwner && (
                    <React.Fragment>
                        {/* The spell slot that is being expended must be chosen by the attacker. */}
                        <Select
                            mt={1}
                            fullWidth
                            value={results.spellSlotCost ? JSON.stringify(results.spellSlotCost) : ""}
                            onChange={e => {
                                const slot: { level: number; name?: string } | undefined =
                                    e.target.value == null || e.target.value === ""
                                        ? undefined
                                        : JSON.parse(e.target.value);
                                dispatch(
                                    modifyOptionResult(
                                        campaign,
                                        location,
                                        annotation,
                                        undefined,
                                        id,
                                        instanceId,
                                        option,
                                        {
                                            spellSlotCost: slot,
                                        }
                                    )
                                );
                            }}>
                            <option value="">--</option>
                            {creature?.spellSlots?.flatMap(o => {
                                const slots: ReactNode[] = [];
                                if (Array.isArray(o)) {
                                    // Regular spell slots.
                                    for (let i = 0; i < o.length; i++) {
                                        const available = o[i] ?? 0;
                                        const used = creature?.usedSpellSlots?.default?.[i] ?? 0;
                                        if (used < available) {
                                            // This can be chosen as a spell slot to expend.
                                            slots.push(
                                                <option key={i} value={`{"level":${i + 1}}`}>
                                                    Level {i + 1}
                                                </option>
                                            );
                                        }
                                    }
                                } else {
                                    // Named slots.
                                    for (let i = 0; i < o.spellSlots.length; i++) {
                                        const available = o.spellSlots[i] ?? 0;
                                        const used = creature?.usedSpellSlots?.[o.name]?.[i] ?? 0;
                                        if (used < available) {
                                            slots.push(
                                                <option
                                                    key={`${o.name}_${i}`}
                                                    value={`{"level":${i + 1},"name":"${o.name}"}`}>
                                                    Level {i + 1} ({o.name})
                                                </option>
                                            );
                                        }
                                    }
                                }

                                return slots;
                            })}
                        </Select>
                    </React.Fragment>
                )}
                {isEnabled &&
                    targetEffect?.savingThrow &&
                    (!targetEffect.savingThrowTrigger || targetEffect.savingThrowTrigger?.cast) && (
                        <SavingThrowHelper
                            ability={targetEffect.savingThrow}
                            isDragging={isDragging}
                            annotation={annotation}
                            creature={targetCreature}
                            isAttackerOwner={isAttackerOwner}
                            isTargetOwner={isTargetOwner}
                            token={target}
                            roll={results.savingThrow}
                            dc={
                                creature && targetEffect.savingThrowDcAbility
                                    ? getAbilityDc(creature, targetEffect.savingThrowDcAbility)
                                    : undefined
                            }
                            onRoll={roll => {
                                dispatch(
                                    applySavingThrow(
                                        campaign,
                                        location,
                                        annotation,
                                        undefined,
                                        id,
                                        instanceId,
                                        option,
                                        roll
                                    )
                                );
                            }}
                        />
                    )}
            </AnimatePresence>
            {effects.length > 0 && (
                <Text fullWidth fontSize={0} color="grayscale.2">
                    {effects.join(", ")}
                </Text>
            )}
        </Box>
    );
};

function filterAttackOptions<T extends AttackOptionBase>(
    character: ResolvedCharacter,
    ability: Ability,
    options: T[] | undefined
) {
    if (options == null) {
        return [];
    }

    let attack = getSingleAttackType(ability);
    if (attack == null) {
        return [];
    }

    const item = ability.item ? character.inventory[ability.item] : undefined;

    options = options.filter(o => {
        if (o.type !== attack) {
            return false;
        }

        if (o.weapon?.proficient) {
            if (!isResolvedWeapon(item) || !isProficient(character, item)) {
                return false;
            }
        }

        if (o.weapon?.properties) {
            if (!item || !o.weapon.properties?.every(p => item.properties?.find(ip => ip.abbreviation === p))) {
                return false;
            }
        }

        return true;
    });

    return options;
}

const AttackBox = React.forwardRef<
    HTMLDivElement,
    {
        annotation: DnD5EAnnotation;
        isDragging: boolean;
        token: ResolvedToken<DnD5EToken>;
        isAttackerOwner: boolean;
        creature?: ResolvedCharacter | ResolvedMonster;
        target: ResolvedToken<DnD5EToken>;
        resolvedTarget: ResolvedCharacter | ResolvedMonster;
        ability: Ability;
        instanceId: string;
    }
>(({ annotation, token, creature, target, resolvedTarget, ability, instanceId, isDragging, isAttackerOwner }, ref) => {
    const { campaign, location, system } = useValidatedLocation();
    const dispatch = useDispatch();
    const id = getModifyId(target);
    const appliedInstance = annotation.dnd5e.targetEffects?.[id]?.[instanceId];
    const role = useRole();
    const grid = useLocalGrid();

    const [isRolling, setIsRolling] = useState(false);

    if (!ability.attack) {
        return <React.Fragment></React.Fragment>;
    }

    let attackModifier = 0;
    if (ability.attackModifier != null) {
        attackModifier = ability.attackModifier;
    } else if (annotation.dnd5e.attackModifier != null) {
        attackModifier = annotation.dnd5e.attackModifier;
    }

    const feetToTarget =
        system.getGridRange(campaign, location, grid, token, target) *
        (location.unitsPerGrid ?? system.defaultUnitsPerGrid);

    const attackModifiers = getAttackModifiers(creature, resolvedTarget, ability, feetToTarget);

    // Work out whether the character can ignore cover for this attack.
    let ignoreCover = false;
    let beforeOptions: ResolvedBeforeAttackOption[] | undefined;
    let afterOptions: ResolvedAfterAttackOption[] | undefined;
    if (isCharacter(creature)) {
        beforeOptions = filterAttackOptions(creature, ability, creature.beforeAttack);
        if (beforeOptions) {
            for (let option of beforeOptions) {
                // Check if the option is enabled
                const isEnabled = !!appliedInstance?.options?.[getRuleKey(option.feature)]?.[option.key];
                if (isEnabled) {
                    if (option.attack != null) {
                        attackModifier += option.attack;
                    }

                    if (option.advantage) {
                        attackModifiers.advantages.push({ content: option.feature.name });
                    }
                }
            }
        }

        afterOptions = filterAttackOptions(creature, ability, creature.afterAttack);

        ignoreCover = (creature.ignoreCover?.indexOf(getSingleAttackType(ability)!) ?? -1) >= 0;
    }

    const setAcModifier = (acModifier: number | undefined) =>
        dispatch(applyAcModifier(campaign, location, annotation, id, instanceId, acModifier));

    let targetAc = 10;
    if (role === "GM" && appliedInstance?.attack) {
        // Work out what the target's AC is.
        if (isCharacter(resolvedTarget)) {
            targetAc = resolveModifiedValue(resolvedTarget.ac);
        } else if (isMonster(resolvedTarget)) {
            targetAc = resolvedTarget.ac?.value ?? 10;
        }
    }

    let modifiedTargetAc = targetAc + (appliedInstance?.acModifier ?? 0);

    // Advantage & disadvantage
    if (!ignoreCover && ability.rangeNear != null && feetToTarget > ability.rangeNear) {
        attackModifiers.disadvantages.push({
            content: `The target is more than ${ability.rangeNear} feet away.`,
        });
    }

    let attackResult = appliedInstance?.attack
        ? isHit(appliedInstance?.attack, modifiedTargetAc, ability.criticalRange)
        : undefined;
    if (attackResult === "hit" && attackModifiers.autoCrit) {
        attackResult = "crit_hit";
    }

    if (!!attackModifiers.advantages.length !== !!attackModifiers.disadvantages.length) {
        attackModifiers.advantage = attackModifiers.advantages.length ? "adv" : "dis";
    }

    return (
        <MotionBox
            ref={ref}
            layout={isDragging ? false : "position"}
            position="relative"
            flexDirection="column"
            alignItems="flex-start"
            fullWidth
            maxWidth="100%">
            {beforeOptions?.map(option => {
                return (
                    <AttackOptionBox
                        key={getRuleKey(option.feature) + "~~" + option.key}
                        disabled={isRolling || !!appliedInstance?.attack}
                        ability={ability}
                        annotation={annotation}
                        instanceId={instanceId}
                        target={target}
                        token={token}
                        creature={creature as ResolvedCharacter}
                        targetCreature={resolvedTarget}
                        isAttackerOwner={isAttackerOwner}
                        option={option}
                    />
                );
            })}

            {(appliedInstance?.hit === "hit" || appliedInstance?.hit === "crit_hit") &&
                afterOptions?.map(option => {
                    return (
                        <AttackOptionBox
                            key={getRuleKey(option.feature) + "~~" + option.key}
                            disabled={isRolling}
                            ability={ability}
                            annotation={annotation}
                            instanceId={instanceId!}
                            target={target!}
                            token={token}
                            creature={creature as ResolvedCharacter}
                            targetCreature={resolvedTarget}
                            isAttackerOwner={isAttackerOwner}
                            option={option}
                        />
                    );
                })}

            <AnimatePresence mode="popLayout">
                {!appliedInstance?.attack && isAttackerOwner && (
                    <MotionBox
                        key="preroll_owner"
                        flexDirection="column"
                        alignItems="flex-start"
                        maxWidth="100%"
                        layout={!isDragging}
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <AnimatePresence>
                            {attackModifiers.advantages.map((o, i) => (
                                <AdvantageBox
                                    key={"adv_" + o.content}
                                    mb={2}
                                    advantage="adv"
                                    reason={o.content}
                                    condition={o.condition}
                                    maxWidth="100%"
                                />
                            ))}
                            {attackModifiers.disadvantages.map((o, i) => (
                                <AdvantageBox
                                    key={"dis_" + o.content}
                                    mb={2}
                                    advantage="dis"
                                    reason={o.content}
                                    condition={o.condition}
                                    maxWidth="100%"
                                />
                            ))}
                            {attackModifiers.extraRolls.map((o, i) => (
                                <Text key={i} mb={2}>{`${o.source ? `${o.source.name}: ` : ""}${
                                    o.roll.startsWith("-") ? o.roll : "+" + o.roll
                                }`}</Text>
                            ))}

                            {attackModifiers.autoCrit && (
                                <MotionBox
                                    layout={!isDragging}
                                    key="autocrit"
                                    mb={2}
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}>
                                    A hit is an automatic critical hit.
                                </MotionBox>
                            )}
                        </AnimatePresence>

                        <MotionBox fullWidth flexDirection="column" layout={!isDragging}>
                            <RollButtonHorizontal
                                token={token}
                                modifier={attackModifier}
                                data={{ attack: ability.attack }}
                                advantage={attackModifiers.advantage}
                                extraRolls={attackModifiers.extraRolls}
                                onIsRolling={setIsRolling}
                                onRoll={async roll => {
                                    const result = await roll.confirmed;
                                    dispatch(
                                        applyAttackRoll(campaign, location, annotation, id, instanceId, {
                                            expression: result.expression,
                                            result: result.result,
                                            terms: result.terms,
                                        })
                                    );
                                }}
                                notify={{ notSelected: annotation.id }}>
                                Make a {attackTypeToString(ability.attack)} ({formatModifier(attackModifier)})
                            </RollButtonHorizontal>
                        </MotionBox>
                    </MotionBox>
                )}

                {appliedInstance?.hit == null && !isAttackerOwner && !appliedInstance?.attack && (
                    <MotionBox
                        key="preroll_other"
                        layout={!isDragging}
                        mt={1}
                        flexDirection="column"
                        alignItems="flex-start"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        Waiting for attack roll…
                    </MotionBox>
                )}

                {appliedInstance?.hit == null && appliedInstance?.attack && (
                    <MotionBox
                        key="postroll"
                        layout={!isDragging}
                        flexDirection="column"
                        alignItems="flex-start"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        {attackTypeToString(ability.attack)}
                        <DiceRollResult roll={appliedInstance.attack} compact />
                        {role === "GM" && appliedInstance?.attack && (
                            <React.Fragment>
                                {/* The attack roll has been made, and we're the GM. We need to adjudicate on whether or not this is a hit. */}
                                {!ignoreCover && (
                                    <Box flexDirection="row" my={2}>
                                        <Button
                                            css={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                            variant={
                                                isHitOrCrit(appliedInstance.attack, targetAc + 2, ability.criticalRange)
                                                    ? "success"
                                                    : "danger"
                                            }
                                            toggled={appliedInstance.acModifier === 2}
                                            onClick={() =>
                                                setAcModifier(appliedInstance.acModifier === 2 ? undefined : 2)
                                            }>
                                            ½ cover
                                        </Button>
                                        <Button
                                            css={{
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                            }}
                                            variant={
                                                isHitOrCrit(appliedInstance.attack, targetAc + 5, ability.criticalRange)
                                                    ? "success"
                                                    : "danger"
                                            }
                                            toggled={appliedInstance.acModifier === 5}
                                            onClick={() =>
                                                setAcModifier(appliedInstance.acModifier === 5 ? undefined : 5)
                                            }>
                                            ¾ cover
                                        </Button>
                                    </Box>
                                )}

                                {ignoreCover && (
                                    <Box my={2}>The attacker ignores half cover and three-quarters cover.</Box>
                                )}

                                {attackModifiers.autoCrit && <Box my={2}>A hit is an automatic critical hit.</Box>}

                                <Text>The target's AC is {modifiedTargetAc}</Text>

                                <Box flexDirection="row" my={2}>
                                    {attackResult === "crit_hit" && (
                                        <Button
                                            variant="success"
                                            onClick={() =>
                                                dispatch(
                                                    applyAttackHit(
                                                        campaign,
                                                        location,
                                                        annotation,
                                                        id,
                                                        instanceId,
                                                        attackResult!
                                                    )
                                                )
                                            }>
                                            Confirm critical hit
                                        </Button>
                                    )}
                                    {attackResult === "hit" && (
                                        <Button
                                            variant="success"
                                            onClick={() =>
                                                dispatch(
                                                    applyAttackHit(
                                                        campaign,
                                                        location,
                                                        annotation,
                                                        id,
                                                        instanceId,
                                                        attackResult!
                                                    )
                                                )
                                            }>
                                            Confirm hit
                                        </Button>
                                    )}
                                    {attackResult === "miss" && (
                                        <Button
                                            variant="danger"
                                            onClick={() =>
                                                dispatch(
                                                    applyAttackHit(
                                                        campaign,
                                                        location,
                                                        annotation,
                                                        id,
                                                        instanceId,
                                                        attackResult!
                                                    )
                                                )
                                            }>
                                            Confirm miss
                                        </Button>
                                    )}
                                    {attackResult === "crit_miss" && (
                                        <Button
                                            variant="danger"
                                            onClick={() =>
                                                dispatch(
                                                    applyAttackHit(
                                                        campaign,
                                                        location,
                                                        annotation,
                                                        id,
                                                        instanceId,
                                                        attackResult!
                                                    )
                                                )
                                            }>
                                            Confirm critical miss
                                        </Button>
                                    )}
                                </Box>
                            </React.Fragment>
                        )}
                        {role !== "GM" && appliedInstance?.attack && (
                            <React.Fragment>
                                {/* The attack roll has been made, and we're NOT the GM. Show that we're waiting for the GM to adjudicate on whether we hit. */}
                                Waiting for GM…
                            </React.Fragment>
                        )}
                    </MotionBox>
                )}
            </AnimatePresence>
        </MotionBox>
    );
});

const DamageBox = React.forwardRef<
    HTMLDivElement,
    {
        annotation: DnD5EAnnotation;
        damageType: DamageType;
        amount?: number;
        target: DnD5EToken;
        resolvedTarget: ResolvedCharacter | ResolvedMonster;
        resistance?: number;
        setResistance: (resistance: number | undefined) => void;
        isApplied?: boolean;
        isDragging: boolean;
    }
>(
    (
        { annotation, damageType, amount, target, resolvedTarget, resistance, setResistance, isApplied, isDragging },
        ref
    ) => {
        const role = useRole();
        resistance = resistance ?? getDefaultResistance(resolvedTarget, damageType);
        amount = amount != null ? Math.floor(amount * resistance) : undefined;

        return (
            <MotionBox
                ref={ref}
                layout={isDragging ? false : "position"}
                flexDirection="column"
                alignItems="flex-start"
                initial={defaultInitial}
                animate={defaultAnimate}
                exit={defaultExit}>
                {role === "GM" && !isApplied && (
                    <React.Fragment>
                        <Text color="grayscale.2" fontSize={0}>
                            {damageType.toLocaleUpperCase()} DAMAGE
                        </Text>
                        <Box flexDirection="row" my={2}>
                            <Button
                                size="s"
                                variant="inOverlaySecondary"
                                css={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                                toggled={resistance === 2}
                                onClick={() => setResistance(resistance === 2 ? undefined : 2)}>
                                ×2
                            </Button>
                            <Button
                                size="s"
                                variant="inOverlaySecondary"
                                css={{ borderRadius: 0 }}
                                toggled={resistance === 1}
                                onClick={() => setResistance(resistance === 1 ? undefined : 1)}>
                                ×1
                            </Button>
                            <Button
                                size="s"
                                variant="inOverlaySecondary"
                                css={{ borderRadius: 0 }}
                                toggled={resistance === 0.5}
                                onClick={() => setResistance(resistance === 0.5 ? undefined : 0.5)}>
                                ×½
                            </Button>
                            <Button
                                size="s"
                                variant="inOverlaySecondary"
                                css={{ borderRadius: 0 }}
                                toggled={resistance === 0.25}
                                onClick={() => setResistance(resistance === 0.25 ? undefined : 0.25)}>
                                ×¼
                            </Button>
                            <Button
                                size="s"
                                variant="inOverlaySecondary"
                                css={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                                toggled={resistance === 0}
                                onClick={() => setResistance(resistance === 0 ? undefined : 0)}>
                                ×0
                            </Button>
                        </Box>
                    </React.Fragment>
                )}
                {amount != null && !isApplied && (
                    <Text>
                        {resolvedTarget.name} will take <b>{amount}</b> {damageType} damage.
                    </Text>
                )}
                {amount != null && isApplied && (
                    <Text>
                        {resolvedTarget.name} took <b>{amount}</b> {damageType} damage.
                    </Text>
                )}
            </MotionBox>
        );
    }
);

const TargetAbilityEffect: FunctionComponent<{
    annotation: DnD5EAnnotation;
    token: Token;
    creature?: ResolvedCharacter | ResolvedMonster;
    target: ResolvedToken<DnD5EToken>;
    resolvedTarget: ResolvedCharacter | ResolvedMonster;
    ability: Ability;
    effect: AbilityEffect<AbilityDamage>;
    effectId: string;
    instanceId: string;
    isTargetOwner: boolean;
    isAttackerOwner: boolean;
    isDragging: boolean;
}> = ({
    annotation,
    token,
    creature,
    target,
    resolvedTarget,
    ability,
    effect,
    effectId,
    instanceId,
    isTargetOwner,
    isAttackerOwner,
    isDragging,
}) => {
    const savingThrow = effect.savingThrow;
    const { campaign, location } = useValidatedLocation();
    const dispatch = useDispatch();
    const rules = useRules();

    // Use the template ID if the token is delegated to its template.
    const id = getModifyId(target);
    const appliedEffect = annotation.dnd5e.targetEffects?.[id]?.[instanceId]?.effects?.[effectId];
    const savingThrowResult = savingThrow != null ? appliedEffect?.savingThrow : undefined;

    // Need the ability DC to work out if the saving throw values are a success or failure.
    const savingThrowDc = annotation.dnd5e.savingThrowDc;
    const isSaveSuccess =
        savingThrowDc != null && savingThrowResult != null && savingThrowResult.result >= savingThrowDc;

    const excluded = isExcluded(annotation, target, ability, effectId, instanceId, campaign, location, rules);

    const effectInstance = annotation.dnd5e.effects?.[effectId];
    // let dts: DamageType[] | undefined;
    // if (effect.damage) {
    //     if (effect.damage.choice) {
    //         // Spell effect has a choice of damage types - don't recognise any until one is chosen.
    //         if (effectInstance?.damageType) {
    //             dts = [effectInstance!.damageType];
    //         } else {
    //             dts = [];
    //         }
    //     } else {
    //         dts = Object.keys(effect.damage).filter(o => damageTypes.indexOf(o as DamageType) >= 0) as DamageType[];
    //     }
    // }

    let healing = appliedEffect?.healing?.result ?? effectInstance?.healing?.result;
    if (!healing && effect.heal) {
        healing = Number(effect.heal.base);
        if (isNaN(healing)) {
            healing = undefined;
        }
    }

    if (healing != null) {
        const maxHp = resolveModifiedValue(resolvedTarget.maxHp);
        healing = Math.min(healing, maxHp - (resolvedTarget.hp ?? maxHp));
    }

    const applicableEffect = isNamedRuleRef(effect.applied) ? rules.effects.get(effect.applied) : effect.applied;

    let showSavingThrowAndDamage = !excluded;
    return (
        <Box flexDirection="row" fullWidth alignItems="flex-start">
            {!appliedEffect?.applied && !isTargettedAnnotation(annotation) && (
                <MotionBox layout={!isDragging}>
                    <Checkbox
                        checked={!excluded}
                        onChange={() => {
                            dispatch(setExclusion(campaign, location, annotation, effectId, id, instanceId, !excluded));
                        }}
                        onClick={() => {
                            dispatch(setExclusion(campaign, location, annotation, effectId, id, instanceId, !excluded));
                        }}></Checkbox>
                </MotionBox>
            )}
            <Box
                pl={appliedEffect?.applied || isTargettedAnnotation(annotation) ? 0 : 2}
                flexDirection="column"
                alignItems="flex-start"
                fullWidth
                flexGrow={1}
                flexShrink={1}>
                <AnimatePresence initial={false} mode="popLayout">
                    {excluded && (
                        <MotionText
                            key="excluded"
                            layout={!isDragging}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            Not affected.
                        </MotionText>
                    )}
                    {(effect.rollPerTarget === true ||
                        (effect.rollPerTarget == null && isTargettedAnnotation(annotation))) &&
                        showSavingThrowAndDamage && (
                            <AbilityDamageRoll
                                key="damage"
                                compact
                                annotation={annotation}
                                creature={creature}
                                ability={ability}
                                damageRolls={appliedEffect?.damage}
                                effect={effect}
                                effectId={effectId}
                                target={target}
                                resolvedTarget={resolvedTarget}
                                instanceId={instanceId}
                                token={token}
                                isDragging={isDragging}
                            />
                        )}
                    {(effect.rollPerTarget === true ||
                        (effect.rollPerTarget == null && isTargettedAnnotation(annotation))) &&
                        showSavingThrowAndDamage && (
                            <AbilityHealRoll
                                key="healing"
                                compact
                                annotation={annotation}
                                creature={creature}
                                ability={ability}
                                healingRoll={appliedEffect?.healing}
                                effect={effect}
                                effectId={effectId}
                                target={target}
                                resolvedTarget={resolvedTarget}
                                instanceId={instanceId}
                                token={token}
                                isDragging={isDragging}
                            />
                        )}
                    {showSavingThrowAndDamage && (
                        <React.Fragment key="savingthrow">
                            {savingThrow && (
                                <SavingThrowHelper
                                    isDragging={isDragging}
                                    annotation={annotation}
                                    ability={savingThrow}
                                    isAttackerOwner={isAttackerOwner}
                                    isTargetOwner={isTargetOwner}
                                    token={target}
                                    creature={resolvedTarget}
                                    dc={savingThrowDc}
                                    roll={savingThrowResult}
                                    onRoll={roll => {
                                        dispatch(
                                            applySavingThrow(
                                                campaign,
                                                location,
                                                annotation,
                                                effectId,
                                                id,
                                                instanceId,
                                                undefined,
                                                roll
                                            )
                                        );
                                    }}
                                />
                            )}
                            {applicableEffect && (!savingThrow || (savingThrowResult != null && !isSaveSuccess)) && (
                                <AppliedAbilityEffectChoicesEditor
                                    applicableEffect={applicableEffect}
                                    choices={appliedEffect?.appliedChoices}
                                    target={resolvedTarget}
                                    onChoicesUpdated={delta => {
                                        dispatch(
                                            applyAnnotationAppliedEffectChoices(
                                                campaign,
                                                location,
                                                annotation,
                                                effectId,
                                                id,
                                                instanceId,
                                                undefined,
                                                delta
                                            )
                                        );
                                    }}
                                />
                            )}
                            {/* {dts?.map(o => {
                                const damageRoll = isTargettedAnnotation(annotation)
                                    ? appliedEffect?.damage?.[o]
                                    : annotation.dnd5e.effects?.[effectId].damage?.[o];
                                const damageForType = damageRoll?.result;
                                return (
                                    <DamageBox
                                        key={o}
                                        annotation={annotation}
                                        damageType={o}
                                        amount={
                                            damageForType != null
                                                ? Math.floor(
                                                      damageForType *
                                                          (isSaveSuccess ? effect?.damage?.[o]?.onSave ?? 1 : 1)
                                                  )
                                                : undefined
                                        }
                                        target={target}
                                        resistance={appliedEffect?.resistances?.[o]}
                                        setResistance={resistance =>
                                            dispatch(
                                                applyResistance(
                                                    campaign,
                                                    location,
                                                    annotation,
                                                    effectId,
                                                    id,
                                                    instanceId,
                                                    undefined,
                                                    o,
                                                    resistance
                                                )
                                            )
                                        }
                                        resolvedTarget={resolvedTarget!}
                                        isApplied={appliedEffect?.applied}
                                        isDragging={isDragging}
                                    />
                                );
                            })} */}
                            {healing != null && appliedEffect?.applied && (
                                <Text>
                                    {resolvedTarget.name} healed <b>{healing}</b>.
                                </Text>
                            )}
                            {healing != null && !appliedEffect?.applied && (
                                <Text>
                                    {resolvedTarget.name} will heal <b>{healing}</b>.
                                </Text>
                            )}
                        </React.Fragment>
                    )}
                </AnimatePresence>
            </Box>
        </Box>
    );
};

function getTargetEffects(
    target: ResolvedToken<DnD5EToken>,
    ability: Ability | undefined,
    campaign: Campaign,
    location: Location,
    rules: CharacterRuleSet
) {
    const isTargetsTurn = location.combat?.turn === target.id;
    const effects = keyedListToKeyArray(ability?.effects);

    let creature: Creature | undefined;
    const targetEffects: { effect: AbilityEffect<AbilityDamage>; key: string }[] = [];
    if (effects) {
        for (let i = 0; i < effects.length; i++) {
            const effect = ability?.effects![effects[i]];
            if (effect && (isTargetsTurn || (!effect.trigger?.targetStartOfTurn && !effect.trigger?.targetEndOfTurn))) {
                // The effect itself can have a target filter.
                if (effect.targetFilter) {
                    if (!creature) {
                        creature = resolveTokenCreature(target, campaign, rules);
                    }

                    if (creature && !creatureMatchesFilter(creature, effect.targetFilter)) {
                        continue;
                    }
                }

                targetEffects.push({ effect, key: effects[i] });
            }
        }
    }

    return targetEffects;
}

const AnnotationTargetInstance: FunctionComponent<{
    annotation: DnD5EAnnotation;
    token: ResolvedToken<DnD5EToken>;
    creature?: ResolvedCharacter | ResolvedMonster;
    target: ResolvedToken<DnD5EToken>;
    resolvedTarget: ResolvedCharacter | ResolvedMonster;
    ability: Ability;
    targetEffects: { effect: AbilityEffect<AbilityDamage>; key: string }[];
    instanceId: string;
    isTargetOwner: boolean;
    isAttackerOwner: boolean;
    isDragging: boolean;
}> = ({
    annotation,
    token,
    creature,
    target,
    resolvedTarget,
    ability,
    targetEffects,
    instanceId,
    isTargetOwner,
    isAttackerOwner,
    isDragging,
}) => {
    const appliedInstance = annotation?.dnd5e.targetEffects?.[getModifyId(target)]?.[instanceId];

    // If the effect requires an attack before it does anything else, handle that.
    let requiresAttack = isTargettedAnnotation(annotation) && ability.attack;
    const isHit = appliedInstance?.hit === "crit_hit" || appliedInstance?.hit === "hit";
    const showEffects = !requiresAttack || isHit;
    return (
        <MotionBox layout={!isDragging} flexDirection="column" fullWidth>
            <AnimatePresence mode="popLayout">
                {requiresAttack && (
                    <AttackBox
                        key="attack"
                        annotation={annotation}
                        token={token}
                        isAttackerOwner={isAttackerOwner}
                        creature={creature}
                        target={target}
                        resolvedTarget={resolvedTarget}
                        ability={ability}
                        instanceId={instanceId}
                        isDragging={isDragging}
                    />
                )}
                {showEffects && (
                    <MotionBox
                        layout={!isDragging}
                        key="effects"
                        flexDirection="column"
                        alignItems="flex-start"
                        fullWidth
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        {targetEffects.map(o => (
                            <TargetAbilityEffect
                                key={o.key}
                                annotation={annotation}
                                creature={creature}
                                token={token}
                                target={target}
                                resolvedTarget={resolvedTarget}
                                ability={ability}
                                effect={o.effect}
                                effectId={o.key}
                                instanceId={instanceId}
                                isAttackerOwner={isAttackerOwner}
                                isTargetOwner={isTargetOwner}
                                isDragging={isDragging}
                            />
                        ))}
                    </MotionBox>
                )}
                {requiresAttack && appliedInstance?.hit != null && !isHit && (
                    <MotionBox
                        layout={isDragging ? false : "position"}
                        key="missed"
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        Missed!
                    </MotionBox>
                )}
            </AnimatePresence>
        </MotionBox>
    );
};

const AnnotationTarget: FunctionComponent<{
    annotation: DnD5EAnnotation;
    token: ResolvedToken<DnD5EToken>;
    creature?: ResolvedCharacter | ResolvedMonster;
    target: DnD5EToken;
    ability: Ability;
    targetEffects: { effect: AbilityEffect<AbilityDamage>; key: string }[];
    count: number;
    isDragging: boolean;
    isAttackerOwner: boolean;
}> = ({ annotation, target, ability, token, creature, targetEffects, count, isDragging, isAttackerOwner }) => {
    const { campaign } = useValidatedLocation();
    const rules = useRules();
    const resolvedTarget = resolveToken(campaign, target);
    const { profiles } = useProfiles(resolvedTarget.owner ? [resolvedTarget.owner] : []);
    const user = useUser();
    const role = useRole();

    const isTargetOwner = role === "GM" || resolvedTarget.owner === user.id;

    let resolvedCreature = fullResolveTokenCreature(resolvedTarget, campaign, rules);
    resolvedCreature = resolvedCreature?.transformedInto ?? resolvedCreature;

    const instances: ReactElement[] = [];
    if (resolvedCreature) {
        for (let i = 0; i < count; i++) {
            const instanceId = i.toString(10);
            if (i > 0) {
                instances.push(<Spacer key={instanceId + "_spacer"} css={{ alignSelf: "center" }} opacity={0.4} />);
            }

            instances.push(
                <AnnotationTargetInstance
                    key={instanceId}
                    instanceId={instanceId}
                    annotation={annotation}
                    ability={ability}
                    token={token}
                    creature={creature}
                    target={resolvedTarget}
                    resolvedTarget={resolvedCreature!}
                    targetEffects={targetEffects}
                    isTargetOwner={isTargetOwner}
                    isAttackerOwner={isAttackerOwner}
                    isDragging={isDragging}
                />
            );
        }
    }

    return (
        <MotionBox
            animate={{ opacity: targetEffects.length > 0 ? 1 : 0.25 }}
            flexDirection="row"
            alignItems="flex-start"
            fullWidth
            css={{ gap: theme.space[2] }}>
            <MotionBox
                layout={!isDragging}
                width={tokenImageSize}
                height={tokenImageSize}
                bg="grayscale.7"
                borderRadius={3}
                css={{ position: "relative" }}>
                <Image
                    src={resolveUri(resolvedTarget.imageUri)}
                    borderRadius={3}
                    css={{ overflow: "hidden" }}
                    responsive
                    fullWidth
                    fullHeight
                    draggable={false}
                />
                {resolvedTarget.owner && (
                    <Box
                        css={{
                            position: "absolute",
                            right: -theme.space[1],
                            bottom: -theme.space[1],
                        }}>
                        <ProfileAvatar
                            profile={profiles[resolvedTarget.owner]}
                            campaignProfile={campaign.players[resolvedTarget.owner]}
                        />
                    </Box>
                )}
            </MotionBox>
            <LobotomizedBox flexDirection="column" flexGrow={1} flexShrink={1} alignItems="flex-start" fullWidth>
                <MotionText layout={!isDragging} color="grayscale.2" fontSize={0}>
                    {resolvedCreature?.name ?? "Unnamed"}
                </MotionText>
                {instances}
            </LobotomizedBox>
        </MotionBox>
    );
};

function addDamageFromEffects(
    creature: ResolvedCharacter | ResolvedMonster,
    attacker: ResolvedCharacter | ResolvedMonster,
    ability: Ability,
    annotation: DnD5EAnnotation
): Partial<DamageTypes<{ [effectKey: string]: DiceBag }, DamageType>> | undefined {
    if (!creature.effects) {
        return;
    }

    let damageFromEffects: Partial<DamageTypes<{ [effectKey: string]: DiceBag }, DamageType>> | undefined;
    let effectKeys = keyedListToKeyArray(creature.effects);
    for (let effectKey of effectKeys) {
        const effect = creature.effects[effectKey];
        const attacks = keyedListToArray(creature === attacker ? effect.attacksBy : effect.attacksOn) as
            | AttackModifiers[]
            | undefined;
        if (attacks) {
            for (let attack of attacks) {
                if (attack && attack.damage && shouldApplyAttackModifiers(attack, attacker, ability)) {
                    for (let dt in attack.damage) {
                        let damage = attack.damage[dt];

                        // If the damage is a lookup, process it here.
                        // Lookups only exist for characters, and they only really make sense when this is a self-applied effect (otherwise
                        // how would you be sure the lookup was there?). However, when this is the case it is best to do the lookup here
                        // rather than at the time the effect is applied, as the lookup value could conceivably change in the meantime.
                        // IF we ever need lookups to work for an effect applied to a target, we'll have to revisit that then.
                        if (isCharacter(creature)) {
                            const lookup = creature.lookups?.[damage];
                            if (lookup != null) {
                                damage = lookup.value.toString();
                            }
                        }

                        // If the damage type is weapon, attempt to replace it with the weapon's damage type.
                        if (dt === "weapon") {
                            if (annotation.dnd5e.item && isCharacter(creature)) {
                                const item = creature.inventory[annotation.dnd5e.item];
                                if (item && isWeapon(item) && item.dmgType != null) {
                                    dt = item.dmgType;
                                }
                            }

                            // If we can't work out what the damage type is, something has probably gone wrong so
                            // just ignore it. The player will have to deal with it manually.
                            if (dt === "weapon") {
                                continue;
                            }
                        }

                        if (!damageFromEffects) {
                            damageFromEffects = {};
                        }

                        let existing = damageFromEffects[dt];
                        if (!existing) {
                            existing = {};
                            damageFromEffects[dt] = existing;
                        }

                        existing[effectKey] = parseDiceBag(damage);
                    }
                }
            }
        }
    }

    return damageFromEffects;
}

const DamageFromEffect: FunctionComponent<{
    effect: AppliedAbilityEffect & { feature?: Feature; spell?: Spell };
    diceBag: DiceBag;
}> = ({ effect, diceBag }) => {
    const feature = effect.feature;
    const spell = effect.spell;

    // TODO: If we had a reference back to the relevant spell or feature here, we could show details for it.
    // That's probably a good thing to have on an appliedeffect.
    // Do we want a single prop that we can find the relevant spell/feature for by trying one at a time, or do
    // we want a different prop for spells than we do for features (for e.g.)?
    return (
        <Box flexDirection="row">
            {feature == null && spell == null && <Text>{effect.name}</Text>}
            {feature && <Markdown>{`:feature[${feature.name}]{id="${getRuleKey(feature)}"}`}</Markdown>}
            {spell && <Markdown>{`:spell[${spell.name}]{id="${getRuleKey(spell)}"}`}</Markdown>}
            <Text ml={2}>{diceBagToExpression(diceBag, "+")}</Text>
        </Box>
    );
};

const AbilityHealRoll = React.forwardRef<
    HTMLDivElement,
    {
        annotation: DnD5EAnnotation;
        creature?: ResolvedCharacter | ResolvedMonster;
        ability: Ability;
        compact?: boolean;
        healingRoll?: DiceRoll;
        effect: AbilityEffect<AbilityDamage>;
        effectId: string;
        target?: DnD5EToken;
        resolvedTarget?: ResolvedCharacter | ResolvedMonster;
        instanceId?: string;
        token: Token;
        isDragging: boolean;
    }
>(
    (
        {
            annotation,
            creature,
            ability,
            compact,
            healingRoll,
            effect,
            effectId,
            target,
            resolvedTarget,
            instanceId,
            token,
            isDragging,
        },
        ref
    ) => {
        const { campaign, location } = useValidatedLocation();
        const { setDice } = useDiceBag();
        const dispatch = useDispatch();
        const role = useRole();
        const user = useUser();

        if (!effect.heal) {
            return <React.Fragment></React.Fragment>;
        }

        let bag: DiceBag;
        if (effect.heal) {
            let baseRoll = effect.heal.base;
            if (annotation.dnd5e.castAbility && creature) {
                // Calculate the actual cast modifier and substitute it into the base damage roll.
                const spellcastingMod = modifierFromAbilityScore(
                    resolveModifiedValue(creature[annotation.dnd5e.castAbility])
                );
                baseRoll = baseRoll.replaceAll("{mod}", spellcastingMod.toString(10));
            }

            bag = parseDiceBag(baseRoll, {
                location: location.id,
                token: annotation.tokenId,
                notify: { notSelected: annotation.id },
                data: {
                    isHeal: true,
                },
            });

            // Add any extra damage from casting above the minimum level.
            let extraBag: DiceBag | undefined;
            if (isSpell(ability)) {
                const spellHealing = (effect as SpellEffect).heal;
                const extraLevels = annotation.dnd5e.castAt != null ? annotation.dnd5e.castAt - ability.level : 0;
                if (extraLevels > 0 && spellHealing) {
                    let perLevelRoll: string | undefined;
                    if (spellHealing.perLevel != null) {
                        perLevelRoll = spellHealing.perLevel;
                    }

                    if (perLevelRoll != null) {
                        const perLevelBag = parseDiceBag(perLevelRoll);
                        if (perLevelBag != null) {
                            extraBag = perLevelBag;
                            for (let i = 1; i < extraLevels; i++) {
                                extraBag = addDiceBags(extraBag, perLevelBag);
                            }
                        } else {
                            console.error(`Could not parse per level dice roll ${spellHealing.perLevel}.`);
                        }
                    }
                }
            }

            if (extraBag) {
                bag = addDiceBags(bag, extraBag);
            }
        } else {
            bag = {};
        }

        const requiresRoll = diceBagRequiresRoll(bag);
        const healing = healingRoll?.result;

        let attacker = getResolvedToken(campaign, location.id, annotation.tokenId);
        const isOwner = !attacker || (!attacker.owner && role === "GM") || attacker.owner === user.id;
        return (
            <MotionBox
                ref={ref}
                layout={isDragging ? false : "position"}
                fullWidth
                flexDirection="column"
                alignItems="flex-start">
                <AnimatePresence>
                    {healing != null && requiresRoll && (
                        <MotionBox key="dmgroll" initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
                            <DiceRollResult roll={healingRoll!} compact={compact} />
                        </MotionBox>
                    )}
                    {healing != null && !requiresRoll && <Text fontSize={5}>{bag.modifier ?? 0}</Text>}
                    {healing != null && !compact && (
                        <Text mt={2} key="dmgtxt">
                            <b>{healing}</b>
                        </Text>
                    )}
                    {healing == null && isOwner && (
                        <Button
                            key="dmgbtn"
                            fullWidth
                            onClick={e => {
                                e.stopPropagation();
                                e.preventDefault();

                                bag.onRolled = async roll => {
                                    // Set the results to the annotation.
                                    var result = await roll.confirmed;
                                    dispatch(
                                        applyHealing(
                                            campaign,
                                            location,
                                            annotation,
                                            effectId,
                                            target ? getModifyId(target) : undefined,
                                            instanceId,
                                            undefined,
                                            {
                                                expression: result.expression,
                                                result: result.result,
                                                terms: result.terms,
                                            }
                                        )
                                    );
                                };
                                setDice(bag);
                            }}>
                            Roll {diceBagToExpression(bag)} healing
                        </Button>
                    )}
                    {healing == null && !isOwner && (
                        <Text mb={2}>Waiting for healing roll ({diceBagToExpression(bag)})…</Text>
                    )}
                </AnimatePresence>
            </MotionBox>
        );
    }
);

const AbilityDamageRoll = React.forwardRef<
    HTMLDivElement,
    {
        annotation: DnD5EAnnotation;
        creature?: ResolvedCharacter | ResolvedMonster;
        ability: Ability;
        compact?: boolean;
        damageRolls: Partial<DamageTypes<DiceRoll>> | undefined;
        effect: AbilityEffect<AbilityDamage>;
        effectId: string;
        target?: ResolvedToken<DnD5EToken>;
        resolvedTarget?: ResolvedCharacter | ResolvedMonster;
        instanceId?: string;
        token: Token;
        isDragging: boolean;
    }
>(
    (
        {
            annotation,
            creature,
            ability,
            compact,
            damageRolls,
            effect,
            effectId,
            target,
            resolvedTarget,
            instanceId,
            token,
            isDragging,
        },
        ref
    ) => {
        const { campaign, location } = useValidatedLocation();
        const { setDice } = useDiceBag();
        const dispatch = useDispatch();
        const role = useRole();
        const user = useUser();
        const rules = useRules();

        const effectInstance = annotation.dnd5e.effects?.[effectId];

        let doubleDice = false;
        let attackOptions: (ResolvedBeforeAttackOption | ResolvedAfterAttackOption)[] = [];
        let appliedInstance: AbilityInstanceResult | undefined;
        if (target && instanceId) {
            const id = getModifyId(target);
            appliedInstance = annotation.dnd5e.targetEffects?.[id]?.[instanceId];

            if (isCharacter(creature)) {
                if (appliedInstance?.options) {
                    for (let featureKey in appliedInstance.options) {
                        // Find the option that matches the key
                        if (creature.beforeAttack) {
                            for (let option of creature.beforeAttack) {
                                if (
                                    getRuleKey(option.feature) === featureKey &&
                                    appliedInstance!.options![featureKey][option.key]
                                ) {
                                    // This option is turned on for this attack.
                                    attackOptions.push(option);
                                }
                            }
                        }

                        if (creature.afterAttack) {
                            for (let option of creature.afterAttack) {
                                if (
                                    getRuleKey(option.feature) === featureKey &&
                                    appliedInstance!.options![featureKey][option.key]
                                ) {
                                    // This option is turned on for this attack.
                                    attackOptions.push(option);
                                }
                            }
                        }
                    }
                }
            }

            doubleDice = appliedInstance?.hit === "crit_hit";
        }

        // If we're choosing a damage type, then there's no damage types until one is chosen, and then the types are that type.
        let dts: DamageType[] | undefined;
        if (effect.damage) {
            if (effectInstance?.damageType) {
                dts = [effectInstance!.damageType];
            } else {
                dts = Object.keys(effect.damage).filter(o => damageTypes.indexOf(o as DamageType) >= 0) as DamageType[];
            }
        }

        // Make sure any damage types from attack options are recognised.
        for (let attackOption of attackOptions) {
            if (attackOption.damage) {
                const results = appliedInstance?.options?.[getRuleKey(attackOption.feature)]?.[attackOption.key];
                if (isAttackOptionComplete(attackOption, "beforeDamage", rules, results)) {
                    for (let dt of damageTypes) {
                        if (attackOption.damage[dt]) {
                            if (!dts) {
                                dts = [dt];
                            } else if (dts.indexOf(dt) < 0) {
                                dts.push(dt);
                            }
                        }
                    }
                }
            }
        }

        // Check for any effects on the source or target that could affect the attack.
        let damageFromSourceEffects = creature
            ? addDamageFromEffects(creature, creature, ability, annotation)
            : undefined;
        if (damageFromSourceEffects) {
            const dfets = Object.keys(damageFromSourceEffects) as DamageType[];
            if (dts) {
                for (let dfe of dfets) {
                    if (dts.indexOf(dfe) < 0) {
                        dts.push(dfe);
                    }
                }
            } else {
                dts = dfets;
            }
        }

        let damageFromTargetEffects =
            creature && resolvedTarget
                ? addDamageFromEffects(resolvedTarget, creature, ability, annotation)
                : undefined;
        if (damageFromTargetEffects) {
            const dfets = Object.keys(damageFromTargetEffects) as DamageType[];
            if (dts) {
                for (let dfe of dfets) {
                    if (dts.indexOf(dfe) < 0) {
                        dts.push(dfe);
                    }
                }
            } else {
                dts = dfets;
            }
        }

        let attacker = getResolvedToken(campaign, location.id, annotation.tokenId);
        const isOwner = !attacker || role === "GM" || attacker.owner === user.id;

        return (
            <MotionLobotomizedBox
                mb={2}
                flexDirection="column"
                fullWidth
                initial={defaultInitial}
                animate={defaultAnimate}
                exit={defaultExit}>
                {dts?.map(dt => {
                    const abilityDamage = effect.damage?.[dt];
                    const damageRoll = damageRolls?.[dt];
                    let damage = damageRoll?.result;

                    let bag: DiceBag;
                    if (abilityDamage) {
                        let baseRoll = abilityDamage.base;
                        if (annotation.dnd5e.castAbility && creature) {
                            // Calculate the actual cast modifier and substitute it into the base damage roll.
                            const spellcastingMod = modifierFromAbilityScore(
                                resolveModifiedValue(creature[annotation.dnd5e.castAbility])
                            );
                            baseRoll = baseRoll.replaceAll("{mod}", spellcastingMod.toString(10));
                        }

                        bag = parseDiceBag(baseRoll, {
                            location: location.id,
                            token: annotation.tokenId,
                            notify: { notSelected: annotation.id },
                            data: {
                                dmgType: dt,
                            },
                        });
                    } else {
                        bag = {
                            options: {
                                location: location.id,
                                token: annotation.tokenId,
                                notify: { notSelected: annotation.id },
                                data: {
                                    dmgType: dt,
                                },
                            },
                        };
                    }

                    // Add any extra damage from attack options.
                    for (let option of attackOptions) {
                        if (option.damage != null) {
                            const results = appliedInstance?.options?.[getRuleKey(option.feature)]?.[option.key];

                            // TODO: This applies to ALL damage types - it should only apply to the main one for the effect.
                            // Can we get that from effectInstance.damageType, or do we need to go looking for the weapon?
                            const weaponDamage = option.damage["weapon"];
                            if (
                                weaponDamage != null &&
                                isAttackOptionComplete(option, "beforeDamage", rules, results)
                            ) {
                                const dmg = evaluateAttackOptionDamage(
                                    creature as ResolvedCharacter,
                                    weaponDamage,
                                    resolvedTarget,
                                    results
                                );
                                if (dmg) {
                                    const optionBag = parseDiceBag(dmg);
                                    bag = addDiceBags(bag, optionBag);
                                }
                            }

                            const dmgOfType = option.damage[dt];
                            if (dmgOfType != null && isAttackOptionComplete(option, "beforeDamage", rules, results)) {
                                const dmg = evaluateAttackOptionDamage(
                                    creature as ResolvedCharacter,
                                    dmgOfType,
                                    resolvedTarget,
                                    results
                                );
                                if (dmg) {
                                    const optionBag = parseDiceBag(dmg);
                                    bag = addDiceBags(bag, optionBag);
                                }
                            }
                        }
                    }

                    // Add any extra damage from effects.
                    const sourceEffectDamage = damageFromSourceEffects?.[dt];
                    const sourceEffectDamageKeys = sourceEffectDamage ? Object.keys(sourceEffectDamage) : undefined;
                    if (sourceEffectDamageKeys != null) {
                        for (let sourceEffectKey of sourceEffectDamageKeys) {
                            bag = addDiceBags(bag, sourceEffectDamage![sourceEffectKey]);
                        }
                    }

                    const targetEffectDamage = damageFromTargetEffects?.[dt];
                    const targetEffectDamageKeys = targetEffectDamage ? Object.keys(targetEffectDamage) : undefined;
                    if (targetEffectDamageKeys != null) {
                        for (let targetEffectKey of targetEffectDamageKeys) {
                            bag = addDiceBags(bag, targetEffectDamage![targetEffectKey]);
                        }
                    }

                    // Add any extra damage from casting above the minimum level.
                    let extraBag: DiceBag | undefined;
                    if (isSpell(ability)) {
                        const spellDamage = abilityDamage as SpellDamage;
                        const spellEffect = effect as SpellEffect;
                        const extraLevels =
                            annotation.dnd5e.castAt != null ? annotation.dnd5e.castAt - ability.level : 0;
                        if (extraLevels > 0) {
                            let perLevelRoll: string | undefined;
                            if (spellDamage.perLevel != null) {
                                perLevelRoll = spellDamage.perLevel;
                            } else if (
                                spellEffect.damage!.perLevel &&
                                (effectInstance?.perLevelType === dt || effectInstance?.damageType === dt)
                            ) {
                                perLevelRoll = spellEffect.damage!.perLevel.amount;
                            }

                            if (perLevelRoll != null) {
                                const perLevelBag = parseDiceBag(perLevelRoll);
                                if (perLevelBag != null) {
                                    extraBag = perLevelBag;
                                    for (let i = 1; i < extraLevels; i++) {
                                        extraBag = addDiceBags(extraBag, perLevelBag);
                                    }
                                } else {
                                    console.error(`Could not parse per level dice roll ${spellDamage.perLevel}.`);
                                }
                            }
                        }
                    }

                    if (extraBag) {
                        bag = addDiceBags(bag, extraBag);
                    }

                    const criticalHitType = (campaign as DnD5ECampaign).dnd5e?.criticalHitType;
                    if (doubleDice) {
                        if (criticalHitType === "double_result") {
                            bag.diceModifier = "*2";
                        } else {
                            bag = visitDiceBagTerms(bag, o => {
                                return Object.assign({}, o, { amount: o.amount * 2 });
                            });
                        }
                    }

                    const requiresRoll = diceBagRequiresRoll(bag);

                    const appliedEffect = appliedInstance?.effects?.[effectId];
                    const savingThrowResult = effect.savingThrow != null ? appliedEffect?.savingThrow : undefined;
                    const savingThrowDc = annotation.dnd5e.savingThrowDc;
                    const isSaveSuccess =
                        savingThrowDc != null && savingThrowResult != null && savingThrowResult.result >= savingThrowDc;

                    // TODO: Set the disabled property on AttackOptionBox while rolling.
                    return (
                        <MotionBox
                            layout={isDragging ? false : "position"}
                            key={dt}
                            fullWidth
                            flexDirection="column"
                            position="relative"
                            alignItems="flex-start">
                            <AnimatePresence mode="popLayout">
                                {damage != null && requiresRoll && (
                                    <MotionBox
                                        key="dmgroll"
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}>
                                        <DiceRollResult roll={damageRoll!} compact={compact} />
                                    </MotionBox>
                                )}
                                {damage != null && !requiresRoll && <Text fontSize={5}>{bag.modifier ?? 0}</Text>}
                                {damage != null && !compact && (
                                    <Text mt={2} key="dmgtxt">
                                        <b>{damage}</b> {dt} damage
                                    </Text>
                                )}
                                {damage == null && isOwner && (
                                    <MotionBox
                                        key="dmgbtn"
                                        fullWidth
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}>
                                        <Button
                                            fullWidth
                                            onClick={e => {
                                                e.stopPropagation();
                                                e.preventDefault();

                                                bag.onRolled = async roll => {
                                                    // Set the results to the annotation.
                                                    var result = await roll.confirmed;
                                                    dispatch(
                                                        applyDamage(
                                                            campaign,
                                                            location,
                                                            annotation,
                                                            effectId,
                                                            target ? getModifyId(target) : undefined,
                                                            instanceId,
                                                            undefined,
                                                            {
                                                                expression: result.expression,
                                                                result: result.result,
                                                                terms: result.terms,
                                                            },
                                                            (result as DamageRollLogEntry).data.dmgType
                                                        )
                                                    );
                                                };
                                                setDice(bag);
                                            }}>
                                            Roll {diceBagToExpression(bag)} {dt} damage
                                        </Button>
                                    </MotionBox>
                                )}
                                {damage == null && !isOwner && (
                                    <Text mb={2}>
                                        Waiting for damage roll ({diceBagToExpression(bag)} {dt})…
                                    </Text>
                                )}
                                {sourceEffectDamageKeys?.map(o => (
                                    <DamageFromEffect
                                        key={o}
                                        effect={creature!.effects![o]}
                                        diceBag={sourceEffectDamage![o]}
                                    />
                                ))}
                                {targetEffectDamageKeys?.map(o => (
                                    <DamageFromEffect
                                        key={o}
                                        effect={resolvedTarget!.effects![o]}
                                        diceBag={targetEffectDamage![o]}
                                    />
                                ))}
                                {target && (
                                    <DamageBox
                                        annotation={annotation}
                                        damageType={dt}
                                        amount={
                                            damageRoll?.result != null
                                                ? Math.floor(
                                                      damageRoll.result *
                                                          (isSaveSuccess ? effect?.damage?.[dt]?.onSave ?? 1 : 1)
                                                  )
                                                : undefined
                                        }
                                        target={target}
                                        resistance={appliedEffect?.resistances?.[dt]}
                                        setResistance={resistance =>
                                            dispatch(
                                                applyResistance(
                                                    campaign,
                                                    location,
                                                    annotation,
                                                    effectId,
                                                    target,
                                                    instanceId,
                                                    undefined,
                                                    dt,
                                                    resistance
                                                )
                                            )
                                        }
                                        resolvedTarget={resolvedTarget!}
                                        isApplied={appliedEffect?.applied}
                                        isDragging={isDragging}
                                    />
                                )}
                            </AnimatePresence>
                        </MotionBox>
                    );
                })}
            </MotionLobotomizedBox>
        );
    }
);

const OwnerEffect: FunctionComponent<{
    annotation: DnD5EAnnotation;
    creature?: ResolvedCharacter | ResolvedMonster;
    ability: Ability;
    effect: AbilityEffect<AbilityDamage>;
    effectId: string;
    token: Token;
    isDragging: boolean;
}> = ({ annotation, creature, ability, effect, effectId, token, isDragging }) => {
    const { campaign, location } = useValidatedLocation();
    const dispatch = useDispatch();

    const effectInstance = annotation.dnd5e.effects?.[effectId];

    const damageTypeOptions = effect.damage?.choice;
    const chooseDamageType = damageTypeOptions != null && !effectInstance?.damageType;

    // If we're choosing a damage type, we don't need to choose the per level type, as the spell only actually has that chosen type.
    const perLevelOptions =
        chooseDamageType || effectInstance?.damageType
            ? undefined
            : isSpell(ability)
            ? (effect as SpellEffect).damage?.perLevel?.choice
            : undefined;
    const choosePerLevelOption =
        perLevelOptions != null &&
        !effectInstance?.perLevelType &&
        annotation.dnd5e.castAt != null &&
        annotation.dnd5e.castAt > (ability as Spell).level;

    return (
        <AnimatePresence mode="wait">
            {chooseDamageType && (
                <MotionBox
                    key="dmgtype"
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}
                    flexDirection="column">
                    Choose the damage type for the spell:
                    <LobotomizedBox fullWidth mt={2} flexDirection="row" justifyContent="flex-start">
                        {damageTypeOptions!.map(o => (
                            <Button
                                key={o}
                                onClick={e => {
                                    dispatch(chooseAnnotationDamageType(campaign, location, annotation, effectId, o));
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}>{`${o.substring(0, 1).toUpperCase()}${o.substring(1)}`}</Button>
                        ))}
                    </LobotomizedBox>
                </MotionBox>
            )}

            {choosePerLevelOption && !chooseDamageType && (
                <MotionBox
                    key="dmgtypeperlevel"
                    initial={defaultInitial}
                    animate={defaultAnimate}
                    exit={defaultExit}
                    flexDirection="column">
                    Choose the damage type to do an extra {(effect as SpellEffect).damage!.perLevel!.amount} damage:
                    <LobotomizedBox fullWidth mt={2} flexDirection="row" justifyContent="flex-start">
                        {perLevelOptions!.map(o => (
                            <Button
                                key={o}
                                onClick={e => {
                                    dispatch(choosePerLevelDamageType(campaign, location, annotation, effectId, o));
                                    e.stopPropagation();
                                    e.preventDefault();
                                }}>{`${o.substring(0, 1).toUpperCase()}${o.substring(1)}`}</Button>
                        ))}
                    </LobotomizedBox>
                </MotionBox>
            )}

            {!choosePerLevelOption &&
                !chooseDamageType &&
                (effect.rollPerTarget === false ||
                    (effect.rollPerTarget == null && !isTargettedAnnotation(annotation))) && (
                    <AbilityDamageRoll
                        key="damage"
                        creature={creature}
                        annotation={annotation}
                        ability={ability}
                        damageRolls={annotation.dnd5e.effects?.[effectId]?.damage}
                        effect={effect}
                        effectId={effectId}
                        token={token}
                        isDragging={isDragging}
                    />
                )}

            {(effect.rollPerTarget === false ||
                (effect.rollPerTarget == null && !isTargettedAnnotation(annotation))) && (
                <AbilityHealRoll
                    key="healing"
                    creature={creature}
                    annotation={annotation}
                    ability={ability}
                    healingRoll={annotation.dnd5e.effects?.[effectId]?.healing}
                    effect={effect}
                    effectId={effectId}
                    token={token}
                    isDragging={isDragging}
                />
            )}
        </AnimatePresence>
    );
};

const OwnerEffects: FunctionComponent<{
    annotation: DnD5EAnnotation;
    creature?: ResolvedCharacter | ResolvedMonster;
    ability: Ability;
    targets: {
        target: DnD5EToken;
        effects: { effect: AbilityEffect<AbilityDamage>; key: string }[];
        count: number;
    }[];
    token: Token;
    isDragging: boolean;
}> = ({ annotation, ability, targets, creature, token, isDragging }) => {
    const { campaign, location } = useValidatedLocation();
    const rules = useRules();

    // Check that there is at least one target that is not excluded from all effects.
    const finalEffectKeys: string[] = [];
    for (let target of targets) {
        for (let e of target.effects) {
            for (let i = 0; i < target.count; i++) {
                const excluded = isExcluded(
                    annotation,
                    target.target,
                    ability,
                    e.key,
                    i.toString(10),
                    campaign,
                    location,
                    rules
                );
                if (!excluded) {
                    // Only include effects that aren't rolled per target in the owner effects area.
                    const effect = ability.effects?.[e.key];
                    if (
                        effect &&
                        (effect.rollPerTarget === false ||
                            (effect.rollPerTarget == null && !isTargettedAnnotation(annotation)))
                    ) {
                        finalEffectKeys.push(e.key);
                    }
                }
            }
        }
    }

    const keys = keyedListToKeyArray(ability.effects)?.filter(o => finalEffectKeys.indexOf(o) >= 0);
    return (
        <MotionBox layout={isDragging ? false : "position"} flexDirection="column" alignItems="flex-start">
            <Heading as="h6">Effects</Heading>
            <AnimatePresence>
                {finalEffectKeys.length > 0 && (
                    <ul css={{ ">li:not(:last-child)": { marginBottom: theme.space[2] } }}>
                        {keys?.map((k, i) => (
                            <li key={k}>
                                <OwnerEffect
                                    annotation={annotation}
                                    creature={creature}
                                    ability={ability}
                                    effect={ability.effects![k]}
                                    effectId={k}
                                    token={token}
                                    isDragging={isDragging}
                                />
                            </li>
                        ))}
                    </ul>
                )}
                {finalEffectKeys.length === 0 && (
                    <MotionText initial={defaultInitial} animate={defaultAnimate} exit={defaultExit}>
                        {targets.length > 0 || !ability.effects
                            ? `See ${isSpell(ability) ? "spell" : "ability"} description for effects.`
                            : `The ${
                                  isSpell(ability) ? "spell" : "ability"
                              } has no effect as there are no valid targets.`}
                    </MotionText>
                )}
            </AnimatePresence>
        </MotionBox>
    );
};

interface AnnotationAdornerContentProps {
    annotation: DnD5EAnnotation;
    token?: ResolvedToken<WithOverride<DnD5EToken>>;
    bounds: LocalRect;
    attacker?: ResolvedMonster | ResolvedCharacter;
    ability?: Ability;
}

// TODO: Revisit the memo here - we're passing in bounds now, so maybe it's not a good idea? the bounds obj will be different every time.
const AnnotationAdornerContent = React.memo<AnnotationAdornerContentProps>(
    ({ annotation, token, bounds, attacker, ability }) => {
        const rules = useRules();
        const { campaign, location } = useValidatedLocation();
        const clipper = useClipper();
        const role = useRole();
        const grid = useLocalGrid();
        const user = useUser();
        const { tokenOverrides } = useTokenOverrides();
        const dispatch = useDispatch();
        const annotationCache = useAnnotationCache();
        const { session } = useSession();

        // let creature = isDnD5EToken(token) ? fullResolveTokenCreature(token, campaign, rules) : undefined;
        // const ability =
        //     getAbilityForCreature(annotation, creature, rules) ??
        //     getAbilityForCreature(annotation, creature?.transformedInto, rules);
        // creature = creature?.transformedInto ?? creature;

        // If the ability has an area, we might find targets via its coverage.
        const coverage = clipper
            ? annotationCache.getGridPoints(annotation, campaign, location, grid, tokenOverrides, clipper)
            : undefined;
        let targetTokens = coverage
            ? getTargets(coverage, campaign, location, grid, role).map(o => ({
                  target: o,
                  count: 1,
              }))
            : undefined;

        // If the ability is targetted, we can get the targets directly that way.
        if (isTargettedAnnotation(annotation)) {
            if (!targetTokens) {
                targetTokens = [];
            }

            for (let targetId in annotation.targets) {
                const targetToken = location.tokens[targetId];
                if (isDnD5EToken(targetToken)) {
                    targetTokens.push({
                        target: resolveToken(campaign, targetToken),
                        count: annotation.targets[targetId],
                    });
                }
            }
        }

        const targets: {
            target: DnD5EToken;
            effects: { effect: SpellEffect; key: string }[];
            count: number;
        }[] = [];
        if (targetTokens) {
            for (let i = 0; i < targetTokens?.length; i++) {
                const effects = getTargetEffects(targetTokens[i].target, ability, campaign, location, rules);
                targets.push({
                    target: targetTokens[i].target,
                    effects: effects,
                    count: targetTokens[i].count,
                });
            }
        }

        const abilityEffectKeys = keyedListToKeyArray(ability?.effects);
        const allApplied = abilityEffectKeys?.every(o => {
            return targets.every(target => {
                // Get the results for this target.
                const t = annotation.dnd5e.targetEffects?.[getModifyId(target.target)];
                if (!t) {
                    return false;
                }

                // Check every instance for this target to make sure the effect is applied in each.
                for (let instanceId in t) {
                    if (!t[instanceId]?.effects?.[o]?.applied) {
                        return false;
                    }
                }

                return true;
            });
        });

        const hasDuration = !!ability?.duration;
        const canApply =
            (!hasDuration || !allApplied) &&
            abilityEffectKeys?.every(o => {
                // Targets is only undefined if clipper has not loaded yet.
                if (!targets) {
                    return false;
                }

                // Allow if there are no targets - hey, it's their choice.
                if (targets.length === 0) {
                    return true;
                }

                const abilityEffect = ability!.effects![o];
                if (abilityEffect.damage) {
                    // If we're waiting for the damage to be rolled, then we can't apply yet.
                    const effectInstance = annotation.dnd5e.effects?.[o];
                    const damageToApply = effectInstance?.damage;

                    if (abilityEffect.damage.choice) {
                        // There's a choice of damage types. If we haven't chosen, then we definitely can't apply.
                        if (!effectInstance?.damageType) {
                            return false;
                        }

                        if (!damageToApply?.[effectInstance!.damageType]) {
                            return false;
                        }
                    } else {
                        const spellDamageTypes = Object.keys(abilityEffect.damage).filter(
                            o => damageTypes.indexOf(o as DamageType) >= 0
                        ) as DamageType[];

                        // No choice of damage types, check each one.
                        if (
                            abilityEffect.rollPerTarget === true ||
                            (abilityEffect.rollPerTarget == null && isTargettedAnnotation(annotation))
                        ) {
                            const allDamageRolled = targets.every(target => {
                                if (abilityEffect.targetFilter) {
                                    const creature = resolveTokenCreature(target.target, campaign, rules);
                                    if (creature && !creatureMatchesFilter(creature, abilityEffect.targetFilter)) {
                                        return true;
                                    }
                                }

                                // Get the results for this target.
                                const t = annotation.dnd5e.targetEffects?.[getModifyId(target.target)];
                                if (!t) {
                                    return false;
                                }

                                // Check every instance for this target to make sure the damage is rolled in each.
                                for (let i = 0; i < target.count; i++) {
                                    const instanceId = i.toString(10);
                                    const abilityInstanceResult = t[instanceId];

                                    // Targetted abilities may have to do attack rolls, so we need to take that into account.
                                    if (ability!.attack) {
                                        if (
                                            abilityInstanceResult?.hit != null &&
                                            abilityInstanceResult?.hit !== "crit_hit" &&
                                            abilityInstanceResult?.hit !== "hit"
                                        ) {
                                            // The attack missed, so the instance is considered completed immediately.
                                            continue;
                                        } else if (abilityInstanceResult == null) {
                                            // The attack hasn't been rolled yet, so we definitely can't apply.
                                            return false;
                                        }
                                    }

                                    // Attack options can have stuff that needs to be dealt with too.
                                    if (isCharacter(attacker)) {
                                        if (attacker.beforeAttack) {
                                            for (let beforeAttack of attacker.beforeAttack) {
                                                if (
                                                    !isAttackOptionComplete(
                                                        beforeAttack,
                                                        "all",
                                                        rules,
                                                        abilityInstanceResult?.options?.[
                                                            getRuleKey(beforeAttack.feature)
                                                        ]?.[beforeAttack.key]
                                                    )
                                                ) {
                                                    return false;
                                                }
                                            }
                                        }

                                        if (attacker.afterAttack) {
                                            for (let afterAttack of attacker.afterAttack) {
                                                if (
                                                    !isAttackOptionComplete(
                                                        afterAttack,
                                                        "all",
                                                        rules,
                                                        abilityInstanceResult?.options?.[
                                                            getRuleKey(afterAttack.feature)
                                                        ]?.[afterAttack.key]
                                                    )
                                                ) {
                                                    return false;
                                                }
                                            }
                                        }
                                    }

                                    // The isNaN here is to filter out ability damage that does not require a roll - i.e. the damage is deterministically
                                    // a simple value.
                                    const abilityEffectResult = abilityInstanceResult?.effects?.[o];
                                    const damageToApply = abilityEffectResult?.damage;
                                    if (
                                        spellDamageTypes.some(
                                            dt =>
                                                damageToApply?.[dt] == null &&
                                                isNaN(Number(abilityEffect.damage![dt]!.base))
                                        )
                                    ) {
                                        return false;
                                    }
                                }

                                return true;
                            });

                            if (!allDamageRolled) {
                                return false;
                            }
                        } else {
                            if (spellDamageTypes.some(dt => damageToApply?.[dt] == null)) {
                                // At least one damage type hasn't been rolled yet, so we can't apply unless every target
                                // is excluded.
                                if (
                                    !targets.every(target => {
                                        for (let i = 0; i < target.count; i++) {
                                            if (
                                                !isExcluded(
                                                    annotation,
                                                    target.target,
                                                    ability!,
                                                    o,
                                                    i.toString(),
                                                    campaign,
                                                    location,
                                                    rules
                                                )
                                            ) {
                                                return false;
                                            }
                                        }

                                        return true;
                                    })
                                ) {
                                    return false;
                                }
                            }
                        }
                    }
                }

                if (abilityEffect.heal) {
                    if (
                        abilityEffect.rollPerTarget === true ||
                        (abilityEffect.rollPerTarget == null && isTargettedAnnotation(annotation))
                    ) {
                        // Healing is being rolled per-target, so make sure that all the targets have rolled their healing.
                        const allHealingRolled = targets.every(target => {
                            if (abilityEffect.targetFilter) {
                                const creature = resolveTokenCreature(target.target, campaign, rules);
                                if (creature && !creatureMatchesFilter(creature, abilityEffect.targetFilter)) {
                                    return true;
                                }
                            }

                            // Get the results for this target.
                            const t = annotation.dnd5e.targetEffects?.[getModifyId(target.target)];
                            if (!t) {
                                return false;
                            }

                            // Check every instance for this target to make sure the healing is rolled in each.
                            for (let i = 0; i < target.count; i++) {
                                const instanceId = i.toString(10);
                                const abilityInstanceResult = t[instanceId];

                                // The isNaN here is to filter out ability damage that does not require a roll - i.e. the damage is deterministically
                                // a simple value.
                                const abilityEffectResult = abilityInstanceResult?.effects?.[o];
                                const healingToApply = abilityEffectResult?.healing;
                                if (healingToApply == null && isNaN(Number(abilityEffect.heal!.base))) {
                                    return false;
                                }
                            }

                            return true;
                        });

                        if (!allHealingRolled) {
                            return false;
                        }
                    } else {
                        // Healing is rolled for the entire ability.
                        const effectInstance = annotation.dnd5e.effects?.[o];
                        if (!effectInstance?.healing && isNaN(Number(abilityEffect.heal!.base))) {
                            return false;
                        }
                    }
                }

                if (abilityEffect.savingThrow && targets.length) {
                    // Check to see whether every target has rolled their saving throw.
                    // If they haven't, we can't apply.
                    const allSavingThrowsRolled = targets.every(target => {
                        if (target.effects.length === 0) {
                            return true;
                        }

                        if (abilityEffect.targetFilter) {
                            const creature = resolveTokenCreature(target.target, campaign, rules);
                            if (creature && !creatureMatchesFilter(creature, abilityEffect.targetFilter)) {
                                return true;
                            }
                        }

                        const t = annotation.dnd5e.targetEffects?.[getModifyId(target.target)];
                        if (!t) {
                            // If the target is excluded, this is still ok.
                            for (let i = 0; i < target.count; i++) {
                                if (
                                    !isExcluded(
                                        annotation,
                                        target.target,
                                        ability!,
                                        o,
                                        i.toString(),
                                        campaign,
                                        location,
                                        rules
                                    )
                                ) {
                                    return false;
                                }
                            }

                            return true;
                        }

                        for (let instanceId in t) {
                            if (
                                isExcluded(
                                    annotation,
                                    target.target,
                                    ability!,
                                    o,
                                    instanceId,
                                    campaign,
                                    location,
                                    rules
                                )
                            ) {
                                return true;
                            }

                            const targetSave = t[instanceId]?.effects?.[o]?.savingThrow;
                            if (targetSave == null) {
                                return false;
                            }
                        }

                        return true;
                    });

                    if (!allSavingThrowsRolled) {
                        return false;
                    }
                }

                if (abilityEffect.applied) {
                    // Get the effect that will be applied. If it has choices, we need to know.
                    let applicable: ApplicableAbilityEffect | undefined;
                    if (isNamedRuleRef(abilityEffect.applied)) {
                        applicable = rules.effects.get(abilityEffect.applied);
                    } else {
                        applicable = abilityEffect.applied;
                    }

                    if (applicable) {
                        if (applicable.transform && applicable.transform.filter) {
                            // A creature must be selected to apply.
                            const allTargetsDone = targets.every(target => {
                                if (target.effects.length === 0) {
                                    return true;
                                }

                                if (abilityEffect.targetFilter) {
                                    const creature = resolveTokenCreature(target.target, campaign, rules);
                                    if (creature && !creatureMatchesFilter(creature, abilityEffect.targetFilter)) {
                                        return true;
                                    }
                                }

                                const t = annotation.dnd5e.targetEffects?.[getModifyId(target.target)];
                                if (!t) {
                                    return false;
                                }

                                for (let instanceId in t) {
                                    if (
                                        isExcluded(
                                            annotation,
                                            target.target,
                                            ability!,
                                            o,
                                            instanceId,
                                            campaign,
                                            location,
                                            rules
                                        )
                                    ) {
                                        return true;
                                    }

                                    if (t[instanceId]?.effects?.[o]?.appliedChoices?.transform?.creature == null) {
                                        // It's still ok if they made their save.
                                        const targetSave = t[instanceId]?.effects?.[o]?.savingThrow;
                                        if (
                                            annotation.dnd5e.savingThrowDc != null &&
                                            abilityEffect.savingThrow != null &&
                                            targetSave != null &&
                                            targetSave.result >= annotation.dnd5e.savingThrowDc
                                        ) {
                                            return true;
                                        }

                                        return false;
                                    }
                                }

                                return true;
                            });

                            if (!allTargetsDone) {
                                return false;
                            }
                        }
                    }
                }

                return true;
            });

        const stuff: string[] = [];
        if (isSpell(ability) && annotation.dnd5e.castAt != null && annotation.dnd5e.castAt > ability.level) {
            stuff.push(`Cast at level ${annotation.dnd5e.castAt}`);
        }

        if (annotation.dnd5e.duration?.expires != null) {
            const source = annotation.tokenId ? location.tokens[annotation.tokenId] : undefined;
            const duration = getTimeUntil(session, annotation.dnd5e.duration.expires, source);
            stuff.push(`${durationToString(duration)} of ${durationToString(annotation.dnd5e.duration)} remaining`);
        }

        // Stuff for drag/dropping the adorner to set its position relative to its associated token/annotation.
        const [pointerDownPos, setPointerDownPos] = useState<ScreenPixelPosition>();
        const [pointerDownOffset, setPointerDownOffset] = useState<ScreenPixelPosition>();
        const [isDragging, setIsDragging] = useState<boolean>(false);
        const [ref, adornerScreenBounds] = useMeasure({ offsetSize: true, scroll: false });
        const [draggedPos, setDraggedPos] = useState<LocalPixelPosition | undefined>();

        const dragPointerDown: React.PointerEventHandler<HTMLDivElement> = e => {
            setPointerDownPos(screenPoint(e.clientX, e.clientY));
            const clientRect = (e.target as HTMLDivElement).getBoundingClientRect();
            setPointerDownOffset(screenPoint(clientRect.x - e.clientX, clientRect.y - e.clientY));
            (e.target as HTMLDivElement).setPointerCapture(e.pointerId);

            e.preventDefault();
            e.stopPropagation();
        };
        const getAdornerRelativePoint: (sp: ScreenPixelPosition) => RelativeToBoundsPoint = sp => {
            // lp is the top left of the dragged adorner, in local pixels (adjusted for the grab position)
            const tsp = translate(sp, pointerDownOffset!);
            const lp = grid.toLocalPoint(tsp);
            const relativeToBounds = token ? grid.toLocalBounds(token.pos, token.scale) : bounds;
            const adornerBounds = localRect(
                lp.x,
                lp.y,
                adornerScreenBounds.width / grid.ref.current!.scale,
                adornerScreenBounds.height / grid.ref.current!.scale
            );

            const points = getClosestBoundsPoints(adornerBounds, relativeToBounds);
            return {
                x: points.pa.x - points.pb.x,
                y: points.pa.y - points.pb.y,
                relativeTo: points.cb,
                anchor: points.ca,
            };
        };
        const dragPointerMove: React.PointerEventHandler<HTMLDivElement> = e => {
            if (pointerDownPos) {
                let moveAdorner = isDragging;
                const sp = screenPoint(e.clientX, e.clientY);
                if (!isDragging && distanceBetween(sp, pointerDownPos) > 4) {
                    setIsDragging(true);
                    moveAdorner = true;
                }

                if (moveAdorner) {
                    // // Use an override to move the adorner. Only want to actually apply the change on pointer up.
                    // const existingOverride = annotationOverrides?.[annotation.id] as
                    //     | DeepPartial<DnD5EAnnotation>
                    //     | undefined;
                    // const existingDnd5e = existingOverride?.dnd5e;

                    // const relativePoint = getAdornerRelativePoint(sp);
                    // const dnd5e = Object.assign({}, existingDnd5e, {
                    //     adornerPos: { [user.id]: relativePoint },
                    // });
                    // const ov = Object.assign({}, existingOverride, { dnd5e: dnd5e });
                    // overrideAnnotation(annotation.id, ov);

                    const tsp = translate(sp, pointerDownOffset!);
                    const lp = grid.toLocalPoint(tsp);
                    setDraggedPos(lp);
                }

                e.preventDefault();
                e.stopPropagation();
            }
        };
        const dragClickCapture: React.MouseEventHandler<HTMLDivElement> = e => {
            if (isDragging) {
                e.preventDefault();
                e.stopPropagation();
            }
        };
        const dragPointerUp: React.PointerEventHandler<HTMLDivElement> = e => {
            setPointerDownPos(undefined);
            setPointerDownOffset(undefined);
            (e.target as HTMLDivElement).releasePointerCapture(e.pointerId);

            if (isDragging) {
                flushSync(() => {
                    dispatch(
                        moveAnnotation(
                            campaign,
                            location,
                            annotation,
                            user,
                            getAdornerRelativePoint(screenPoint(e.clientX, e.clientY))
                        )
                    );
                });

                e.preventDefault();
                e.stopPropagation();

                setTimeout(() => {
                    setIsDragging(false);
                    setDraggedPos(undefined);
                }, 0);
            }
        };

        const posElementRef = useRef<HTMLDivElement>(null);
        const adorner = useHtmlAdorner();

        const userPos = annotation.dnd5e.adornerPos?.[user.id];

        const xPos = useMotionValue(0);
        const yPos = useMotionValue(0);

        const oldSizeRef = useRef<Size>();
        const oldPosRef = useRef<Point>();

        useEffect(() => {
            var handler: (args: ScreenPixelPosition) => void = sp => {
                if (!posElementRef.current) {
                    return;
                }

                // Calculate the offset from the relativeTo position that the user has positioned the adorner at.
                let relativeX = 0;
                let relativeY = 0;
                if (draggedPos) {
                    const relativeTo = grid.toLocalPoint(sp);
                    relativeX = draggedPos.x - relativeTo.x;
                    relativeY = draggedPos.y - relativeTo.y;
                } else if (userPos && adornerScreenBounds) {
                    relativeX = userPos.x;
                    relativeY = userPos.y;
                    if (userPos.anchor === "tr" || userPos.anchor === "br") {
                        relativeX -= adornerScreenBounds.width / grid.ref.current!.scale;
                    }

                    if (userPos.anchor === "bl" || userPos.anchor === "br") {
                        relativeY -= adornerScreenBounds.height / grid.ref.current!.scale;
                    }
                }

                relativeX *= grid.ref.current!.scale;
                relativeY *= grid.ref.current!.scale;

                const hasSizeChanged =
                    oldSizeRef.current &&
                    adornerScreenBounds &&
                    (oldSizeRef.current.width !== adornerScreenBounds?.width ||
                        oldSizeRef.current.height !== adornerScreenBounds?.height);
                const hasPosChanged =
                    !oldPosRef.current || oldPosRef.current.x !== relativeX || oldPosRef.current.y !== relativeY;

                if (hasSizeChanged) {
                    oldPosRef.current = { x: relativeX, y: relativeY };
                    oldSizeRef.current = { width: adornerScreenBounds.width, height: adornerScreenBounds.height };
                    animate(xPos, relativeX, { duration: 0.3 });
                    animate(yPos, relativeY, { duration: 0.3 });
                } else if (hasPosChanged) {
                    oldPosRef.current = { x: relativeX, y: relativeY };
                    xPos.set(relativeX);
                    yPos.set(relativeY);
                }

                if (!oldSizeRef.current && adornerScreenBounds.width && adornerScreenBounds.height) {
                    oldSizeRef.current = { width: adornerScreenBounds.width, height: adornerScreenBounds.height };
                }

                posElementRef.current.style.left = `${xPos.get()}px`;
                posElementRef.current.style.top = `${yPos.get()}px`;
            };

            adorner.layoutUpdated.on(handler);
            return () => {
                adorner.layoutUpdated.off(handler);
            };
        }, [adorner, draggedPos, userPos, adornerScreenBounds, grid, xPos, yPos]);

        const isAttackerOwner = !!token && (role === "GM" || token.owner === user.id);

        return (
            <Box ref={posElementRef} position="absolute">
                <LayoutGroup>
                    {ability && token && (
                        <MotionOverlayCard
                            ref={ref}
                            layout={!isDragging}
                            flexDirection="column"
                            boxShadowSize="l"
                            borderRadius={4}
                            width={theme.space[12]}
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}>
                            <Box
                                overflow="hidden"
                                borderRadius={4}
                                flexDirection="column"
                                fullWidth
                                fullHeight
                                alignItems="stretch">
                                {isSpell(ability) && (
                                    <SpellInfo
                                        spell={ability}
                                        isInOverlay
                                        bg="transparent"
                                        dc={annotation.dnd5e.savingThrowDc}
                                        innerPaddingH={3}
                                        layout={!isDragging}
                                        cardHeader
                                        onPointerDown={dragPointerDown}
                                        onPointerMove={dragPointerMove}
                                        onPointerUp={dragPointerUp}
                                        onClickCapture={dragClickCapture}
                                        cursor={isDragging ? "grabbing" : undefined}
                                    />
                                )}
                                {!isSpell(ability) && (
                                    <FeatureExpander
                                        title={ability.name}
                                        isInOverlay
                                        bg="transparent"
                                        innerPaddingH={3}
                                        layout={!isDragging}
                                        cardHeader
                                        onPointerDown={dragPointerDown}
                                        onPointerMove={dragPointerMove}
                                        onPointerUp={dragPointerUp}
                                        onClickCapture={dragClickCapture}
                                        cursor={isDragging ? "grabbing" : undefined}>
                                        <Markdown>{ability.content}</Markdown>
                                    </FeatureExpander>
                                )}
                                <Box px={3} pb={3} pt={2} flexDirection="column" alignItems="flex-start">
                                    {stuff.length > 0 && (
                                        <MotionText layout={!isDragging} mb={2} color="grayscale.2" fontSize={0}>
                                            {stuff.join(" • ")}
                                        </MotionText>
                                    )}
                                    <OwnerEffects
                                        isDragging={isDragging}
                                        annotation={annotation}
                                        ability={ability}
                                        creature={attacker}
                                        targets={targets}
                                        token={token}
                                    />
                                    {targets && targets.length > 0 && (
                                        <React.Fragment>
                                            <MotionBox
                                                layout={!isDragging}
                                                flexDirection="column"
                                                alignItems="flex-start">
                                                <Heading as="h6" mt={2}>
                                                    Targets
                                                </Heading>
                                            </MotionBox>
                                            <AnimatedList>
                                                {targets.map((target, i) => (
                                                    <AnimatedListItem
                                                        key={target.target.id}
                                                        index={i}
                                                        fullWidth
                                                        layout={!isDragging}
                                                        flexDirection="column">
                                                        <AnnotationTarget
                                                            isDragging={isDragging}
                                                            annotation={annotation}
                                                            token={token}
                                                            creature={attacker}
                                                            target={target.target}
                                                            targetEffects={target.effects}
                                                            ability={ability!}
                                                            count={target.count}
                                                            isAttackerOwner={isAttackerOwner}
                                                        />
                                                        {i < targets.length - 1 && (
                                                            <Spacer
                                                                mt={2}
                                                                css={{ alignSelf: "center" }}
                                                                opacity={0.4}
                                                            />
                                                        )}
                                                    </AnimatedListItem>
                                                ))}
                                            </AnimatedList>
                                        </React.Fragment>
                                    )}
                                    {role === "GM" && (
                                        <MotionBox mt={3} layout={!isDragging} alignSelf="flex-end">
                                            <Button
                                                disabled={!canApply}
                                                onClick={() => {
                                                    let applyTo = targets.map(o => o.target);
                                                    const source = annotation.tokenId
                                                        ? location.tokens[annotation.tokenId]
                                                        : undefined;

                                                    // // If there are effects that don't need anything from the target
                                                    // // (i.e. beneficial spells like Bless), then we might not have added
                                                    // // any target effect information yet. Make sure that every target has
                                                    // // some in the annotation we pass through, otherwise they won't be
                                                    // // recognised as a valid target.
                                                    // const allTargetEffects = Object.assign(
                                                    //     {},
                                                    //     annotation.dnd5e.targetEffects
                                                    // );
                                                    // for (let i = 0; i < targets.length; i++) {
                                                    //     const target = targets[i];

                                                    //     const targetEffects = Object.assign(
                                                    //         {},
                                                    //         allTargetEffects[target.target.id]
                                                    //     );
                                                    //     for (let instanceId = 0; instanceId < target.count; instanceId++) {
                                                    //         const instance = Object.assign({}, targetEffects[instanceId]);

                                                    //         // For each instance, add a blank effect, but don't override anything
                                                    //         // that is already there.
                                                    //         instance.effects = Object.assign({}, instance.effects);
                                                    //         for (let effect of target.effects) {
                                                    //             const effectInstance = instance.effects[effect.key];
                                                    //             instance.effects[effect.key] = Object.assign(
                                                    //                 {},
                                                    //                 effectInstance
                                                    //             );
                                                    //         }

                                                    //         targetEffects[instanceId] = instance;
                                                    //     }

                                                    //     allTargetEffects[target.target.id] = targetEffects;
                                                    // }

                                                    // const newdnd = Object.assign({}, annotation.dnd5e, {
                                                    //     targetEffects: allTargetEffects,
                                                    // });
                                                    // const newannotation = Object.assign({}, annotation, { dnd5e: newdnd });

                                                    dispatch(
                                                        applyEffects(
                                                            campaign,
                                                            location,
                                                            annotation,
                                                            applyTo,
                                                            isDnD5EToken(source) ? source : undefined
                                                        )
                                                    );
                                                }}>
                                                Apply
                                            </Button>
                                        </MotionBox>
                                    )}
                                </Box>
                            </Box>
                        </MotionOverlayCard>
                    )}
                </LayoutGroup>
            </Box>
        );
    }
);

export const AnnotationAdorner: FunctionComponent<AnnotationAdornerProps<DnD5EAnnotation>> = ({
    annotation,
    localBounds,
    isSelected,
}) => {
    const { campaign, location } = useValidatedLocation();
    const { tokenOverrides } = useTokenOverrides();
    const rules = useRules();
    const grid = useLocalGrid();
    const user = useUser();

    const isPresent = useIsPresent();

    const token =
        annotation.tokenId && location.tokens[annotation.tokenId]
            ? resolveToken(campaign, applyOverrides(location.tokens[annotation.tokenId], tokenOverrides))
            : undefined;
    if (!isDnD5EToken(token)) {
        return <React.Fragment></React.Fragment>;
    }

    let pos: LocalPixelPosition | undefined;
    if (isPresent && isSelected) {
        const bounds = token ? grid.toLocalBounds(token.pos, token.scale) : localBounds;
        const userPos = annotation.dnd5e.adornerPos?.[user.id];
        if (userPos) {
            // If the adorner has a position specified, use that.
            const relativeToPoint = getBoundsPoint(bounds, userPos.relativeTo ?? "tl");
            pos = localPoint(relativeToPoint.x, relativeToPoint.y);
        } else {
            // Otherwise we put it to the right of the related token or the annotation.
            pos = localPoint(bounds.x + bounds.width + theme.space[2], bounds.y);
        }
    }

    let attacker = isDnD5EToken(token) ? fullResolveTokenCreature(token, campaign, rules) : undefined;
    let ability = getAbilityForCreature(annotation, attacker, rules);
    attacker = attacker?.transformedInto ?? attacker;

    let animations: (AnimationSequence & { key: string })[] = [];
    const animation = ability?.animation?.onPlaced;
    if (animation) {
        animations.push(Object.assign({}, animation, { annotation: annotation, key: annotation.id }));
    }

    return (
        <React.Fragment>
            <Animations animations={animations} source={token} />
            <HtmlAdorner pos={pos}>
                {pos && (
                    <AnnotationAdornerContent
                        annotation={annotation}
                        token={token as ResolvedToken<WithOverride<DnD5EToken>>}
                        bounds={localBounds}
                        attacker={attacker}
                        ability={ability}
                    />
                )}
            </HtmlAdorner>
        </React.Fragment>
    );
};
