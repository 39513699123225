/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useRef } from "react";
import { PercentageBar } from "../../../components/PercentageBar";
import { Box } from "../../../components/primitives";
import { theme } from "../../../design";
import {
    Ability,
    AppliedAbilityEffect,
    DnD5EAnnotation,
    DnD5EToken,
    fromRuleKey,
    getRuleKey,
    isDnD5EAnnotation,
} from "../common";
import { AnimationSequence, TokenAdornerProps } from "../../../store";
import { defaultAnimate, defaultExit, defaultInitial, MotionBox } from "../../../components/motion";
import { HtmlAdorner } from "../../../components/LocationStage/HtmlAdorner";
import {
    resolveTokenCreature,
    isCharacter,
    fullResolveTokenCreature,
    resolveModifiedValue,
    CreatureCondition,
    Feature,
    ResolvedMonster,
    ResolvedCharacter,
    Creature,
} from "../creature";
import { useRules } from "./hooks";
import { AnimatePresence } from "framer-motion";
import { BoxPopup } from "../../../components/TextPopup";
import { ConditionPopupContent, FeaturePopupContent, StatusPopupContent } from "./Popups";
import { ReactNode } from "react";
import { ConditionIcon } from "./ConditionEditor";
import styled from "@emotion/styled";
import ConcentrationIcon from "../../../components/icons/Concentration";
import { Spell } from "../spells";
import { Markdown } from "../../../components/markdown";
import { useLocalGrid, useValidatedLocation } from "../../../components/contexts";
import { renderIconForEffect } from "./common";
import SkullIcon from "../../../components/icons/Skull";
import { KeyedList, keyedListToKeyArray } from "../../../common";
import { getAbilityForCreature } from "../abilities";
import { Animations } from "./AnimationSequence";

const statusInitial = { opacity: 0, y: -10 };
const statusAnimate = { opacity: 1, y: 0 };
const statusExit = { opacity: 0, y: -10, transition: { ease: "easeOut" } };

const IconBox = styled(Box)`
    &:hover {
        color: ${props => props.theme.colors.accent[3]};
    }
`;

const MainTokenAdorner = React.forwardRef<
    HTMLDivElement,
    {
        creature: Creature;
        resolvedCreature: ResolvedCharacter | ResolvedMonster | undefined;
    }
>(({ creature, resolvedCreature }, ref) => {
    let maxHpValue = resolvedCreature?.transformedInto?.maxHp ?? resolvedCreature?.maxHp;
    let maxHp = maxHpValue ? resolveModifiedValue(maxHpValue) : 1;

    return (
        <MotionBox initial={statusInitial} animate={statusAnimate} exit={statusExit} ref={ref} flexDirection="column">
            <PercentageBar
                mt={2}
                fullWidth
                total={maxHp}
                complete={
                    (resolvedCreature?.transformedInto ? resolvedCreature.transformedInto.hp : resolvedCreature?.hp) ??
                    maxHp
                }
                size="s"
            />
            {resolvedCreature?.name ?? creature.name}
        </MotionBox>
    );
});

export const TokenAdorner: FunctionComponent<TokenAdornerProps<DnD5EToken>> = React.forwardRef<
    HTMLDivElement,
    TokenAdornerProps<DnD5EToken>
>(({ token, isSelected }, ref) => {
    const { system, campaign, location } = useValidatedLocation();
    const rules = useRules();

    const grid = useLocalGrid();

    const tokenAppearance = system.getTokenAppearance?.(token, campaign, location) ?? token;

    // TODO: Remove localBounds altogether, all adorners should be positioned using localPoints so they work in 3d.
    const localPoints = grid.toLocalPoints((token.withoutOverride ?? token).pos, tokenAppearance.scale);

    const lastAbilityRef = useRef<{ instanceId: string; id: string } | null>();
    const lastAppliedRef = useRef<DnD5EAnnotation | null>();

    const creature = resolveTokenCreature(token, campaign, rules);
    if (!creature) {
        return <React.Fragment></React.Fragment>;
    }

    // TODO: This will potentially get called a LOT - move this inwards where it won't get done so much? getMaxHp calls resolveCharacter which does a lot...
    const resolvedCreature = fullResolveTokenCreature(token, campaign, rules);

    // const conditions = creature.conditions ? Object.keys(creature.conditions).sort() as CreatureConditionName[] : [];
    const effectKeys = keyedListToKeyArray(
        resolvedCreature?.effects as KeyedList<
            AppliedAbilityEffect & {
                source?: string;
                feature?: Feature;
                ability?: Ability;
                spell?: Spell;
                condition?: CreatureCondition;
            }
        >
    );
    const effectsWithIcon: {
        key: string;
        effect: AppliedAbilityEffect & {
            source?: string;
            feature?: Feature;
            ability?: Ability;
            spell?: Spell;
            condition?: CreatureCondition;
        };
        icon: JSX.Element;
    }[] = [];
    if (effectKeys) {
        for (let i = 0; i < effectKeys?.length; i++) {
            const effect = resolvedCreature!.effects![effectKeys[i]];
            const icon = renderIconForEffect(effect, 20);
            if (icon) {
                effectsWithIcon.push({
                    key: effectKeys[i],
                    effect: effect,
                    icon: icon,
                });
            }
        }
    }

    let concentratingOn: Spell | undefined;
    if (creature.concentrating) {
        concentratingOn = rules.spells.get(creature.concentrating);
    }

    let exhaustion: number | undefined;
    if (isCharacter(creature)) {
        exhaustion = creature.exhaustion;
    }

    const hasConditions =
        !resolvedCreature?.isDead && (effectsWithIcon.length > 0 || exhaustion != null || concentratingOn != null);

    // Detect changes in the last ability used to trigger animations.
    const animations: (AnimationSequence & { key: string })[] = [];
    if (lastAbilityRef.current === undefined) {
        lastAbilityRef.current = creature.lastUsedAbility ?? null;
    } else {
        const la = creature.lastUsedAbility ?? null;
        if (la?.instanceId !== lastAbilityRef.current?.instanceId) {
            // Last ability has changed. If it has an animation, add it.
            if (la) {
                let ability: Ability | undefined;

                // First, check if it's an annotation.
                const annotation = location.annotations[la.id];
                if (isDnD5EAnnotation(annotation)) {
                    // Find the spell/ability for the annotation.
                    ability = getAbilityForCreature(annotation, resolvedCreature, rules);
                } else {
                    const ruleRef = fromRuleKey(la.id);
                    if (ruleRef) {
                        // Not an annotation, might be referencing a spell directly.
                        ability = rules.spells.get(ruleRef);

                        // TODO: Abilities should also be able to have animations, but we don't have any way to specify them
                        // in the rules store at the moment, because they're not stored at the top level.
                    } else {
                        // Not an annotation, and not a rule ref. It might be an annotation that has since been deleted?
                    }
                }

                const castAnim = ability?.animation?.onUse;
                if (castAnim) {
                    // TODO: Do we even want to pass the annotation in here? Maybe onUse should ALWAYS be on the caster, we
                    // have onPlaced for animations relating to the annotation.
                    // animations.push(Object.assign({}, castAnim, { annotation: annotation, key: la.instanceId }));
                    animations.push(Object.assign({}, castAnim, { key: la.instanceId }));
                }
            }

            lastAbilityRef.current = la ?? null;
        }
    }

    if (lastAppliedRef.current === undefined) {
        lastAppliedRef.current = creature.lastAppliedAbility ?? null;
    } else {
        const la = creature.lastAppliedAbility ?? null;
        if (la?.id !== lastAppliedRef.current?.id) {
            // Last applied ability has changed. If it has an animation, add it.
            if (la) {
                const ability = getAbilityForCreature(la, resolvedCreature, rules);
                const applyAnim = ability?.animation?.onApply;
                if (applyAnim) {
                    animations.push(Object.assign({}, applyAnim, { annotation: la, key: la.id }));
                }
            }

            lastAppliedRef.current = la ?? null;
        }
    }

    // Include animations from effects.
    if (resolvedCreature?.effects) {
        for (let effect of Object.values(resolvedCreature.effects)) {
            if (effect.animation) {
                const key = effect.instanceId ?? getRuleKey(effect.spell ?? effect.feature ?? effect);
                animations.push(Object.assign({}, effect.animation, { key: key }));
            }
        }
    }

    const originalToken = token.withoutOverride ?? token;
    const tokenPositionKey = `${originalToken.pos.x},${originalToken.pos.y}`;
    return (
        <React.Fragment>
            <Animations source={token} animations={animations} />
            <HtmlAdorner
                key={`Dead-${tokenPositionKey}`}
                pos={resolvedCreature?.isDead ? localPoints : undefined}
                boundingPosHorizontal="c"
                boundingPosVertical="c"
                pointerEvents="none">
                {resolvedCreature?.isDead && (
                    <MotionBox
                        css={{ pointerEvents: "none" }}
                        initial={defaultInitial}
                        animate={defaultAnimate}
                        exit={defaultExit}>
                        <SkullIcon />
                    </MotionBox>
                )}
            </HtmlAdorner>
            <HtmlAdorner
                key={`Main-${tokenPositionKey}`}
                pos={isSelected ? localPoints : undefined}
                boundingPosVertical="bo"
                boundingPosHorizontal="c"
                minFullWidth
                pointerEvents="none">
                {isSelected && <MainTokenAdorner creature={creature} resolvedCreature={resolvedCreature} />}
            </HtmlAdorner>
            <HtmlAdorner
                key={`Conditions-${tokenPositionKey}`}
                pos={hasConditions ? localPoints : undefined}
                boundingPosHorizontal="ri"
                boundingPosVertical="ti">
                {hasConditions && (
                    <MotionBox
                        layout="size"
                        // layout
                        // transition={{ layout: { duration: 0 } }}
                        initial={{ opacity: 0, x: -theme.space[2] }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{
                            opacity: 0,
                            x: -theme.space[2],
                            transition: { ease: "easeOut" },
                        }}
                        ml={-4}
                        css={{ gap: theme.space[1] }}
                        flexDirection="column"
                        alignItems="flex-start">
                        <AnimatePresence initial={false}>
                            {concentratingOn && (
                                <MotionBox
                                    initial={defaultInitial}
                                    animate={defaultAnimate}
                                    exit={defaultExit}
                                    css={{ pointerEvents: "all" }}>
                                    <BoxPopup mr={2} content={<ConcentrationIcon size={20 as any} />} placement="right">
                                        <StatusPopupContent
                                            status={
                                                rules.statuses.get({
                                                    name: "Concentration",
                                                    source: "PHB",
                                                })!
                                            }
                                        />
                                    </BoxPopup>
                                    <Markdown>{`:spell[${
                                        concentratingOn.displayName ?? concentratingOn.name
                                    }]{spell="${getRuleKey(concentratingOn)}"}`}</Markdown>
                                </MotionBox>
                            )}
                            {effectsWithIcon.map(o => {
                                const icon = <IconBox>{o.icon}</IconBox>;
                                let content: ReactNode;
                                if (o.effect.condition) {
                                    content = (
                                        <BoxPopup content={icon} placement="right">
                                            <ConditionPopupContent condition={o.effect.condition} />
                                        </BoxPopup>
                                    );
                                } else if (o.effect.feature) {
                                    content = (
                                        <BoxPopup content={icon} placement="right">
                                            <FeaturePopupContent feature={o.effect.feature} />
                                        </BoxPopup>
                                    );
                                } else {
                                    content = icon;
                                }

                                return (
                                    <MotionBox
                                        key={o.key}
                                        layout
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}
                                        css={{ pointerEvents: "all" }}>
                                        {content}
                                    </MotionBox>
                                );
                            })}
                            {exhaustion != null && (
                                <React.Fragment>
                                    <MotionBox
                                        key="exhaustion"
                                        layout
                                        initial={defaultInitial}
                                        animate={defaultAnimate}
                                        exit={defaultExit}
                                        css={{ pointerEvents: "all" }}>
                                        <BoxPopup
                                            content={
                                                <IconBox>
                                                    <ConditionIcon condition="exhaustion" size={20 as any} />
                                                    <span>{exhaustion}</span>
                                                </IconBox>
                                            }
                                            placement="right">
                                            <ConditionPopupContent condition={rules.conditions.get("exhaustion")!} />
                                        </BoxPopup>
                                    </MotionBox>
                                </React.Fragment>
                            )}
                        </AnimatePresence>
                    </MotionBox>
                )}
            </HtmlAdorner>
        </React.Fragment>
    );
});
