import { CampaignAction } from "../../../actions/common";
import { CampaignProperties, DnD5ECampaign } from "../common";

export function setRuleSets(campaign: DnD5ECampaign | string, ruleSets: string[] | undefined): CampaignAction {
    return {
        type: "DnD5E_SetRuleSets",
        props: {
            campaignId: typeof campaign === "string" ? campaign : campaign.id,
        },
        payload: ruleSets,
    };
}

export function modifyCampaign(campaign: DnD5ECampaign | string, delta: Partial<CampaignProperties>): CampaignAction {
    return {
        type: "DnD5E_ModifyCampaignProperties",
        props: {
            campaignId: typeof campaign === "string" ? campaign : campaign.id,
        },
        payload: delta,
    };
}
