import { HandoutAction } from "../actions/common";
import { Handout } from "../store";
import { copyState } from "./common";

const handoutReducer = (state: Handout | undefined, action: HandoutAction) => {
    if (action.type === "ModifyHandout") {
        const { userId, handout } = action.payload as { userId: string; handout: Partial<Handout> };

        const modified = Date.now();
        let newHandout = copyState(state, handout);
        newHandout = Object.assign({}, newHandout, { modified: modified });

        // Changes to the content/label are considered contributions.
        if (handout.content != null || handout.label != null) {
            const newContributors = Object.assign({}, handout.contributors, { [userId]: modified });
            newHandout = Object.assign({}, newHandout, { contributors: newContributors });
        }

        return copyState(state, newHandout);
    }

    return state;
};

export default handoutReducer;
