/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import React, { FunctionComponent, useState } from "react";
import { DnD5EMonsterToken } from "../../common";
import { ResolvedMonster } from "../../creature";
import { LayoutGroup, AnimatePresence } from "framer-motion";
import { Box } from "../../../../components/primitives";
import { Button } from "../../../../components/Button";
import {
    MotionBox,
    sectionAnimate,
    sectionExit,
    sectionInitial,
    defaultInitial,
    defaultExit,
    defaultAnimate,
    MotionText,
    MotionMessage,
} from "../../../../components/motion";
import { startsWithVowel } from "../../../../components/utils";
import { MonsterSheetContent } from "./MonsterSheetContent";

export const MonsterSheet: FunctionComponent<{
    monster: ResolvedMonster;
    token: DnD5EMonsterToken;
}> = ({ monster, token }) => {
    const [isShowingTransformed, setIsShowingTransformed] = useState(true);
    const transformEffect = monster.transformedInto ? monster.effects?.[monster.transformedInto.byEffect] : undefined;

    return (
        <LayoutGroup>
            <AnimatePresence>
                <Box px={3}>
                    {monster.transformedInto && (
                        <MotionMessage
                            layout
                            initial={defaultInitial}
                            animate={defaultAnimate}
                            exit={defaultExit}
                            variant="info"
                            fullWidth>
                            {isShowingTransformed && (
                                <React.Fragment>
                                    <MotionText layout css={{ color: "inherit" }}>
                                        This creature is actually {startsWithVowel(monster.name) ? "an" : "a"}{" "}
                                        {monster.name}
                                        {transformEffect ? ` under the ${transformEffect.name} effect` : ""}.
                                    </MotionText>
                                    <MotionBox layoutId="switch_button" layout>
                                        <Button mt={2} onClick={() => setIsShowingTransformed(false)}>
                                            Show {monster.name}
                                        </Button>
                                    </MotionBox>
                                </React.Fragment>
                            )}
                            {!isShowingTransformed && (
                                <React.Fragment>
                                    <MotionText layout css={{ color: "inherit" }}>
                                        This creature is currently transformed into{" "}
                                        {startsWithVowel(monster.transformedInto.name) ? "an" : "a"}{" "}
                                        {monster.transformedInto.name}
                                        {transformEffect ? ` by the ${transformEffect.name} effect` : ""}.
                                    </MotionText>
                                    <MotionBox layoutId="switch_button" layout>
                                        <Button mt={2} onClick={() => setIsShowingTransformed(true)}>
                                            Show {monster.transformedInto.name}
                                        </Button>
                                    </MotionBox>
                                </React.Fragment>
                            )}
                        </MotionMessage>
                    )}
                </Box>
            </AnimatePresence>
            <AnimatePresence mode="wait" initial={false}>
                {isShowingTransformed && monster.transformedInto && (
                    <MotionBox
                        layout
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}
                        key="transformedInto"
                        flexDirection="column"
                        fullWidth
                        flexGrow={1}>
                        <MonsterSheetContent
                            monster={monster.transformedInto}
                            thumbnailUri={monster.transformedInto.transform.appearance?.imageUri ?? token.imageUri}
                            token={token}
                        />
                    </MotionBox>
                )}
                {(!isShowingTransformed || !monster.transformedInto) && (
                    <MotionBox
                        layout
                        initial={sectionInitial}
                        animate={sectionAnimate}
                        exit={sectionExit}
                        key="default"
                        flexDirection="column"
                        fullWidth
                        flexGrow={1}>
                        <MonsterSheetContent monster={monster} thumbnailUri={token.imageUri} token={token} />
                    </MotionBox>
                )}
            </AnimatePresence>
        </LayoutGroup>
    );
};
