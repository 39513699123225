import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
// import registerServiceWorker from './registerServiceWorker';
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import reducer from "./reducers/root";
import ReduxThunk from "redux-thunk";
import { createSessionMiddleware, signalRSessionFactory } from "./session-middleware";
import { DispatchContext } from "./components/contexts";
import { title } from "./common";
import { BrowserRouter as Router } from "react-router-dom";

const rootElement = document.getElementById("root");
const store = createStore(
    reducer,
    compose(applyMiddleware(createSessionMiddleware(signalRSessionFactory), ReduxThunk))
);

document.title = title;

const root = createRoot(rootElement!);
root.render(
    <Provider store={store}>
        <Router>
            <DispatchContext.Provider value={store.dispatch}>
                <App />
            </DispatchContext.Provider>
        </Router>
    </Provider>
);

// registerServiceWorker();

function getCookie(name: string) {
    var match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
    return match ? decodeURIComponent(match[2]) : undefined;
}

var profile = getCookie("UserProfile");
if (profile) {
    store.dispatch({ type: "Authenticate", payload: JSON.parse(profile) });
}
