import {
    AnnotationAction,
    isTokensAction,
    isTokenTemplatesAction,
    TokensAction,
    TokenTemplatesAction,
} from "../../../actions/common";
import { Annotation } from "../../../annotations";
import { keyedListToKeyArray } from "../../../common";
import { Point } from "../../../position";
import { copyState } from "../../../reducers/common";
import { getCombatParticipants } from "../../../reducers/location";
import { DiceRoll, getGameTime, Location, Session, UserInfo } from "../../../store";
import { getAbility } from "../abilities";
import { DnD5EAnnotationAction } from "../actions/annotation";
import {
    DamageType,
    DnD5EAnnotation,
    isDnD5EToken,
    SpellEffectInstance,
    AbilityInstanceResult,
    AbilityEffectResult,
    getRuleKey,
    isDnD5EAnnotation,
    AppliedAbilityEffectChoices,
    AbilityEffectResultBase,
    AttackOptionResult,
} from "../common";
import { CharacterRuleSet, DamageTypes, resolveTokenCreature } from "../creature";
import { isSpell } from "../spells";

function markEffectsAsApplied(
    annotation: DnD5EAnnotation,
    token: string,
    abilityEffectKeys: string[]
): DnD5EAnnotation {
    const oldEffects = annotation.dnd5e.targetEffects;
    const oldAbilityResults = oldEffects?.[token];

    const newAbilityResults = Object.assign({}, oldAbilityResults);
    for (let instanceKey in oldAbilityResults) {
        const oldInstance = oldAbilityResults?.[instanceKey];
        const oldTargetEffects = oldInstance.effects;
        const newTargetEffects = Object.assign({}, oldTargetEffects);
        for (let effectKey of abilityEffectKeys) {
            const oldTargetEffect = oldTargetEffects?.[effectKey];
            newTargetEffects[effectKey] = Object.assign({}, oldTargetEffect, { applied: true });
        }

        newAbilityResults[instanceKey] = Object.assign({}, oldInstance, { effects: newTargetEffects });
    }

    const newEffects = Object.assign({}, annotation.dnd5e.targetEffects, { [token]: newAbilityResults });
    const newDnd5e = Object.assign({}, annotation.dnd5e, { targetEffects: newEffects });
    annotation = Object.assign({}, annotation, { dnd5e: newDnd5e });
    return annotation;
}

function reduceTargetAbilityResult(
    annotation: DnD5EAnnotation,
    action: DnD5EAnnotationAction,
    abilityDelta: Partial<AbilityInstanceResult>
) {
    const annotationAction = action as DnD5EAnnotationAction;

    const oldTarget = annotation.dnd5e.targetEffects?.[annotationAction.props.tokens![0]];
    const oldInstance = oldTarget?.[annotationAction.props.instanceId ?? "0"];

    const newInstance = Object.assign({}, oldInstance, abilityDelta);
    const newTarget = Object.assign({}, oldTarget, { [annotationAction.props.instanceId ?? "0"]: newInstance });
    const newTargetEffects = Object.assign({}, annotation.dnd5e.targetEffects, {
        [annotationAction.props.tokens![0]]: newTarget,
    });

    const dnd5e = Object.assign({}, annotation.dnd5e, { targetEffects: newTargetEffects });
    return copyState(annotation, { dnd5e: dnd5e });
}

function reduceTargetEffectResult(
    annotation: DnD5EAnnotation,
    action: DnD5EAnnotationAction,
    effectDelta: Partial<AbilityEffectResult> | ((o: AbilityEffectResult | undefined) => AbilityEffectResult)
) {
    const annotationAction = action as DnD5EAnnotationAction;

    const oldTarget = annotation.dnd5e.targetEffects?.[annotationAction.props.tokens![0]];
    const oldInstance = oldTarget?.[annotationAction.props.instanceId ?? "0"];

    let newInstance: AbilityInstanceResult;
    if (action.props.option) {
        // This effect is on an attack option for the target instance - e.g. stunning strike, divine smite, etc.
        const featureKey = getRuleKey(action.props.option.feature);
        const oldOptions = oldInstance?.options;
        const oldOptionResults = oldOptions?.[featureKey];
        const oldOptionEffectResult = oldOptionResults?.[action.props.option.key];

        const newEffect =
            typeof effectDelta === "function"
                ? effectDelta(oldOptionEffectResult)
                : copyState(oldOptionEffectResult ?? {}, effectDelta);
        const newOptionResults = Object.assign({}, oldOptionResults, { [action.props.option.key]: newEffect });
        const newOptions = Object.assign({}, oldOptions, { [featureKey]: newOptionResults });
        newInstance = Object.assign({}, oldInstance, { options: newOptions });
    } else {
        // The effect is on the main ability.
        const oldEffects = oldInstance?.effects;
        const oldEffect = oldEffects?.[annotationAction.props.effectId!];

        const newEffect =
            typeof effectDelta === "function" ? effectDelta(oldEffect) : copyState(oldEffect ?? {}, effectDelta);
        const newEffects = Object.assign({}, oldEffects, { [annotationAction.props.effectId!]: newEffect });
        newInstance = Object.assign({}, oldInstance, { effects: newEffects });
    }

    const newTarget = Object.assign({}, oldTarget, { [annotationAction.props.instanceId ?? "0"]: newInstance });
    const newTargetEffects = Object.assign({}, annotation.dnd5e.targetEffects, {
        [annotationAction.props.tokens![0]]: newTarget,
    });

    const dnd5e = Object.assign({}, annotation.dnd5e, { targetEffects: newTargetEffects });
    return copyState(annotation, { dnd5e: dnd5e });
}

export function reduceAnnotation(
    annotation: Annotation,
    action: AnnotationAction,
    session: Session,
    location: Location,
    rules: CharacterRuleSet,
    isTargetted: boolean
) {
    if (!isDnD5EAnnotation(annotation)) {
        return annotation;
    }

    if (isTargetted) {
        return reduceDnD5EAnnotation(annotation, action, session, location, rules);
    } else {
        return reduceDnD5EAnnotationUntargetted(annotation, action, session, location, rules);
    }
}

function reduceDnD5EAnnotation(
    annotation: DnD5EAnnotation,
    action: AnnotationAction,
    session: Session,
    location: Location,
    rules: CharacterRuleSet
) {
    const campaign = session.campaign;
    if (action.type === "DnD5E_AnnotationApplyDamage") {
        const annotationAction = action as DnD5EAnnotationAction;
        const { diceRoll, damageType } = annotationAction.payload as { diceRoll: DiceRoll; damageType: DamageType };

        if (!annotationAction.props.instanceId) {
            const oldEffect = annotation.dnd5e.effects?.[annotationAction.props.effectId!];
            const oldDamage = oldEffect?.damage;

            const newDamage = Object.assign({}, oldDamage, { [damageType]: diceRoll });
            const newEffect = Object.assign({}, oldEffect, { damage: newDamage });
            const newEffects = Object.assign({}, annotation.dnd5e.effects, {
                [annotationAction.props.effectId!]: newEffect,
            });

            const dnd5e = Object.assign({}, annotation.dnd5e, { effects: newEffects });
            return copyState(annotation, { dnd5e: dnd5e });
        } else {
            const oldTarget = annotation.dnd5e.targetEffects?.[annotationAction.props.tokens![0]];
            const oldInstance = oldTarget?.[annotationAction.props.instanceId];
            const oldEffects = oldInstance?.effects;
            const oldEffect = oldEffects?.[annotationAction.props.effectId!];
            const oldDamage = oldEffect?.damage;

            const newDamage = Object.assign({}, oldDamage, { [damageType]: diceRoll });
            const newEffect = Object.assign({}, oldEffect, { damage: newDamage });
            const newEffects = Object.assign({}, oldEffects, { [annotationAction.props.effectId!]: newEffect });
            const newInstance = Object.assign({}, oldInstance, { effects: newEffects });
            const newTarget = Object.assign({}, oldTarget, { [annotationAction.props.instanceId ?? "0"]: newInstance });
            const newTargetEffects = Object.assign({}, annotation.dnd5e.targetEffects, {
                [annotationAction.props.tokens![0]]: newTarget,
            });

            const dnd5e = Object.assign({}, annotation.dnd5e, { targetEffects: newTargetEffects });
            return copyState(annotation, { dnd5e: dnd5e });
        }
    } else if (action.type === "DnD5E_AnnotationApplyHealing") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as DiceRoll;

        if (!annotationAction.props.instanceId) {
            const oldEffect = annotation.dnd5e.effects?.[annotationAction.props.effectId!];

            const newEffect = Object.assign({}, oldEffect, { healing: payload });
            const newEffects = Object.assign({}, annotation.dnd5e.effects, {
                [annotationAction.props.effectId!]: newEffect,
            });

            const dnd5e = Object.assign({}, annotation.dnd5e, { effects: newEffects });
            return copyState(annotation, { dnd5e: dnd5e });
        } else {
            const oldTarget = annotation.dnd5e.targetEffects?.[annotationAction.props.tokens![0]];
            const oldInstance = oldTarget?.[annotationAction.props.instanceId];
            const oldEffects = oldInstance?.effects;
            const oldEffect = oldEffects?.[annotationAction.props.effectId!];

            const newEffect = Object.assign({}, oldEffect, { healing: payload });
            const newEffects = Object.assign({}, oldEffects, { [annotationAction.props.effectId!]: newEffect });
            const newInstance = Object.assign({}, oldInstance, { effects: newEffects });
            const newTarget = Object.assign({}, oldTarget, { [annotationAction.props.instanceId ?? "0"]: newInstance });
            const newTargetEffects = Object.assign({}, annotation.dnd5e.targetEffects, {
                [annotationAction.props.tokens![0]]: newTarget,
            });

            const dnd5e = Object.assign({}, annotation.dnd5e, { targetEffects: newTargetEffects });
            return copyState(annotation, { dnd5e: dnd5e });
        }
    } else if (action.type === "DnD5E_AnnotationChoosePerLevelDamageType") {
        const annotationAction = action as DnD5EAnnotationAction;
        const damageType = annotationAction.payload as DamageType | undefined;

        const oldEffect = annotation.dnd5e.effects?.[annotationAction.props.effectId!];

        const newEffect = Object.assign({}, oldEffect, { perLevelType: damageType });
        const newEffects = Object.assign({}, annotation.dnd5e.effects, {
            [annotationAction.props.effectId!]: newEffect,
        });
        const dnd5e = Object.assign({}, annotation.dnd5e, { effects: newEffects });
        return copyState(annotation, { dnd5e: dnd5e });
    } else if (action.type === "DnD5E_AnnotationChooseDamageType") {
        const annotationAction = action as DnD5EAnnotationAction;
        const damageType = annotationAction.payload as DamageType | undefined;

        const oldEffect = annotation.dnd5e.effects?.[annotationAction.props.effectId!];

        const newEffect = Object.assign({}, oldEffect, { damageType: damageType });
        const newEffects = Object.assign({}, annotation.dnd5e.effects, {
            [annotationAction.props.effectId!]: newEffect,
        });
        const dnd5e = Object.assign({}, annotation.dnd5e, { effects: newEffects });
        return copyState(annotation, { dnd5e: dnd5e });
    } else if (action.type === "DnD5E_AnnotationApplyAttackRoll") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as DiceRoll;

        return reduceTargetAbilityResult(annotation, annotationAction, { attack: payload });
    } else if (action.type === "DnD5E_AnnotationApplyAcModifier") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as number | undefined;

        return reduceTargetAbilityResult(annotation, annotationAction, { acModifier: payload });
    } else if (action.type === "DnD5E_AnnotationApplyAttackHit") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as "crit_hit" | "hit" | "miss" | "crit_miss";

        return reduceTargetAbilityResult(annotation, annotationAction, { hit: payload });
    } else if (action.type === "DnD5E_AnnotationApplySavingThrow") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as DiceRoll;

        return reduceTargetEffectResult(annotation, annotationAction, { savingThrow: payload });
    } else if (action.type === "DnD5E_AnnotationApplyModifyOption") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as Partial<AttackOptionResult>;

        return reduceTargetEffectResult(annotation, annotationAction, payload);
    } else if (action.type === "DnD5E_AnnotationApplyResistance") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as DamageTypes<number | undefined>;

        return reduceTargetEffectResult(annotation, annotationAction, o => {
            const oldResistances = o?.resistances;
            const newResistances = copyState(oldResistances ?? {}, payload);
            return Object.assign({}, o, { resistances: newResistances });
        });
    } else if (action.type === "DnD5E_AnnotationAppliedEffectChoices") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as AppliedAbilityEffectChoices;

        return reduceTargetEffectResult(annotation, annotationAction, o => {
            const oldChoices = o?.appliedChoices;
            const newChoices = copyState(oldChoices ?? {}, payload);
            return Object.assign({}, o, { appliedChoices: newChoices });
        });
    } else if (action.type === "DnD5E_AnnotationSetExclusion") {
        const annotationAction = action as DnD5EAnnotationAction;
        const isExcluded = annotationAction.payload as boolean | undefined;

        return reduceTargetEffectResult(annotation, annotationAction, { isExcluded: isExcluded });
    } else if (action.type === "DnD5E_SetAttackOption") {
        const annotationAction = action as DnD5EAnnotationAction;
        const option = annotationAction.props.option!;
        const isEnabled = annotationAction.payload as boolean;

        const featureKey = getRuleKey(option.feature);

        const oldTarget = annotation.dnd5e.targetEffects?.[annotationAction.props.tokens![0]];
        const oldInstance = oldTarget?.[annotationAction.props.instanceId ?? "0"];
        const oldFeatures = oldInstance?.options;
        const oldFeature = oldFeatures?.[featureKey];

        let newFeature: { [id: string]: AbilityEffectResultBase } | undefined;
        if (!isEnabled && oldFeature?.[option.key] != null) {
            // Attack option has been DISABLED. Remove the results for it.
            newFeature = copyState(oldFeature ?? {}, { [option.key]: undefined });
        } else if (isEnabled && !oldFeature?.[option.key]) {
            // Attack option has been ENABLED. Add a blank options for it, which is enough to enable it.
            newFeature = copyState(oldFeature ?? {}, { [option.key]: {} });
        }

        const newFeatures = Object.assign({}, oldFeatures, { [featureKey]: newFeature });
        const newInstance = Object.assign({}, oldInstance, { options: newFeatures });
        const newTarget = Object.assign({}, oldTarget, { [annotationAction.props.instanceId ?? "0"]: newInstance });
        const newTargetEffects = Object.assign({}, annotation.dnd5e.targetEffects, {
            [annotationAction.props.tokens![0]]: newTarget,
        });

        const dnd5e = Object.assign({}, annotation.dnd5e, { targetEffects: newTargetEffects });
        return copyState(annotation, { dnd5e: dnd5e });
    } else if (action.type === "DnD5E_AnnotationMove") {
        const annotationAction = action as DnD5EAnnotationAction;
        const payload = annotationAction.payload as { user: UserInfo; pos: Point };

        const oldPositions = annotation.dnd5e.adornerPos;
        const newPositions = Object.assign({}, oldPositions, { [payload.user.id]: payload.pos });
        const dnd5e = Object.assign({}, annotation.dnd5e, { adornerPos: newPositions });
        return copyState(annotation, { dnd5e: dnd5e });
    } else if (action.type === "DnD5E_AnnotationApply") {
        if (!annotation.dnd5e.duration || annotation.type === "target") {
            // The annotation has no duration (so it's an instant effect, like fireball etc).
            // We should remove it as soon as it has been applied.
            // Same goes for targetted annotations - if they have a lasting effect it is usually an applied effect which doesn't
            // need the annotation to remain. Keep a look out for use cases where we want the annotation to stick around though...
            return undefined;
        }

        const annotationAction = action as DnD5EAnnotationAction & (TokensAction | TokenTemplatesAction);

        const ability = getAbility(annotation, campaign, location, rules);
        if (ability && ability.effects) {
            const abilityEffectKeys = keyedListToKeyArray(ability.effects);

            if (isTokenTemplatesAction(annotationAction)) {
                for (let i = 0; i < annotationAction.props.templates.length; i++) {
                    annotation = markEffectsAsApplied(
                        annotation,
                        annotationAction.props.templates[i],
                        abilityEffectKeys
                    );
                }
            }

            if (isTokensAction(annotationAction)) {
                for (let i = 0; i < annotationAction.props.tokens.length; i++) {
                    annotation = markEffectsAsApplied(annotation, annotationAction.props.tokens[i], abilityEffectKeys);
                }
            }
        }
    }

    return annotation;
}

function reduceDnD5EAnnotationUntargetted(
    annotation: DnD5EAnnotation,
    action: AnnotationAction,
    session: Session,
    location: Location,
    rules: CharacterRuleSet
) {
    const campaign = session.campaign;
    if (action.type === "GameTimeTick") {
        // Shouldn't get this action during combat, but just in case - don't handle time based events during
        // combat - in combat this is handled by the NextCombatTurn action.
        if (
            !session.combatLocation &&
            annotation.dnd5e.duration?.expires != null &&
            getGameTime(session.time) >= annotation.dnd5e.duration.expires
        ) {
            // Annotation has expired, it should be removed.
            return undefined;
        }
    } else if (action.type === "NextCombatTurn") {
        // The turn has ended, reset any sot/eot triggered effects so they'll apply again next turn.
        const ability = getAbility(annotation, campaign, location, rules);
        if (ability && ability.effects) {
            const spellEffectKeys = keyedListToKeyArray(ability.effects);

            let newTargetEffects: { [tokenId: string]: { [instanceId: string]: AbilityInstanceResult } } | undefined;
            if (annotation.dnd5e.targetEffects) {
                const targetsWithEffects = Object.keys(annotation.dnd5e.targetEffects);
                for (let j = 0; j < targetsWithEffects.length; j++) {
                    const oldTarget = annotation.dnd5e.targetEffects[targetsWithEffects[j]];
                    let newTarget: { [instanceId: string]: AbilityInstanceResult } | undefined;

                    const instanceIds = Object.keys(oldTarget);
                    for (let k = 0; k < instanceIds.length; k++) {
                        const oldInstance = oldTarget[instanceIds[k]];
                        let newInstance: AbilityInstanceResult | undefined;

                        for (let i = 0; i < spellEffectKeys.length; i++) {
                            const spellEffect = ability.effects[spellEffectKeys[i]];
                            if (spellEffect.trigger?.targetStartOfTurn || spellEffect.trigger?.targetEndOfTurn) {
                                if (!newInstance) {
                                    const newEffects = Object.assign({}, oldInstance.effects);
                                    newInstance = Object.assign({}, oldInstance, { effects: newEffects });
                                }

                                const effects = newInstance.effects;
                                delete effects[spellEffectKeys[i]];
                            }
                        }

                        if (newInstance) {
                            if (!newTarget) {
                                newTarget = Object.assign({}, oldTarget);
                            }

                            newTarget[instanceIds[k]] = newInstance;
                        }
                    }

                    if (newTarget) {
                        if (!newTargetEffects) {
                            newTargetEffects = Object.assign({}, annotation.dnd5e.targetEffects);
                        }

                        newTargetEffects[targetsWithEffects[j]] = newTarget;
                    }
                }
            }

            let newEffects: { [id: string]: SpellEffectInstance } | undefined;
            if (annotation.dnd5e.effects) {
                newEffects = Object.assign({}, annotation.dnd5e.effects);

                for (let i = 0; i < spellEffectKeys.length; i++) {
                    const effect = annotation.dnd5e.effects?.[spellEffectKeys[i]];
                    if (effect) {
                        const newEffect = Object.assign({}, effect);
                        delete newEffect.damage;
                        newEffects[spellEffectKeys[i]] = newEffect;
                    }
                }
            }

            if (newTargetEffects || newEffects) {
                const dnd5e = copyState(annotation.dnd5e, {
                    targetEffects: newTargetEffects ?? annotation.dnd5e.targetEffects,
                    effects: newEffects,
                });
                annotation = copyState(annotation, { dnd5e: dnd5e });
            }
        }

        if (location.combat && annotation.tokenId) {
            const expires = annotation.dnd5e.duration?.expires;
            if (expires != null) {
                // If the creature taking the next turn is the caster of the annotation, then evaluate the expiry of the annotation.
                const participants = getCombatParticipants(location.combat, campaign, location);
                let turn = participants.findIndex(o => o.token?.id === location.combat!.turn);
                if (turn >= Object.keys(location.combat!.participants).length) {
                    turn = 0;
                }

                if (annotation.tokenId === participants[turn].tokenId && getGameTime(session.time) >= expires) {
                    return undefined;
                }
            }
        }
    }

    // If the annotation is centered on a token that doesn't exist any more, then we have to get rid of it.
    if (annotation.centerOn && !location.tokens[annotation.centerOn]) {
        return undefined;
    }

    // This isn't specifically an annotation action. However, if we're here then it has specified a scope that means that the
    // action may have affected this annotation so we do some checks to make sure related tokens/creatures are still relevant.
    const ability = getAbility(annotation, campaign, location, rules);
    if (ability?.duration?.isConcentration && annotation.tokenId) {
        // Check if the token still exists - if not then it can't be concentrating any more.
        const token = location.tokens[annotation.tokenId];
        if (!token) {
            return undefined;
        }

        // If the token is a character, and it is no longer concentrating, or concentrating on something else, remove the annotation.
        if (isDnD5EToken(token) && isSpell(ability)) {
            const resolvedCreature = resolveTokenCreature(token, campaign, rules);
            const c = resolvedCreature?.concentrating;
            if (!c || c.name !== ability.name || c.source !== ability.source) {
                return undefined;
            }
        }
    }

    return annotation;
}
