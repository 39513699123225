/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from "@emotion/react";
import { Box, Heading } from "../../../components/primitives";
import { FunctionComponent } from "react";
import { useCampaign } from "../../../components/contexts";
import TokenBox from "../../../components/TokenBox";
import { DnD5EToken, DnD5ETokenTemplate } from "../common";
import {
    AbilityCheckLogEntry,
    SkillCheckLogEntry,
    isAbilityCheck,
    isSkillCheck,
    isSavingThrow,
    AttackRollLogEntry,
    DamageRollLogEntry,
    isAttackRoll,
    isDamageRoll,
    HitPointRollLogEntry,
    isHitPoints,
    RechargeRollLogEntry,
    isRechargeRoll,
    SavingThrowLogEntry,
} from "../logentries";
import {
    Creature,
    MonsterAbility,
    ResolvedMonster,
    attackTypeToString,
    getCoreAbilityLabel,
    isMonster,
    resolveCreature,
    resolveTokenCreature,
} from "../creature";
import { useRules } from "./hooks";
import { getThemeColorPalette } from "../../../design/utils";
import { Markdown } from "../../../components/markdown";
import { Tag } from "../../../components/Tag";

const RechargeEntryContent: FunctionComponent<{
    logEntry: RechargeRollLogEntry;
    creature?: Creature;
}> = ({ logEntry, creature }) => {
    const { campaign } = useCampaign();
    const rules = useRules();
    let resolvedCreature =
        creature && isMonster(creature) ? (resolveCreature(creature, campaign, rules) as ResolvedMonster) : undefined;
    let ability: MonsterAbility | undefined;
    if (resolvedCreature && logEntry.data.abilityKey) {
        ability = resolvedCreature.abilities?.[logEntry.data.abilityKey];
    }

    const abilityName = ability?.name ?? "Ability";

    if (logEntry.result == null) {
        return (
            <Markdown>{`**${abilityName}** will be recharged on a **${logEntry.data.rechargeOn}** or higher`}</Markdown>
        );
    }

    return (
        <Markdown>
            {logEntry.result >= logEntry.data.rechargeOn
                ? `**${abilityName}** has successfully recharged! (required ${logEntry.data.rechargeOn})`
                : `**${abilityName}** did not recharge (required ${logEntry.data.rechargeOn})`}
        </Markdown>
    );
};

const SavingThrowLogEntryDetails: FunctionComponent<{ logEntry: SavingThrowLogEntry }> = ({ logEntry }) => {
    const isSuccess =
        logEntry.data.dc != null && logEntry.result != null ? logEntry.result >= logEntry.data.dc : undefined;
    const palette = isSuccess ? "greens" : "reds";
    return (
        <Box flexDirection="column" alignItems="flex-start">
            {`${
                logEntry.data.savingThrow === "death" ? "Death" : getCoreAbilityLabel(logEntry.data.savingThrow)
            } saving throw${logEntry.data.dc != null ? ` (DC ${logEntry.data.dc})` : ""}`}
            {isSuccess != null && (
                <Tag my={1} bg={`${palette}.7`} color={`${palette}.0`}>
                    {isSuccess ? "Success" : "Failure"}
                </Tag>
            )}
        </Box>
    );
};

export const LogEntryDetails: FunctionComponent<{
    logEntry:
        | AbilityCheckLogEntry
        | SkillCheckLogEntry
        | SavingThrowLogEntry
        | AttackRollLogEntry
        | DamageRollLogEntry
        | HitPointRollLogEntry
        | RechargeRollLogEntry;
    token: DnD5EToken | DnD5ETokenTemplate;
}> = ({ logEntry, token }) => {
    const { campaign } = useCampaign();
    const rules = useRules();
    const campaignPlayer = campaign.players[logEntry.userId];
    const playerColor = campaignPlayer ? campaignPlayer.colour : undefined;
    const playerColours = getThemeColorPalette(playerColor);

    // Find the actual token.
    const creature = resolveTokenCreature(token, campaign, rules);
    return (
        <Box fullWidth flexDirection="row" justifyContent="flex-start" alignItems="flex-start">
            {token && <TokenBox token={token} background={playerColours[6]} mr={2} />}
            <Box flexDirection="column" alignItems="flex-start" flexShrink={1}>
                {creature && (
                    <Heading as="h6" mb={2}>
                        {creature.name}
                    </Heading>
                )}
                {isAbilityCheck(logEntry) && <Box>{`${getCoreAbilityLabel(logEntry.data.ability)} check`}</Box>}
                {isSkillCheck(logEntry) && <Box>{`${rules.skills[logEntry.data.skill].label} check`}</Box>}
                {isSavingThrow(logEntry) && <SavingThrowLogEntryDetails logEntry={logEntry} />}
                {isAttackRoll(logEntry) && <Box>{`${attackTypeToString(logEntry.data.attack)}`}</Box>}
                {isDamageRoll(logEntry) && <Box>{`${logEntry.data.dmgType}`}</Box>}
                {isHitPoints(logEntry) && (
                    <Box>{`Hit points for ${logEntry.data.class.name} level ${logEntry.data.level}`}</Box>
                )}
                {isRechargeRoll(logEntry) && <RechargeEntryContent logEntry={logEntry} creature={creature} />}
            </Box>
        </Box>
    );
};
