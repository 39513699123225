import {
    Campaign,
    CampaignPlayer,
    isLocation,
    SessionConnection,
    Location,
    UserInfo,
    Handout,
    TokenTemplate,
} from "../store";
import locationReducer from "./location";
import {
    CampaignAction,
    isLocationAction,
    isCampaignPlayerAction,
    isTokenTemplatesAction,
    ActionScope,
    LocationAction,
    isHandoutAction,
} from "../actions/common";
import { reduceDictionary, copyState } from "./common";
import { reduceTokenTemplate } from "./token";
import handoutReducer from "./handout";

const campaignPlayerReducer = (state: CampaignPlayer | undefined, action: CampaignAction) => {
    if (!state) {
        return state;
    }

    if (action.type === "SetPlayerLocation" || action.type === "AddLocation") {
        const payload = action.payload as { location: Location | string; level?: string };
        state = copyState(state, {
            location: typeof payload.location === "string" ? payload.location : (payload.location as Location).id,
            level: payload.level,
        });
    } else if (action.type === "SetPlayerColour") {
        state = copyState(state, { colour: action.payload });
    } else if (action.type === "DeleteLevel") {
        const key = action.payload as string;
        if (state.level === key) {
            state = copyState(state, { level: undefined });
        }
    }

    return state;
};

function reduceSessionConnection(state: Campaign, sessionConnection: SessionConnection) {
    const oldSession = sessionConnection.session!;
    const newSession = Object.assign({}, oldSession, { campaign: state });
    const newSessionConnection = Object.assign({}, sessionConnection, { session: newSession });
    return newSessionConnection;
}

const campaignReducer = (
    state: Campaign,
    action: CampaignAction,
    sessionConnection: SessionConnection,
    user?: UserInfo
) => {
    const originalState = state;

    if (action.type === "SetCampaignLabel" && (action as CampaignAction).payload !== state.label) {
        return copyState(state, { label: (action as CampaignAction).payload });
    } else if (action.type === "SetCampaignDescription") {
        let description = (action as CampaignAction).payload as string;
        return copyState(state, { description: description });
    } else if (action.type === "SetCampaignSharedVision") {
        let sharedVision = (action as CampaignAction).payload as "party" | "none" | undefined;
        return copyState(state, { sharedVision: sharedVision });
    } else if (action.type === "AddLocation" && isLocationAction(action) && action.payload?.location) {
        if (state.locations[action.props.locationId]) {
            throw new Error(`A location with the id ${action.props.locationId} already exists.`);
        }

        state = copyState(state, {
            locations: copyState(state.locations, { [action.props.locationId]: action.payload.location as Location }),
        });
    } else if (action.type === "RemoveLocation" && isLocationAction(action)) {
        var newLocations = Object.assign({}, state.locations);
        delete newLocations[action.props.locationId];
        return copyState(state, { locations: newLocations });
    } else if (action.type === "AddHandout") {
        const handout = action.payload as Handout;
        const newHandouts = copyState(state.handouts, { [handout.id]: handout });
        state = copyState(state, { handouts: newHandouts });
    } else if (action.type === "RemoveHandout") {
        const handout = action.payload as Handout;
        var newHandouts = Object.assign({}, state.handouts);
        delete newHandouts[handout.id];
        return copyState(state, { handouts: newHandouts });
    } else if (action.type === "ImportCampaign") {
        const data = action.payload as {
            locations: Location[];
            tokenTemplates: TokenTemplate[];
        };

        // TODO: Do we want to override stuff that already exists? Probably not?
        let tokenTemplates = state.tokens;
        for (let tokenTemplate of data.tokenTemplates) {
            if (!tokenTemplates[tokenTemplate.templateId]) {
                tokenTemplates = Object.assign({}, tokenTemplates, { [tokenTemplate.templateId]: tokenTemplate });
            }
        }

        let locations = state.locations;
        for (let location of data.locations) {
            if (!locations[location.id]) {
                locations = Object.assign({}, locations, { [location.id]: location });
            }
        }

        state = copyState(state, { tokens: tokenTemplates, locations: locations });
    }

    if (isTokenTemplatesAction(action)) {
        if (action.type === "DeleteTokenTemplate") {
            const tokens = Object.assign({}, state.tokens);
            for (let i = 0; i < action.props.templates.length; i++) {
                delete tokens[action.props.templates[i]];
            }

            return copyState(state, { tokens: tokens });
        }

        const tokens = reduceDictionary(
            state.tokens,
            (s, a) => reduceTokenTemplate(s, a, sessionConnection, true),
            action,
            action.props.templates
        );
        if (tokens !== state.tokens) {
            state = copyState(state, { tokens: tokens });
            sessionConnection = reduceSessionConnection(state, sessionConnection);
        }
    }

    if (isCampaignPlayerAction(action)) {
        const players = reduceDictionary(state.players, campaignPlayerReducer, action, [action.props.userId]);
        if (players !== state.players) {
            return copyState(state, { players: players });
        }
    } else if (isLocationAction(action)) {
        const locations = reduceDictionary(
            state.locations,
            (s, a) => (isLocation(s!) ? locationReducer(s, a, sessionConnection) : s),
            action,
            [action.props.locationId]
        );
        if (locations !== state.locations) {
            state = copyState(state, { locations: locations });
            sessionConnection = reduceSessionConnection(state, sessionConnection);
        }
    } else if (isHandoutAction(action)) {
        const handouts = reduceDictionary(state.handouts, (s, a) => handoutReducer(s, a), action, [
            action.props.handoutId,
        ]);
        if (handouts !== state.handouts) {
            state = copyState(state, { handouts: handouts });
        }
    }

    if (action.type === "AddLevel") {
        const props = (action as LocationAction).props;
        const oldLocation = originalState.locations[props.locationId];
        const newLocation = state.locations[props.locationId];
        if (isLocation(oldLocation) && isLocation(newLocation) && user) {
            // Find the new level key.
            const oldKeys = Object.keys(oldLocation.levels);
            const newKeys = Object.keys(newLocation.levels);

            for (let i = 0; i < oldKeys.length; i++) {
                const j = newKeys.indexOf(oldKeys[i]);
                newKeys.splice(j, 1);
            }

            if (newKeys.length === 1) {
                // Found the new level, move the player there.
                let playerState = state.players[user.id];
                playerState = copyState(playerState, {
                    location: props.locationId,
                    level: newKeys[0],
                });

                let players = Object.assign({}, state.players, { [user.id]: playerState });
                state = Object.assign({}, state, { players: players });
            }
        }
    }

    if (action.type === "AddTokenTemplate") {
        const tokens = copyState(state.tokens, { [action.payload.templateId]: action.payload });
        return copyState(state, { tokens: tokens });
    }

    const scope = action.props.scope as ActionScope | undefined;
    if (scope != null) {
        // For this to work, the sessionConnection that we pass in must be updated to contain the changes that we've made so far.
        sessionConnection = reduceSessionConnection(state, sessionConnection);

        if ((scope & ActionScope.Tokens) === ActionScope.Tokens) {
            const reducedTokens = reduceDictionary(
                state.tokens,
                (s, a) => reduceTokenTemplate(s, a, sessionConnection, false),
                action
            );
            if (reducedTokens !== state.tokens) {
                state = copyState(state, { tokens: reducedTokens });
                sessionConnection = reduceSessionConnection(state, sessionConnection);
            }
        }

        if ((scope & ActionScope.Locations) === ActionScope.Locations) {
            const locations = reduceDictionary(
                state.locations,
                (s, a) => (isLocation(s!) ? locationReducer(s, a, sessionConnection) : s),
                action,
                action.props.locationId ? [action.props.locationId] : undefined
            );
            if (locations !== state.locations) {
                state = copyState(state, { locations: locations });
            }
        }

        if ((scope & ActionScope.CampaignPlayers) === ActionScope.CampaignPlayers) {
            const players = reduceDictionary(state.players, campaignPlayerReducer, action);
            if (players !== state.players) {
                state = copyState(state, { players: players });
            }
        }
    }

    if (sessionConnection.system?.reduceCampaign) {
        return sessionConnection.system.reduceCampaign(state, action);
    }

    return state;
};

export default campaignReducer;
