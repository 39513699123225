import { PayloadAction, TokensAction, TokenTemplatesAction } from "../../../actions/common";
import { isTargettedAnnotation } from "../../../annotations";
import { addToKeyedList, keyedListToArray, keyedListToKeyArray, Overwrite } from "../../../common";
import { copyState } from "../../../reducers/common";
import {
    Campaign,
    Location,
    getModifyId,
    Token,
    DiceRollLogEntry,
    DiceType,
    diceTypeToMax,
    isLocation,
    LocationSummary,
    isToken,
    Session,
    getGameTime,
    CombatEncounter,
    TokenTemplate,
    DiceRoll,
} from "../../../store";
import { DnD5EAnnotationAction } from "../actions/annotation";
import {
    Ability,
    AbilityEffect,
    AbilityEffectResult,
    AbilityInstanceResult,
    AbilityTime,
    ApplicableAbilityEffect,
    AppliedAbilityEffect,
    AppliedAbilityEffectChoices,
    AppliedAbilityEffectRef,
    CreatureConditions,
    DamageType,
    DnD5EAnnotation,
    DnD5EToken,
    DnD5ETokenTemplate,
    durationToMs,
    evaluateCharacterExpression,
    evaluateCreatureExpression,
    evaluateMonsterExpression,
    fromRuleKey,
    getRuleKey,
    isDnD5ECharacterTemplate,
    isDnD5ECharacterToken,
    isDnD5EToken,
    isDnD5ETokenTemplate,
    isNamedRuleRef,
    msPerRound,
    NamedRuleRef,
    StoredCharacter,
    StoredCreature,
    StoredMonster,
} from "../common";
import {
    canPayResourceCosts,
    Character,
    CharacterRuleSet,
    ClassLevels,
    Creature,
    CreatureCombatTurn,
    CreatureDying,
    creatureMatchesFilter,
    effectMatches,
    Feature,
    fullResolveTokenCreature,
    getAbilityDc,
    getAppliedEffect,
    getResolvedMovementSpeeds,
    isCharacter,
    isMonster,
    Monster,
    MonsterAbility,
    MonsterAbilityState,
    MovementSpeeds,
    MovementType,
    NamedSpellSlots,
    resolveCreature,
    ResolvedCharacter,
    ResolvedMonster,
    resolveModifiedValue,
    resolveTokenCreature,
} from "../creature";
import { isInventoryItem, isPack, ResolvedInventoryItem, ResolvedItem, StoredItem, unresolveItem } from "../items";
import { isSpell, Spell } from "../spells";
import { evaluateAbilityDuration, getAbility } from "../abilities";
import { nanoid } from "nanoid";
import { GridPosition } from "../../../position";
import { compareInitiatives } from "../../../reducers/location";

// function clearFields<T>(o: T, fields: (keyof T)[]) {
//     for (let i = 0; i < fields.length; i++) {
//         if (o[fields[i]] != null) {
//             delete o[fields[i]];
//         }
//     }
// }

export function getDefaultResistance(resolvedTarget: ResolvedCharacter | ResolvedMonster, damageType: DamageType) {
    // TODO: How do you even get if the creature is resistant? We have a keyed list of resistances.
    // The first one? What if one of the conditional ones currently applies? This should be sorted out in resolveTokenCreature,
    // but we don't currently resolve things with conditions like resistances/vulnerabilities/immunities.
    const isResistant = keyedListToArray(resolvedTarget?.resistances)?.[0]?.[damageType];
    const isVulnerable = keyedListToArray(resolvedTarget?.vulnerabilities)?.[0]?.[damageType];
    const isImmune = keyedListToArray(resolvedTarget?.damageImmunities)?.[0]?.[damageType];
    if (isImmune) {
        return 0;
    } else if (isResistant) {
        return 0.5;
    } else if (isVulnerable) {
        return 2;
    }

    return 1;
}

export function getTransformEffectKey(creature: Creature, rules: CharacterRuleSet) {
    if (!creature.effects) {
        return undefined;
    }

    const effectKeys = keyedListToKeyArray(creature.effects);

    // Later effects override earlier, so we start at the end and work backwards.
    for (let i = effectKeys.length - 1; i >= 0; i--) {
        const effect = creature.effects[effectKeys[i]];

        let resolvedEffect: AppliedAbilityEffect | undefined;
        if (isNamedRuleRef(effect)) {
            const lookupEffect = rules.effects.get(effect);
            if (lookupEffect) {
                resolvedEffect = getAppliedEffect(lookupEffect, effect);
            }
        } else {
            resolvedEffect = effect;
        }

        if (resolvedEffect?.transform) {
            // const transformedTemplate = campaign.tokens[transform.templateId];
            // if (isDnD5EMonsterTemplate(transformedTemplate)) {
            //     transformedTo = transformedTemplate.dnd5e;
            // } else {
            //     // Not a template, probably referencing a base monster directly.
            //     if (transform.creature) {
            //         transformedTo = rules.monsters.get(transform.creature);
            //     } else {
            //         console.error("Invalid transform.");
            //         transform = undefined;
            //     }
            // }
            if (resolvedEffect.transform.templateId || resolvedEffect.transform.creature) {
                return effectKeys[i];
            }
        }
    }

    return undefined;
}

function reduceDamageForCreature(
    storedCreature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    amount: number,
    destroy: boolean
): StoredCreature {
    let newTempHp = resolvedCreature.tempHp;
    if (destroy) {
        newTempHp = 0;
    } else {
        if (resolvedCreature.tempHp) {
            newTempHp = resolvedCreature.tempHp - amount;
            if (newTempHp < 0) {
                // Damage was left over after removing the temp HP, apply that to the main HP pool.
                amount = Math.abs(newTempHp);
                newTempHp = 0;
            } else {
                // Damage was entirely absorbed by the temp HP.
                amount = 0;
            }
        }
    }

    let maxHp: number | undefined;
    let newHp: number;
    if (destroy) {
        newHp = 0;
    } else {
        if (resolvedCreature.hp != null) {
            newHp = resolvedCreature.hp - amount;
        } else {
            maxHp = resolveModifiedValue(resolvedCreature.maxHp);
            newHp = maxHp - amount;
        }
    }

    const newCreature = copyState(
        storedCreature,
        newTempHp ? { tempHp: newTempHp } : { hp: newHp === maxHp ? undefined : newHp, tempHp: undefined }
    );
    return newCreature;
}

function reduceDamage(
    storedCreature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    amount: number,
    destroy: boolean,
    session: Session,
    rules: CharacterRuleSet,
    isAttackCrit?: boolean
) {
    let originalAmount = amount;
    if (resolvedCreature.transformedInto && !resolvedCreature.transformedInto.transform.hp) {
        const transformEffectKey = resolvedCreature.transformedInto.byEffect;
        if (transformEffectKey) {
            const transform = storedCreature.effects![transformEffectKey]!.transform!;
            if (transform.creature) {
                const newTransformedCreature = reduceDamageForCreature(
                    transform.creature,
                    resolvedCreature.transformedInto,
                    amount,
                    destroy
                ) as StoredMonster;
                if (newTransformedCreature.hp != null && newTransformedCreature.hp <= 0) {
                    // The transformed form has taken too much damage. The transformation effect ends here and the remaining damage
                    // carries over to the original form.
                    amount = -newTransformedCreature.hp;
                    storedCreature = removeEffectByKey(
                        session,
                        storedCreature,
                        resolvedCreature,
                        transformEffectKey,
                        rules
                    );
                } else {
                    // The creature in the effect has been updated, and it hasn't been reduced to 0 so there's nothing to carry over.
                    amount = 0;
                    const newTransform = copyState(transform, { creature: newTransformedCreature });
                    const newEffect = copyState(storedCreature.effects![transformEffectKey], {
                        transform: newTransform,
                    });
                    const newEffects = Object.assign({}, storedCreature.effects, { [transformEffectKey]: newEffect });
                    storedCreature = copyState(storedCreature, { effects: newEffects });
                }
            }
        }
    }

    if (amount > 0 || destroy) {
        if (isCharacter(resolvedCreature) && resolvedCreature.hp === 0) {
            // Check massive damage - if above or equal to the creature's max HP then it dies instantly.
            const maxHp = resolveModifiedValue(resolvedCreature.maxHp);
            if (amount >= maxHp) {
                return copyState(storedCreature, { dying: { failure: 3 } });
            }

            // Death saving throw rules - taking damage while at 0 results in a lost saving throw.
            const currentFailures = resolvedCreature.dying?.failure ?? 0;
            const isStable = resolvedCreature.dying?.stable;

            // Critical hits count as 2 death save failures.
            const newFailures = currentFailures + (isAttackCrit ? 2 : 1);

            if (isStable) {
                // Character was stable. Now they're not!
                storedCreature = Object.assign({}, storedCreature, {
                    dying: {
                        failure: Math.min(3, newFailures),
                    },
                });
            } else {
                // How do we handle death? Should we just record the failure(s), and in the resolve check if there is a
                // dying obj with failures >= 3, and if so add the dead condition? I guess so.
                // An easy way to check for death before healing etc would be good - could just be on the resolved?
                storedCreature = Object.assign({}, storedCreature, {
                    dying: {
                        failure: Math.min(3, newFailures),
                        success: resolvedCreature.dying?.success,
                    },
                });
            }
        }

        if (resolvedCreature.hp !== 0) {
            storedCreature = reduceDamageForCreature(storedCreature, resolvedCreature, amount, destroy);
        }
    }

    // The final creature can never have an hp < 0.
    // For this exact reason we can be sure that if it IS, then this is a new instance and we can just mutate it.
    if (storedCreature.hp != null && storedCreature.hp <= 0) {
        // Check for massive damage rule - if the damage will take the character to a negative amount equal to or greater
        // than the creature's max HP, then it dies instantly.
        const maxHp = resolveModifiedValue(resolvedCreature.maxHp);
        if (storedCreature.hp <= -maxHp) {
            // Massive damage, instant death.
            if (isCharacter(resolvedCreature)) {
                storedCreature = Object.assign({}, storedCreature as StoredCharacter, { hp: 0, dying: { failure: 3 } });
            } else {
                storedCreature = Object.assign({}, storedCreature as StoredMonster, { isDead: true });
            }
        } else {
            storedCreature.hp = 0;

            // Creature has been reduced to less than 0, they are now either dying or dead.
            if (isCharacter(resolvedCreature)) {
                if (!resolvedCreature.dying) {
                    storedCreature = Object.assign({}, storedCreature as StoredCharacter, { dying: {} });
                }
            } else {
                if (!resolvedCreature.isDead) {
                    storedCreature = Object.assign({}, storedCreature as StoredMonster, { isDead: true });
                }
            }
        }
    }

    // If the creature has taken damage at all, that can be a trigger to end certain effects.
    if (originalAmount > 0 && resolvedCreature.effects) {
        const effectKeys = Object.keys(resolvedCreature.effects);
        for (let effectKey of effectKeys) {
            if (resolvedCreature.effects[effectKey].endTriggers?.damage) {
                storedCreature = removeEffectByKey(session, storedCreature, resolvedCreature, effectKey, rules);
            }
        }
    }

    return storedCreature;
}

function reduceHealingForCreature(
    storedCreature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    amount: number,
    campaign: Campaign,
    rules: CharacterRuleSet
): StoredCreature {
    amount = Math.max(amount, 0);

    // No HP means assume full HP, so can't heal.
    if (resolvedCreature?.hp != null) {
        const maxHp = resolveModifiedValue(resolvedCreature.maxHp);
        const newHp = Math.min(maxHp, resolvedCreature.hp + amount);
        const newCreature = copyState(storedCreature, { hp: newHp });
        return newCreature;
    }

    return storedCreature;
}

function reduceHealing(
    storedCreature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    amount: number,
    campaign: Campaign,
    rules: CharacterRuleSet
): StoredCreature {
    amount = Math.max(amount, 0);

    if (resolvedCreature.transformedInto && !resolvedCreature.transformedInto.transform.hp) {
        const transformEffectKey = resolvedCreature.transformedInto.byEffect;
        if (transformEffectKey) {
            const transform = storedCreature.effects![transformEffectKey]!.transform!;
            if (transform.creature) {
                const newTransformedCreature = reduceHealingForCreature(
                    transform.creature,
                    resolvedCreature.transformedInto,
                    amount,
                    campaign,
                    rules
                ) as StoredMonster;
                if (newTransformedCreature !== transform.creature) {
                    // The creature in the effect has been updated.
                    const newTransform = copyState(transform, { creature: newTransformedCreature });
                    const newEffect = copyState(storedCreature.effects![transformEffectKey], {
                        transform: newTransform,
                    });
                    const newEffects = Object.assign({}, storedCreature.effects, { [transformEffectKey]: newEffect });
                    storedCreature = copyState(storedCreature, { effects: newEffects });
                }

                return storedCreature;
            }
        }
    }

    if (resolvedCreature.isDead) {
        // Healing doesn't work on the dead!
        return storedCreature;
    } else if (isCharacter(resolvedCreature) && resolvedCreature.dying) {
        // Healing removes any current death saves/failures.
        storedCreature = copyState(storedCreature, { dying: undefined });
    }

    return reduceHealingForCreature(storedCreature, resolvedCreature, amount, campaign, rules);
}

function applySelfEffects(
    session: Session,
    selfToken: DnD5EToken | DnD5ETokenTemplate,
    location: Location,
    self: StoredCreature,
    resolvedSelf: ResolvedCharacter | ResolvedMonster,
    ability: Ability,
    rules: CharacterRuleSet,
    abilityKey?: string,
    featureOrSpell?: Feature | Spell,
    choices?: { [effectKey: string]: AppliedAbilityEffectChoices }
) {
    if (ability.range !== "self") {
        return self;
    }

    if (ability.effects) {
        const effectKeys = keyedListToKeyArray(ability.effects);
        for (let effectKey of effectKeys) {
            let effect = ability.effects[effectKey];

            if (effect.applied) {
                self = applyEffect(
                    session,
                    self,
                    resolvedSelf,
                    effect.applied,
                    getModifyId(selfToken),
                    location,
                    undefined,
                    undefined,
                    rules,
                    abilityKey,
                    featureOrSpell,
                    choices?.[effectKey]
                );
            }

            if (effect.endApplied) {
                self = removeEffectByRef(session, self, resolvedSelf, effect.endApplied, rules);
            }
        }
    }

    return self;
}

function applySelfEffectsTokenOnly(
    selfToken: DnD5EToken,
    location: Location,
    self: StoredCreature,
    resolvedSelf: ResolvedCharacter | ResolvedMonster,
    ability: Ability,
    rules: CharacterRuleSet,
    abilityKey: string,
    feature?: Feature,
    choices?: { [effectKey: string]: AppliedAbilityEffectChoices }
) {
    if (ability.range !== "self") {
        return self;
    }

    if (ability.effects) {
        const effectKeys = keyedListToKeyArray(ability.effects);
        for (let effectKey of effectKeys) {
            let effect = ability.effects[effectKey];

            if (effect.addMovement) {
                const maxSpeeds = getResolvedMovementSpeeds(resolvedSelf);

                if (maxSpeeds) {
                    const existingMovement = self.combatTurn?.movement ?? maxSpeeds;
                    const finalMovement: Partial<MovementSpeeds<number>> = {};

                    // TODO: Apply to combat resources to increase movement speed.
                    for (let movementType in maxSpeeds) {
                        finalMovement[movementType as MovementType] =
                            (existingMovement[movementType as MovementType] ?? 0) +
                            (maxSpeeds[movementType as MovementType] ?? 0) * effect.addMovement;
                    }

                    const newCombatTurn = Object.assign({}, self.combatTurn, { movement: finalMovement });
                    self = copyState(self, { combatTurn: newCombatTurn });
                }
            }
        }
    }

    return self;
}

function reduceMarkAbilityUse(storedCreature: StoredCreature, abilityKey: string, amount: number = 1) {
    const oldUsedAbilities = storedCreature.abilityUsage;
    const oldUsedAbility = oldUsedAbilities?.[abilityKey];
    const newUsedAbility = Object.assign({}, oldUsedAbility, { used: (oldUsedAbility?.used ?? 0) + amount });
    const newUsedAbilities = Object.assign({}, oldUsedAbilities, { [abilityKey]: newUsedAbility });
    const newCreature = copyState(storedCreature, { abilityUsage: newUsedAbilities });
    return newCreature;
}

function reduceClearAbilityUse(storedCreature: StoredCreature, abilityKey: string, amount: number = 1) {
    const oldUsedAbilities = (storedCreature as Monster).abilityUsage;
    const oldUsedAbility = oldUsedAbilities?.[abilityKey];

    const newAmount = Math.max(0, (oldUsedAbility?.used ?? 0) - amount);
    const newUsedAbility = Object.assign({}, oldUsedAbility, { used: newAmount });
    const newUsedAbilities = Object.assign({}, oldUsedAbilities, { [abilityKey]: newUsedAbility });
    const newCreature = copyState(storedCreature, { abilityUsage: newUsedAbilities });
    return newCreature;
}

function reduceMarkResource(
    storedCreature: StoredCreature,
    creature: Creature | undefined,
    resource: string,
    amount: number = 1
) {
    if (isCharacter(creature)) {
        const oldUsedResources = (storedCreature as Character).usedResources;
        const newUsedResources = Object.assign({}, oldUsedResources, {
            [resource]: (oldUsedResources?.[resource] ?? 0) + amount,
        });
        const newCharacter = copyState(storedCreature, { usedResources: newUsedResources });
        return newCharacter;
    }

    return storedCreature;
}

function reduceClearResource(
    storedCreature: StoredCreature,
    creature: Creature | undefined,
    resource: string,
    amount: number = 1
) {
    if (isCharacter(creature)) {
        const oldUsedResources = (storedCreature as Character).usedResources;
        const newUsedResources = Object.assign({}, oldUsedResources);
        const newAmount = (oldUsedResources?.[resource] ?? 0) - amount;
        if (newAmount <= 0) {
            delete newUsedResources[resource];
        } else {
            newUsedResources[resource] = newAmount;
        }

        const newCharacter = copyState(storedCreature, { usedResources: newUsedResources });
        return newCharacter;
    }

    return storedCreature;
}

function reduceUseSpellSlot(
    storedCreature: StoredCreature,
    creature: Creature | undefined,
    level: number | { name?: string; level: number }
) {
    const lvl = typeof level === "number" ? level : level.level;
    const name = typeof level === "number" ? undefined : level.name;

    if (isCharacter(creature)) {
        const currentSpellSlots = !name ? creature.usedSpellSlots?.default : creature.usedSpellSlots?.[name];
        const spellSlots = currentSpellSlots?.slice() ?? [];
        const usedSlots = spellSlots[lvl - 1];
        spellSlots[lvl - 1] = usedSlots != null ? usedSlots + 1 : 1;
        for (let i = 0; i < lvl - 1; i++) {
            spellSlots[i] = spellSlots[i] ?? 0;
        }

        const newUsedSpellSlots = copyState(creature.usedSpellSlots ?? {}, {
            [name != null ? name : "default"]: spellSlots,
        });
        const newCreature = copyState(storedCreature, { usedSpellSlots: newUsedSpellSlots as any });
        return newCreature;
    } else if (isMonster(creature)) {
        const spellSlots = creature.usedSpellSlots?.slice() ?? [];
        const usedSlots = spellSlots[lvl - 1];
        spellSlots[lvl - 1] = usedSlots != null ? usedSlots + 1 : 1;
        for (let i = 0; i < lvl - 1; i++) {
            spellSlots[i] = spellSlots[i] ?? 0;
        }

        const newCreature = copyState(storedCreature, { usedSpellSlots: spellSlots });
        return newCreature;
    }

    return storedCreature;
}

function reduceClearSpellSlot(
    storedCreature: StoredCreature,
    creature: Creature,
    level: number | { name: string; level: number }
) {
    const lvl = typeof level === "number" ? level : level.level;
    const name = typeof level === "number" ? undefined : level.name;

    if (isCharacter(creature)) {
        const currentSpellSlots = !name ? creature.usedSpellSlots?.default : creature.usedSpellSlots?.[name];
        const spellSlots = currentSpellSlots?.slice() ?? [];
        const usedSlots = spellSlots[lvl - 1];
        if (usedSlots != null) {
            spellSlots[lvl - 1] = Math.max(usedSlots - 1, 0);
            for (let i = 0; i < lvl - 1; i++) {
                spellSlots[i] = spellSlots[i] ?? 0;
            }

            const newUsedSpellSlots = copyState(creature.usedSpellSlots ?? {}, {
                [name != null ? name : "default"]: spellSlots,
            });
            const newCreature = copyState(storedCreature, { usedSpellSlots: newUsedSpellSlots as any });
            return newCreature;
        }
    } else if (isMonster(creature)) {
        const spellSlots = creature.usedSpellSlots?.slice() ?? [];
        const usedSlots = spellSlots[lvl - 1];
        if (usedSlots != null) {
            spellSlots[lvl - 1] = Math.max(usedSlots - 1, 0);
            for (let i = 0; i < lvl - 1; i++) {
                spellSlots[i] = spellSlots[i] ?? 0;
            }

            const newCreature = copyState(storedCreature, { usedSpellSlots: spellSlots });
            return newCreature;
        }
    }

    return storedCreature;
}

function reduceRechargeAbility(storedCreature: StoredCreature, creature: Monster, abilityKey: string) {
    // Remove the isRecharging state from the ability.
    const allAbilitiesState = creature.abilityState;
    const abilityState = allAbilitiesState?.[abilityKey];
    if (abilityState) {
        const newAbilityState: MonsterAbilityState = copyState(abilityState, {
            isRecharging: undefined,
        } as MonsterAbilityState);
        const newAllAbilitiesState = Object.assign({}, allAbilitiesState, {
            [abilityKey]: newAbilityState,
        });
        storedCreature = Object.assign({}, storedCreature, { abilityState: newAllAbilitiesState });
    }

    return storedCreature;
}

function reduceRemoveFromCombat(storedCreature: StoredCreature, creature?: Creature) {
    // Remove any information relating to the combat turn.
    storedCreature = copyState(storedCreature, { combatTurn: undefined });

    // Remove any ability state that is only relevant to combat.
    if (isMonster(creature)) {
        const storedMonster = storedCreature as StoredMonster;
        if (storedMonster.abilityState) {
            let newAbilityState = storedMonster.abilityState;
            for (let key in storedMonster.abilityState) {
                if (storedMonster.abilityState[key].isRecharging) {
                    const state = copyState(storedMonster.abilityState[key], { isRecharging: undefined });
                    newAbilityState = copyState(newAbilityState, { [key]: state });
                }
            }

            if (newAbilityState !== storedMonster.abilityState) {
                storedCreature = copyState(storedCreature, { abilityState: newAbilityState });
            }
        }
    }

    return storedCreature;
}

function lookUpEffect(
    effect: (NamedRuleRef | ApplicableAbilityEffect) | undefined,
    rules: CharacterRuleSet
): ApplicableAbilityEffect | undefined;
function lookUpEffect(
    effect: (NamedRuleRef | AppliedAbilityEffect) | undefined,
    rules: CharacterRuleSet
): AppliedAbilityEffect | ApplicableAbilityEffect | undefined;
function lookUpEffect(
    effect: (NamedRuleRef | AppliedAbilityEffect | ApplicableAbilityEffect) | undefined,
    rules: CharacterRuleSet
) {
    if (effect == null) {
        return undefined;
    }

    if (isNamedRuleRef(effect)) {
        const applicable = rules.effects.get(effect);
        return applicable;
    }

    return effect;
}

// function reduceDurationByRound(duration: DnD5EDuration): DnD5EDuration {
//     let amount: number;
//     switch (duration.unit) {
//         case "special":
//         case "permanent":
//             return duration;
//         case "day":
//             amount = (duration.amount ?? 1) * roundsPerDay;
//             break;
//         case "hour":
//             amount = (duration.amount ?? 1) * roundsPerHour;
//             break;
//         case "minute":
//             amount = (duration.amount ?? 1) * roundsPerMinute;
//             break;
//         case "round":
//             amount = duration.amount ?? 1;
//             break;
//     }

//     amount--;
//     return Object.assign({}, duration, { unit: "round", amount: amount });
// }

// function reduceEffectDurationByRound(creature: StoredCreature, resolvedCreature: ResolvedMonster | ResolvedCharacter, effectKey: string, rules: CharacterRuleSet): StoredCreature {
//     const effect = lookUpEffect(creature.effects?.[effectKey], rules);
//     if (effect && effect.duration) {
//         const newDuration = reduceDurationByRound(effect.duration);
//         if (newDuration !== effect.duration) {
//             if (newDuration.amount! <= 0) {
//                 creature = removeEffectByKey(creature, resolvedCreature, effectKey, rules);
//             } else {
//                 const newEffect = Object.assign({}, creature.effects?.[effectKey], { duration: newDuration });
//                 let newEffects = Object.assign({}, creature.effects, { [effectKey]: newEffect });
//                 creature = copyState(creature, { effects: newEffects });
//             }
//         }
//     }

//     return creature;
// }

function reduceCombatTimeCost(creature: StoredCreature, location: Location, time: AbilityTime | undefined) {
    // If there is a combat in progress, then register the time cost of the ability against the character.
    if (time && location?.combat) {
        let combatTurn = creature.combatTurn;
        if (time.unit === "action") {
            combatTurn = Object.assign({}, combatTurn, { action: true });
        } else if (time.unit === "bonus") {
            combatTurn = Object.assign({}, combatTurn, { bonus: true });
        } else if (time.unit === "reaction") {
            combatTurn = Object.assign({}, combatTurn, { reaction: true });
        } else if (time.unit === "legendary") {
            combatTurn = Object.assign({}, combatTurn, { legendary: (combatTurn?.legendary ?? 0) + time.amount });
        }

        if (combatTurn !== creature.combatTurn) {
            creature = copyState(creature, { combatTurn: combatTurn });
        }
    }

    return creature;
}

export function isExcluded(
    annotation: DnD5EAnnotation,
    target: DnD5EToken | DnD5ETokenTemplate,
    ability: Ability,
    effectId: string,
    instanceId: string,
    campaign: Campaign,
    location: Location,
    rules: CharacterRuleSet
): boolean {
    const isExcludedExplicitly =
        annotation.dnd5e.targetEffects?.[getModifyId(target)]?.[instanceId]?.effects?.[effectId]?.isExcluded;
    if (isExcludedExplicitly != null) {
        return isExcludedExplicitly;
    }

    let caster: Token | undefined;
    if (annotation.tokenId) {
        caster = location.tokens[annotation.tokenId];
    }

    // If there is a target filter, and the creature doesn't match it, then it defaults to excluded.
    if (ability.targetFilter) {
        const creature = resolveTokenCreature(target, campaign, rules);

        if (ability.targetFilter.self != null && caster && getModifyId(target) === getModifyId(caster)) {
            return ability.targetFilter.self;
        }

        if (creature && !creatureMatchesFilter(creature, ability.targetFilter)) {
            return true;
        }
    }

    if (!caster) {
        return false;
    }

    // If an AOE spell is cast upon ones self, typically it won't affect the caster if its a damage effect - i.e. thunderwave, spiritual guardians.
    const effect = ability.effects?.[effectId];
    return (
        ability.aoe != null &&
        ability.range === "self" &&
        getModifyId(target) === getModifyId(caster) &&
        effect?.damage != null
    );
}

function flattenEffect(effect: ApplicableAbilityEffect, rules: CharacterRuleSet): ApplicableAbilityEffect {
    if (effect.extends) {
        const baseEffect = rules.effects.get(effect.extends);
        if (baseEffect) {
            return Object.assign({}, flattenEffect(baseEffect, rules), effect) as ApplicableAbilityEffect;
        }
    }

    return effect;
}

function applyEffect(
    session: Session,
    creature: StoredCreature,
    resolvedCreature: ResolvedCharacter | ResolvedMonster,
    effect: ApplicableAbilityEffect | AppliedAbilityEffectRef,
    appliedBy: string | undefined,
    appliedAt: Location | LocationSummary | undefined,
    instanceId: string | undefined,
    savingThrowDc: number | undefined,
    rules: CharacterRuleSet,
    abilityKey?: string,
    featureOrSpell?: Feature | Spell,
    choices?: AppliedAbilityEffectChoices
): StoredCreature {
    const resolvedEffect = lookUpEffect(effect, rules);
    if (!resolvedEffect) {
        return creature;
    }

    const effectKeys = keyedListToKeyArray(creature.effects);
    if (effectKeys) {
        if (isNamedRuleRef(effect)) {
            const existingEffectKey = findEffectByRef(creature, effect, rules);
            if (existingEffectKey) {
                const existingEffect = lookUpEffect(creature.effects![existingEffectKey], rules);
                if (existingEffect && (!existingEffect.allowMultiple || !resolvedEffect.allowMultiple)) {
                    // The effect has already been applied. Merge the existing effect with the new one.
                    // TODO: As per dnd5e rules, if an effect is applied twice then the best value should be used, e.g.
                    // if a spell is cast again at a higher level, the higher level effect should take precedence.
                    return creature;
                }
            }
        } else {
            // Only one effect with this ID is allowed. Check if the effect has already been applied.
            for (let i = 0; i < effectKeys.length; i++) {
                const existingEffect = creature.effects![effectKeys[i]];
                const resolvedExistingEffect = lookUpEffect(existingEffect, rules);
                if (
                    resolvedExistingEffect &&
                    (!resolvedExistingEffect.allowMultiple || !resolvedEffect.allowMultiple)
                ) {
                    // TODO: Should probably compare by full NamedRuleRef where possible.
                    if (resolvedExistingEffect.name === resolvedEffect.name) {
                        // The effect has already been applied. Merge the existing effect with the new one.
                        // TODO: As per dnd5e rules, if an effect is applied twice then the best value should be used, e.g.
                        // if a spell is cast again at a higher level, the higher level effect should take precedence.
                        return creature;
                    }
                }
            }
        }
    }

    // TODO: Apply the choices that have been made to for the effect?
    // Convert from an applicable effect to an applied effect.
    const appliedEffect: AppliedAbilityEffectRef | AppliedAbilityEffect = isNamedRuleRef(effect)
        ? Object.assign({}, effect, choices)
        : getAppliedEffect(effect, choices);
    const flat = flattenEffect(resolvedEffect, rules);
    if (flat.duration) {
        appliedEffect.duration = evaluateAbilityDuration(resolvedCreature, flat.duration);

        let durationInMs = durationToMs(appliedEffect.duration);
        if (durationInMs != null) {
            // If this is taking place in combat, and the current turn is BEFORE the caster's turn in combat order (i.e.
            // the caster is casting the spell as a reaction), then 1 round should be subtracted from the time remaining.
            // For example, if a monk applies stunning strike to a monster as an opportunity attack on the monster's turn, and
            // the monster is before the monk in initiative, then the stunning strike should expire at the end of the monk's
            // next turn - which will be on this round, because the monk hasn't had their turn yet this round.
            const trigger = appliedEffect.duration.trigger;
            if (durationInMs > 0 && (trigger === "source_sot" || trigger === "source_eot") && appliedBy) {
                if (isLocation(appliedAt) && appliedAt.combat && appliedAt.combat.turn !== appliedBy) {
                    const sourceInitiative = appliedAt.combat.participants[appliedBy];
                    const currentInitiative = appliedAt.combat.turn
                        ? appliedAt.combat.participants[appliedAt.combat.turn]
                        : undefined;
                    if (
                        sourceInitiative &&
                        currentInitiative &&
                        compareInitiatives(currentInitiative, sourceInitiative) < 0
                    ) {
                        durationInMs = Math.max(durationInMs - msPerRound, 0);
                    }
                }
            }

            appliedEffect.duration.expires = getGameTime(session.time) + durationInMs;
        }
    }

    appliedEffect.savingThrowDc = savingThrowDc;

    let effectToApply: (AppliedAbilityEffectRef | AppliedAbilityEffect) & {
        feature?: NamedRuleRef;
        ability?: string;
        spell?: NamedRuleRef;
    };
    const common = { appliedBy: appliedBy, appliedAt: appliedAt?.id, ability: abilityKey };
    if (isSpell(featureOrSpell)) {
        effectToApply = Object.assign(
            {},
            appliedEffect,
            {
                spell: { name: featureOrSpell.name, source: featureOrSpell.source },
                instanceId: featureOrSpell.duration?.isConcentration ? instanceId : undefined,
            },
            common
        );
    } else if (featureOrSpell && featureOrSpell.source) {
        effectToApply = Object.assign(
            {},
            appliedEffect,
            { feature: { name: featureOrSpell.name, source: featureOrSpell.source } },
            common
        );
    } else {
        effectToApply = Object.assign({}, appliedEffect, common);
    }

    return copyState(creature, { effects: addToKeyedList(creature.effects, effectToApply) });
}

function removeEffectByKey(
    session: Session,
    creature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    effectKey: string,
    rules: CharacterRuleSet
) {
    if (creature?.effects) {
        const effect = lookUpEffect(creature.effects[effectKey], rules);
        if (effect) {
            if (effect.endEffects) {
                const effects = keyedListToArray(effect.endEffects);
                for (let effect of effects) {
                    const result = reduceEffect(session, rules, creature, resolvedCreature, effect);
                    creature = result.creature;
                }
            }

            const newEffects = copyState(creature.effects!, { [effectKey]: undefined });
            creature = copyState(creature, { effects: Object.keys(newEffects).length === 0 ? undefined : newEffects });
        }
    }

    return creature;
}

function findEffectByRef(creature: StoredCreature, effect: NamedRuleRef, rules: CharacterRuleSet) {
    const effectKeys = keyedListToKeyArray(creature.effects);

    const refKey = getRuleKey(effect);
    const effectKey = effectKeys?.find(o => {
        const e = creature.effects![o];
        return effectMatches(e, refKey, rules);
    });

    return effectKey;
}

function removeEffectByRef(
    session: Session,
    creature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    effect: NamedRuleRef,
    rules: CharacterRuleSet
) {
    if (creature && creature.effects) {
        const effectKey = findEffectByRef(creature, effect, rules);
        if (effectKey) {
            creature = removeEffectByKey(session, creature, resolvedCreature, effectKey, rules);
        }
    }

    return creature;
}

function reduceEffect(
    session: Session,
    rules: CharacterRuleSet,
    storedCreature: StoredCreature,
    resolvedCreature: ResolvedMonster | ResolvedCharacter,
    abilityStuff: { ability: Ability; abilityEffectKey: string } | AbilityEffect,
    instanceEffect?: AbilityEffectResult,
    annotation?: DnD5EAnnotation,
    location?: Location | LocationSummary
) {
    let damageAmount = 0;
    let healingAmount = 0;
    let ability: Ability | undefined;
    let abilityEffect: AbilityEffect | undefined;
    let abilityEffectKey: string | undefined;
    if (abilityStuff["ability"]) {
        const a = abilityStuff as { ability: Ability; abilityEffectKey: string };
        ability = a.ability;
        abilityEffect = a.ability.effects?.[a.abilityEffectKey];
        abilityEffectKey = a.abilityEffectKey;
    } else {
        abilityEffect = abilityStuff as AbilityEffect;
    }

    if (abilityEffect) {
        // Check if the saving throw succeeded. If it did, halve the damage.
        const savingThrowDc = annotation?.dnd5e.savingThrowDc;
        let isSaveSuccess = false;
        if (abilityEffect.savingThrow != null) {
            const savingThrowResult = instanceEffect?.savingThrow;
            isSaveSuccess =
                savingThrowDc != null && savingThrowResult != null && savingThrowResult.result >= savingThrowDc;
        }

        // Apply the damage by what's in the results, rather than what the original effect says, as options and effects
        // on the target may have added extra damage rolls that aren't in the original effect.
        let damageTypes: DamageType[] | undefined;
        let damageByType = isTargettedAnnotation(annotation)
            ? instanceEffect?.damage
            : abilityEffectKey != null
            ? annotation?.dnd5e.effects?.[abilityEffectKey]?.damage
            : undefined;
        if (damageByType) {
            damageTypes = Object.keys(damageByType) as DamageType[];
        }

        // But also include all the damage types from the original effect, in case they didn't require a roll.
        if (abilityEffect.damage) {
            damageTypes = damageTypes ?? [];
            for (let dt of damageTypes) {
                if (abilityEffect.damage[dt] && damageTypes.indexOf(dt) < 0) {
                    damageTypes.push(dt);
                }
            }
        }

        if (damageTypes) {
            for (let damageType of damageTypes) {
                let damage: number;
                if (damageByType?.[damageType]) {
                    // The damage was rolled.
                    damage = damageByType[damageType]?.result ?? 0;
                } else {
                    // The damage was not rolled, maybe the ability always does the same damage (doesn't require a roll).
                    damage = Number(abilityEffect.damage?.[damageType]?.base);
                    if (isNaN(damage)) {
                        damage = 0;
                    }
                }

                // Check if the saving throw succeeded. If it did, halve the damage.
                if (isSaveSuccess) {
                    damage = Math.floor(damage * (abilityEffect.damage?.[damageType]?.onSave ?? 1));
                }

                // Now apply any resistances/vulnerabilities/immunities.
                const modifier =
                    instanceEffect?.resistances?.[damageType] ?? getDefaultResistance(resolvedCreature, damageType);
                damage = Math.floor(damage * modifier);
                damageAmount += damage;
            }
        }

        if (abilityEffect.heal) {
            let healing: number | undefined = undefined;

            if (instanceEffect?.healing) {
                // The healing was rolled.
                healing = instanceEffect.healing.result;
            } else if (abilityEffectKey != null) {
                healing = annotation?.dnd5e.effects?.[abilityEffectKey]?.healing?.result;
            }

            if (healing == null) {
                // The healing was not rolled, maybe the ability always does the same healing (doesn't require a roll).
                healing = Number(abilityEffect.heal.base);
                if (isNaN(healing)) {
                    healing = 0;
                }
            }

            healingAmount += healing;
        }

        if (abilityEffect.applied && !isSaveSuccess) {
            // TODO: Don't seem to be able to tell if a feature is related to the ability?
            storedCreature = applyEffect(
                session,
                storedCreature,
                resolvedCreature,
                abilityEffect.applied,
                annotation!.tokenId,
                location,
                annotation!.id,
                savingThrowDc,
                rules,
                annotation!.dnd5e.ability,
                isSpell(ability) ? ability : undefined,
                instanceEffect?.appliedChoices
            );
        }

        if (abilityEffect.exhaustion != null && isCharacter(resolvedCreature)) {
            const exhaustion = Math.max(Math.min((resolvedCreature.exhaustion ?? 0) + abilityEffect.exhaustion, 6), 0);
            storedCreature = copyState(storedCreature, { exhaustion: exhaustion === 0 ? undefined : exhaustion });
        }
    }

    return { damage: damageAmount, healing: healingAmount, creature: storedCreature };
}

/**
 * Given a token ID, returns whether the specified token or template should be modified.
 * If a token has a template, then generally the template should be modified (unless the token has specified to ignore the template).
 * @param tokenId The ID of a token.
 * @param tokenOrTemplate The token or token template.
 */
function shouldModifyToken(tokenId: string, tokenOrTemplate: DnD5EToken | DnD5ETokenTemplate, location: Location) {
    if (isDnD5EToken(tokenOrTemplate)) {
        if (tokenOrTemplate.id === tokenId) {
            return !tokenOrTemplate.templateId || !!tokenOrTemplate.ignoreTemplate;
        } else if (tokenOrTemplate.templateId === tokenId) {
            return !!tokenOrTemplate.ignoreTemplate;
        }
    } else if (isDnD5ETokenTemplate(tokenOrTemplate)) {
        if (tokenOrTemplate.templateId === tokenId) {
            return true;
        } else if (location.tokens[tokenId]?.templateId === tokenOrTemplate.templateId) {
            return !location.tokens[tokenId].ignoreTemplate;
        }
    }

    return false;
}

export function reduceCreature(
    token: DnD5EToken | DnD5ETokenTemplate,
    creature: Creature | undefined,
    action: PayloadAction,
    session: Session,
    location: Location,
    rules: CharacterRuleSet
): StoredCreature {
    const campaign = session.campaign;
    let storedCreature = token.dnd5e;
    if (action.type === "MoveToken") {
        const payload = action.payload as {
            path: GridPosition[];
            cost: number;
            rotation: number;
            requiresApproval: boolean;
        };
        if (!payload.requiresApproval && isToken(token) && location?.combat && location.combat.participants[token.id]) {
            let oldMovement = creature?.combatTurn?.movement;
            if (!oldMovement) {
                // Hasn't moved yet this turn, need the creature's full movement.
                const resolvedCreature = fullResolveTokenCreature(token, campaign, rules);
                oldMovement = resolvedCreature ? getResolvedMovementSpeeds(resolvedCreature) : undefined;
            }

            if (oldMovement) {
                // This creature has movement speed, so subtract the cost of the current move.
                const newMovement: Partial<MovementSpeeds<number>> = {};
                for (let speed in oldMovement) {
                    if (oldMovement[speed] != null) {
                        newMovement[speed] = Math.max(0, oldMovement[speed] - payload.cost * 5);
                    }
                }

                const oldCombatTurn = creature?.combatTurn;
                const newCombatTurn = Object.assign({}, oldCombatTurn, { movement: newMovement });
                storedCreature = Object.assign({}, storedCreature, { combatTurn: newCombatTurn });
            }
        }
    } else if (action.type === "DnD5E_Heal") {
        if (creature) {
            const resolvedCreature = resolveCreature(creature, campaign, rules);
            if (resolvedCreature) {
                return reduceHealing(
                    storedCreature,
                    resolvedCreature,
                    (action as PayloadAction).payload,
                    campaign,
                    rules
                );
            }
        }
    } else if (action.type === "DnD5E_Damage") {
        let payload = (action as PayloadAction).payload;
        let amount: number;
        if (typeof payload === "number") {
            amount = payload;
        } else {
            amount = payload[getModifyId(token)] ?? 0;
        }

        if (creature) {
            const resolvedCreature = resolveCreature(creature, campaign, rules);
            if (resolvedCreature) {
                return reduceDamage(storedCreature, resolvedCreature, Math.max(amount, 0), false, session, rules);
            }
        }
    } else if (action.type === "DnD5E_AnnotationApply") {
        const annotationAction = action as DnD5EAnnotationAction &
            (TokensAction | TokenTemplatesAction) & { props: { targets: string[] } };
        const location = campaign.locations[annotationAction.props.locationId];
        if (!isLocation(location)) {
            return storedCreature;
        }

        const annotation = location?.annotations?.[annotationAction.props.annotationId] as DnD5EAnnotation | undefined;
        const tokenId = getModifyId(token);

        if (annotation?.tokenId) {
            let modifySourceToken = shouldModifyToken(annotation.tokenId, token, location);
            const ability = getAbility(annotation, campaign, location, rules);
            if (isDnD5EToken(token) && token.id === annotation.tokenId) {
                storedCreature = reduceCombatTimeCost(storedCreature, location, ability?.time);

                // TODO: We're doing this so that we can trigger animations off the last applied changing... but how
                // do we trigger animations on an annotation that's being removed? We'd have to copy the relevant
                // parts of the annotation here.
                var copy = Object.assign({}, annotation);

                const copyDnd5e = Object.assign({}, annotation.dnd5e);
                copy.dnd5e = copyDnd5e;

                storedCreature = copyState(storedCreature, {
                    lastAppliedAbility: copy,
                });

                // If these are weapon attacks that are part of an attack action, also increment the attacks made so far this turn.
                if (
                    location.combat &&
                    location.combat.participants[token.id] &&
                    ability &&
                    annotation.dnd5e.targetEffects &&
                    annotation.dnd5e.isAttackAction
                ) {
                    // const hasAttacks = ability.attack === "mw" || ability.attack === "rw" || ability.attack === "mw,rw";
                    if (ability.isAttackAction) {
                        if (ability.item) {
                            // This is a weapon attack. Add one attack for each target.
                            let attackCount = storedCreature.combatTurn?.attacks?.[ability.item] ?? 0;
                            for (let targetKey in annotation.dnd5e.targetEffects) {
                                const instances = annotation.dnd5e.targetEffects[targetKey];
                                for (let instanceKey in instances) {
                                    const instance = instances[instanceKey];
                                    if (instance.attack) {
                                        attackCount++;
                                    }
                                }
                            }

                            let newAttacks = Object.assign({}, storedCreature.combatTurn?.attacks, {
                                [ability.item]: attackCount,
                            });
                            const newCombatTurn = Object.assign({}, storedCreature.combatTurn, { attacks: newAttacks });
                            storedCreature = copyState(storedCreature, { combatTurn: newCombatTurn });
                        } else if (annotation.dnd5e.ability) {
                            // Not a weapon attack - a different ability that counts as part of the attack/multiattack action.
                            let abilityCount = storedCreature.combatTurn?.attacks?.[annotation.dnd5e.ability] ?? 0;
                            for (let targetKey in annotation.dnd5e.targetEffects) {
                                const instances = annotation.dnd5e.targetEffects[targetKey];
                                for (let instanceKey in instances) {
                                    const instance = instances[instanceKey];
                                    if (instance.attack) {
                                        abilityCount++;
                                    }
                                }
                            }

                            // If the ability doesn't have any attacks, then its usage count is 1.
                            abilityCount = abilityCount ?? 1;

                            let newAttacks = Object.assign({}, storedCreature.combatTurn?.attacks, {
                                [annotation.dnd5e.ability]: abilityCount,
                            });
                            const newCombatTurn = Object.assign({}, storedCreature.combatTurn, { attacks: newAttacks });
                            storedCreature = copyState(storedCreature, { combatTurn: newCombatTurn });
                        }
                    }
                }
            }

            if (modifySourceToken) {
                if (ability) {
                    for (let resource in ability.resourceCost) {
                        storedCreature = reduceMarkResource(
                            storedCreature,
                            creature,
                            resource,
                            ability.resourceCost[resource]
                        );
                    }
                }

                // Any annotation other than a target annotation has an effect just by existing.
                let hasAnyOngoingEffect = annotation.type !== "target";

                // This token is the source of the annotation. This means there might be some stuff needed to be done
                // even if the token isn't a target.
                const effects = annotation.dnd5e.targetEffects;
                if (effects) {
                    for (let effectKey in effects) {
                        const instanceResults = effects[effectKey];
                        if (instanceResults) {
                            for (let instanceKey in instanceResults) {
                                const instanceResult = instanceResults[instanceKey];

                                if (!hasAnyOngoingEffect) {
                                    for (let instanceEffectKey in instanceResult.effects) {
                                        const instanceEffectResult = instanceResult.effects[instanceEffectKey];
                                        if (
                                            !annotation.dnd5e.savingThrowDc ||
                                            instanceEffectResult.savingThrow == null ||
                                            instanceEffectResult.savingThrow.result < annotation.dnd5e.savingThrowDc
                                        ) {
                                            // There was no save, or the save was failed.
                                            // We have an ongoing effect so long as there is an applied effect for this effect.
                                            hasAnyOngoingEffect = ability?.effects?.[instanceEffectKey].applied != null;
                                        }
                                    }
                                }

                                if (
                                    instanceResult.options &&
                                    (isDnD5ECharacterToken(token) || isDnD5ECharacterTemplate(token))
                                ) {
                                    const character = fullResolveTokenCreature(
                                        token,
                                        campaign,
                                        rules
                                    ) as ResolvedCharacter;
                                    const options = [
                                        ...(character.beforeAttack ?? []),
                                        ...(character.afterAttack ?? []),
                                    ];
                                    for (let featureKey in instanceResult.options) {
                                        const option = options.find(o => getRuleKey(o.feature) === featureKey);
                                        if (option) {
                                            // Pay the costs of the attack option.
                                            if (option.resourceCost) {
                                                for (let resource in option.resourceCost) {
                                                    storedCreature = reduceMarkResource(
                                                        storedCreature,
                                                        creature,
                                                        resource,
                                                        option.resourceCost[resource]
                                                    );
                                                }
                                            }

                                            if (option.spellSlotCost) {
                                                const results = instanceResult.options[featureKey]?.[option.key];
                                                if (results.spellSlotCost) {
                                                    storedCreature = reduceUseSpellSlot(
                                                        storedCreature,
                                                        creature,
                                                        results.spellSlotCost
                                                    );
                                                }
                                            }

                                            if (option.source) {
                                                // The token is the attacker, apply the attacker effects to it.
                                                // TODO: This is where we need to plug in the choices for the effects.
                                                storedCreature = applyEffect(
                                                    session,
                                                    storedCreature,
                                                    character,
                                                    option.source,
                                                    annotation.tokenId,
                                                    location,
                                                    annotation.id,
                                                    annotation.dnd5e.savingThrowDc,
                                                    rules,
                                                    undefined,
                                                    option.feature,
                                                    undefined
                                                );
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                // Targetted annotations mark off their usage upon completion.
                if (annotation.type === "target" && creature && annotation.dnd5e.ability) {
                    const resolvedCreature = resolveCreature(creature, campaign, rules);
                    const maxUses = evaluateCreatureExpression(
                        resolvedCreature,
                        ability?.maxUsesExpr,
                        ability?.maxUses
                    );
                    if (maxUses != null) {
                        storedCreature = reduceMarkAbilityUse(storedCreature, annotation.dnd5e.ability);
                    }
                }

                // If all of the targets made their save and it's a targetted annotation, then stop concentrating on the spell - it
                // doesn't do anything any more.
                if (!hasAnyOngoingEffect && storedCreature.concentrating?.annotation === annotation.id) {
                    storedCreature = copyState(storedCreature, { concentrating: undefined });
                }

                if (
                    isMonster(creature) &&
                    isToken(token) &&
                    (ability as MonsterAbility)?.rechargeOn &&
                    annotation.dnd5e.ability &&
                    location.combat?.participants?.[token.id]
                ) {
                    const abilityKey = annotation.dnd5e.ability;

                    // This is a recharge ability, so we need to store the fact that this ability is recharging now.
                    const allAbilitiesState = creature.abilityState;
                    const abilityState = allAbilitiesState?.[abilityKey];
                    const newAbilityState: MonsterAbilityState = Object.assign({}, abilityState, {
                        isRecharging: true,
                    } as MonsterAbilityState);
                    const newAllAbilitiesState = Object.assign({}, allAbilitiesState, {
                        [abilityKey]: newAbilityState,
                    });
                    storedCreature = Object.assign({}, storedCreature, { abilityState: newAllAbilitiesState });
                }
            }

            // If this is a recharge ability, the combat turn needs to be updated with to record that the recharge
            // ability was used this turn (and therefore no roll for recharging should be made this turn).
            // We do this by storing a 0 as the recharge roll result.
            if (
                location?.combat &&
                isDnD5EToken(token) &&
                token.id === annotation.tokenId &&
                isMonster(creature) &&
                (ability as MonsterAbility)?.rechargeOn &&
                annotation.dnd5e.ability
            ) {
                const newRecharges = Object.assign({}, token.dnd5e.combatTurn?.recharges, {
                    [annotation.dnd5e.ability]: 0,
                });
                const newCombatTurn = Object.assign({}, token.dnd5e.combatTurn, { recharges: newRecharges });
                storedCreature = copyState(storedCreature, { combatTurn: newCombatTurn });
            }
        }

        if (annotationAction.props.targets.indexOf(tokenId) >= 0 && shouldModifyToken(tokenId, token, location)) {
            // This token is a target of this annotation.
            const annotationDnd5e = annotation!.dnd5e!;
            const resolvedTarget = fullResolveTokenCreature(token, campaign, rules);
            if (resolvedTarget) {
                const ability = getAbility(annotation!, campaign, location, rules);
                if (ability) {
                    // TODO: Can't rely on the instance results being there, they might not be for beneficial effects that require no choices.
                    // We need to know here how many times the token has been targetted and then proceed as though we have some instanceResults.
                    // For now we just fake a single instance, deal with this later.
                    const instanceResults = annotationDnd5e.targetEffects?.[tokenId] ?? { "0": {} };
                    if (instanceResults) {
                        for (let instanceKey in instanceResults) {
                            const instanceResult = instanceResults[instanceKey] as AbilityInstanceResult;

                            const isAttackMiss =
                                isTargettedAnnotation(annotation) &&
                                instanceResult.attack &&
                                (instanceResult.hit === "miss" || instanceResult.hit === "crit_miss");
                            const isAttackCrit =
                                isTargettedAnnotation(annotation) &&
                                instanceResult.attack &&
                                instanceResult.hit === "crit_hit";

                            // Get the selected attack options and apply them if necessary.
                            if (instanceResult.options && annotation!.tokenId) {
                                const sourceToken = location.tokens[annotation!.tokenId];
                                if (isDnD5ECharacterToken(sourceToken)) {
                                    const sourceCharacter = fullResolveTokenCreature(
                                        sourceToken,
                                        campaign,
                                        rules
                                    ) as ResolvedCharacter;

                                    const options = [
                                        ...(sourceCharacter.beforeAttack ?? []),
                                        ...(sourceCharacter.afterAttack ?? []),
                                    ];
                                    if (options) {
                                        for (let featureKey in instanceResult.options) {
                                            const option = options.find(o => getRuleKey(o.feature) === featureKey);
                                            if (option?.target && !isAttackMiss) {
                                                const afterOptionResults =
                                                    instanceResult.options[featureKey]?.[option.key];

                                                // TODO: An after attack option is NOT an AbilityEffect, although maybe it should be.
                                                // Maybe AttackOptionBase should extend AbilityEffect and add some stuff.
                                                // That way we could handle damage/healing the same way, but add some stuff about
                                                // weapon damage (which will be pulled out and added to main roll anyway, so no need
                                                // to worry about it here)

                                                // Calculate the saving throw DC for the effect (if necessary).
                                                let shouldApply = true;
                                                let dc: number | undefined;
                                                const targetEffect = isNamedRuleRef(option.target)
                                                    ? rules.effects.get(option.target)
                                                    : option.target;
                                                const requiresSavingThrow =
                                                    targetEffect?.savingThrowTrigger == null ||
                                                    targetEffect.savingThrowTrigger.cast;
                                                if (requiresSavingThrow) {
                                                    dc =
                                                        sourceCharacter && targetEffect?.savingThrowDcAbility
                                                            ? getAbilityDc(
                                                                  sourceCharacter,
                                                                  targetEffect.savingThrowDcAbility
                                                              )
                                                            : undefined;
                                                    const isSaveSuccess =
                                                        dc != null &&
                                                        afterOptionResults.savingThrow != null &&
                                                        afterOptionResults.savingThrow.result >= dc;
                                                    shouldApply = !isSaveSuccess;
                                                }

                                                if (shouldApply) {
                                                    storedCreature = applyEffect(
                                                        session,
                                                        storedCreature,
                                                        sourceCharacter,
                                                        option.target,
                                                        annotation!.tokenId,
                                                        location,
                                                        annotation!.id,
                                                        dc,
                                                        rules,
                                                        undefined,
                                                        option.feature,
                                                        undefined
                                                    );
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            const spellEffectKeys = keyedListToKeyArray(ability?.effects);
                            if (spellEffectKeys) {
                                for (let effectKey of spellEffectKeys) {
                                    const instanceEffect = instanceResult.effects?.[effectKey];

                                    // Work out if the target has been manually excluded from the effect, or is excluded because it is the caster.
                                    let exclude = isExcluded(
                                        annotation!,
                                        token,
                                        ability,
                                        effectKey,
                                        instanceKey,
                                        campaign,
                                        location!,
                                        rules
                                    );

                                    // A target can also be excluded if this is a failed attack.
                                    if (isAttackMiss) {
                                        exclude = true;
                                    }

                                    // The effect itself can have a further filter.
                                    const abilityEffect = ability.effects?.[effectKey];
                                    if (
                                        abilityEffect?.targetFilter &&
                                        !creatureMatchesFilter(resolvedTarget.resolvedFrom, abilityEffect.targetFilter)
                                    ) {
                                        exclude = true;
                                    }

                                    if (!exclude) {
                                        const result = reduceEffect(
                                            session,
                                            rules,
                                            storedCreature,
                                            resolvedTarget,
                                            { ability: ability, abilityEffectKey: effectKey },
                                            instanceEffect,
                                            annotation,
                                            location
                                        );

                                        // Apply damage for each instance individually - this matters for things like death saves etc.
                                        // Do healing first, as damage could potentially have consequences such as changing the creature's
                                        // form if they were polymorphed, etc.
                                        result.creature = reduceHealing(
                                            result.creature,
                                            resolvedTarget,
                                            result.healing,
                                            session.campaign,
                                            rules
                                        );
                                        result.creature = reduceDamage(
                                            result.creature,
                                            resolvedTarget,
                                            result.damage,
                                            !!abilityEffect?.destroy,
                                            session,
                                            rules,
                                            isAttackCrit
                                        );
                                        storedCreature = result.creature;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    } else if (action.type === "DnD5E_ApplyRechargeRoll") {
        const payload = action.payload as { abilityKey: string; ability: MonsterAbility; roll: DiceRollLogEntry };

        if (location?.combat && isMonster(creature)) {
            // This gets sent to the token AND the template, because it needs to modify the combat turn as well.
            // So, we need to work out whether we should be modifying the token or not.
            let shouldModifyToken = true;
            if (isDnD5EToken(token)) {
                // This is the token, so at the very least we have to modify the combat turn.
                const combatTurn = storedCreature.combatTurn;
                const recharges = Object.assign({}, combatTurn?.recharges, {
                    [payload.abilityKey]: payload.roll.result,
                });
                const newCombatTurn = copyState(combatTurn ?? {}, { recharges: recharges });
                storedCreature = copyState(storedCreature, { combatTurn: newCombatTurn });

                shouldModifyToken = !token.templateId || !!token.ignoreTemplate;
            }

            if (shouldModifyToken && payload.roll.result >= (payload.ability.rechargeOn ?? 6)) {
                // Recharge successful, remove the isRecharging state from the ability.
                storedCreature = reduceRechargeAbility(storedCreature, creature, payload.abilityKey);
            }
        }
    } else if (action.type === "DnD5E_RechargeAbility") {
        const abilityKey = action.payload as string;
        if (isMonster(creature) && (!isDnD5EToken(token) || !token.templateId || !!token.ignoreTemplate)) {
            // Remove the isRecharging state from the ability.
            storedCreature = reduceRechargeAbility(storedCreature, creature, abilityKey);
        }
    } else if (action.type === "DnD5E_ApplyConditions") {
        const conditions = (action as PayloadAction).payload as Partial<CreatureConditions<boolean>>;
        const oldConditions = storedCreature.conditions;
        const newConditions = copyState(oldConditions ?? {}, conditions);
        const newCreature = copyState(storedCreature, { conditions: newConditions });
        return newCreature;
    } else if (action.type === "DnD5E_SetTempHP") {
        const amount = (action as PayloadAction).payload;
        const newCreature = copyState(storedCreature, { tempHp: amount == null || amount === 0 ? undefined : amount });
        return newCreature;
    } else if (action.type === "DnD5E_CastSpell") {
        const { spell, level, annotation, instanceId } = (action as PayloadAction).payload as {
            spell: Spell;
            level?: number | { name: string; level: number };
            annotation?: string;
            instanceId: string;
        };
        if (spell && creature) {
            let afds = reduceUseSpellSlot(token.dnd5e, creature, level ?? spell.level);
            if (spell.duration?.isConcentration) {
                afds = copyState(afds, {
                    concentrating: {
                        name: spell.name,
                        source: spell.source,
                        instanceId: instanceId,
                        annotation: annotation,
                        location: action.props!.locationId,
                    },
                });
            }

            afds = copyState(afds, {
                lastUsedAbility: { instanceId: instanceId, id: annotation ?? getRuleKey(spell) },
            });

            // If the range is self, then there won't be any annotation, but we still need to apply any effects.
            if (spell.range === "self") {
                afds = applySelfEffects(
                    session,
                    token,
                    location,
                    afds,
                    resolveCreature(creature, campaign, rules),
                    spell,
                    rules,
                    undefined,
                    spell
                );
            }

            return afds;
        }
    } else if (action.type === "DnD5E_UseSpellSlot") {
        // TODO: The spell slot might also be named.
        const level = (action as PayloadAction).payload as number | { name: string; level: number };
        if (creature) {
            return reduceUseSpellSlot(token.dnd5e, creature, level);
        }
    } else if (action.type === "DnD5E_ClearSpellSlot") {
        const level = (action as PayloadAction).payload as number | { name: string; level: number };
        if (creature) {
            return reduceClearSpellSlot(token.dnd5e, creature, level);
        }
    } else if (action.type === "DnD5E_EquipItem") {
        const item = (action as PayloadAction).payload as { ids: string[]; active: boolean };
        if (isCharacter(creature)) {
            let newInventory = creature.inventory;
            for (let i = 0; i < item.ids.length; i++) {
                const id = item.ids[i];
                const inventoryItem = creature.inventory && creature.inventory[id];
                if (inventoryItem) {
                    const newItem = copyState(inventoryItem, {
                        active: item.active ? true : undefined,
                        attuned: item.active ? inventoryItem.attuned : undefined,
                    });
                    newInventory = copyState(newInventory, { [id]: newItem });
                }
            }

            storedCreature = copyState(storedCreature, { inventory: newInventory });
        }
    } else if (action.type === "DnD5E_AttuneItem") {
        const item = (action as PayloadAction).payload as { ids: string[]; attuned: boolean };
        if (isCharacter(creature)) {
            let newInventory = creature.inventory;
            for (let i = 0; i < item.ids.length; i++) {
                const id = item.ids[i];
                const inventoryItem = creature.inventory && creature.inventory[id];
                if (inventoryItem) {
                    const newItem = copyState(inventoryItem, {
                        active: item.attuned ? true : inventoryItem.active,
                        attuned: item.attuned ? true : undefined,
                    });
                    newInventory = copyState(newInventory, { [id]: newItem });
                }
            }

            storedCreature = copyState(storedCreature, { inventory: newInventory });
        }
    } else if (action.type === "DnD5E_ModifyCurrency") {
        const payload = (action as PayloadAction).payload as { [abbr: string]: number };
        if (isCharacter(creature)) {
            const currency = Object.assign({}, creature.currency);
            for (let abbr in payload) {
                currency[abbr] = Math.max(0, (currency[abbr] ?? 0) + (payload[abbr] ?? 0));
            }

            const newCharacter = copyState(storedCreature, { currency: currency });
            return newCharacter;
        }
    } else if (action.type === "DnD5E_MarkResource") {
        storedCreature = reduceMarkResource(storedCreature, creature, action.payload);
    } else if (action.type === "DnD5E_ClearResource") {
        storedCreature = reduceClearResource(storedCreature, creature, action.payload);
    } else if (action.type === "DnD5E_MarkAbilityUse") {
        const abilityKey = action.payload as string;
        storedCreature = reduceMarkAbilityUse(storedCreature, abilityKey);
    } else if (action.type === "DnD5E_ClearAbilityUse") {
        const abilityKey = action.payload as string;
        storedCreature = reduceClearAbilityUse(storedCreature, abilityKey);
    } else if (action.type === "DnD5E_MarkLegendaryResistance") {
        if (isMonster(creature)) {
            storedCreature = copyState(storedCreature, {
                usedLegendaryResistances: (creature.usedLegendaryResistances ?? 0) + 1,
            });
        }
    } else if (action.type === "DnD5E_ClearLegendaryResistance") {
        if (isMonster(creature)) {
            const lrc = (creature.usedLegendaryResistances ?? 0) - 1;
            storedCreature = copyState(storedCreature, { usedLegendaryResistances: lrc <= 0 ? undefined : lrc });
        }
    } else if (action.type === "DnD5E_MarkDeathSavingThrow") {
        let { isSuccess, amount } = action.payload;
        if (isCharacter(creature) && creature.dying && !creature.dying.stable) {
            const prop: keyof CreatureDying = isSuccess ? "success" : "failure";
            const newAmt = Math.min(((storedCreature as StoredCharacter).dying?.[prop] ?? 0) + (amount ?? 1), 3);
            if (isSuccess && newAmt === 3) {
                // Three successes stabilise.
                storedCreature = copyState(storedCreature, { dying: { stable: true } });
            } else {
                const dying = Object.assign({}, (storedCreature as StoredCharacter).dying, { [prop]: newAmt });
                storedCreature = copyState(storedCreature, { dying: dying });
            }
        }
    } else if (action.type === "DnD5E_ClearDeathSavingThrow") {
        if (isCharacter(creature) && creature.dying && !creature.dying.stable) {
            const prop: keyof CreatureDying = action.payload ? "success" : "failure";
            const dying = Object.assign({}, (storedCreature as StoredCharacter).dying, {
                [prop]: Math.max(((storedCreature as StoredCharacter).dying?.[prop] ?? 0) - 1, 0),
            });
            storedCreature = copyState(storedCreature, { dying: dying });
        }
    } else if (action.type === "DnD5E_SetMultiattackOption") {
        const { ability, option } = action.payload;
        storedCreature = copyState(storedCreature, {
            combatTurn: Object.assign({}, storedCreature.combatTurn, {
                attackAbility: ability,
                attackOption: option,
                attacks: {},
                action: true,
            }),
        });
    } else if (action.type === "DnD5E_MarkCombatResource") {
        const time = action.payload as "action" | "bonus" | "reaction";
        const delta: CreatureCombatTurn = {};
        switch (time) {
            case "action":
                delta.action = true;
                break;
            case "bonus":
                delta.bonus = true;
                break;
            case "reaction":
                delta.reaction = true;
                break;
        }

        storedCreature = copyState(storedCreature, { combatTurn: Object.assign({}, storedCreature.combatTurn, delta) });
    } else if (action.type === "DnD5E_ClearCombatResource") {
        const time = action.payload as "action" | "bonus" | "reaction";
        const combatTurn = storedCreature.combatTurn;
        if (combatTurn) {
            const delta: CreatureCombatTurn = {};
            switch (time) {
                case "action":
                    delta.action = undefined;
                    delta.attacks = undefined;
                    delta.attackAbility = undefined;
                    delta.attackOption = undefined;
                    break;
                case "bonus":
                    delta.bonus = undefined;
                    break;
                case "reaction":
                    delta.reaction = undefined;
                    break;
            }

            const newCombatTurn = copyState(combatTurn, delta);
            storedCreature = copyState(storedCreature, {
                combatTurn: Object.keys(newCombatTurn).length === 0 ? undefined : newCombatTurn,
            });
        }
    } else if (action.type === "DnD5E_UseAbility") {
        const tokenSpecificOnly = isDnD5EToken(token) && token.templateId != null && !token.ignoreTemplate;
        const { abilityKey, choices } = (action as PayloadAction).payload as {
            abilityKey: string;
            choices: { [effectKey: string]: AppliedAbilityEffectChoices };
        };
        if (isMonster(creature)) {
            const monster = fullResolveTokenCreature(token, campaign, rules) as ResolvedMonster;
            let ability = monster.abilities?.[abilityKey] ?? monster.transformedInto?.abilities?.[abilityKey];
            if (!ability) {
                const key = fromRuleKey(abilityKey);
                if (key) {
                    ability = rules.actions.get(key);
                }
            }

            const abilityUsage = monster.abilityUsage?.[abilityKey];
            const maxUses =
                ability != null ? evaluateMonsterExpression(monster, ability.maxUsesExpr, ability.maxUses) : undefined;
            let canUseAbility =
                ability != null &&
                (maxUses == null || (abilityUsage?.used ?? 0) < maxUses || action.props!["isCostPaid"]);

            if (canUseAbility) {
                if (maxUses != null && !tokenSpecificOnly) {
                    // Increment the number of uses.
                    const newAbilityUsage = Object.assign({}, abilityUsage, { used: (abilityUsage?.used ?? 0) + 1 });
                    const newAbilities = Object.assign({}, storedCreature.abilityUsage, {
                        [abilityKey]: newAbilityUsage,
                    });
                    storedCreature = copyState(storedCreature, { abilityUsage: newAbilities });
                    action.props!["isCostPaid"] = true;
                }

                if (!tokenSpecificOnly) {
                    storedCreature = applySelfEffects(
                        session,
                        token,
                        location,
                        storedCreature,
                        monster,
                        ability!,
                        rules,
                        abilityKey,
                        undefined,
                        choices
                    );
                }

                if (isToken(token)) {
                    storedCreature = applySelfEffectsTokenOnly(
                        token,
                        location,
                        storedCreature,
                        monster,
                        ability!,
                        rules,
                        abilityKey,
                        undefined,
                        choices
                    );
                }

                if (isToken(token) && ability?.rechargeOn && location.combat?.participants?.[token.id]) {
                    // This is a recharge ability, so we need to store the fact that this ability is recharging now.
                    const allAbilitiesState = creature.abilityState;
                    const abilityState = allAbilitiesState?.[abilityKey];
                    const newAbilityState: MonsterAbilityState = Object.assign({}, abilityState, {
                        isRecharging: true,
                    } as MonsterAbilityState);
                    const newAllAbilitiesState = Object.assign({}, allAbilitiesState, {
                        [abilityKey]: newAbilityState,
                    });
                    storedCreature = Object.assign({}, storedCreature, { abilityState: newAllAbilitiesState });

                    // If this is a recharge ability, the combat turn needs to be updated with to record that the recharge
                    // ability was used this turn (and therefore no roll for recharging should be made this turn).
                    // We do this by storing a 0 as the recharge roll result.
                    if (location?.combat) {
                        const newRecharges = Object.assign({}, token.dnd5e.combatTurn?.recharges, {
                            [abilityKey]: 0,
                        });
                        const newCombatTurn = Object.assign({}, token.dnd5e.combatTurn, { recharges: newRecharges });
                        storedCreature = copyState(storedCreature, { combatTurn: newCombatTurn });
                    }
                }

                // If this is a token and there is a combat in progress, then register the time cost of the ability against the monster.
                storedCreature = reduceCombatTimeCost(storedCreature, location, ability?.time);

                if (ability!.isAttackAction && storedCreature.combatTurn?.attacks) {
                    // The ability is an attack action, so record it in the combat turn.
                    const attacks = storedCreature.combatTurn!.attacks;
                    const newAttacks = Object.assign({}, attacks, { [abilityKey]: (attacks[abilityKey] ?? 0) + 1 });
                    const newCombatTurn = Object.assign({}, storedCreature.combatTurn, { attacks: newAttacks });
                    storedCreature = Object.assign({}, storedCreature, { combatTurn: newCombatTurn });
                }
            }
        } else if (isCharacter(creature)) {
            const character = fullResolveTokenCreature(token, campaign, rules) as ResolvedCharacter;
            let ability = character.abilities?.[abilityKey] ?? character.transformedInto?.abilities?.[abilityKey];
            if (!ability) {
                const key = fromRuleKey(abilityKey);
                if (key) {
                    ability = rules.actions.get(key);
                }
            }

            const abilityUsage = character.abilityUsage?.[abilityKey];
            const maxUses =
                ability != null
                    ? evaluateCharacterExpression(character, ability.maxUsesExpr, ability.maxUses)
                    : undefined;
            let canUseAbility =
                ability != null &&
                (((maxUses == null || (abilityUsage?.used ?? 0) < maxUses) &&
                    canPayResourceCosts(character, ability.resourceCost)) ||
                    action.props!["isCostPaid"]);

            if (ability && canUseAbility) {
                if (!tokenSpecificOnly) {
                    for (let resource in ability.resourceCost) {
                        storedCreature = reduceMarkResource(
                            storedCreature,
                            creature,
                            resource,
                            ability.resourceCost[resource]
                        );
                    }

                    // Increment the number of uses.
                    const newAbilityUsage = Object.assign({}, abilityUsage, { used: (abilityUsage?.used ?? 0) + 1 });
                    const newAbilities = Object.assign({}, storedCreature.abilityUsage, {
                        [abilityKey]: newAbilityUsage,
                    });
                    storedCreature = copyState(storedCreature, { abilityUsage: newAbilities });

                    storedCreature = applySelfEffects(
                        session,
                        token,
                        location,
                        storedCreature,
                        character,
                        ability,
                        rules,
                        abilityKey,
                        ability["feature"],
                        choices
                    );
                    action.props!["isCostPaid"] = true;
                }

                if (isToken(token)) {
                    storedCreature = applySelfEffectsTokenOnly(
                        token,
                        location,
                        storedCreature,
                        character,
                        ability!,
                        rules,
                        abilityKey,
                        ability["feature"],
                        choices
                    );
                }

                // If there is a combat in progress, then register the time cost of the ability against the character.
                storedCreature = reduceCombatTimeCost(storedCreature, location, ability?.time);
            }
        }

        return storedCreature;
    } else if (action.type === "DnD5E_UseInnateSpell") {
        if (isCharacter(creature)) {
            const payload = (action as PayloadAction).payload as {
                source: Overwrite<NamedRuleRef, { source?: string }>;
                spell: Spell;
            };
            const spellKey = getRuleKey(payload.spell);
            const additionalSpellsForSource = creature.additionalSpells
                ? creature.additionalSpells[getRuleKey(payload.source)]
                : undefined;
            const stateForSpell = additionalSpellsForSource ? additionalSpellsForSource[spellKey] : undefined;

            const newStateForSpell = Object.assign({}, stateForSpell, { used: (stateForSpell?.used ?? 0) + 1 });
            const newAdditionalSpellsForSource = Object.assign({}, additionalSpellsForSource, {
                [spellKey]: newStateForSpell,
            });
            const newAdditionalSpells = Object.assign({}, creature.additionalSpells, {
                [getRuleKey(payload.source)]: newAdditionalSpellsForSource,
            });

            const newCharacter = copyState(storedCreature, { additionalSpells: newAdditionalSpells });
            return newCharacter;
        } else if (isMonster(creature)) {
            const payload = (action as PayloadAction).payload as { source: string; spell: Spell };
            const spellKey = getRuleKey(payload.spell);

            const spellcasting = creature.innateSpellUsage?.[payload.source];
            const usage = spellcasting?.[spellKey];

            const newUsage = copyState(usage ?? {}, { used: (usage?.used ?? 0) + 1 });
            const newSpellcasting = copyState(spellcasting ?? {}, { [spellKey]: newUsage });
            const newInnateUsage = copyState(creature.innateSpellUsage ?? {}, { [payload.source]: newSpellcasting });
            const newMonster = copyState(storedCreature, { innateSpellUsage: newInnateUsage });
            return newMonster;
        }
    } else if (action.type === "DnD5E_ClearInnateSpellUse") {
        if (isCharacter(creature)) {
            const payload = (action as PayloadAction).payload as {
                source: Overwrite<NamedRuleRef, { source?: string }>;
                spell: Spell;
            };
            const spellKey = getRuleKey(payload.spell);
            const additionalSpellsForSource = creature.additionalSpells
                ? creature.additionalSpells[getRuleKey(payload.source)]
                : undefined;
            const stateForSpell = additionalSpellsForSource ? additionalSpellsForSource[spellKey] : undefined;

            if (stateForSpell && stateForSpell.used != null && stateForSpell.used > 0) {
                const newStateForSpell = Object.assign({}, stateForSpell, { used: stateForSpell.used - 1 });
                const newAdditionalSpellsForSource = Object.assign({}, additionalSpellsForSource, {
                    [spellKey]: newStateForSpell,
                });
                const newAdditionalSpells = Object.assign({}, creature.additionalSpells, {
                    [getRuleKey(payload.source)]: newAdditionalSpellsForSource,
                });

                const newCharacter = copyState(storedCreature, { additionalSpells: newAdditionalSpells });
                return newCharacter;
            }
        } else if (isMonster(creature)) {
            const payload = (action as PayloadAction).payload as { source: string; spell: Spell };
            const spellKey = getRuleKey(payload.spell);

            const spellcasting = creature.innateSpellUsage?.[payload.source];
            const usage = spellcasting?.[spellKey];

            if (usage && usage.used != null && usage.used > 0) {
                const newUsage = copyState(usage ?? {}, { used: usage.used - 1 });
                const newSpellcasting = copyState(spellcasting ?? {}, { [spellKey]: newUsage });
                const newInnateUsage = copyState(creature.innateSpellUsage ?? {}, {
                    [payload.source]: newSpellcasting,
                });
                const newMonster = copyState(storedCreature, { innateSpellUsage: newInnateUsage });
                return newMonster;
            }
        }
    } else if (action.type === "DnD5E_ModifyCharacter") {
        if (isCharacter(creature)) {
            const payload = (action as PayloadAction).payload as Partial<Character>;
            const newCharacter = copyState(storedCreature, payload);
            return newCharacter;
        }
    } else if (action.type === "DnD5E_ModifyMonster") {
        if (isMonster(creature)) {
            const payload = (action as PayloadAction).payload as Partial<Monster>;
            const newMonster = copyState(storedCreature, payload);
            return newMonster;
        }
    } else if (action.type === "DnD5E_AddItems") {
        if (isCharacter(creature)) {
            const itemsToAdd = action.payload as {
                items: (number | ResolvedItem)[];
                inventoryKeys?: string[];
                from?: Token | TokenTemplate;
                to?: Token | TokenTemplate;
            };

            // Expand packs into individual items.
            const tokenId = getModifyId(token);
            const fromId = itemsToAdd.from ? getModifyId(itemsToAdd.from) : undefined;
            const toId = itemsToAdd.to ? getModifyId(itemsToAdd.to) : undefined;

            const inventory = Object.assign({}, creature.inventory);
            const currency = Object.assign({}, creature.currency);

            if (fromId === tokenId && toId !== fromId) {
                // Remove all inventory items from our inventory.
                for (let i = 0; i < itemsToAdd.items.length; i++) {
                    const item = itemsToAdd.items[i];
                    if (typeof item === "number") {
                        // Remove this much gold.
                        // TODO: Implement.
                    } else if (isInventoryItem(item)) {
                        delete inventory[item.id];
                    }
                }
            } else if (fromId !== toId || (fromId == null && toId == null)) {
                const itemsToStore = itemsToAdd.items.flatMap(o => {
                    if (typeof o === "number") {
                        return o;
                    }

                    if (isPack(o)) {
                        // TODO: The pack contents need quantity as well.
                        // Maybe it really has to be included in the base item.
                        return o.packContents.map(c => unresolveItem(c));
                    }

                    return unresolveItem(o);
                });

                const now = Date.now();

                itemsToAdd.inventoryKeys = itemsToAdd.inventoryKeys ?? [];
                for (let i = 0; i < itemsToStore.length; i++) {
                    if (itemsToStore[i] != null) {
                        const item = itemsToStore[i];

                        if (fromId != null && fromId === toId) {
                            if (typeof item === "number" || isInventoryItem(item)) {
                                // The item is being sent from a token. If it's being sent from the same token, and the item
                                // is already in an inventory, we can safely ignore it.
                                continue;
                            }
                        }

                        // Otherwise, we DO need to add it.
                        if (typeof item === "number") {
                            // This is a currency value.
                            const value = item as number;
                            currency["GP"] = Math.max(0, (currency["GP"] ?? 0) + value);
                        } else {
                            // Cache the generated keys against the action in case the action needs to be repeated.
                            const key = itemsToAdd.inventoryKeys[i] ?? nanoid();
                            itemsToAdd.inventoryKeys[i] = key;
                            inventory[key] = Object.assign({}, item as StoredItem, { acquired: now });
                        }
                    }
                }
            }

            return copyState(storedCreature, { inventory, currency });
        }
    } else if (action.type === "DnD5E_RemoveItems") {
        if (isCharacter(creature)) {
            const items = action.payload as ResolvedInventoryItem[];
            const newInventory = Object.assign({}, creature.inventory);
            for (let i = 0; i < items.length; i++) {
                delete newInventory[items[i].id];
            }

            return copyState(storedCreature, { inventory: newInventory });
        }
    } else if (action.type === "DnD5E_LongRest") {
        if (creature) {
            if (isCharacter(creature)) {
                // A long rest reduces the exhaustion level by 1.
                let exhaustion = creature.exhaustion;
                if (exhaustion != null) {
                    exhaustion = exhaustion === 1 ? undefined : exhaustion - 1;
                }

                // The number of hit dice spent is reduced by half the character's total level (minimum 1).
                // We remove them from the classes in descending hit die value (i.e. d10 before d8, etc).
                const totalLevel = creature.classes
                    ? Object.values(creature.classes).reduce((total, o) => total + o.level, 0)
                    : 0;
                let hitDiceToRegain = Math.max(1, Math.floor(totalLevel / 2));

                const classes = creature.classes ? Object.values(creature.classes) : [];
                classes.sort((a, b) => {
                    const ca = rules.classes.get(a.class);
                    const cb = rules.classes.get(b.class);

                    const cam = ca?.hitDie != null ? diceTypeToMax(ca.hitDie) : 0;
                    const cbm = cb?.hitDie != null ? diceTypeToMax(cb.hitDie) : 0;

                    return cam - cbm;
                });

                let newClasses: { [classKey: string]: ClassLevels } | undefined;
                for (let i = 0; i < classes.length && hitDiceToRegain > 0; i++) {
                    const c = classes[i];
                    if (c.hitDiceSpent != null) {
                        const hitDiceRegained = Math.min(c.hitDiceSpent, hitDiceToRegain);
                        let newHitDice: number | undefined = c.hitDiceSpent - hitDiceRegained;
                        if (newHitDice === 0) {
                            newHitDice = undefined;
                        }

                        const newC = copyState(c, { hitDiceSpent: newHitDice });
                        if (!newClasses) {
                            newClasses = Object.assign({}, creature.classes);
                        }

                        newClasses[newC.class.name] = newC;

                        hitDiceToRegain -= hitDiceRegained;
                    }
                }

                return copyState(storedCreature, {
                    usedSpellSlots: undefined,
                    hp: undefined,
                    tempHp: undefined,
                    exhaustion: exhaustion,
                    concentrating: undefined,
                    usedResources: undefined,
                    classes: newClasses ?? creature.classes,
                    abilityUsage: undefined,
                });
            } else {
                return copyState(storedCreature, {
                    usedSpellSlots: undefined,
                    hp: undefined,
                    tempHp: undefined,
                    concentrating: undefined,
                    abilityUsage: undefined,
                    innateSpellUsage: undefined,
                    usedLegendaryResistances: undefined,
                });
            }
        }
    } else if (action.type === "DnD5E_ApplyHitDie") {
        if (creature) {
            if (isCharacter(creature)) {
                const { classRef, roll } = action.payload as { classRef: NamedRuleRef; roll: DiceRollLogEntry };
                const classKey = getRuleKey(classRef);

                const key = creature.classes
                    ? Object.keys(creature.classes).find(o => {
                          const cls = creature.classes?.[o].class;
                          return cls && getRuleKey(cls) === classKey;
                      })
                    : undefined;
                if (key != null) {
                    const oldClass = (storedCreature as StoredCharacter).classes![key];
                    const newClass = copyState(oldClass, {
                        hitDiceSpent: (oldClass.hitDiceSpent ?? 0) + roll.terms.length,
                    });
                    const newClasses = copyState((storedCreature as StoredCharacter).classes, {
                        [key]: newClass,
                    });
                    storedCreature = copyState(storedCreature, { classes: newClasses });

                    const resolvedCreature = resolveCreature(creature, campaign, rules);
                    if (resolvedCreature) {
                        return reduceHealing(storedCreature, resolvedCreature, roll.result, campaign, rules);
                    }
                }
            } else if (isMonster(creature)) {
                const { dice, roll } = action.payload as { dice: DiceType; roll: DiceRollLogEntry };

                const oldSpent = (storedCreature as StoredMonster).hitDiceSpent;
                const oldAmount = oldSpent?.[dice];
                const newSpent = copyState(oldSpent ?? {}, { [dice]: (oldAmount ?? 0) + roll.terms.length });
                storedCreature = copyState(storedCreature, { hitDiceSpent: newSpent });

                const resolvedCreature = resolveCreature(creature, campaign, rules);
                if (resolvedCreature) {
                    return reduceHealing(storedCreature, resolvedCreature, roll.result, campaign, rules);
                }
            }
        }
    } else if (action.type === "DnD5E_ShortRest") {
        if (creature) {
            const resolvedCreature = resolveCreature(creature, campaign, rules);

            // Reset any limited use abilities that reset on short rest.
            if (resolvedCreature.abilities && creature.abilityUsage) {
                let abilityUsage = creature.abilityUsage;
                const abilityKeys = Object.keys(resolvedCreature.abilities);
                for (let abilityKey of abilityKeys) {
                    const ability = resolvedCreature.abilities[abilityKey];
                    if (ability.reset === "shortrest") {
                        const oldUsage = abilityUsage[abilityKey];
                        if ((oldUsage?.used ?? 0) > 0) {
                            // The ability had been used and resets on short rest, remove its usage.
                            abilityUsage = copyState(abilityUsage, { [abilityKey]: undefined });
                        }
                    }
                }

                if (abilityUsage !== creature.abilityUsage) {
                    storedCreature = copyState(storedCreature, { abilityUsage: abilityUsage });
                }
            }

            if (isCharacter(creature) && isCharacter(resolvedCreature)) {
                // Find all spell slots that reset on short rest (i.e. Warlock Pact slots) and reset them.
                const shortRestSlots = resolvedCreature.spellSlots?.filter(
                    o => !Array.isArray(o) && o.reset === "shortrest"
                ) as NamedSpellSlots[] | undefined;
                if (shortRestSlots && shortRestSlots.length > 0) {
                    const usedSlots = Object.assign({}, creature.usedSpellSlots);
                    for (let i = 0; i < shortRestSlots.length; i++) {
                        delete usedSlots[shortRestSlots[i].name];
                    }

                    storedCreature = copyState(storedCreature, { usedSpellSlots: usedSlots });
                }

                // Find all additionalSpells that reset on short rest and reset them.
                if (creature.additionalSpells) {
                    const oldAdditionalSpells = creature.additionalSpells;
                    let newAdditionalSpells:
                        | {
                              [name: string]: {
                                  [spellKey: string]: {
                                      used?: number | undefined;
                                  };
                              };
                          }
                        | undefined;
                    for (let additionalSpell of resolvedCreature.additionalSpells) {
                        const additionalSpellKey = getRuleKey(additionalSpell.source);
                        const srSpells = additionalSpell.spells.filter(o => o.reset === "shortrest");
                        if (srSpells.length) {
                            const oldSpells = creature.additionalSpells[additionalSpellKey];
                            if (oldSpells) {
                                let newSpells: { [spellKey: string]: { used?: number | undefined } } | undefined;
                                for (let srSpell of srSpells) {
                                    const key = getRuleKey(srSpell);
                                    if (oldSpells[key]) {
                                        if (!newSpells) {
                                            newSpells = Object.assign({}, oldSpells);
                                        }

                                        delete newSpells[key];
                                    }
                                }

                                if (newSpells) {
                                    if (!newAdditionalSpells) {
                                        newAdditionalSpells = Object.assign({}, oldAdditionalSpells);
                                    }

                                    newAdditionalSpells[additionalSpellKey] = newSpells;
                                }
                            }
                        }
                    }

                    if (newAdditionalSpells != null) {
                        storedCreature = copyState(storedCreature, { additionalSpells: newAdditionalSpells });
                    }
                }

                // Find all resources that reset on short rest and reset them.
                if (creature.usedResources) {
                    const resourceKeys = Object.keys(creature.usedResources);
                    let newUsedResources: { [name: string]: number } | undefined;
                    for (let resourceKey of resourceKeys) {
                        // Check if this resource resets on short rest.
                        if (resolvedCreature.resources?.[resourceKey]?.reset === "shortrest") {
                            if (!newUsedResources) {
                                newUsedResources = Object.assign({}, creature.usedResources);
                            }

                            delete newUsedResources[resourceKey];
                        }
                    }

                    if (newUsedResources) {
                        const newKeys = Object.keys(newUsedResources);
                        if (newKeys.length > 0) {
                            storedCreature = copyState(storedCreature, { usedResources: newUsedResources });
                        } else {
                            storedCreature = copyState(storedCreature, { usedResources: undefined });
                        }
                    }
                }
            } else if (isMonster(creature)) {
                // Reset any innate spell usage that resets on short rest.
                if (creature.spellcasting) {
                    let innateSpellUsage = creature.innateSpellUsage;
                    const spellCastingKeys = Object.keys(creature.spellcasting);
                    for (let spellCastingKey of spellCastingKeys) {
                        const spellcasting = creature.spellcasting[spellCastingKey];
                        const spellKeys = Object.keys(spellcasting.spells);

                        for (let spellKey of spellKeys) {
                            const spell = spellcasting.spells[spellKey];
                            if (spell.reset === "shortrest") {
                                if (innateSpellUsage) {
                                    const oldSpellCastingUsage = innateSpellUsage[spellCastingKey];
                                    if (oldSpellCastingUsage) {
                                        const oldSpellUsage = oldSpellCastingUsage[spellKey];
                                        if ((oldSpellUsage?.used ?? 0) > 0) {
                                            // There were used spells for a spell that resets on short rest, so we have to remove them.
                                            const newSpellCastingUsage = copyState(oldSpellCastingUsage, {
                                                [spellKey]: undefined,
                                            });
                                            innateSpellUsage = copyState(innateSpellUsage, {
                                                [spellCastingKey]: newSpellCastingUsage,
                                            });
                                        }
                                    }
                                }
                            }
                        }
                    }

                    if (innateSpellUsage !== creature.innateSpellUsage) {
                        storedCreature = copyState(storedCreature, { innateSpellUsage: innateSpellUsage });
                    }
                }
            }
        }
    } else if (action.type === "DnD5E_RemoveEffect") {
        if (creature) {
            const effectKey = action.payload as string;
            const resolvedCreature = resolveCreature(creature, campaign, rules);
            storedCreature = removeEffectByKey(session, storedCreature, resolvedCreature, effectKey, rules);
        }
    } else if (action.type === "DnD5E_ChangeMovementForTurn" && isToken(token) && creature) {
        const { type, amount } = action.payload as { type: MovementType | undefined; amount: number };

        const movement =
            storedCreature.combatTurn?.movement ??
            getResolvedMovementSpeeds(resolveCreature(creature, campaign, rules));
        if (movement) {
            const newMovement: Partial<MovementSpeeds<number>> = {};
            if (type == null) {
                for (let t in movement) {
                    newMovement[t as MovementType] = Math.max(0, movement[t] + amount);
                }
            } else {
                for (let typeToCopy in movement) {
                    newMovement[typeToCopy as MovementType] = movement[typeToCopy as MovementType];
                }

                newMovement[type] = Math.max(0, (movement[type] ?? 0) + amount);
            }
            const newCombatTurn = Object.assign({}, storedCreature.combatTurn, { movement: newMovement });
            storedCreature = copyState(storedCreature, { combatTurn: newCombatTurn });
        }
    } else if (action.type === "DnD5E_ApplyDeathSave") {
        const isReducingToken = isToken(token);
        if (isReducingToken && location?.combat && location.combat.participants[(token as DnD5EToken).id]) {
            // Update the combat turn to say that the death save for this turn is done.
            const newCombatTurn = Object.assign({}, storedCreature.combatTurn, { deathSavingThrow: true });
            storedCreature = Object.assign({}, storedCreature, { combatTurn: newCombatTurn });
        }

        if ((isReducingToken && (!token.templateId || token.ignoreTemplate)) || !isReducingToken) {
            const deathSave = action.payload as DiceRoll;
            const resolvedCreature = fullResolveTokenCreature(token, campaign, rules);
            if (deathSave && isCharacter(resolvedCreature) && !resolvedCreature?.isDead && resolvedCreature.dying) {
                const currentDying = (storedCreature as StoredCharacter).dying;

                const term = deathSave.terms.find(o => !o.isExcluded)!;
                if (term.result === 1) {
                    // Nat 1 on the death save. That counts as 2 failures.
                    const dying = Object.assign({}, currentDying, {
                        failure: Math.min((currentDying?.failure ?? 0) + 2, 3),
                    });
                    storedCreature = copyState(storedCreature, { dying: dying });
                } else if (term.result === 20) {
                    // Nat 20 on the death save. That brings the character back to consciousness on 1 hp!
                    storedCreature = reduceHealing(storedCreature, resolvedCreature, 1, campaign, rules);
                } else {
                    if (deathSave.result >= 10) {
                        // Success, add one to successes.
                        const successes = Math.min((currentDying?.success ?? 0) + 1, 3);
                        if (successes === 3) {
                            // Three successes stabilise.
                            storedCreature = copyState(storedCreature, { dying: { stable: true } });
                        } else {
                            const dying = Object.assign({}, currentDying, { success: successes });
                            storedCreature = copyState(storedCreature, { dying: dying });
                        }
                    } else {
                        // Failure, add one to failures.
                        const dying = Object.assign({}, currentDying, {
                            failure: Math.min((currentDying?.failure ?? 0) + 1, 3),
                        });
                        storedCreature = copyState(storedCreature, { dying: dying });
                    }
                }
            }
        }
    } else if (action.type === "DnD5E_SetSurprised") {
        const combatTurn = copyState(storedCreature.combatTurn ?? {}, { surprised: action.payload ? true : undefined });
        storedCreature = copyState(storedCreature, { combatTurn: combatTurn });
    } else if (action.type === "DnD5E_MarkSavingThrow") {
        const savingThrows = copyState(storedCreature.combatTurn?.savingThrows ?? {}, action.payload);
        const combatTurn = copyState(storedCreature.combatTurn ?? {}, { savingThrows: savingThrows });

        // If the saving throw is a success, we can remove the effect.
        for (let effectKey in savingThrows) {
            if (savingThrows[effectKey]) {
                const resolvedCreature = fullResolveTokenCreature(token, campaign, rules);
                if (resolvedCreature) {
                    storedCreature = removeEffectByKey(session, storedCreature, resolvedCreature, effectKey, rules);
                }
            }
        }

        storedCreature = copyState(storedCreature, { combatTurn: combatTurn });
    }

    return storedCreature;
}

function shouldModifyTokenForTurn(token: DnD5EToken | DnD5ETokenTemplate, location: Location, combat: CombatEncounter) {
    let modifyToken = false;
    if (combat.turn) {
        if (isDnD5EToken(token) && combat.turn === token.id) {
            modifyToken = !token.templateId || !!token.ignoreTemplate;
        } else if (isDnD5ETokenTemplate(token) && location.tokens?.[combat.turn]?.templateId === token.templateId) {
            modifyToken = !location.tokens?.[combat.turn].ignoreTemplate;
        }
    }

    return modifyToken;
}

function reduceCombatTurnEffects(
    storedCreature: StoredCreature,
    token: DnD5EToken | DnD5ETokenTemplate,
    creature: Creature | undefined,
    session: Session,
    location: Location,
    combat: CombatEncounter,
    gameTime: number,
    rules: CharacterRuleSet,
    trigger: "sot" | "eot",
    sourceTrigger: "source_sot" | "source_eot"
) {
    let modifyToken = shouldModifyTokenForTurn(token, location, combat);

    // Check for any effects whose duration expires on start of turn.
    const effectKeys = keyedListToKeyArray(creature?.effects);
    if (modifyToken && effectKeys) {
        for (let i = 0; i < effectKeys.length; i++) {
            const appliedEffect = creature!.effects![effectKeys[i]];
            const expires = appliedEffect.duration?.expires;
            if (expires != null) {
                // Assume no trigger means start of turn for now.
                let effectTrigger = appliedEffect!.duration!.trigger ?? "sot";

                // If the trigger is for the source token's start of turn, but we can't find the source, then we need
                // to deal with it here.
                if (effectTrigger === sourceTrigger) {
                    if (!appliedEffect.appliedBy || !appliedEffect.appliedAt) {
                        effectTrigger = trigger;
                    } else {
                        const sourceLocation = session.campaign.locations[appliedEffect.appliedAt];
                        if (!isLocation(sourceLocation)) {
                            effectTrigger = trigger;
                        } else {
                            const token = sourceLocation?.tokens?.[appliedEffect.appliedBy];
                            if (!token) {
                                effectTrigger = trigger;
                            }
                        }
                    }
                }

                if (effectTrigger === trigger) {
                    const resolvedCreature = fullResolveTokenCreature(token, session.campaign, rules);
                    if (resolvedCreature) {
                        // TODO: If the creature is the first in the order, then the game time might not be correct yet?
                        if (gameTime >= expires) {
                            storedCreature = removeEffectByKey(
                                session,
                                storedCreature,
                                resolvedCreature,
                                effectKeys[i],
                                rules
                            );
                        }
                    }
                }
            }
        }
    }

    // Check if we're modifying the correct version of this token (the token or the token template).
    modifyToken = isDnD5ETokenTemplate(token) || !token.templateId || !!token.ignoreTemplate;
    if (modifyToken && effectKeys) {
        // Ok, that takes care of effects whose duration triggers on the token's turn, but what about effects that trigger
        // at the start/end of another token's turn?!
        for (let i = 0; i < effectKeys.length; i++) {
            const appliedEffect = creature!.effects![effectKeys[i]];
            const expires = appliedEffect.duration?.expires;
            if (
                expires != null &&
                appliedEffect?.duration?.trigger === sourceTrigger &&
                appliedEffect.appliedBy != null &&
                appliedEffect.appliedAt != null
            ) {
                // This effect triggers at the start of the source's turn (the source is the token that applied
                // the effect). We have to find that source and resolve them so that we can check if their turn
                // is just starting.
                const sourceLocation = session.campaign.locations[appliedEffect.appliedAt];
                if (isLocation(sourceLocation)) {
                    const sourceToken = sourceLocation?.tokens?.[appliedEffect.appliedBy];
                    if (sourceToken && combat.turn === sourceToken.id) {
                        const resolvedCreature = fullResolveTokenCreature(token, session.campaign, rules);
                        if (resolvedCreature) {
                            if (gameTime >= expires) {
                                storedCreature = removeEffectByKey(
                                    session,
                                    storedCreature,
                                    resolvedCreature,
                                    effectKeys[i],
                                    rules
                                );
                            }
                        }
                    }
                }
            }
        }
    }

    return storedCreature;
}

export function reduceCreatureUntargetted(
    token: DnD5EToken | DnD5ETokenTemplate,
    creature: Creature | undefined,
    action: PayloadAction,
    session: Session,
    location: Location,
    rules: CharacterRuleSet
): StoredCreature {
    const campaign = session.campaign;
    let storedCreature = token.dnd5e;

    if (action.type === "GameTimeTick") {
        // Shouldn't get this action during combat, but just in case - don't handle time based events during
        // combat - in combat this is handled by the NextCombatTurn action.
        // If this is a token, then we only handle it if the template is ignored. If it's a template, we always handle.
        const modifyToken =
            !session.combatLocation && (isDnD5ETokenTemplate(token) || token.ignoreTemplate || !token.templateId);
        if (modifyToken) {
            // Check if any effects are past their expiry. If they are, remove them.
            // Check for any effects whose duration expires on start of turn.
            const effectKeys = keyedListToKeyArray(creature?.effects);
            if (effectKeys) {
                for (let i = 0; i < effectKeys.length; i++) {
                    const appliedEffect = creature!.effects![effectKeys[i]];
                    const expires = appliedEffect.duration?.expires;
                    if (expires != null) {
                        const resolvedCreature = fullResolveTokenCreature(token, campaign, rules);
                        if (resolvedCreature && getGameTime(session.time) >= expires) {
                            storedCreature = removeEffectByKey(
                                session,
                                storedCreature,
                                resolvedCreature,
                                effectKeys[i],
                                rules
                            );
                        }
                    }
                }
            }
        }

        return storedCreature;
    } else if (action.type === "NextCombatTurn") {
        // Handle any changes to this creature's effects.
        const location = campaign.locations[action.props?.locationId];
        if (isLocation(location)) {
            const combat = location?.combat;
            if (combat) {
                // The previous turn gets stored on the action as the payload.
                const previousCombat = action.payload as CombatEncounter;

                // First, if the turns match and this token is an actual token (not a template) then modify anything we
                // need to modify on the token's combat turn, which is always on the actual token.
                if (isDnD5EToken(token)) {
                    if (combat.turn === token.id) {
                        // Clear the creature's combat turn at the start of turn, but preserve their surprised status.
                        // Surprise is cleared at the end of the creature's first turn of combat.
                        storedCreature = copyState(storedCreature, {
                            combatTurn: creature?.combatTurn?.surprised ? { surprised: true } : undefined,
                        });
                    } else if (previousCombat.turn === token.id) {
                        // Clear the surprised status, if it's set.
                        if (creature?.combatTurn?.surprised) {
                            const combatTurn = copyState(creature.combatTurn, { surprised: undefined });
                            storedCreature = copyState(storedCreature, { combatTurn: combatTurn });
                        }
                    }
                }

                const gameTime = getGameTime(session.time);
                storedCreature = reduceCombatTurnEffects(
                    storedCreature,
                    token,
                    creature,
                    session,
                    location,
                    previousCombat,
                    previousCombat.round !== combat.round ? gameTime - msPerRound : gameTime, // If the trigger is for EOT, and the previous turn was a different round, then rewind time for the purposes of evaluating the effect timing.
                    rules,
                    "eot",
                    "source_eot"
                );
                storedCreature = reduceCombatTurnEffects(
                    storedCreature,
                    token,
                    creature,
                    session,
                    location,
                    combat,
                    gameTime,
                    rules,
                    "sot",
                    "source_sot"
                );
            }
        }
    } else if (action.type === "PreviousCombatTurn") {
        // TODO: Add one to the relevant effect durations? Doesn't help if we already removed it. We can't do proper time travel
        // unless we add a whole bunch of stuff to support it...
    } else if (action.type === "EndCombat") {
        // If combat is ended, remove any combat related state.
        storedCreature = reduceRemoveFromCombat(storedCreature, creature);
    } else if (action.type === "RemoveCombatParticipants") {
        if (isDnD5EToken(token) && (action.payload as string[]).indexOf(token.id) >= 0) {
            // If the token is removed from combat, remove any combat related state.
            storedCreature = reduceRemoveFromCombat(storedCreature, creature);
        }
    }

    // The action isn't specifically for a creature, but if we're dealing with it here then it must have affected a state that
    // could be relevant to us.
    if (creature) {
        // If the character is concentrating on something, make sure it's still valid.
        const concentratingOn = creature.concentrating;
        if (concentratingOn?.annotation && concentratingOn?.location) {
            let stopConcentrating = true;
            const location = campaign.locations[concentratingOn.location];
            if (isLocation(location)) {
                stopConcentrating = !location.annotations[concentratingOn.annotation];
            }

            if (stopConcentrating) {
                storedCreature = copyState(storedCreature, { concentrating: undefined });
            }
        }

        // An effect might have dropped - if it's got an appliedBy and an instanceId then it requires concentration.
        if (creature.effects) {
            for (let effectKey in creature.effects) {
                const effect = creature.effects[effectKey];
                if (effect.appliedBy && effect.instanceId) {
                    // Check that the caster is still concentrating on the ability causing this effect.
                    // First, we need to locate the caster - check if it's a template, then if that's not found then look for a token.
                    let isEffectValid = true;
                    let appliedBy: Creature | undefined;

                    const template = campaign.tokens[effect.appliedBy];
                    if (!isDnD5ETokenTemplate(template)) {
                        const appliedAt = effect.appliedAt ? campaign.locations[effect.appliedAt] : undefined;
                        if (appliedAt == null) {
                            // Location is missing entirely - assume effect can drop.
                            isEffectValid = false;
                        } else if (isLocation(appliedAt)) {
                            const appliedByToken = appliedAt.tokens[effect.appliedBy];
                            if (isDnD5EToken(appliedByToken)) {
                                appliedBy = resolveTokenCreature(appliedByToken, campaign, rules);
                            } else {
                                // Found the token, but it wasn't a valid one. This shouldn't happen.
                                isEffectValid = false;
                            }
                        } else {
                            // Just a summary, the full location hasn't been loaded yet. Assume that the effect is still valid for now.
                        }
                    } else {
                        appliedBy = resolveTokenCreature(template, campaign, rules);
                    }

                    if (isEffectValid && appliedBy) {
                        // Found the token/template that applied the effect, now check if they're still concentrating.
                        isEffectValid = appliedBy.concentrating?.instanceId === effect.instanceId;
                    }

                    if (!isEffectValid && storedCreature.effects) {
                        // The effect is no longer valid! Remove it.
                        const newEffects = copyState(storedCreature.effects, { [effectKey]: undefined });
                        storedCreature = copyState(storedCreature, {
                            effects:
                                newEffects == null || Object.values(newEffects).length === 0 ? undefined : newEffects,
                        });
                    }
                }
            }
        }
    }
    return storedCreature;
}
